import React from "react";

import { WrapperDiv, Option } from "../../../Modal/Modal.style";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { FilterDiv, CalendarDiv } from "./Sidebar.style";
import Calendar from "react-calendar";
// import './dropdown.css';
import "react-calendar/dist/Calendar.css";

const DropDown = (props) => {
  const { placeholder, options, name, setFormData, formData, variable, width } =
    props;
  return (
    <WrapperDiv style={{ width: width ? "100%" : "48%" }} id="sel">
      <DropdownButton
        className="dropdown "
        title={formData[name]?.name || placeholder}
      >
        {options.length &&
          options.map((item) => {
            return (
              <Dropdown.Item>
                <Option
                  onClick={() =>
                    setFormData({ ...formData, [name]: { ...item } })
                  }
                >
                  {item?.name}
                  <div
                    style={
                      formData[name]?.name === item?.name
                        ? { backgroundColor: "#5CE0D2" }
                        : {}
                    }
                    className="fill"
                  ></div>
                </Option>
              </Dropdown.Item>
            );
          })}

        {/* //   </Dropdown.Item>
                ))} */}
      </DropdownButton>
    </WrapperDiv>
  );
};

DropDown.defaultProps = {
  placeholder: "Temp",
  formData: { name: "" },
  options: [],
  variable: "",
  width: false,
};

export default DropDown;

export const FilterComponent = (props) => {
  const { name, formData, text, options, placeholder, setFormData, cond } =
    props;
  // console.log(formData)
  // console.log(options);

  return (
    <WrapperDiv style={{ marginRight: "15px" }} id="sel">
      <FilterDiv>
        <DropdownButton
          className="dropdown "
          title={formData[name]?.name || placeholder}
        >
          {options.length &&
            options.map((item) => {
              return (
                <Dropdown.Item>
                  <Option
                    onClick={() => setFormData({ ...formData, [name]: item })}
                  >
                    {item.name}
                    <div
                      style={
                        formData[name]?.[cond] === item?.[cond]
                          ? { backgroundColor: "#5CE0D2" }
                          : {}
                      }
                      className="fill"
                    ></div>
                  </Option>
                </Dropdown.Item>
              );
            })}

          {/* //   </Dropdown.Item>
       ))} */}
        </DropdownButton>
      </FilterDiv>
    </WrapperDiv>
  );
};

FilterComponent.defaultProps = {
  placeholder: "Subjects",
  options: [],
  formData: { name: "" },
  cond: "sectionId",
};

export const CalenderComponent = (props) => {
  const { formData, name, placeholder, setFormData } = props;
  const handleOnChange = (data) => {
    let m = data.getMonth();
    let d = data.getDate();
    let y = data.getFullYear();

    setFormData({
      ...formData,
      [name]: new Date(y, m, d).toLocaleDateString(),
    });
  };

  return (
    // <WrapperDiv>
    <CalendarDiv>
      <DropdownButton title={formData[name] || placeholder}>
        <Calendar onChange={handleOnChange} />
      </DropdownButton>
    </CalendarDiv>
    // {/* </WrapperDiv> */}
  );
};

export const Status = (props) => {
  const { formData, setFormData, name, placeholder, options } = props;
  return (
    <WrapperDiv style={{ marginRight: "15px" }} id="sel">
      <FilterDiv>
        <DropdownButton
          className="dropdown "
          title={formData[name] || placeholder}
        >
          {options.length &&
            options.map((item) => {
              return (
                <Dropdown.Item>
                  <Option
                    onClick={() => setFormData({ ...formData, [name]: item })}
                  >
                    {item}
                    <div
                      style={
                        formData[name] === item
                          ? { backgroundColor: "#5CE0D2" }
                          : {}
                      }
                      className="fill"
                    ></div>
                  </Option>
                </Dropdown.Item>
              );
            })}

          {/* //   </Dropdown.Item>
     ))} */}
        </DropdownButton>
      </FilterDiv>
    </WrapperDiv>
  );
};
