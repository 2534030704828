import React, { useState, useEffect } from "react";
import {
  PracticeAssessmentContainer,
  PracticeAssessmentHeader,
  PracticeAssessmentContent,
  PracticeAssessmentTable,
} from "../PracticeAssessment/practiceAssesment.style";
import { AnalyticsTabs } from "../AnalyticsHelper/AnalyticsHelper.style";
import AnalyticsContainer3 from "../AnalyticsHelper/AnalyticsContainer3";
import HomeWorkReport from "../HomeworkReport/HomeWorkReport";
import { Status } from "../AnalyticsHelper/Sidebar/DropDown";
import {
  HomeWorkHeader,
  HomeWorkData,
} from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader";
import AssessmentReport from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTable";
import { FilterComponent } from "../AnalyticsHelper/Sidebar/DropDown";
import Sidebar from "../AnalyticsHelper/Sidebar/Sidebar";
import HeaderPage from "../../Header/HeaderPage";
import { Line } from "../../Dashboard/Dashboard.style";
import { useLocation } from "react-router-dom";
import {
  Homework,
  HomeworkFilterbySection,
  HomeworkFilterbySubject,
  HomeworkFilterbyChapter,
  HomeworkFilterByStudent,
} from "../../../Api/HomeWorkAnalytics";
import { useAuth } from "../../../Auth/Auth";
import axios from "axios";
function HomeWork() {
  const search = useLocation().search;
  const { readCookie } = useAuth();
  const schoolName = new URLSearchParams(search).get("schoolName");
  const schoolId = new URLSearchParams(search).get("schoolId");
  const [homeWorkReport, setHomeWorkReport] = useState([]);
  const [submissionReport, setSubmissionReport] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [student, setStudent] = useState("");
  const [studentGrade, setStudentGrade] = useState("");
  const [grade, setGrade] = useState([]);
  // const activeFilter=React.useRef();
  const [filterByGrade, setFilterByGrade] = useState({ grade: "" });
  const [allTabData, setAllTabData] = useState([]);
  const [totalHomeWorks, setTotalHomeWorks] = useState("");
  const [homeWorkReportData, setHomeWorkReportData] = useState([]);
  const [loaders, setLoaders] = useState([false, false]);
  const [FilterByStatus, setFilterByStatus] = useState({ status: "" });

  const [show, setShow] = useState(false);
  const [overView, setOverView] = useState([]);
  const [status, setStatus] = useState([]);
  const activeFilter = React.useRef("");
  const [formData, setFormData] = useState({
    class: "",
    subject: "",
    chapter: "",
    topic: "",
  });
  const getOverViewData = async () => {
    let str;
    if (student) {
      str = HomeworkFilterByStudent.overView(schoolId, studentGrade, student);
    } else if (formData.chapter) {
      activeFilter.current = "chapter";
      str = HomeworkFilterbyChapter.overView(
        schoolId,
        formData.class.name,
        formData.subject.name,
        formData.chapter.name
      );
    } else if (formData.subject) {
      activeFilter.current = "subject";
      str = HomeworkFilterbySubject.overView(
        schoolId,
        formData.class.name,
        formData.subject.name
      );
    } else if (formData.class) {
      activeFilter.current = "section";
      str = HomeworkFilterbySection.overView(schoolId, formData.class.name);
    } else {
      str = Homework.overView(schoolId);
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics.length
          ? res.data.data.analytics[0]
          : [];
        setOverView(() => [
          { heading: "HomeWorks", count: data.homeworks || 0 },
          {
            heading: "Average Students Done",
            count: `${data.submitted || 0}%`,
          },
          {
            heading: "Average Students Pending",
            count: `${data.pending || 0}%`,
          },
        ]);
      })
      .catch((err) => console.log(err));
  };
  //console.log(activeFilter);
  const getStatus = async () => {
    loaders[0] = true;
    setLoaders(() => [...loaders]);
    let str;
    if (student) {
      str = HomeworkFilterByStudent.status(schoolId, studentGrade, student);
    } else if (formData.chapter) {
      activeFilter.current = "chapter";
      str = HomeworkFilterbyChapter.status(
        schoolId,
        formData.class.name,
        formData.subject.name,
        formData.chapter.name
      );
    } else if (formData.subject) {
      activeFilter.current = "subject";
      str = HomeworkFilterbySubject.status(
        schoolId,
        formData.class.name,
        formData.subject.name
      );
    } else if (formData.class) {
      activeFilter.current = "section";
      str = HomeworkFilterbySection.status(schoolId, formData.class.name);
    } else {
      str = Homework.status(schoolId);
    }

    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        //console.log(res);
        let data = res.data.data.analytics;
        let temp = 0;
        setStatus(
          data.map((item) => {
            temp += item.homeworks;
            return {
              name:
                activeFilter.current === ""
                  ? `${item.content.grade}-${item.content.section}`
                  : item.content,
              Done: item.submitted === 0 ? "0" : item.submitted,
              Pending: item.pending === 0 ? "0" : item.pending,
            };
          })
        );
        setTotalHomeWorks(temp);
      })
      .catch((err) => console.log(err));
    loaders[0] = false;
    setLoaders([...loaders]);
  };
  //console.log(formData);
  const handleStudent = (dta) => {
    //console.log(dta);
    setStudent(dta._id);
    setStudentGrade(`${dta.grade}-${dta.section}`);
  };
  const getReport = async () => {
    let str;
    loaders[1] = true;
    setLoaders([...loaders]);
    if (student) {
      str = HomeworkFilterByStudent.report(schoolId, studentGrade, student);
    } else if (formData.chapter) {
      activeFilter.current = "chapter";
      str = HomeworkFilterbyChapter.report(
        schoolId,
        formData.class.name,
        formData.subject.name,
        formData.chapter.name
      );
    } else if (formData.subject) {
      activeFilter.current = "subject";
      str = HomeworkFilterbySubject.report(
        schoolId,
        formData.class.name,
        formData.subject.name
      );
    } else if (formData.class) {
      activeFilter.current = "section";
      str = HomeworkFilterbySection.report(schoolId, formData.class.name);
    } else {
      str = Homework.report(schoolId);
    }

    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        // console.log(data);
        setHomeWorkReport(data);
        setHomeWorkReportData([...data]);
      })
      .catch((err) => console.log(err));
    loaders[1] = false;
    setLoaders([...loaders]);
  };
  const getSubmissionReport = () => {
    let str;
    if (student) {
      str = HomeworkFilterByStudent.submissionReport(
        schoolId,
        studentGrade,
        student
      );
    } else if (formData.chapter) {
      activeFilter.current = "chapter";
      str = HomeworkFilterbyChapter.submissionReport(
        schoolId,
        formData.class.name,
        formData.subject.name,
        formData.chapter.name
      );
    } else if (formData.subject) {
      activeFilter.current = "subject";
      str = HomeworkFilterbySubject.submissionReport(
        schoolId,
        formData.class.name,
        formData.subject.name
      );
    } else if (formData.class) {
      activeFilter.current = "section";
      str = HomeworkFilterbySection.submissionReport(
        schoolId,
        formData.class.name
      );
    } else {
      str = Homework.submissionReport(schoolId);
    }
    axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        // console.log(data);
        let temp = [];

        temp = data.map((item, idx) => {
          return {
            sno: idx + 1,
            grade: `${item._id.grade}-${item._id.section}`,
            studentName: item?._id?.student?.name,
            totalHomeworks: item.homeworks,
            done: item.completed,
            pending: `${item.homeworks - item.completed}`,
          };
        });
        setSubmissionReport([...temp]);
        setAllTabData([...temp]);
        //console.log(data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    // getOverViewData()
    const getData = async () => {
      if (student) {
        activeFilter.current = "student";
      } else if (formData.chapter) {
        activeFilter.current = "chapter";
      } else if (formData.subject) {
        activeFilter.current = "subject";
      } else if (formData.class) {
        activeFilter.current = "class";
      } else {
        activeFilter.current = "";
      }

      await getOverViewData();
      await getStatus();
      await getReport();
      await getSubmissionReport();
    };

    getData();
  }, [formData, student]);

  // console.log(formData);
  useEffect(() => {
    if (filterByGrade.grade.name) {
      let temp = allTabData.filter(
        (item) => item.grade === filterByGrade.grade.name
      );
      setSubmissionReport(
        temp.map((item, idx) => {
          return { ...item, sno: idx + 1 };
        })
      );
    }
  }, [filterByGrade]);

  useEffect(() => {
    if (FilterByStatus.status) {
      // console.log(homeWorkReport);
      let dt = FilterByStatus.status;
      let temp = homeWorkReportData;
      if (dt === "Pending") {
        temp = temp.filter((item) => item?.pending >= 1);
      } else {
        temp = temp.filter((item) => item?.pending === 0);
      }
      setHomeWorkReport([...temp]);
    }
  }, [FilterByStatus]);

  const leftHeader = () => {
    return (
      <span
        style={{
          fontWeight: "600",
          padding: "5px",
          margin: "30px 0px",
          background:
            "linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)",
        }}
      >
        Homework
      </span>
    );
  };

  return (
    <PracticeAssessmentContainer>
      <PracticeAssessmentHeader>
        <HeaderPage
          currentPage="/school"
          leftHeader={leftHeader()}
          school="true"
          title={schoolName}
          profilePic={false}
        />
        <Line style={{ marginBottom: "30px" }} />
        <div className="tabContainer">
          {overView.length &&
            overView.map((item) => {
              return (
                <AnalyticsTabs style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {item.heading}
                  </div>
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.count}
                  </h5>
                </AnalyticsTabs>
              );
            })}
        </div>
      </PracticeAssessmentHeader>
      <PracticeAssessmentContent style={{ marginTop: "50px" }}>
        <AnalyticsContainer3
          colors={["#F28684", "#59E0B0"]}
          headerText="HomeWork Report of All Classes"
          subHeading="Total Homeworks-"
          data={status}
          dataField={["Done", "Pending"]}
          yAxis="Student"
          total={totalHomeWorks}
          loading={loaders[0]}
        />

        <div style={{ width: "48%", position: "relative" }}>
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "0",
              transform: "translateY(-50%)",
              marginLeft: "50px",
              marginBottom: "10px",
            }}
          >
            {/* <FilterComponent/>
                    <FilterComponent placeholder="Chapter"/> */}
            {/* <FilterComponent placeholder="Status" /> */}
            <Status
              placeholder="Status"
              options={["Done", "Pending"]}
              name="status"
              formData={FilterByStatus}
              setFormData={setFilterByStatus}
            />
          </div>

          <HomeWorkReport
            data={homeWorkReport}
            width="100%"
            loading={loaders[1]}
          />
        </div>
      </PracticeAssessmentContent>
      <PracticeAssessmentContent></PracticeAssessmentContent>
      <PracticeAssessmentTable>
        <div
          style={{ display: "flex", marginLeft: "50px", marginBottom: "10px" }}
        >
          {/* <FilterComponent placeholder="Name"/>
                    <FilterComponent placeholder="Subject"/> */}
          <FilterComponent
            name="grade"
            formData={filterByGrade}
            setFormData={setFilterByGrade}
            options={grade}
            placeholder="Grade"
          />
        </div>
        <AssessmentReport
          tabContent={submissionReport}
          tabHeader={HomeWorkHeader}
        />
      </PracticeAssessmentTable>
      <Sidebar
        handleStudent={handleStudent}
        setGrade={setGrade}
        activeStudent={student}
        show={show}
        setSubjectData={setSubjectData}
        formData={formData}
        setFormData={setFormData}
        schoolId={schoolId}
        setShow={setShow}
      />
    </PracticeAssessmentContainer>
  );
}

export default HomeWork;
