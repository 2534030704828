import React, { useState, useEffect } from "react";
import TableComponent from "../../Table/Table";
import { SubjectTeachers } from "../../Table/TableHeaders";
import { CommonHeader } from "./TabComponent.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../../Header/Headerfeatures";
import { Line } from "../Dashboard.style";
import { useNavigate, useParams } from "react-router-dom";
// import AssignSubjectComponent from '../../Modal/AssignSubjectsComponent';
import FilterComponent from "../../Modal/FilterComponent";
import {
  SubjectFilter,
  AssignSubject,
} from "../../RenderDetailsData/RenderDetailsData";
import { schools, subjectTeachers, Teacher } from "../../../Api/api";
import AddModalComponent from "../../Modal/AddModalComponent";

import axios from "axios";

import { useAuth } from "../../../Auth/Auth";

function SubTeachersComponent() {
  const [modal, setModal] = useState(false);
  const { schoolId } = useParams();
  const { readCookie } = useAuth();
  const [popupfilter, setPopUpFilter] = useState(false);
  const [grades, setGrades] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [tabContent, setTabContent] = useState([]);
  const [formData2, setFormData2] = useState({
    subject: "",
    teacher: "",
  });

  // const [subject,setSubject]=useState([]);
  const [staff, setStaffs] = useState([]);
  const [formData, setFormData] = useState({
    class: "",
    section: "",
  });
  const [label, setLabel] = useState([]);
  const [label2, setLabel2] = useState([]);
  useEffect(() => {
    axios
      .get(schools.getClasses(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setGrades(res.data.data.grades);
        setLabel(SubjectFilter(res.data.data.grades, []));
      })
      .catch((err) => console.log(err));
    axios
      .get(Teacher.getStaffBySchool(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setStaffs(res.data.data.staffs))
      .catch((err) => console.log(err));
  }, []);

  const handleCreate = () => {
    let data = {
      sections: [
        {
          sectionId: teachers[0].sectionId,
          subjects: [formData2.subject],
        },
      ],
    };
    console.log(data);

    axios
      .put(subjectTeachers.assignSubjects(formData2.teacher), data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (teachers.length) {
      setLabel2(AssignSubject(teachers, staff));
    }
  }, [teachers]);

  const handleApply = () => {
    axios
      .get(
        subjectTeachers.getSubjectTeachers(
          formData.class.split("-")[0],
          formData.section,
          schoolId
        ),
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setTeachers(res.data.data.subjects);
        setPopUpFilter(false);
      })
      .catch((err) => console.log(err));
  };
  // console.log(grades);
  useEffect(() => {
    if (teachers.length) {
      setTabContent(
        teachers.map((item, idx) => {
          return {
            sno: idx + 1,
            subject: item.subject,
            teacher: item.teacher?.name,
          };
        })
      );
    }
  }, [teachers]);

  useEffect(() => {
    if (formData.class) {
      let temp = grades.find((item) => item.uniqueGrade === formData.class);
      setLabel(SubjectFilter(grades, temp.sections));
      // console.log(grades);
    }
  }, [formData.class]);
  // console.log(label);
  // console.log(formData);

  return (
    <div>
      <CommonHeader>
        <div
          onClick={() => setModal(true)}
          className="iconContainer"
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <AddBoxOutlinedIcon style={{ marginRight: "7px" }} /> Assign
        </div>
        <Headerfeatures
          exportcsv={false}
          sort={false}
          setPopUpFilter={setPopUpFilter}
          filter
          viewBy={false}
        />
      </CommonHeader>
      <Line />
      <TableComponent tabHeader={SubjectTeachers} tabContent={tabContent} />
      {modal && (
        <AddModalComponent
          formData={formData2}
          setFormData={setFormData2}
          handleCreate={handleCreate}
          label={label2}
          headerText="Assign Subject"
          setModal={setModal}
        />
      )}
      {popupfilter && (
        <FilterComponent
          formData={formData}
          setFormData={setFormData}
          setPopUpFilter={setPopUpFilter}
          handleApply={handleApply}
          isLabel={false}
          data={label}
        />
      )}
    </div>
  );
}

export default SubTeachersComponent;
