import React, { useEffect, useState } from "react";
import {
  ModalWrapper,
  ModalContainer,
  AddSchoolWrapper,
  AddSchoolHeader,
  AddSchoolContainer,
} from "../Dashboard.style";
import { ButtonWrapper, Button } from "../Dashboard.style";
import { Input } from "../../Input/InputComponent";
import { courses, schools } from "../../../Api/api";
import axios from "axios";
import { useAuth } from "../../../Auth/Auth";
import { DropDown } from "../../Input/InputComponent";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
// import FormControl from '@mui/material/FormControl';
import Select from "@mui/material/Select";
// import { schools } from "../../../Api/api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddSchoolModal(props) {
  const { headersText, setModal, data, render, setRender } = props;
  const { readCookie, DisplaySnackbar } = useAuth();
  const [allCountry, setAllCountry] = useState([]);
  const [formData, setFormData] = useState({
    country: "",
    board: "",
    class: [],
    name: "",
    state: "",
    city: "",
    branch: "",
    teacherCount: "",
    studentCount: "",
    expiryDate: "",
  });
  const [boards, SetBoards] = useState({});

  const [classes, setClasses] = useState([]);

  const [classesId, setClassesId] = useState([]);

  // console.log(data);
  useEffect(() => {
    axios
      .get(courses.getAllCountry, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setAllCountry({
          name: "country",
          options: [
            { name: "Select Country", value: "select Country" },
            ...res.data.data.countries.map((item) => {
              return { name: item.name, value: item._id };
            }),
          ],
        });
      })
      .catch((err) => console.log(err));

    if (headersText === "Edit School") {
      setFormData({
        name: data?.name,
        state: data?.state,
        studentCount: data?.studentCounts,
        teacherCount: data?.teacherCounts,
        city: data?.city,
        branch: data?.branch,
        country: data?.country,
        board: data?.board,
        expiryDate: new Date(data?.expiryDate).toISOString().slice(0, 10),
      });
    }
  }, [data]);
  //console.log(formData);

  const handleOnChange = (e) => {
    if (e.target.name === "class") {
      setFormData({ ...formData, class: [...e.target.value] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  //console.log(formData);

  useEffect(() => {
    if (formData.country) {
      axios
        .get(courses.getCountryBoard(formData.country), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          SetBoards({
            name: "board",
            options: [
              { name: "select Board", value: "select" },
              ...res.data.data.country.boards.map((item) => {
                return { name: item.name, value: item._id };
              }),
            ],
          });
        })
        .catch((err) => console.log(err));
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.board) {
      axios
        .get(courses.getBoardContent(formData.board), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setClasses(
            res.data.data.board.courses.map((item) => {
              return { name: item.name, value: item._id };
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [formData.board]);

  const handleClassesId = (id) => {
    if (classesId.includes(id)) {
      setClassesId(classesId.filter((item) => item !== id));
    } else {
      setClassesId([...classesId, id]);
    }
  };

  // console.log(classesId);

  const handleCreate = () => {
    if (headersText === "Edit School") {
      let dt = {
        name: formData.name,
        state: formData.state,
        city: formData.city,
        branch: formData.branch,
        teacherCount: formData.teacherCount,
        studentCount: formData.studentCount,
        expiryDate: new Date(formData.expiryDate),
      };
      axios
        .patch(schools.updateSchools(data._id), dt, {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            DisplaySnackbar("School Updated", "success");
            setModal(false);
            setRender(!render);
          }
        })
        .catch((err) => {
          if (err.response) {
            DisplaySnackbar(err.response.data.message, "error");
          }
          console.log(err);
        });
    } else {
      let bdName = boards.options.find((item) => item.value === formData.board);
      let cnName = allCountry.options.find(
        (item) => item.value === formData.country
      );
      // console.log(bdName,cnName);
      let data = {
        name: formData.name,
        country: cnName.name,
        board: bdName.name,
        state: formData.state,
        city: formData.city,
        branch: formData.branch,
        teacherCount: formData.teacherCount,
        studentCount: formData.studentCount,
        courses: classesId,
        expiryDate: new Date(formData.expiryDate),
      };
      axios
        .post(schools.registerSchool, data, {
          headers: {
            token: `${readCookie("token")}`,
          },
          validateStatus: () => true,
        })
        .then((res) => {
          if (res.data.status !== "success") {
            DisplaySnackbar(res.data.message, "error");
            return;
          }
          props.addSchool(res.data.data.school);
          DisplaySnackbar("School Created", "success");
          setModal(false);
          setRender(!render);
        })
        .catch((err) => {
          DisplaySnackbar(err.response.data.message, "error");
        });
    }
  };
  return (
    <ModalWrapper>
      <ModalContainer>
        <AddSchoolWrapper>
          <AddSchoolHeader>
            <h2 style={{ margin: "0px" }}>{headersText}</h2>
            <h2
              style={{ margin: "0px", cursor: "pointer" }}
              onClick={() => setModal(false)}
            >
              ×
            </h2>
          </AddSchoolHeader>
          <AddSchoolContainer>
            <div>
              <label>SCHOOL NAME</label>
              <Input
                name="name"
                handleOnChange={handleOnChange}
                value={formData.name}
                style={{ fontSize: ".9rem" }}
                color="grey"
                placeholder="Enter School name"
                height="36px"
              />
            </div>
            <div className="formDiv">
              <div>
                <label>Country</label>

                {headersText === "Edit School" ? (
                  <Input
                    name="state"
                    disabled={true}
                    value={formData.country}
                    style={{ fontSize: ".9rem" }}
                    color="grey"
                    placeholder="Enter state name"
                    height="36px"
                  />
                ) : (
                  <DropDown
                    data={allCountry}
                    value={formData.country}
                    handleOnChange={handleOnChange}
                    style={{ fontSize: ".9rem" }}
                    color="grey"
                    placeholder="Enter School Name"
                    height="36px"
                  />
                )}
              </div>
              <div>
                <label>STATE</label>
                <Input
                  name="state"
                  handleOnChange={handleOnChange}
                  value={formData.state}
                  style={{ fontSize: ".9rem" }}
                  color="grey"
                  placeholder="Enter state name"
                  height="36px"
                />
              </div>
            </div>
            <div className="formDiv">
              <div>
                <label>CITY</label>
                <Input
                  name="city"
                  handleOnChange={handleOnChange}
                  value={formData.city}
                  style={{ fontSize: ".9rem" }}
                  color="grey"
                  placeholder="Enter your city"
                  height="36px"
                />
              </div>
              <div>
                <label>BOARD</label>
                {headersText === "Edit School" ? (
                  <Input
                    name="city"
                    value={formData.board}
                    disabled={true}
                    style={{ fontSize: ".9rem" }}
                    color="grey"
                    placeholder="Enter your city"
                    height="36px"
                  />
                ) : (
                  <DropDown
                    handleOnChange={handleOnChange}
                    value={formData.board}
                    data={boards}
                    style={{ fontSize: ".9rem" }}
                    color="grey"
                    placeholder="Enter Board"
                    height="36px"
                  />
                )}
              </div>
            </div>

            <div className="formDiv">
              <div>
                <label>BRANCH</label>
                <Input
                  name="branch"
                  value={formData.branch}
                  handleOnChange={handleOnChange}
                  style={{ fontSize: ".9rem" }}
                  color="grey"
                  placeholder="Enter school's branch"
                  height="36px"
                />
              </div>
              <div>
                <label>STUDENT'S COUNT</label>
                <Input
                  name="studentCount"
                  type="number"
                  value={formData.studentCount}
                  handleOnChange={handleOnChange}
                  style={{ fontSize: ".9rem" }}
                  color="grey"
                  placeholder="Enter the student's count"
                  height="36px"
                />
              </div>
            </div>
            <div className="formDiv">
              <div>
                <label>TEACHER'S COUNT</label>
                <Input
                  name="teacherCount"
                  type="number"
                  value={formData.teacherCount}
                  handleOnChange={handleOnChange}
                  style={{ fontSize: ".9rem" }}
                  color="grey"
                  placeholder="Enter the teacher's count"
                  height="36px"
                />
              </div>
              <div>
                <label>Expiry Date</label>
                <Input
                  name="expiryDate"
                  handleOnChange={handleOnChange}
                  value={formData.expiryDate}
                  style={{ fontSize: ".9rem" }}
                  color="grey"
                  type="date"
                  placeholder="dd-mm-yyyy"
                  height="36px"
                />
              </div>
            </div>
            {headersText !== "Edit School" && (
              <>
                <div>
                  {" "}
                  <label>Assign Classes</label>{" "}
                </div>

                <div className="formDiv">
                  <Select
                    multiple
                    displayEmpty
                    value={formData.class}
                    onChange={handleOnChange}
                    name="class"
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Placeholder</em>;
                      }

                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      <em>Assign Classes</em>
                    </MenuItem>
                    {classes.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.name}
                        name={item.name}
                        onClick={() => handleClassesId(item.value)}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </>
            )}
          </AddSchoolContainer>
          <ButtonWrapper>
            <Button onClick={() => setModal(false)}>CLEAR All</Button>
            <Button onClick={() => handleCreate()}>
              {headersText === "Edit School" ? "EDIT" : "CREATE"}
            </Button>
          </ButtonWrapper>
        </AddSchoolWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default AddSchoolModal;
