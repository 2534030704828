import React,{useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {TablePage} from './AnalyticsTable.style';
import { PracticeAssesment } from './AnalyticsTableHeader';
// import Paper from '@mui/material/Paper';

function AssessmentReport(props) {
    const {tabHeader,tabContent,handleOnClick,headerText="practice Assessment"}=props;
    // const [page,setPage]=useState(0);
    // const [rowsPerPage,setRowsPerPage]=useState(10);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    return (
      <TablePage>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <h2>{headerText}</h2>
          
            <div style={{display:"flex",alignItems:"center"}}>
              <TablePagination 
              rowsPerPageOptions={[5,10,]}
              component="div"
              count={tabContent.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              
              />
              </div>
          
          </div>
           <TableContainer style={{overflowX:"auto",maxWidth:"100%",margin:"0 auto"}}>
           
                <Table style={{boxSizing:"border-box"}} stickyHeader aria-label="sticky table">
                    <TableHead  style={{backgroundColor:"transparent !important"}}>
                        <TableRow>
                            {
                                
                                    tabHeader.map((column)=>(
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth:column.minWidth,backgroundColor:column.backgroundColor,color:column.color,fontSize:column.fontSize,fontWeight:"600",borderBottom:column.borderBottom}}

                                        >
                                            {column.label}

                                        </TableCell>
                                    ))
                                
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tabContent.slice(rowsPerPage*page,rowsPerPage*(page+1)).map((row,index)=>{
                            return(
                                   <TableRow className={`${index%2===0?"hover":""}`} style={{fontWeight:"700"}} onClick={(e)=>handleOnClick(row)} tabIndex={-1}>
                                       {tabHeader.map((column)=>{
                                           const value=row[column.id];
                                           return(
                                               <TableCell style={{fontWeight:"700"}}>
                                                   
                                                       <div>{value}</div>
                                                   
                                               </TableCell>
                                           )
                                       })
                                           
                                       }
                                    </TableRow>

                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer> 
    </TablePage>

      
    )
}

AssessmentReport.defaultProps={
    tabHeader:[],
    tabContent:[],
    handleOnClick:()=>{}
}
export default AssessmentReport;
