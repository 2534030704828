import React from 'react'

function ArrowComponent() {
    return (
        <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C18.6274 22 24 17.0751 24 11C24 4.92487 18.6274 0 12 0C5.37258 0 0 4.92487 0 11C0 17.0751 5.37258 22 12 22ZM17.5607 10.0277L13.0607 5.90273C12.4749 5.36576 11.5251 5.36576 10.9393 5.90273C10.3536 6.4397 10.3536 7.3103 10.9393 7.84727L12.8787 9.625L7.5 9.625C6.67157 9.625 6 10.2406 6 11C6 11.7594 6.67157 12.375 7.5 12.375H12.8787L10.9393 14.1527C10.3536 14.6897 10.3536 15.5603 10.9393 16.0973C11.5251 16.6342 12.4749 16.6342 13.0607 16.0973L17.5607 11.9723C18.1464 11.4353 18.1464 10.5647 17.5607 10.0277Z" fill="white"/>
</svg>

    )
}

export default ArrowComponent
