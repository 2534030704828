import styled from "styled-components";

export const CardContainer = styled.div`
  //

  ${({ width }) =>
    width ? `width:${width};` : `flex:${({ flex }) => (flex ? flex : "0.28")};`}

  padding: 15px;
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "110px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: #ededed;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  box-shadow: 1px 1px 3px #c7c6c1;
  border: 1px solid #f5f5f5;
  cursor: pointer;
  transition: 0.4s;
  max-width: 100%;
  ${({ ht }) => ht && `height:100%`};

  &:hover {
    ${({ hoveranimation }) =>
      hoveranimation &&
      `
    transform:scale(1.1);
    `}
    ${({ afterHover }) =>
      afterHover &&
      `
    border:1px solid #5CE0D2;
    `}
  }
  .onHover {
    display: flex;
    align-items: center;
    transition: 0.5s;
    &:hover {
      font-size: 1.2rem;
    }
  }

  ${({ disable }) => disable && `opacity:0.6;`}
`;

export const CardHeading = styled.div`
  font-weight: 800;
  font-size: 1.3rem;
`;
export const CardContent = styled.div``;
