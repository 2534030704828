
import React from 'react'
import {Container} from './HomeWorkReport.style';
import AnanlyticsSvgComponent from '../../Svg/Ananlytics.component';
import ClockComponent from '../../Svg/Clock.component';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function HomeWorkCard(props) {
    const {homework,subject,classes,chapter,showClass,heading,section,date,time,progress}=props;
    const getPathColor=(val)=>{
        if(val<=50)
        return "#FF7E7E";
        else if(val>50&&val<=80)
        return "#4BDF3F"
        else 
        return `#56E58F`
    }
    return (
        <Container>
            <div className='firstDiv'>
                <AnanlyticsSvgComponent/>
            </div>
            <div className='secondDiv'>
                <div style={{fontSize:"16px",fontWeight:"600"}}>{heading}</div>
                    <p>{subject}</p>
                    <p>{chapter}</p>
                    {showClass&&<p>{classes}-{section}</p>}
                    <div className='time'>
                   <div> <ClockComponent/> {date}
                   </div>
                   <div>
                   <ClockComponent/> {time||"09:00"}
                   </div>

                    </div>
            </div>
            <div className='thirdDiv'>
                <div>
                <CircularProgressbar styles={buildStyles({pathColor:getPathColor(progress) ,
    textColor: 'black',
    trailColor: '#d6d6d6',
    backgroundColor: '#f88',})} value={progress} text={`${progress}%`}/>
                </div>
            </div>
        </Container>
    )
}

HomeWorkCard.defaultProps={
    homework:"Homework Name",
    subject:"Subject Name",
    chapter:"Chapter Name",
    classes:"Class VI",
    showClass:true,
    section:"",
    date:"",
    time:"",
    progress:66
}
export default HomeWorkCard
