import styled from "styled-components";

export const DropZoneContainer=styled.div`


`
export const Button=styled.button`


padding:5px 15px;
background-color:transparent;

border:1px solid grey;
display:flex;
// background-color:cyan;
cursor:pointer;
border-radius:5px;
margin-right:6px;
background-color:grey;
color:#fff;

`