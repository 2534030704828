import React, { useState, useEffect } from "react";
import {
  TeacherPageContainer,
  TeacherPageContent,
} from "./TeacherProfile.style";
// import AddStaffComponent from "../Modal/AddStaffComponent";
import { useNavigate } from "react-router-dom";
import {
  DashboardHeaderWrapper,
  DashboardHeader,
  Line,
} from "../Dashboard/Dashboard.style";
import InputComponent from "../Input/InputComponent";
import Loader from "../Loading/Loader";
// import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import Switch from "@material-ui/core/Switch";
import ProfileImg from "../../images/profileImg.svg";
import { TablePagination } from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../Header/Headerfeatures";
import TableComponent from "../Table/Table";
import { AllMembersTeacher } from "../Table/TableHeaders";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import AddModalComponent from "../Modal/AddModalComponent";
import DeleteIcon from "@material-ui/icons/Delete";
// import DocumentLoading from "../Loading/ExportLoader";
// import Loader from "../Loading/Loader";
import {
  addStaffForm,
  LinkClass,
} from "../RenderDetailsData/RenderDetailsData";
import { Teacher, schools, ExportData } from "../../Api/api";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import exportFromJSON from "export-from-json";
import { DocumentLoader } from "../Loading/Loader.style";

const sortField = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Role",
    field: "role",
  },
  {
    label: "Signup Date",
    field: "signUpDate",
  },
  {
    label: "Expiry Date",
    field: "expiryDate",
  },
];

function TeacherPage() {
  const navigation = useNavigate();
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { readCookie, DisplaySnackbar } = useAuth();
  const [exportingData, setExportingData] = useState(false);
  const [loading, setLoading] = useState(false);

  const [staffs, setStaffs] = useState([]);
  const [tabContent, setTabContent] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [sortBy, setSortBy] = useState([]);
  const [label2, setLabel2] = useState([]);
  const [search, setSearch] = useState("");
  const [render, setRender] = useState(false);
  const [formData, setFormData] = useState({
    schoolName: "",
    expiryDate: "",
    teacherName: "",
    role: "",
    email: "",
    contact: "",
    password: "",
    class: "",
    section: "",
    subject: "",
  });

  const [classes, setClasses] = useState([]);

  const [sectionsId, setSectionsId] = useState([]);

  const [label, setLabel] = useState([]);

  const handleOnClick = (data, event, elem) => {
    navigation(
      `/dashboard/${data.schoolName}/${data.schoolId}/staff/${data._id}`
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };

  useEffect(() => {
    if (formData.schoolName) {
      axios
        .get(schools.getClasses(formData.schoolName), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setClasses(res.data.data.grades);
          setLabel2(LinkClass(res.data.data.grades, [], []));
        })
        .catch((err) => console.log(err));
    }
  }, [formData.schoolName]);

  useEffect(() => {
    if (formData.class) {
      let temp = classes.find((item) => item._id === formData.class);

      setLabel2(LinkClass(classes, temp.sections, temp.subjects));
    }
  }, [formData.class]);

  const handleMultiSelect = (type, id) => {
    if (type === "section") {
      if (sectionsId.includes(id)) {
        setSectionsId(sectionsId.filter((item) => item !== id));
      } else {
        setSectionsId([...sectionsId, id]);
      }
    }
  };

  const handleCreate = () => {
    const data = {
      schoolId: formData.schoolName,
      name: formData.teacherName,
      role: formData.role,
      expiryDate: new Date(formData.expiryDate),
      email: formData.email,
      contact: formData.contact,
      password: formData.password,
      sections: sectionsId.map((item, idx) => {
        return { sectionId: item, subjects: formData.subject };
      }),
    };
    axios
      .post(Teacher.addTeacher, data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        DisplaySnackbar("Staff Added", "success");
        setModal(false);
        setRender(!render);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (!modal) {
      setFormData({
        schoolName: "",
        expiryDate: "",
        teacherName: "",
        role: "",
        email: "",
        contact: "",
        password: "",
        class: "",
        section: "",
        subject: "",
      });
    }
  }, [modal]);
  const getTeacher = () => {
    axios
      .get(Teacher.getTeacher(page + 1, rowsPerPage), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setStaffs(res.data.data.staffs);
      })
      .catch((err) => console.log(err));
    axios
      .get(schools.getSchoolsLmt(150), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setLabel(addStaffForm(res.data.data.schools));
        setLabel2(LinkClass());
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getTeacher();

    axios
      .get(Teacher.getStaffCount, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setTotalCount(res.data.data.totalStaffs);
        }
      })
      .catch((err) => console.log(err));
  }, [render]);

  const handleSort = (field, type) => {
    let find = sortBy.find((item) => item.field === field);
    if (find) {
      setSortBy(
        sortBy.map((item) =>
          item.field === field ? { field: field, type: type } : item
        )
      );
    } else {
      setSortBy([...sortBy, { field: field, type: type }]);
    }
    let temp = tabContent;

    if (type === "asc") {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setTabContent(temp);
    } else {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }
        if (name1 < name2) return 1;
        if (name1 > name2) return -1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setTabContent(temp);
    }
  };

  useEffect(() => {
    if (totalCount) {
      console.log(rowsPerPage);
      getTeacher();
    }
  }, [page, rowsPerPage, render]);
  // console.log(totalCount);

  //console.log(staffs);

  const deleteTeacher = (id) => {
    // console.log(id);
    axios
      .delete(Teacher.deleteTeacher(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setRender(!render);
          DisplaySnackbar("Teacher's data deleted successfully", "success");
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  useEffect(() => {
    if (staffs?.length) {
      // console.log(new Date(staffs[0].expiryDate))
      if (sortBy.length) {
        let temp = staffs;
        for (let i = 0; i < sortBy.length; i++) {
          const { field, type } = sortBy[i];
          if (type === "asc") {
            temp.sort((a, b) => {
              let name1, name2;

              if (field === "expiryDate" || field === "signUpDate") {
                name1 = new Date(a[field]);
                name2 = new Date(b[field]);
              } else {
                name1 = a[field].toLowerCase().split(" ").join("");
                name2 = b[field].toLowerCase().split(" ").join("");
              }

              if (name1 < name2) return -1;
              if (name1 > name2) return 1;
              return 0;
            });
            // console.log(temp);

            // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
          } else {
            temp.sort((a, b) => {
              let name1, name2;

              if (field === "expiryDate" || field === "signUpDate") {
                name1 = new Date(a[field]);
                name2 = new Date(b[field]);
              } else {
                name1 = a[field].toLowerCase().split(" ").join("");
                name2 = b[field].toLowerCase().split(" ").join("");
              }
              if (name1 < name2) return 1;
              if (name1 > name2) return -1;
              return 0;
            });
            // console.log(temp);

            // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
          }
        }
        setTabContent(
          temp.map((item) => {
            return {
              _id: item._id,
              email: item.email,
              contact: item.contact,
              expiryDate: new Date(item.expiryDate).toLocaleDateString(),
              name: item.name,
              role: item.role,
              signUpDate: new Date(item.signUpDate).toLocaleDateString(),
              schoolId: item.school._id,
              del: (
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTeacher(item._id);
                  }}
                  style={{ zIndex: "1000" }}
                  color="secondary"
                />
              ),

              schoolName: item.school.name,
              enable: (
                <Switch
                  onClick={(e) => {
                    e.stopPropagation();
                    toogleDisabled(item._id);
                  }}
                  style={{ zIndex: "1000" }}
                  checked={item.enabled}
                  color="primary"
                  className="enableIcon"
                />
              ),
              reset: (
                <CachedOutlinedIcon
                  className="resetPassword"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetPassword(item?._id);
                  }}
                />
              ),
            };
          })
        );
      } else {
        setTabContent(
          staffs.map((item) => {
            return {
              _id: item._id,
              email: item.email,
              contact: item.contact,
              expiryDate: new Date(item.expiryDate).toLocaleDateString(),
              name: item.name,
              role: item.role,
              signUpDate: new Date(item.signUpDate).toLocaleDateString(),
              schoolId: item.school._id,
              schoolName: item.school.name,
              del: (
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTeacher(item._id);
                  }}
                  color="secondary"
                />
              ),

              enable: (
                <Switch
                  onClick={(e) => {
                    e.stopPropagation();
                    toogleDisabled(item._id);
                  }}
                  style={{ zIndex: "100000" }}
                  checked={item.enabled}
                  color="primary"
                  className="enableIcon"
                />
              ),
              reset: (
                <CachedOutlinedIcon
                  className="resetPassword"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetPassword(item?._id);
                  }}
                />
              ),
            };
          })
        );
      }
    }
  }, [staffs]);

  const resetPassword = (id) => {
    setLoading(true);
    axios
      .get(Teacher.resetPassword(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Password Reset");
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
          setLoading(false);
        }
      });
  };

  const getFilterData = () => {
    axios
      .get(Teacher.getFilterData(search), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setStaffs(res.data.data.staffs);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  useEffect(() => {
    if (search === "") {
      getTeacher();
    } else {
      getFilterData();
    }
  }, [search]);

  const toogleDisabled = (id) => {
    axios
      .patch(
        Teacher.toogleDisable(id),
        {},
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(res.data.message, "success");
          setStaffs(
            staffs.map((item) =>
              item._id === id ? { ...item, enabled: !item.enabled } : item
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  // console.log(tabContent);
  // console.log(staffs);

  const exportCSV = () => {
    setExportingData(true);
    axios
      .get(ExportData.exportTeacher, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = [];
        data = res.data.data.staffs.map((item) => {
          return {
            "School Name": item?.school?.name,
            "Teacher Name": item?.name,
            Email: item?.email,
            Contact: item?.contact,
            "SignUp Date": item?.signUpDate?.split("T"),
            "Expiry Date": item?.expiryDate?.split("T"),
            Sections: item?.sections
              ?.map((item) => `${item?.grade}-${item?.section}`)
              .join(","),
          };
        });
        const fileName = "Staff";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
        setExportingData(false);
      })
      .catch((err) => console.log(err));
    // let data=staffs.map((item)=>{
    //   return{
    //     "School Name":item.school.name,
    //                 "Teacher Name":item.name,
    //                 "Email":item.email,
    //                 "Contact":item.contact,
    //                 "SignUp Date":item.signUpDate.split("T"),
    //                 "Expiry Date":item.expiryDate.split("T"),
    //                 "Sections":(item.sections.map((item)=>`${item.grade}-${item.section}`)).join(",")

    // }})
  };
  // console.log(staffs);
  return (
    <TeacherPageContainer>
      <DashboardHeaderWrapper>
        <DashboardHeader>
          <div>
            <h1>Teacher</h1>
          </div>

          <div>
            <InputComponent
              val={search}
              setVal={setSearch}
              type="text"
              search
              placeholder="Search..."
            />
            <img src={ProfileImg} alt="profileimg" />
          </div>
        </DashboardHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "-50px",
            marginLeft: "-25px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <DashboardHeader>
          <div
            style={{ cursor: "pointer", fontWeight: "500" }}
            onClick={() => {
              setModal(true);
            }}
          >
            <AddBoxOutlinedIcon /> Add Teacher
          </div>
          <Headerfeatures
            handleSort={handleSort}
            sortField={sortField}
            viewBy={false}
            exportFile={exportCSV}
          />
        </DashboardHeader>
        {loading && <Loader type="spin" color="cyan" />}
      </DashboardHeaderWrapper>
      <Line />
      <TeacherPageContent>
        <TableComponent
          handleOnClick={handleOnClick}
          tabContent={tabContent}
          tabHeader={AllMembersTeacher}
        />
      </TeacherPageContent>
      {modal && (
        <AddModalComponent
          formData={formData}
          setFormData={setFormData}
          label={label}
          headerText="Add Teacher"
          setModal={setModal}
          label2={label2}
          link={true}
          headerText2="Link Classes And Subjects"
          handleCreate={handleCreate}
          handleMultiSelect={handleMultiSelect}
          minWidth="630px"
          maxWidth="630px"
        />
      )}
      {exportingData && <Loader />}
    </TeacherPageContainer>
  );
}

export default TeacherPage;
