import styled from 'styled-components';
export const EditProfileContainer=styled.div`


.fileUpload{
    margin-top:100px;
    label{
        border:1px solid black;
        padding:5px 10px;
    }
}
`