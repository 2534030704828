import React, { useState } from "react";
import LineGraph from "./LineGraph";
import {
  AnalyticsContainer,
  AnalyticsHeader,
  ExportPageButton,
  AnalyticsContent,
} from "./Analytics.style";
import ProfileImg from "../../images/2xcell_logo.png";
import { Grid } from "@mui/material";
import SystemUpdateAltOutlined from "@material-ui/icons/SystemUpdateAltOutlined";
import NewUsers from "./NewUsers";
import ProgressBarGraph from "./ProgressBarGraph";
import ActiveUsers from "./ActiveUsers";
import axios from "axios";
import exportFromJSON from "export-from-json";
import { useAuth } from "../../Auth/Auth";
import { analytics } from "../../Api/api";
import FilterComponent from "../Modal/FilterComponent";
import { PageVisits } from "../RenderDetailsData/RenderDetailsData";
function Analytics() {
  const { readCookie } = useAuth();
  const [popUpFilter, setPopUpFilter] = useState(false);
  const [formData, setFormData] = useState({
    school: "",
    role: "all",
    from: "",
    to: "",
  });
  const handleApply = () => {
    let data = [];
    const { role, from, to } = formData;
    axios
      .get(analytics.getPageVisitByUser(null, from, to, role), {
        headers: {
          token: readCookie("token"),
        },
      })
      .then((res) => {
        // console.log(res);
        let pageVisitData = res.data.data.pageVisits;
        pageVisitData.forEach((item) => {
          const obj = {
            School: item?.school?.name,
            Type: item?.role,
            User: item.user?.name,
            Email: item.user?.email,
            Class: item.section?.split("-")[0],
            Section: item.section?.split("-")[1],
            Subject: item.subject,
            Chapter: item.chapter,
            Topic: item.topic,
            Module: item.module,
            "Visit On Date": new Date(item.visitOn).toLocaleDateString(),
            "Visit On Time": new Date(item.visitOn).toLocaleTimeString(),
          };
          data.push(obj);
        });
        const fileName = "PageVisitBySchools";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
      })
      .catch((err) => console.log(err));
  };

  return (
    <AnalyticsContainer>
      {popUpFilter && (
        <FilterComponent
          handleApply={handleApply}
          formData={formData}
          setFormData={setFormData}
          data={PageVisits()}
          isClear={false}
          isLabel={true}
          setPopUpFilter={setPopUpFilter}
        />
      )}
      <AnalyticsHeader>
        <div className="firstClass">
          <div>
            <h2>Analytics</h2>
            <h2 style={{ marginTop: "8px" }}>{new Date().toDateString()}</h2>
          </div>
          <div style={{ width: "60px", height: "60px" }}>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%" }}
              src={ProfileImg}
              alt="profileimg"
            />
          </div>
        </div>
        <div className="secondClass">
          <ExportPageButton
            style={{ cursor: "pointer" }}
            onClick={() => setPopUpFilter(true)}
          >
            <SystemUpdateAltOutlined /> Export page visits
          </ExportPageButton>
        </div>
      </AnalyticsHeader>
      <AnalyticsContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <LineGraph />
          </Grid>
          <Grid item xs={4}>
            <ProgressBarGraph />
          </Grid>
        </Grid>
        <Grid style={{ marginTop: "20px" }} container spacing={2}>
          <Grid item xs={8}>
            <NewUsers />
          </Grid>
          <Grid item xs={4}>
            <ActiveUsers />
          </Grid>
        </Grid>
      </AnalyticsContent>
    </AnalyticsContainer>
  );
}

export default Analytics;
