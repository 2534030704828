import React, { useState, useEffect } from "react";
import {
  DashboardContainer,
  DashboardHeader,
  DashboardContent,
  DashboardHeaderWrapper,
  Line,
} from "./Dashboard.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import { schools, ExportData } from "../../Api/api";
import { Link } from "react-router-dom";
import InputComponent from "../Input/InputComponent";
import ProfileImg from "../../images/profileImg.svg";
import Headerfeatures from "../Header/Headerfeatures";
import Schools from "./Schools/Schools";
import AddSchoolModal from "./Schools/AddSchoolModal";
import exportFromJSON from "export-from-json";
import DocumentLoading from "../Loading/ExportLoader";

import { TablePagination } from "@material-ui/core";
// import { DocumentLoading } from "../Loading/Loader.style";

const sortField = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "State",
    field: "state",
  },
  {
    label: "City",
    field: "city",
  },
];

const Dashboard = () => {
  const [view, setView] = useState("card");
  const [modal, setModal] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(12);
  const [allSchools, setSchools] = useState([]);
  const [totalSchools, setTotalSchools] = useState(0);
  const [search, setSearch] = useState("");
  const [headersText, setHeaderText] = useState("Add School");
  const [editData, setEditData] = useState({});
  const [exportingData, setExportingData] = useState(false);
  const [render, setRender] = useState(false);

  const addSchool = (school) => {
    allSchools.unshift(school);
    setSchools([...allSchools]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };
  const { readCookie, DisplaySnackbar, imgUrl } = useAuth();

  const getSchools = () => {
    axios
      .get(schools.getSchools(page + 1, rowsPerPage), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setSchools(res.data.data.schools))
      .catch((err) => console.log(err));
  };

  const updateList = (id) => {
    const filteredData = allSchools.filter((i) => i._id !== id);
    setSchools(filteredData);
  };
  const toogleDisable = (id) => {
    axios
      .patch(
        schools.toogleDisableSchool(id),
        {},
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(res.data.message, "success");
          setSchools(
            allSchools.map((item) =>
              item._id === id ? { ...item, enabled: !item.enabled } : item
            )
          );
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (search) {
      axios
        .get(schools.getFilterData(search), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setSchools(res.data.data.schools);
          }
        })
        .catch((err) => {
          if (err.response) {
            DisplaySnackbar(err.response.data.message, "error");
          }
        });
    } else {
      getSchools();
    }
  }, [search]);
  useEffect(() => {
    getSchools();

    axios
      .get(schools.totalSchools, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setTotalSchools(res.data.data.totalSchools))
      .catch((err) => console.log(err));
  }, [render]);
  // console.log(allSchools);
  useEffect(() => {
    getSchools();
  }, [rowsPerPage, page]);

  // console.log(imgUrl);
  const handleSort = (field, type) => {
    if (type === "asc") {
      axios
        .get(schools.sortSchools(field, totalSchools), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => setSchools(res.data.data.schools))
        .catch((err) => console.log(err));
    } else {
      axios
        .get(schools.sortSchools(`-${field}`, totalSchools), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => setSchools(res.data.data.schools))
        .catch((err) => console.log(err));
    }
  };
  const exportCSV = () => {
    setExportingData(true);
    axios
      .get(ExportData.exportSchool, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = [];
        data = res.data.data.schools.map((item) => {
          return {
            Name: item.name,
            Country: item.country,
            Board: item.board,
            State: item.state,
            City: item.city,
            Branch: item.branch,
            "Maximum Teachers": item.teacherCounts,
            "Current Teachers": item.teacherRegistered,
            "Maximum Students": item.studentCounts,
            "Current Students": item.studentRegistered,
            "SignUp Date": new Date(item.createdAt),
            "Expiry Date": new Date(item.expiryDate),
            Enabled: item.enabled,
          };
        });
        const fileName = "SchoolsData";
        const exportType = exportFromJSON.types.csv;

        exportFromJSON({ data, fileName, exportType });
        setExportingData(false);
      })
      .catch((err) => console.log(err));
  };
  const handleEdit = (data) => {
    // console.log(data);
    setEditData(data);
    setHeaderText("Edit School");
    setModal(true);
  };
  useEffect(() => {
    if (!modal) {
      setHeaderText("Add School");
    }
  }, [modal]);
  return (
    <DashboardContainer>
      {modal && (
        <AddSchoolModal
          render={render}
          setRender={setRender}
          data={editData}
          headersText={headersText}
          setModal={setModal}
          addSchool={addSchool}
        />
      )}
      <DashboardHeaderWrapper>
        <DashboardHeader>
          <div>
            <h1>Dashboard</h1>
          </div>

          <div>
            <InputComponent
              val={search}
              setVal={setSearch}
              type="text"
              search
              placeholder="Search..."
            />
            <Link to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImg}
                alt="profileimg"
              />
            </Link>
          </div>
        </DashboardHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "-50px",
            marginLeft: "-25px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalSchools}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <DashboardHeader>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
            }}
            className="iconContainer"
          >
            <AddBoxOutlinedIcon /> Add School
          </div>
          <Headerfeatures
            view={view}
            exportFile={exportCSV}
            setView={setView}
            sortField={sortField}
            handleSort={handleSort}
          />
        </DashboardHeader>
      </DashboardHeaderWrapper>
      <Line />
      <DashboardContent>
        <Schools
          handleEdit={handleEdit}
          toogleDisable={toogleDisable}
          view={view}
          allSchools={allSchools}
          handleupdate={updateList}
        />
      </DashboardContent>
      {exportingData && <DocumentLoading />}
    </DashboardContainer>
  );
};
export default Dashboard;
