import styled from "styled-components";

export const FileContainer=styled.div`

display:flex;
justify-content:center;
align-items:center;

img{
    max-width:100%;
    max-height:100%;
}

`