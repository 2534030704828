import React, { useState, useEffect } from "react";
import { TeachersData } from "../../RenderDetailsData/RenderDetailsData";
import Details from "../../Details/Details";
import { TeacherDetailsContainer } from "./tabcomponent.style";
import {
  ButtonWrapper,
  Button,
} from "../../Dashboard/TabComponent/TabComponent.style";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Teacher } from "../../../Api/api";
import { useAuth } from "../../../Auth/Auth";

function TeacherDetails(props) {
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const { readCookie, DisplaySnackbar } = useAuth();
  const { staffId } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    schoolName: "",
    email: "",
    contact: "",
    role: "",
    expiryDate: "",
  });

  useEffect(() => {
    if (props?.data?.school?.name) {
      setData(TeachersData(props.data));
      setFormData({
        name: props.data.name,
        schoolName: props.data.school.name,
        email: props.data.email,
        contact: props.data.contact,
        role: props.data.role,
        expiryDate: new Date(props.data.expiryDate).toISOString().slice(0, 10),
      });
    }
  }, [props]);
  useEffect(() => {
    if (edit) {
    }
  }, [edit]);

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleUpdate = () => {
    const { name, schoolName, role, expiryDate, email, contact } = formData;
    if (!name || !schoolName || !email || !contact || !role || !expiryDate) {
      DisplaySnackbar("All fields are mandatory", "error");
    }
    axios
      .patch(
        Teacher.updateStaff(staffId),
        { ...formData },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          props.setStaffData({ ...props.data, ...formData });
          DisplaySnackbar("Edit Successfully", "success");
          setEdit(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  return (
    <TeacherDetailsContainer style={{ width: "80%" }}>
      <Details
        formData={formData}
        setFormData={setFormData}
        handleOnChange={handleOnChange}
        justifycontent="space-between"
        data={data}
        edit={edit}
        setEdit={setEdit}
      />
      {edit && (
        <ButtonWrapper>
          <Button onClick={() => setEdit(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleUpdate();
            }}
          >
            Save
          </Button>
        </ButtonWrapper>
      )}
    </TeacherDetailsContainer>
  );
}

export default TeacherDetails;
