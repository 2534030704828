import React from 'react'

function QuestionSvgComponent() {
    return (
        <div style={{background:"rgba(255, 106, 153, 0.5)",height:"31px",borderRadius:"50%",display:"flex",justifyContent:"center",alignItems:"center",width:"30px"}}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00004 14.6666C11.6819 14.6666 14.6667 11.6819 14.6667 7.99998C14.6667 4.31808 11.6819 1.33331 8.00004 1.33331C4.31814 1.33331 1.33337 4.31808 1.33337 7.99998C1.33337 11.6819 4.31814 14.6666 8.00004 14.6666Z" stroke="#FF6A99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.06006 6.00001C6.21679 5.55446 6.52616 5.17875 6.93336 4.93944C7.34056 4.70012 7.81932 4.61264 8.28484 4.69249C8.75036 4.77234 9.1726 5.01436 9.47678 5.3757C9.78095 5.73703 9.94743 6.19436 9.94673 6.66668C9.94673 8.00001 7.94673 8.66668 7.94673 8.66668" stroke="#FF6A99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8 11.3333H8.00833" stroke="#FF6A99" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>

    )
}

export default QuestionSvgComponent;
