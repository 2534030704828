export const Practice={
    overView:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/practiceoverview?school=${schoolId}&section=${sectionId}`,
    completeStatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionscompletestatus?school=${schoolId}&section=${sectionId}`,
    // testStatus:`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionsattemptstatus`,
    questionAttemptStatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionsattemptstatus?school=${schoolId}&section=${sectionId}`,
    questionAttempPieChart:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionsattemptstatus?school=${schoolId}&section=${sectionId}&overall=true`,
    questionLevelStatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionslevelstatus?school=${schoolId}&section=${sectionId}`,
    learningStatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionslearningstatus?school=${schoolId}&section=${sectionId}`,
    classReports:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/classreports?school=${schoolId}&section=${sectionId}`,
}

export const PracticeFilterByStudent={

    overView:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/practiceoverview?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    completeStatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionscompletestatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    // testStatus:`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionsattemptstatus`,
    questionAttemptStatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionsattemptstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    questionAttempPieChart:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionsattemptstatus?school=${schoolId}&section=${sectionId}&&student=${studentId}&overall=true`,
    questionLevelStatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionslevelstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    learningStatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/questionslearningstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    classReports:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/practiceanalytics/classreports?school=${schoolId}&section=${sectionId}&student=${studentId}&userId=${studentId}`,
}