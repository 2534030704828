import styled from 'styled-components';



export const SchoolDetailsContainer=styled.div`
display:flex;
justify-content:space-between;
width:100%;

`


export const ButtonWrapper=styled.div`

display:flex;
justify-content:flex-end;
margin-top:50px;
margin-right:50px;

`
export const Button=styled.button`

background-color:transparent;
border:1px solid #5CE0D2;
padding:8px 50px;
font-size:1rem;
margin:0px 14px;
cursor:pointer;
border-radius:8px;
transition: 0.5s;
&:hover{
    background-color:#5CE0D2;
}


`
export const CommonHeader=styled.div`
display:flex;
justify-content:space-between;
margin-top:20px;
margin-bottom:10px;
.iconContainer{
    padding:8px 15px;
    border-radius:5px;
    &:hover{
        background-color:#5CE0D2;
        
    }
}

`

export const AnalyticsContainer=styled.div`

display:flex;
justify-content:space-between;
flex-wrap:wrap;

`

export const AnalyticsBox=styled.div`
width:280px;
border-radius:8px;
height:60px;
box-sizing:border-box;
padding:0px 10px;
display:flex;
align-items:center;
color:#fff;
font-weight:600;
justify-content:space-between;
cursor:pointer;
text-decoration:none;


background-color:${({bgColor})=>bgColor};
 margin-top:20px;
 .ftDiv{
     display:flex;
 }
`