import React from 'react';
import {LoadingContainer,DocumentLoader,Text} from './Loader.style';
import ReactLoading from 'react-loading';

function DocumentLoading({type,color,text}) {
    return (
        <LoadingContainer>
            <DocumentLoader>
            <ReactLoading type="cylon" color="#0dcaf0" height={100} width={100}/>
            <Text>{text} </Text>
            </DocumentLoader>
            
        </LoadingContainer>
    )
}

DocumentLoading.defaultProps={
    text:"Please wait while we are exporting your Data"
}
export default DocumentLoading;
