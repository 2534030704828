import styled from "styled-components";

export const HeaderContainer=styled.div`
display:flex;

div{
    margin-left:15px;
    font-weight:500;
    display:flex;
    align-items:center;
}
.Icons{
    margin-right:5px;
}
button{
    background-color:transparent;
    border:none;

}
.iconContainer{
    padding:8px 15px;
    border-radius:5px;
    &:hover{
        background-color:#5CE0D2;
        
    }
}

`
export const Header=styled.div`
margin-bottom:16px;
.ft{
display:flex;
justify-content:space-between;
margin:${({margin})=>margin};
.firstChild{
    font-size:2rem;
    font-weight:500;
    
}
}
`

export const CoverScreen=styled.div`

position:absolute;
top:0;
left:0;
right:0;
bottom:0;

z-index:1000;
`