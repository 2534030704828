import React from "react";
import { WrapOptions } from "./Questions.style";
import Options from "./Options";

function QuestionOptions(props) {
  const { questionType, options, answer, images, isQuestionImg } = props;
 
  return (
    <WrapOptions>
      {options.map((item, idx) => {
        if (questionType === "mmcq")
          return (
            <Options
              isQuestionImg={isQuestionImg}
              images={images ? images[item.optionId] : ""}
              mark={answer.indexOf(item.optionId) !== -1 ? true : false}
              option={item}
            />
          );
        else if (questionType === "fillup" || questionType === "oneword")
          return (
            <Options
              isQuestionImg={isQuestionImg}
              images={images ? images[item.optionId] : ""}
              radio={false}
              mark={false}
              option={{
                optionId: answer[idx].blankId,
                value: answer[idx].answer,
              }}
            />
          );

        return (
          <Options
            isQuestionImg={isQuestionImg}
            images={images ? images[item.optionId] : ""}
            mark={item.optionId === answer}
            option={item}
          />
        );
      })}
    </WrapOptions>
  );
}

QuestionOptions.defaultProps = {
  questionType: "Mcq",
};
export default QuestionOptions;
