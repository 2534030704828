import React from 'react';
import { FileContainer } from './Media.style';

function Audio(props) {
    const {file}=props;
    return (
        <FileContainer>
             <audio width="60%" style={{marginTop:"2rem"}} controls  controlsList="nodownload">
                <source src={file} type="video/mp4"/>
                Your browser does not support HTML video.
            </audio>
        </FileContainer>
    )
}

export default Audio
