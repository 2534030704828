import React, { useState, useEffect } from "react";
import { studentData } from "../RenderDetailsData/RenderDetailsData";

import Details from "../Details/Details";
import { TeacherDetailsContainer } from "../Teacher/TabComponent/tabcomponent.style";
import {
  ButtonWrapper,
  Button,
} from "../Dashboard/TabComponent/TabComponent.style";
import { StudentDetailsContainer } from "./Students.style";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Auth/Auth";
import { Student, schools } from "../../Api/api";
function StudentDetails(props) {
  // console.log(props);
  // console.log(props);

  const [edit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const { schoolId, studentId } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    grade: "",
    section: "",
    email: "",
    contact: "",
    schoolName: "",
    expiryDate: "",
    signUpDate: "",
  });
  const { readCookie, DisplaySnackbar } = useAuth();
  const [grade, setGrade] = useState([]);

  useEffect(() => {
    if (props?.data?.email) {
      axios
        .get(schools.getClasses(schoolId), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          let section = res.data.data.grades.find(
            (item) => props.data.grade === item.grade
          );
          //   console.log(res, props);

          setData(
            studentData(props.data, res.data.data.grades, section?.sections||[])
          );

          setGrade(res.data.data.grades);
        })
        .catch((err) => console.log(err));
      setFormData({
        name: props.data.name,
        grade: props.data.grade,
        section: props.data.section,
        email: props.data.email,
        contact: props.data.contact,
        schoolName: props.schoolName,
        expiryDate: new Date(props.data.expiryDate).toISOString().slice(0, 10),
      });
    }
  }, [props]);
  //   console.log(data);
  useEffect(() => {
    console.log("Selected section");
    console.log(formData);
    if (formData.grade) {
      let section = grade.find((item) => formData.grade === item.grade);
      console.log(section);
      setData(studentData(props.data, grade, section?.sections || []));
    }
  }, [formData.grade]);

  const handleOnChange = (e) => {
    // console.log(e);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = () => {
    axios
      .patch(
        Student.updateStudent(studentId),
        { ...formData },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Update Successfull");
          props.setStudentData({ ...props.data, ...formData });
          setEdit(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  return (
    <StudentDetailsContainer>
      <Details
        justifycontent="flex-start"
        height="32px"
        data={data}
        edit={edit}
        setEdit={setEdit}
        handleOnChange={handleOnChange}
        formData={formData}
        setFormData={setFormData}
      />
      {edit && (
        <ButtonWrapper>
          <Button onClick={() => setEdit(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleUpdate();
              setEdit(false);
            }}
          >
            Save
          </Button>
        </ButtonWrapper>
      )}
    </StudentDetailsContainer>
  );
}

export default StudentDetails;
