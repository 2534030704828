import styled from 'styled-components';
export const ManageSectionContainer=styled.div`

`
export const ManageSectionHeader=styled.div`
h1{
    margin:0px;
    font-weight:500;
}
.add-section{
    margin-top:20px;
    display:flex;
    align-items:center;
}

`
export const ManageSectionContent=styled.div`
display:flex;
justify-content:space-between;
flex-wrap:wrap;

`