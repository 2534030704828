export const PracticeAssesment=[
    {
        id:"sno",
        align:"left",
        label:"S.No",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"studentName",
        align:"left",
        label:"Student Name",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"grade",
        align:"left",
        label:"Grade",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        minWidth:"100px",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"subjectName",
        align:"left",
        label:"Subject",
        minWidth:"70px",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"chapterName",
        align:"left",
        label:"Chapter",
        backgroundColor:"transparent",
        color:"#000000",
        minWidth:"150px",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"topicName",
        align:"left",
        minWidth:"150px",
        label:"Topic",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"timespent",
        align:"left",
        label:"Time Spent",
        backgroundColor:"transparent",
        minWidth:"100px",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"questions",
        align:"left",
        label:"Total Questions",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"questionsdone",
        align:"left",
        label:"Questions Done",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"correct",
        align:"left",
        label:"Correct",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"incorrect",
        align:"left",
        label:"Incorrect",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"easy",
        align:"left",
        label:"Easy",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"medium",
        align:"left",
        label:"Medium",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"difficult",
        align:"left",
        label:"Difficult",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    // {
    //     id:"knowledge",
    //     align:"left",
    //     label:"Knowledge",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    // {
    //     id:"understanding",
    //     align:"left",
    //     label:"Understanding",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    // {
    //     id:"analysis",
    //     align:"left",
    //     label:"Analysis",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    // {
    //     id:"application",
    //     align:"left",
    //     label:"Application",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    // {
    //     id:"1stattempt",
    //     align:"left",
    //     label:"1st Attempt",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    // {
    //     id:"2ndattempt",
    //     align:"left",
    //     label:"2nd Attempt",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",
    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
]




export const PracticeAssesmentData=[
    {
        sno:1,
        subject:"Hindi",
        chaptersdone:3,
        topicdone:5,
        timespent:"22 min",
        questionsdone:100,
        correct:70,
        incorrect:20,
        easy:10,
        medium:20,
        difficult:18,
        knowledge:12,
        understanding:9,
        analysis:10,
        application:20,
        "1stattempt":12,
        "2ndattempt":10

    },
    {
        sno:2,
        subject:"Hindi",
        chaptersdone:3,
        topicdone:5,
        timespent:"22 min",
        questionsdone:100,
        correct:70,
        incorrect:20,
        easy:10,
        medium:20,
        difficult:18,
        knowledge:12,
        understanding:9,
        analysis:10,
        application:20,
        "1stattempt":12,
        "2ndattempt":10

    },
    {
        sno:2,
        subject:"Hindi",
        chaptersdone:3,
        topicdone:5,
        timespent:"22 min",
        questionsdone:100,
        correct:70,
        incorrect:20,
        easy:10,
        medium:20,
        difficult:18,
        knowledge:12,
        understanding:9,
        analysis:10,
        application:20,
        "1stattempt":12,
        "2ndattempt":10

    },
    {
        sno:2,
        subject:"Hindi",
        chaptersdone:3,
        topicdone:5,
        timespent:"22 min",
        questionsdone:100,
        correct:70,
        incorrect:20,
        easy:10,
        medium:20,
        difficult:18,
        knowledge:12,
        understanding:9,
        analysis:10,
        application:20,
        "1stattempt":12,
        "2ndattempt":10

    },
    
   
   
    
  
]

export const OnlineAssesmentHeader=[
    {
        id:"sno",
        align:"left",
        label:"S.No",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"studentName",
        align:"left",
        label:"Student",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        minWidth:"80px",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"grade",
        align:"left",
        label:"Grade",
        minWidth:"120px",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        // minWidth:"120px",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"startDate",
        align:"left",
        label:"Start Date",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"endDate",
        align:"left",
        label:"End Date",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"subject",
        align:"left",
        label:"Subject",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        minWidth:"120px",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"chapter",
        align:"left",
        label:"Chapter",
        backgroundColor:"transparent",
       
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"topic",
        align:"left",
        label:"Topic",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"testType",
        align:"left",
        label:"Test Type",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"date",
        align:"left",
        label:"Date",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"startTimeOfTest",
        align:"left",
        label:"Start time of Test",
        minWidth:"120px",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"timeSpent",
        align:"left",
        label:"Time Spent",
        minWidth:"120px",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"marks",
        align:"left",
        label:"Marks",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"classAverage",
        align:"left",
        label:"Class Average",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"marksObtained",
        minWidth:"120px",
        align:"left",
        label:"Marks Obtained",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
   
    {
        id:"correct",
        align:"left",
        label:"Correct",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"inCorrect",
        align:"left",
        label:"Incorrect",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"easy",
        align:"left",
        label:"Easy",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"medium",
        align:"left",
        label:"Medium",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"difficult",
        align:"left",
        label:"Difficult",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"knowledge",
        align:"left",
        label:"Knowledge",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"understanding",
        align:"left",
        label:"Understanding",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"analysis",
        align:"left",
        label:"Analysis",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"application",
        align:"left",
        label:"Application",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },
]

export const onlineAssesmentData=[
    {
        sno:1,
        startDate:"12-11-2021",
        endDate:"12-12-2021",
        subject:"V",
        chapter:"chap5",
        topic:"topics",
        testType:"sd",
        date:"12-11-2021",
        startTimeOfTest:"10:00",
        timeSpent:"10min",
        marks:100,
        marksObtained:60,
        classAverage:60,
        correct:20,
        inCorrect:10,
        easy:10,
        medium:20,
        difficult:10,
        knowledge:10,
        understanding:10,
        analysis:10,
        application:10
    },
    {
        sno:2,
        startDate:"12-11-2021",
        endDate:"12-12-2021",
        subject:"V",
        chapter:"chap5",
        topic:"topics",
        testType:"sd",
        date:"12-11-2021",
        startTimeOfTest:"10:00",
        timeSpent:"10min",
        marks:100,
        marksObtained:60,
        classAverage:60,
        correct:20,
        inCorrect:10,
        easy:10,
        medium:20,
        difficult:10,
        knowledge:10,
        understanding:10,
        analysis:10,
        application:10
    }
]


export const attendanceHeader=[
    {
        id:"sno",
        align:"left",
        label:"S No.",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"

    },{
        id:"studentName",
        align:"left",
        label:"Student Name",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"presentLectures",
        align:"left",
        label:"Present Lectures",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"absentLectures",
        align:"left",
        label:"Absent Lectures",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"totalLectures",
        align:"left",
        label:"Total Lectures",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"attendance%",
        align:"left",
        label:"Attendance %",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"loginTime",
        align:"left",
        label:"Login Time",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"logoutTime",
        align:"left",
        label:"Logout Time",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"studentName",
        align:"left",
        label:"Student Name",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    }
]
export const ClassAttendanceHeader=[
    {
        id:"sno",
        align:"left",
        label:"S No.",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"

    },{
        id:"subject",
        align:"left",
        label:"Subject",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"presentLectures",
        align:"left",
        label:"Present Lectures",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"absentLectures",
        align:"left",
        label:"Absent Lectures",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"totalLectures",
        align:"left",
        label:"Total Lectures",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"attendance%",
        align:"left",
        label:"Attendance %",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"loginTime",
        align:"left",
        label:"Login Time",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"logoutTime",
        align:"left",
        label:"Logout Time",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",
        fontSize:"13px",
        borderBottom:"none"
    }
]

export const ClassAttendanceData=[
    {
        sno:1,
        subject:"Math",
        presentLectures:6,
        absentLectures:4,
        totalLectures:10,
        "attendance%":"60%",
        loginTime:"10: 02",
        logoutTime:"11: 02"

    },{
        sno:2,
        subject:"English",
        presentLectures:6,
        absentLectures:4,
        totalLectures:10,
        "attendance%":"60%",
        loginTime:"10: 02",
        logoutTime:"11: 02"
    }
]
export const AttendanceReportData=[
    {
        sno:1,
        studentName:"John",
        presentLectures:6,
        absentLectures:4,
        totalLectures:10,
        "attendance%":"60%",
        loginTime:"10: 02",
        logoutTime:"11: 02"
    },
    {
        sno:2,
        studentName:"John",
        presentLectures:6,
        absentLectures:4,
        totalLectures:10,
        "attendance%":"60%",
        loginTime:"10: 02",
        logoutTime:"11: 02"
    }
]


export const StudentAttendanceHeader=[
    
        {
            id:"sno",
            align:"left",
            label:"SNo.",
            backgroundColor:"transparent",
            color:"#000000",
            fontWeight:"600",
            fontSize:"13px",
            borderBottom:"none"
        },{
            id:"subjects",
            align:"left",
            label:"Subjects",
            backgroundColor:"transparent",
            color:"#000000",
            fontWeight:"600",
            fontSize:"13px",
            borderBottom:"none"
        },
        {
            id:"present",
            align:"left",
            label:"Present/Absent",
            backgroundColor:"transparent",
            color:"#000000",
            fontWeight:"600",
            fontSize:"13px",
            borderBottom:"none"
        }

    
]

export const StudentAttendanceData=[
    {
        sno:1,
        subjects:"Math",
        present:"Present"
    },
    {
        sno:1,
        subjects:"Math",
        present:"Present"
    }
]

export const HomeWorkHeader=[
    {
        id:"sno",
        align:"left",
        label:"S.No",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"grade",
        align:"left",
        label:"Grade",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"

    },
    // {
    //     id:"subject",
    //     align:"left",
    //     label:"Subject",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",

    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    {
        id:"studentName",
        align:"left",
        label:"Student Name",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"totalHomeworks",
        align:"left",
        label:"Total HomeWorks",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    }
    // ,{
    //     id:"marks",
    //     align:"left",
    //     label:"Marks",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",

    //     fontSize:"13px",
    //     borderBottom:"none"
    // },
    ,{
        id:"done",
        align:"left",
        label:"Done",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"pending",
        align:"left",
        label:"Pending",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    }
    // ,{
    //     id:"marksObtained",
    //     align:"left",
    //     label:"Marks Obtained",
    //     backgroundColor:"transparent",
    //     color:"#000000",
    //     fontWeight:"600",

    //     fontSize:"13px",
    //     borderBottom:"none"
    // }
]


export const HomeWorkData=[
    {
        sno:1,
        grade:"VI",
        subject:"English",
        studentName:"John",
        totalHomeworks:80,
        marks:80,
        done:80,
        pending:40,
        marksObtained:60
    }, {
        sno:1,
        grade:"VI",
        subject:"English",
        studentName:"John",
        totalHomeworks:80,
        marks:80,
        done:80,
        pending:40,
        marksObtained:60
    }
]

export const ResourcesHeader=[
    {
        id:"sno",
        align:"left",
        label:"S.no",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"

    },{
        id:"grade",
        align:"left",
        label:"Grade",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"subject",
        align:"left",
        label:"Subject",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"studentName",
        align:"left",
        label:"Student Name",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"totalResources",
        align:"left",
        label:"Total Resources",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"done",
        align:"left",
        label:"Done",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"pending",
        align:"left",
        label:"Pending",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"time",
        align:"left",
        label:"Time",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"date",
        align:"left",
        label:"Date",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    }
]

export const ResourcesData=[

    {
        sno:1,
        grade:"VI",
        subject:"English",
        studentName:"John",
        totalResources:10,
        done:6,
        pending:4,
        time:"09:00",
        date:"18/11/2021"

    },{
        sno:2,
        grade:"VI",
        subject:"English",
        studentName:"John",
        totalResources:10,
        done:6,
        pending:4,
        time:"09:00",
        date:"18/11/2021"
    },{
        sno:3,
        grade:"VI",
        subject:"English",
        studentName:"John",
        totalResources:10,
        done:6,
        pending:4,
        time:"09:00",
        date:"18/11/2021"

    }
]


export const ResourcesStudentHeader=[
    {
        id:"sno",
        align:"left",
        label:"S.no",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"

    },{
        id:"grade",
        align:"left",
        label:"Grade",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"subject",
        align:"left",
        label:"Subject",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"chapters",
        align:"left",
        label:"Chapters",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    }, {
        id:"topics",
        align:"left",
        label:"Topics",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    }, {
        id:"subTopics",
        align:"left",
        label:"SubTopics",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },
    {
        id:"totalResources",
        align:"left",
        label:"Total Resources",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"done",
        align:"left",
        label:"Done",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"pending",
        align:"left",
        label:"Pending",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"time",
        align:"left",
        label:"Time",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    },{
        id:"date",
        align:"left",
        label:"Date",
        backgroundColor:"transparent",
        color:"#000000",
        fontWeight:"600",

        fontSize:"13px",
        borderBottom:"none"
    }
]



export const ResourcesStudentData=[

    {
        sno:1,
        grade:"VI",
        subject:"English",
        chapters:"chapter1",
        topics:"topic 1 ",
        subTopics:"subTopic1",
        totalResources:10,
        done:6,
        pending:4,
        time:"09:00",
        date:"18/11/2021"

    },{
        sno:2,
        grade:"VI",
        subject:"English",
        chapters:"chapter1",
        topics:"topic 1 ",
        subTopics:"subTopic1",
        totalResources:10,
        done:6,
        pending:4,
        time:"09:00",
        date:"18/11/2021"
    },{
        sno:3,
        grade:"VI",
        subject:"English",
        chapters:"chapter1",
        topics:"topic 1 ",
        subTopics:"subTopic1",
        totalResources:10,
        done:6,
        pending:4,
        time:"09:00",
        date:"18/11/2021"

    }
]

