import React from 'react'

function AnalyticsIcon() {
    return (
        <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.8409 1.35208L28.1295 1.30255C28.4967 1.3019 28.796 1.67176 28.796 2.12653L28.8233 19.8778C29.1795 19.6912 29.5717 19.5542 30 19.47L29.9733 2.12395C29.9727 0.951342 29.144 -0.00192677 28.1289 2.92454e-06L1.84032 0.0488885C0.824572 0.0508182 -0.00174052 1.00666 2.75328e-06 2.17991L0.0267329 19.753C0.448605 19.789 0.843747 19.8701 1.20461 20L1.17671 2.17799C1.17671 1.72322 1.47365 1.35272 1.8409 1.35208Z" fill="white"/>
<path d="M12.36 8.38327L8.36554 14.5371L6.72222 12.8325L2.97534 18.5277L4.08929 19.5183L6.43283 16.0744L8.53929 17.9629L12.5296 11.7918L17.4404 16.2185L23.4762 8.64828L27.0383 11.6715L26.5182 1.69366L17.8466 3.72113L20.8625 6.35967L16.8814 12.2137L12.36 8.38327Z" fill="white"/>
</svg>

    )
}

export default AnalyticsIcon;
