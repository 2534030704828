import React from "react";

import { FileContainer } from "./Media.style";

function Images(props) {
  const { file } = props;
  //console.log("images");
  return (
    <FileContainer>
      <img src={file} alt="resourcesData" />
    </FileContainer>
  );
}

export default Images;
