import React from 'react';
import { FileContainer } from './Media.style';

function Documents(props) {
    const {file}=props
    // console.log(file);
    return (
        <FileContainer>
             <iframe title="file" src={file} alt="file" width="80%" height="700px">
                </iframe>
            
        </FileContainer>
    )
}

export default Documents
