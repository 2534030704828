export const Staff = [
  {
    id: "name",
    align: "left",
    label: "Name",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "role",
    align: "left",
    label: "Role",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "email",
    align: "left",
    label: "Email",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "contact",
    align: "left",
    label: "Contact",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "signUpDate",
    align: "left",
    label: "SignupDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "expiryDate",
    align: "left",
    label: "ExpiryDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "enable",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "del",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "reset",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];
export const AllMembersTeacher = [
  {
    id: "name",
    align: "left",
    label: "Name",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "role",
    align: "left",
    label: "Role",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "email",
    align: "left",
    label: "Email",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "contact",
    align: "left",
    label: "Contact",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },

  {
    id: "signUpDate",
    align: "left",
    label: "SignupDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "expiryDate",
    align: "left",
    label: "ExpiryDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "enable",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "del",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "reset",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const Students = [
  {
    id: "name",
    align: "left",
    label: "Name",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "class",
    align: "left",
    label: "Class",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "section",
    align: "left",
    label: "Section",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "email",
    align: "left",
    label: "Email",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "contact",
    align: "left",
    label: "Contact",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "signUpDate",
    align: "left",
    label: "SignupDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "expiryDate",
    align: "left",
    label: "ExpiryDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "enable",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "del",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "reset",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];
export const StudentsPageData = [
  {
    id: "name",
    align: "left",
    label: "Name",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "class",
    align: "left",
    label: "Class",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "section",
    align: "left",
    label: "Section",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "email",
    align: "left",
    label: "Email",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "contact",
    align: "left",
    label: "Contact",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "signUpDate",
    align: "left",
    label: "SignUpDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "expiryDate",
    align: "left",
    label: "ExpiryDate",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "enable",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "del",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "reset",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const SubjectTeachers = [
  {
    id: "sno",
    align: "left",
    label: "S.NO.",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "subject",
    align: "left",
    label: "Subject",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "teacher",
    align: "left",
    label: "Teacher",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const Classes = [
  {
    id: "sno",
    align: "left",
    label: "SNO.",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "class",
    align: "left",
    label: "Class",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "section",
    align: "left",
    label: "Sections",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "subjects",
    align: "left",
    label: "Subjects",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "subscription",
    align: "left",
    label: "Subscription",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "edit",
    align: "left",
    label: "Edit",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const ListView = [
  {
    id: "name",
    align: "left",
    label: "School Name",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "board",
    align: "left",
    label: "Board",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "branch",
    align: "left",
    label: "Branch",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "city",
    align: "left",
    label: "City",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "state",
    align: "left",
    label: "State",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "studentCounts",
    align: "left",
    label: "Students",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "teacherCounts",
    align: "left",
    label: "Teachers",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "signUpDate",
    align: "left",
    label: "Sign Up Date",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "expiryDate",
    align: "left",
    label: "Expiry Date",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const TeacherSchedule = [
  {
    id: "sno",
    align: "left",
    label: "S.NO",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "class",
    align: "left",
    label: "Class",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "section",
    align: "left",
    label: "Section",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "subject",
    align: "left",
    label: "Subject",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "remove",
    align: "left",
    label: "Remove",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const studentsSchedule = [
  {
    id: "sno",
    align: "left",
    label: "SNO.",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "subject",
    align: "left",
    label: "Subject",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "assignedteacher",
    align: "left",
    label: "Assigned Teacher",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const ResourcesTable = [
  {
    id: "_id",
    align: "left",
    label: "Id",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "fileName",
    align: "left",
    label: "File Name",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "fileUrl",
    align: "left",
    label: "File Url",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "del",
    align: "left",
    label: "Delete Resources",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];

export const Courses = [
  {
    id: "sno",
    align: "left",
    label: "SNO.",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "class",
    align: "left",
    label: "Class",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "section",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "subject",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "remove",
    align: "left",
    label: "",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
  {
    id: "enable",
    align: "left",
    label: "Delete",
    backgroundColor: "transparent",
    color: "#969696",
    fontSize: "13px",
    borderBottom: "none",
  },
];
