export function sortSections(sections) {
  return sections.sort((a, b) => {
    const isAlphaA = /^[A-Z]$/.test(a.section);
    const isAlphaB = /^[A-Z]$/.test(b.section);

    if (isAlphaA && isAlphaB) {
      return a.section.localeCompare(b.section);
    } else if (isAlphaA) {
      return -1;
    } else if (isAlphaB) {
      return 1;
    } else {
      return a.section.localeCompare(b.section);
    }
  });
}
