export const SchoolDetails = (data) => {
  // console.log(data);
  return [
    [
      {
        label: "Name",
        type: "text",

        border: "none",
        width: "35%",
        name: "name",
      },
      {
        label: "City",
        type: "text",

        border: "none",
        width: "20%",
        name: "city",
      },
      {
        label: "State",
        type: "text",

        border: "none",
        width: "20%",
        name: "state",
      },
      {
        label: "Branch",
        type: "text",

        border: "none",
        width: "20%",
        name: "branch",
      },
    ],

    [
      {
        label: "Registration Date",
        type: "date",
        value: new Date(data.createdAt).toISOString().slice(0, 10),
        border: "none",
        width: "23%",
        disabled: true,
      },
      {
        label: "Expiry Date",
        type: "date",

        value: new Date(data.expiryDate).toISOString().slice(0, 10),
        border: "none",
        width: "23%",
        disabled: true,
      },
    ],
    [
      {
        label: "TEACHERS COUNT",
        type: "number",

        border: "none",
        width: "23%",
        name: "teacherCounts",
      },
      {
        label: "STUDENT COUNT",
        type: "number",

        border: "none",
        width: "23%",
        name: "studentCounts",
      },
    ],
  ];
};
export const TeachersData = (data) => {
  //console.log(data);
  return [
    [
      {
        label: "Name",
        type: "text",

        border: "none",
        width: "48%",
        name: "name",
      },
      {
        label: "School",
        type: "text",

        border: "none",
        width: "48%",
        name: "schoolName",
        disabled: true,
      },
    ],
    [
      {
        label: "Email",
        type: "text",

        border: "none",
        width: "48%",
        name: "email",
      },
      {
        label: "Contact",
        type: "text",

        border: "none",
        width: "48%",
        name: "contact",
      },
    ],

    [
      {
        label: "Role",
        type: "select",

        border: "none",
        options: [
          { name: "School Admin", value: "school admin" },
          { name: "Teacher", value: "teacher" },
          { name: "HOD", value: "hod" },
          { name: "Principal", value: "principal" },
        ],
        width: "48%",
        name: "role",
      },
      {
        label: "Expiry Date",
        type: "date",
        value: new Date(data.expiryDate).toISOString().slice(0, 10),
        border: "none",
        width: "48%",
        name: "expiryDate",
      },
    ],
  ];
};

export const addStaffForm = (data) => [
  [
    {
      label: "SCHOOL NAME",
      type: "select",
      placeholder: "Enter Schools Name",
      options: [
        { name: "Select School", value: "select" },
        ...data.map((item) => {
          return { name: item.name, value: item._id };
        }),
      ],
      width: "100%",
      height: "38px",
      name: "schoolName",
    },
  ],
  [
    {
      label: "TEACHER'S NAME",
      type: "text",
      placeholder: "Enter teacher name",
      width: "48%",
      name: "teacherName",
    },
    {
      label: "EXPIRY DATE",
      type: "date",
      placeholder: "dd-mm-yy",
      width: "48%",
      name: "expiryDate",
    },
  ],
  [
    {
      label: "EMAIL",
      type: "text",
      placeholder: "Enter email",
      width: "48%",
      name: "email",
    },
    {
      label: "CONTACT",
      type: "number",
      placeholder: "Enter no",
      width: "48%",
      name: "contact",
    },
  ],
  [
    {
      label: "ROLE",
      type: "select",
      options: [
        { name: "Select Role", value: "select" },
        { name: "School Admin", value: "school admin" },
        { name: "Teacher", value: "teacher" },
        { name: "Principal", value: "principal" },
        { name: "HOD", value: "hod" },
      ],

      placeholder: "Select Role",
      width: "48%",
      name: "role",
    },
    {
      label: "PASSWORD",
      type: "password",
      placeholder: "Enter Password",
      width: "48%",
      name: "password",
    },
  ],
];
export const addStaffFormDashboard = (data) => [
  [
    {
      label: "SCHOOL NAME",
      type: "text",
      placeholder: "Enter Schools Name",

      width: "100%",
      height: "36px",
      name: "schoolName",
      disabled: true,
      bdr: true,
    },
  ],
  [
    {
      label: "TEACHER'S NAME",
      type: "text",
      placeholder: "Enter teacher name",
      width: "48%",
      name: "teacherName",
    },
    {
      label: "EXPIRY DATE",
      type: "date",
      placeholder: "dd-mm-yy",
      width: "48%",
      name: "expiryDate",
    },
  ],
  [
    {
      label: "EMAIL",
      type: "text",
      placeholder: "Enter email",
      width: "48%",
      name: "email",
    },
    {
      label: "CONTACT",
      type: "number",
      placeholder: "Enter no",
      width: "48%",
      name: "contact",
    },
  ],
  [
    {
      label: "ROLE",
      type: "select",
      options: [
        { name: "Select Role", value: "select" },
        { name: "School Admin", value: "school admin" },
        { name: "Teacher", value: "teacher" },
        { name: "Principal", value: "principal" },
        { name: "HOD", value: "hod" },
      ],

      placeholder: "Select Role",
      width: "48%",
      name: "role",
    },
    {
      label: "PASSWORD",
      type: "password",
      placeholder: "Enter Password",
      width: "48%",
      name: "password",
    },
  ],
];
// export const addSubjectFormDashboard = (data) => [
//   [
//     {
//       label: "SUBJECT NAME",
//       type: "text",
//       placeholder: "Enter Subject Name",

//       width: "100%",
//       height: "36px",
//       name: "subjectName",
//       disabled: true,
//       bdr: true,
//     },
//   ],
//   [
//     {
//       label: "EXPIRY DATE",
//       type: "date",
//       placeholder: "dd-mm-yy",
//       width: "48%",
//       name: "expiryDate",
//     },
//   ],
// ];

export const LinkClass = (classes = [], section = [], subject = []) => {
  return [
    [
      {
        label: "CLASS",
        type: "select",
        options: classes.length
          ? [
              { name: "Select Class", value: "select" },
              ...classes.map((item) => {
                return { name: item.grade || item.name, value: item._id };
              }),
            ]
          : [],

        placeholder: "Select Class",
        width: "32%",
        name: "class",
        height: "36px",
      },
      {
        label: "SECTION",
        type: "multiselect",
        height: "36px",
        options: section.length
          ? [
              { name: "select Section", value: "slect" },
              ...section.map((item) => {
                return { name: item.section, value: item._id, id: item._id };
              }),
            ]
          : [],
        placeholder: "select Section",
        width: "32%",
        name: "section",
      },
      {
        label: "SUBJECT",
        type: "multiselect",
        options: subject.length
          ? [
              { name: "select Subject", value: "select" },
              ...subject.map((item) => {
                return {
                  name: item.subject,
                  value: item.subject,
                  id: item._id,
                };
              }),
            ]
          : [],
        placeholder: "Select Subject",
        width: "32%",
        name: "subject",
        height: "36px",
      },
    ],
  ];
};

export const addStudents = (schools, classes, sections) => [
  [
    {
      label: "SCHOOL NAME",
      type: "select",
      options: schools.length
        ? [
            { name: "Select School", value: "select" },
            ...schools.map((item) => {
              return { name: item.name, value: item._id };
            }),
          ]
        : [],
      placeholder: "Add School",
      width: "100%",
      name: "schoolName",
    },
  ],
  [
    {
      label: "NAME",
      type: "text",

      placeholder: "Enter Student name",
      width: "48%",
      name: "name",
    },

    {
      label: "PASSWORD",
      type: "password",
      placeholder: "Enter password",
      width: "48%",
      name: "password",
    },
  ],
  [
    {
      label: "CLASS",
      type: "select",
      placeholder: "Select Class",
      options: classes.length
        ? [
            { name: "Select Class", value: "select Class" },
            ...classes.map((item) => {
              return { name: item.grade, value: item.uniqueGrade };
            }),
          ]
        : [],
      name: "class",
      width: "48%",
    },
    {
      label: "SECTION",
      type: "select",
      placeholder: "Select section",
      options: sections.length
        ? [
            { name: "select Section", value: "select Section" },
            ...sections.map((item) => {
              return { name: item.section, value: item.section };
            }),
          ]
        : [],
      name: "section",
      width: "48%",
    },
  ],
  [
    {
      label: "CONTACT",
      type: "number",

      placeholder: "Enter contact",
      width: "48%",
      name: "contact",
    },
    {
      label: "Email",
      type: "email",
      placeholder: "Enter email",
      width: "48%",
      name: "email",
    },
  ],
  [
    {
      label: "EXPIRY DATE",
      type: "date",
      placeholder: "25-12-2022",
      width: "100%",
      name: "expiryDate",
    },
  ],
];
export const addStudentFormDashboard = (classes, sections) => [
  [
    {
      label: "SCHOOL NAME",
      type: "text",
      disabled: true,
      bdr: true,
      placeholder: "Add School",
      width: "100%",
      name: "schoolName",
    },
  ],
  [
    {
      label: "NAME",
      type: "text",

      placeholder: "Enter Student name",
      width: "48%",
      name: "name",
    },

    {
      label: "PASSWORD",
      type: "password",
      placeholder: "Enter password",
      width: "48%",
      name: "password",
    },
  ],
  [
    {
      label: "CLASS",
      type: "select",
      placeholder: "Select Class",
      options: classes.length
        ? [
            { name: "Select Class", value: "select Class" },
            ...classes.map((item) => {
              return { name: item.grade, value: item.uniqueGrade };
            }),
          ]
        : [],
      name: "class",
      width: "48%",
    },
    {
      label: "SECTION",
      type: "select",
      placeholder: "Select section",
      options: sections.length
        ? [
            { name: "select Section", value: "select Section" },
            ...sections.map((item) => {
              return { name: item.section, value: item.section };
            }),
          ]
        : [],
      name: "section",
      width: "48%",
    },
  ],
  [
    {
      label: "CONTACT",
      type: "number",

      placeholder: "Enter contact",
      width: "48%",
      name: "contact",
    },
    {
      label: "Email",
      type: "email",
      placeholder: "Enter email",
      width: "48%",
      name: "email",
    },
  ],
  [
    {
      label: "EXPIRY DATE",
      type: "date",
      placeholder: "25-12-2022",
      width: "100%",
      name: "expiryDate",
    },
  ],
];

export const AssignSubject = (subject, teacher) => [
  [
    {
      type: "select",
      placeholder: "Select Teacher",
      options: teacher.length
        ? [
            { name: "Select Teacher", value: "select" },
            ...teacher.map((item) => {
              return { name: item.name, value: item._id };
            }),
          ]
        : [],
      width: "100%",
      name: "teacher",
    },
  ],
  [
    {
      type: "select",
      placeholder: "Select Subject",
      options: subject.length
        ? [
            { name: "Select subject", value: "select" },
            ...subject.map((item) => {
              return { name: item.subject, value: item.subject };
            }),
          ]
        : [],
      width: "100%",
      name: "subject",
    },
  ],
];

export const StaffFilter = [
  [
    {
      label: "ROLE",
      type: "text",
      placeholder: "Select Role",
      width: "48%",
    },
    {
      label: "SIGN UP DATE",
      type: "date",
      placeholder: "dd-mm-yyyy",
      width: "48%",
    },
  ],
  [
    {
      label: "EXPIRY DATE",
      type: "date",
      placeholder: "dd-mm-yyyy",
      width: "48%",
    },
  ],
];

export const StudentFilter = [
  [
    {
      label: "CLASS",
      type: "text",
      placeholder: "Select class",
      width: "48%",
    },
    {
      label: "SECTION",
      type: "text",
      placeholder: "Select section",
      width: "48%",
    },
  ],
  [
    {
      label: "SIGN UP DATE",
      type: "date",
      placeholder: "dd-mm-yyyy",
      width: "48%",
    },
    {
      label: "EXPIRY DATE",
      type: "date",
      placeholder: "dd-mm-yyyy",
      width: "48%",
    },
  ],
];

export const SubjectFilter = (classes, section) => [
  [
    {
      placeholder: "Select Grade",
      type: "select",
      options: classes.length
        ? [
            { name: "Select Class", value: "select" },
            ...classes.map((item) => {
              return { name: item.grade || item.name, value: item.uniqueGrade };
            }),
          ]
        : [],
      width: "100%",
      name: "class",
    },
  ],
  [
    {
      placeholder: "Select Section",

      type: "select",
      options: section.length
        ? [
            { name: "select Section", value: "select" },
            ...section.map((item) => {
              return { name: item.section, value: item.section };
            }),
          ]
        : [],
      width: "100%",
      name: "section",
    },
  ],
];

export const assignClassSubject = (grades, section, subject) => [
  [
    {
      label: "CLASS",
      type: "select",
      name: "class",
      options: grades.length
        ? [
            { name: "Select Class", value: "select" },
            ...grades.map((item) => {
              return { name: item.grade, value: item.uniqueGrade };
            }),
          ]
        : [],
      placeholder: "select Class",
      width: "48%",
      height: "36px",
    },
    {
      label: "SECTION",
      type: "multiselect",
      name: "section",
      height: "36px",
      options: section.length
        ? [
            ...section.map((item) => {
              return { name: item.section, value: item.section, id: item._id };
            }),
          ]
        : [],
      placeholder: "select section",
      width: "48%",
    },
  ],
  [
    {
      label: "SUBJECT",
      name: "subject",
      height: "36px",
      type: "multiselect",
      options: subject.length
        ? [
            ...subject.map((item) => {
              return { name: item.subject, value: item.subject };
            }),
          ]
        : [],
      placeholder: "select SUBJECTS",
      width: "100%",
    },
  ],
];

export const assignClassSubjectInSchool = (grades, section, subject) => [
  [
    {
      label: "CLASS",
      type: "select",
      name: "class",
      options: grades.length
        ? [
            { name: "Select Class", value: "select" },
            ...grades.map((item) => {
              return { name: item.name, value: item._id };
            }),
          ]
        : [],
      placeholder: "select Class",
      width: "48%",
      height: "36px",
    },
    {
      label: "SECTION",
      type: "multiselect",
      name: "section",
      height: "36px",
      options: section.length
        ? [
            ...section.map((item) => {
              return { name: item.section, value: item.value };
            }),
          ]
        : [],
      placeholder: "select section",
      width: "48%",
    },
  ],
  [
    {
      label: "SUBJECT",
      name: "subject",
      height: "36px",
      type: "multiselect",
      options: subject.length
        ? [
            ...subject.map((item) => {
              return { name: item.name, value: item._id, id: item._id };
            }),
          ]
        : [],
      placeholder: "select SUBJECTS",
      width: "100%",
    },
  ],
];

export const AddSection = (classes, section) => [
  [
    {
      label: "CLASS",
      name: "class",
      type: "select",
      options: [
        { name: "Select Class", value: "select" },
        ...classes.map((item) => {
          return { name: item.grade, value: item._id };
        }),
      ],
      placeholder: "select class",
      width: "100%",
    },
  ],
  [
    {
      label: "SECTION",
      type: "multiselect",
      name: "section",
      options: [
        ...section.map((item) => {
          return { name: item.section, value: item.section };
        }),
      ],

      placeholder: "select section",
      width: "100%",
    },
  ],
];

export const AddSubject = (classes, subject) => () => {
  // console.log(subject);
  return [
    [
      {
        label: "CLASS",
        type: "select",
        options: [
          { name: "Select Class", value: "select" },
          ...classes.map((item) => {
            return { name: item.grade, value: [item.grade, item._id] };
          }),
        ],
        placeholder: "select class",
        width: "100%",
        name: "class",
      },
    ],
    [
      {
        label: "SUBJECT",
        type: "multiselect",
        options: subject.length
          ? [
              { name: "Select Subject", value: "Select Subject" },
              ...subject.map((item) => {
                return { name: item.name, value: item._id, id: item._id };
              }),
            ]
          : [],
        placeholder: "select subject",
        width: "100%",
        name: "subject",
      },
    ],
  ];
};

export const studentData = (data, grade, Section) => {
  // console.log(grade, Section);
  return [
    [
      {
        label: "NAME",
        type: "text",
        placeholder: "Enter Name",

        width: "30%",
        name: "name",
        margin: "0px 6px",
      },
      {
        label: "CLASS",
        type: "select",
        placeholder: "Enter Class",
        width: "24%",
        options: grade.length
          ? [
              { name: "Select Class ", value: "select" },
              ...grade.map((item) => {
                return { name: item.grade, value: item.grade };
              }),
            ]
          : [],
        height: "32px",
        value: data.grade,
        name: "grade",
        margin: "0px 6px",
      },
      {
        label: "SECTIONS",
        type: "select",
        placeholder: "Select Sections",
        options: Section.length
          ? [
              { name: "Select Section", value: ":select" },
              ...Section.map((item) => {
                return { name: item.section, value: item.section };
              }),
            ]
          : [],
        height: "32px",
        width: "24%",
        value: data.section,
        name: "section",
        margin: "0px 6px",
      },
    ],
    [
      {
        label: "EMAIL",
        type: "email",
        placeholder: "Enter Email",
        width: "30%",

        name: "email",
        margin: "0px 6px",
      },
      {
        label: "Contact",
        type: "number",
        placeholder: "Enter Number",
        width: "24%",

        name: "contact",
        margin: "0px 6px",
      },
      {
        label: "School Name",
        type: "text",
        placeholder: "",
        width: "24%",
        disabled: true,
        name: "schoolName",
        margin: "0px 6px",
      },
    ],
    [
      {
        label: "EXPIRY DATE",
        type: "date",
        placeholder: "Enter Name",
        width: "30%",
        margin: "0px 6px",

        value: new Date(data.expiryDate).toISOString().slice(0, 10),
        name: "expiryDate",
      },
      {
        label: "SIGNUP DATE",
        type: "date",
        margin: "0px 6px",

        width: "30%",
        value: new Date(data.signUpDate).toISOString().slice(0, 10),
        name: "sigUpDate",
        disabled: true,
      },
    ],
  ];
};

export const changePassword = [
  [
    {
      label: "Old Password",
      type: "password",
      name: "oldPassword",
      width: "100%",
    },
  ],
  [
    {
      label: " Password",
      type: "New  Password",
      name: "newPassword",

      width: "100%",
    },
  ],
  [
    {
      label: "Re-Enter New Password",
      type: "password",
      name: "confirmPassword",

      width: "100%",
    },
  ],
];

export const addsection = (sec, headerText) => {
  return [
    [
      {
        label: headerText,
        placeholder: "Enter Section",
        width: "100%",
        value: sec,
        name: "name",
      },
    ],
  ];
};
export const addTopic = [
  [
    {
      label: "Topic",
      placeholder: "Enter Topic",
      width: "100%",
      name: "name",
      value: "",
    },
  ],
  [
    {
      label: "THUMBNAIL",
      placeholder: "Enter an url",
      width: "100%",
      name: "thumbnail",
    },
  ],
];

export const PageVisits = () => [
  [
    {
      label: "School",
      type: "select",
      value: "All",
      width: "100%",
      name: "school",
      height: "36px",
      options: [{ name: "All", value: "All" }],
    },
  ],
  [
    {
      label: "From",
      type: "date",
      width: "48%",
      height: "36px",
      name: "from",
      placeholder: "dd-mm-yyyy",
    },
    {
      label: "To",
      type: "date",
      width: "48%",
      height: "36px",
      name: "to",

      placeholder: "dd-mm-yyyy",
    },
  ],
  [
    {
      label: "Role",
      type: "select",
      width: "48%",
      height: "36px",
      options: [
        { name: "All", value: "All" },
        { name: "Students", value: "student" },
        { name: "Teacher", value: "teacher" },
      ],
      name: "role",

      // placeholder:"dd-mm-yyyy"
    },
  ],
];

// export const assigncourseInSchool = (grades, section, subject) => [
//   [
//     {
//       label: "CLASS",
//       type: "select",
//       name: "class",
//       options: grades.length
//         ? [
//             { name: "Select Class", value: "select" },
//             ...grades.map((item) => {
//               return { name: item.name, value: item._id };
//             }),
//           ]
//         : [],
//       placeholder: "select Class",
//       width: "48%",
//       height: "36px",
//     },
//     {
//       label: "SECTION",
//       type: "multiselect",
//       name: "section",
//       height: "36px",
//       options: section.length
//         ? [
//             ...section.map((item) => {
//               return { name: item.section, value: item.value };
//             }),
//           ]
//         : [],
//       placeholder: "select section",
//       width: "48%",
//     },
//   ],
//   [
//     {
//       label: "SUBJECT",
//       name: "subject",
//       height: "36px",
//       type: "multiselect",
//       options: subject.length
//         ? [
//             ...subject.map((item) => {
//               return { name: item.name, value: item._id, id: item._id };
//             }),
//           ]
//         : [],
//       placeholder: "select SUBJECTS",
//       width: "100%",
//     },
//   ],
// ];
