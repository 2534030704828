import { useRef, useEffect } from "react";

import { Header } from "../Modal.style";
import { LoadingContainer } from "../../Loading/Loader.style";

import Upload from "./Upload";

import { useAuth } from "../../../Auth/Auth";

function Uploader(props) {
  const { DisplaySnackbar } = useAuth();
  const uploadStatus = useRef({});

  const closeUploadModal = () => {
    var close = true;
    for (let status in uploadStatus.current) {
      console.log(`Status ${status}`);
      if (uploadStatus.current[status] === "pending") {
        close = false;
        DisplaySnackbar(
          "Cannot close modal. Uploading is in progress!",
          "error"
        );
        break;
      }
    }
    if (close) props.setModal(false);
  };

  useEffect(() => {
    if (!props.files) return;
    for (let file in props.files) {
      uploadStatus.current[file] = "pending";
    }
  }, [props.files]);

  return (
    <LoadingContainer>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
          width: "500px",
          height: "500px",
          padding: "20px",
          overflowY:"scroll"
        }}
      >
        <Header>
          <p style={{ margin: "auto 0" }}>Do not close the window</p>
          <h2 onClick={closeUploadModal} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        {/**["thumbnail", "video", "audio", "file"] */}
        {Object.keys(props.files).map((file, index) => {
         
          if (props.files[file]) {
            return (
              <Upload
                key={index}
                moduleId={props.moduleId}
                field={file}
                file={props.files[file]}
                status={uploadStatus}
                setResources={props.setResources}
                isResourceLevel={props.resourceLevel}
              />
            );
          }
        })}
      </div>
    </LoadingContainer>
  );
}

export default Uploader;
