import React from "react";
import {NavLink ,useNavigate } from "react-router-dom";
import {
  NavbarLinkWrapper,
  Logo,
  NavbarWrapper,
  NavbarLink,
  Logout,
} from "./Navbar.style";
import HomeIcon from "@material-ui/icons/Home";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore";
import SettingsIcon from "@material-ui/icons/Settings";
import SubtitlesIcon from "@material-ui/icons/Subtitles";
import Logo2excell from '../../images/2xcell_logo.png';
import {useAuth} from '../../Auth/Auth';

const Navbar = ({collapse}) => {
  const {imgUrl,deleteCookie}=useAuth()
  const navigation=useNavigate();
  return (
    <NavbarWrapper>
      <Logo>

        
      {!collapse&&<div className="logoContainer">
      <NavLink to='/profile'><img  src={localStorage.getItem("imgUrl")||Logo2excell} alt="profileimg" /></NavLink>
        <p style={{textAlign:"center"}}>2xcell</p>
      </div>}
      </Logo>
      <NavbarLinkWrapper>
        <NavbarLink>
          <NavLink className="link" exact  to="/">
            <HomeIcon className="navIcon" /> {!collapse&&`Dashboard`}
          </NavLink>
        </NavbarLink>
        <NavbarLink style={{zIndex:"10000"}} submenu>
          <NavLink style={{zIndex:"10000"}} className="link " exact  to="/teacher">
            <GroupOutlinedIcon className="navIcon" />  {!collapse&&`All Members`}
          
          </NavLink>
          { <div style={{zIndex:"10000"}} className="afterhover">
              <NavLink style={{zIndex:"10000"}} className="sub link" to="/teacher" >
                Teacher
                </NavLink>
             <NavLink style={{zIndex:"10000",borderTop:"none"}} className="sub link" to="/students">
              Students
             </NavLink>
            </div>
            }
        </NavbarLink>
        
        <NavbarLink>
          <NavLink className="link" exact  to="/courses">
            <SubtitlesIcon className="navIcon" />{!collapse&&`Courses`} 
          </NavLink>
        </NavbarLink>
        <NavbarLink>
          <NavLink className="link" exact  to="/analytics">
            <PollOutlinedIcon className="navIcon" />{!collapse&&`Analytics`} 
          </NavLink>
        </NavbarLink>
        <NavbarLink>
          <NavLink className="link" exact  to="/manageSection">
            <SettingsBackupRestoreIcon className="navIcon" />{!collapse&&`Manage Sections`}  
          </NavLink>
        </NavbarLink>
        <NavbarLink>
          <NavLink className="link" exact  to="/settings">
            <SettingsIcon className="navIcon" /> {!collapse&&`Settings`}
          </NavLink>
        </NavbarLink>
      </NavbarLinkWrapper>

      <Logout>
        <div style={{cursor:"pointer"}} onClick={()=>{deleteCookie();navigation('/login')}} className="link" >
          <ExitToAppIcon className="navIcon" />
          {!collapse&&`Logout`} 
        </div>
      </Logout>
    </NavbarWrapper>
  );
};
export default Navbar;
