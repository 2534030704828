import React from 'react'
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {ResponsiveContainer,BarChart,XAxis,YAxis,Bar,CartesianGrid,Tooltip,Cell,LabelList} from 'recharts';




function AnalyticsContainer4(props) {
  const {data,field,colors}=props;
    return (
        <AnalyticsContainer>
          <div style={{textAlign:"center"}}>
          <div className='yaxis'>
          Questions
          </div>
            <div style={{fontSize:"1.3rem",fontWeight:"600"}}>
           Question Status Report of Class VA
            </div>
            <div>
              Total Question -200
            </div>
          </div>
          <div style={{width:"100%",height:"320px"}}>
            <ResponsiveContainer>
            <BarChart width="100%" height="100%" data={data}>
  <CartesianGrid strokeDasharray="1 1" />
  <XAxis tick={{ fill: "rgba(0, 0, 0)" }} dataKey="name" style={{fontWeight:"600",color:"black"}} />
  <YAxis tickCount={10} domain={[0, 'dataMax + 20']} />
  <Tooltip cursor={{fill: '#fff'}} />
  
  <Bar barSize={26} dataKey={field[0]} stackId="a" fill={colors[0]} >
    {/* {
      data.map((item,index)=>{
        return  <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
      })
       
    } */}
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} dataKey={field[0]} position="middle" />
    </Bar>
    <Bar barSize={26} dataKey={field[1]} stackId="a" fill={colors[1]}>
    {/* {
      data.map((item,index)=>{
        return  <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
      })
       
    } */}
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} dataKey={field[1]} position="middle" />
    </Bar>
    <Bar barSize={26} dataKey={field[2]} stackId="a" fill={colors[2]}>
    {/* {
      data.map((item,index)=>{
        return  <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
      })
       
    } */}
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} dataKey={field[2]} position="middle" />
    </Bar>
   
  
</BarChart>
</ResponsiveContainer>
</div>
        </AnalyticsContainer>
    )
}

AnalyticsContainer4.defaultProps={
  data:[
    {
        name: 'Page A',
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: 'Page B',
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: 'Page C',
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: 'Page D',
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: 'Page E',
        uv: 40,
        pv: 80,
        amt: 21,
      },
      {
        name: 'Page F',
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: 'Page G',
        uv: 40,
        pv: 80,
        amt: 20,
      },
  ],
  field:["uv","pv","amt"],
  colors:["#7DE6DB","#FF7E7E","#56E58F"]
}
export default AnalyticsContainer4
