import React, { useState, useEffect } from "react";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useAuth } from "../../Auth/Auth";
import { analytics } from "../../Api/api";
import { Grid } from "@mui/material";
import axios from "axios";

function BarGraph(props) {
  const { readCookie } = useAuth();
  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const [barGraphData, setBarGraphData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  useEffect(() => {
    axios
      .get(analytics.getWeeklyRegisterStudents(props.previous), {
        headers: {
          token: readCookie("token"),
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          // console.log(res);
          const data = res.data.data.weeklyStudents.map((d, index) => {
            return {
              day: days[index],
              students: d["users"],
            };
          });
          setBarGraphData(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(barGraphData);
  useEffect(() => {
    let total = 0;
    if (barGraphData.length) {
      barGraphData.forEach((data) => {
        total += data["students"];
      });
      setTotalUsers(total);
      props.exportData[props.previous ? "lastWeek" : "currentWeek"] =
        barGraphData;
      props.setExportData(props.exportData);
    }
  }, [barGraphData]);
  return (
    <Grid
      style={{ margin: "10px 0", height: "114px" }}
      container
      justify="space-between"
    >
      <Grid style={{ margin: "auto 0" }} item>
        <p style={{ margin: "10px 0 0 0", fontWeight: 800, fontSize: "0.9em" }}>
          {props.previous ? "Previous week users" : "New users this week"}
        </p>
        <h3 style={{ margin: 0 }}>{totalUsers}</h3>
      </Grid>
      <Grid style={{ width: "70%", height: "100px" }} item>
        <ResponsiveContainer aspect="5">
          <BarChart
            data={barGraphData}
            barSize={10}
            height={100}
            margin={{
              top: -30,
              right: 30,
              left: 20,
              bottom: -10,
            }}
          >
            <XAxis dataKey="day" />
            <Tooltip />
            <Bar dataKey="students" fill="#5CE0D2" />
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
}

export default BarGraph;
