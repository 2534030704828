import React from 'react';
import {
    PracticeAssessmentContainer,
    PracticeAssessmentHeader,
    PracticeAssessmentContent,
    PracticeAssessmentTable,
  } from "../PracticeAssessment/practiceAssesment.style";
  import { AnalyticsTabs } from "../AnalyticsHelper/AnalyticsHelper.style";
  import AnalyticsContainer4 from "../AnalyticsHelper/AnalyticsContainer4";
  import HomeWorkReport from '../HomeworkReport/HomeWorkReport';
  import AssessmentReport from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTable';
  import {ResourcesStudentHeader,ResourcesStudentData} from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader';

function ResourcesStudent() {
    const label = [
        {
          heading: "Total Students",
          count: 20000,
        },
        {
          heading: "Average Students Done",
          count: "50%",
        },
        {
          heading: "Average Students Pending",
          count: "30%",
        },
      ];
      const data = [
        {
          name: "Class VI",
          Pending: 40,
          InProgress: 10,
          Done:10
        },
        {
          name: "Class VI",
          Pending: 40,
          InProgress: 10,
          Done:10
        },
        {
          name: "Class VI",
          Pending: 40,
          InProgress: 10,
          Done:10
        },
        {
          name: "Class VI",
          Pending: 40,
          InProgress: 10,
          Done:10
        },
        {
          name: "Class VI",
          Pending: 40,
          InProgress: 10,
          Done:10
        },
        {
          name: "Class VI",
          Pending: 40,
          InProgress: 10,
          Done:10
        },
      ];
    return (
        <PracticeAssessmentContainer>
      <PracticeAssessmentHeader>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h5
            style={{
              fontWeight: "600",
              padding: "5px",
              background:
                "linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)",
            }}
          >
            Resources
          </h5>
          {/* <h5 style={{background:"rgba(86, 229, 143, 0.72)",fontWeight:"600",padding:"5px",borderRadius: "5px"}}></h5> */}
        </div>

        <div className="tabContainer">
          {label.length &&
            label.map((item) => {
              return (
                <AnalyticsTabs style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {item.heading}
                  </div>
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.count}
                  </h5>
                </AnalyticsTabs>
              );
            })}
        </div>
      </PracticeAssessmentHeader>
      <PracticeAssessmentContent>
          <AnalyticsContainer4 colors={["#F28684","#0079EA","#59E0B0"]} data={data} field={["Pending","InProgress","Done"]}/>
          <AnalyticsContainer4 colors={["#F28684","#0079EA","#59E0B0"]} data={data} field={["Pending","InProgress","Done"]}/>
          </PracticeAssessmentContent>
          <PracticeAssessmentContent>
          <AnalyticsContainer4 colors={["#F28684","#0079EA","#59E0B0"]} data={data} field={["Pending","InProgress","Done"]}/>
          <AnalyticsContainer4 colors={["#F28684","#0079EA","#59E0B0"]} data={data} field={["Pending","InProgress","Done"]}/>
          </PracticeAssessmentContent>
          <PracticeAssessmentContent>
          <HomeWorkReport headerText="Resource Report" cardHeader="Resource Name" classes={false}/>
          </PracticeAssessmentContent>
     
      <PracticeAssessmentTable>
                <AssessmentReport headerText="Resources Report" tabHeader={ResourcesStudentHeader} tabContent={ResourcesStudentData}/>
      </PracticeAssessmentTable>
    </PracticeAssessmentContainer>
        
    )
}

export default ResourcesStudent
