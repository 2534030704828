import React, { useState, useEffect } from "react";
import TableComponent from "../../Table/Table";
import { Students } from "../../Table/TableHeaders";
import { CommonHeader } from "./TabComponent.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../../Header/Headerfeatures";
import { Line } from "../Dashboard.style";
import { useParams, useNavigate } from "react-router-dom";
import Switch from "@material-ui/core/Switch";

import axios from "axios";
import { useAuth } from "../../../Auth/Auth";
import { schools, Student, ExportData } from "../../../Api/api";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";
import CSVtoJSON from "../../Functions/CSVtoJSON";
import DeleteIcon from "@material-ui/icons/Delete";
import { TablePagination } from "@material-ui/core";
// import { studentData } from "../../RenderDetailsData/RenderDetailsData";
import AddModalComponent from "../../Modal/AddModalComponent";
import DocumentLoading from "../../Loading/ExportLoader";
import Loader from "../../Loading/Loader";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import { addStudentFormDashboard } from "../../RenderDetailsData/RenderDetailsData";
import exportFromJSON from "export-from-json";
// const tabContent = [
//   {
//     name: "Rahul",
//     class: "12th",
//     email: "abc@gmail.com",
//     contact: "97865467",
//     signupDate: "21-10-2020",
//     expiryDate: "21-12-2023",
//   },
//   {
//     name: "Rahul",
//     class: "12th",
//     email: "abc@gmail.com",
//     contact: "97865467",
//     signupDate: "21-10-2020",
//     expiryDate: "21-12-2023",
//   },
// ];

const sortField = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Class",
    field: "class",
  },
  {
    label: "Email", 
    field: "email",
  },
  {
    label: "SignUp Date",
    field: "signUpDate",
  },
  {
    label: "Expiry Date",
    field: "expiryDate",
  },
];
function StudentComponent(props) {
  const [modal, setModal] = useState(false);
  const { schoolId, schoolName } = useParams();
  const { readCookie, DisplaySnackbar } = useAuth();
  const [allStudents, setStudents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [cnt, setCnt] = useState(0);
  const [tabContent, setTabContent] = useState([]);
  const [label, setLabel] = useState([]);
  const navigation = useNavigate();
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState([]);
  const [exportingData, setExportingData] = useState(false);
  const [formData, setFormData] = useState({
    schoolName: "",
    name: "",
    class: "",
    section: "",
    password: "",
    email: "",
    contact: "",
    expiryDate: "",
  });
  const handleOnClick = (data) => {
    navigation(`/dashboard/${schoolName}/${schoolId}/student/${data.id}`);
  };
  //console.log(cnt);
  useEffect(() => {
    axios
      .get(schools.getStudentCount(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setCnt(res.data.data.totalStudents))
      .catch((err) => console.log(err));
    axios
      .get(schools.getStudentsBySChool(schoolId, page + 1, rowsPerPage), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setStudents(res.data.data.students);
        // console.log(res);
      })
      .catch((err) => console.log(err));
    axios
      .get(schools.getClasses(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setLabel(addStudentFormDashboard(res.data.data.grades, []));
        setClasses(res.data.data.grades);
      })
      .catch((err) => console.log(err));
  }, [rowsPerPage, page]);

  /// console.log(allStudents);
  useEffect(() => {
    setFormData({ ...formData, schoolName: props.schoolsData.name });
  }, [props]);

  useEffect(() => {
    if (props.search) {
      axios
        .get(Student.getFilterDataBySchool(schoolId, props.search), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setStudents(res.data.data.students);
        })
        .catch((err) => console.log(err));
    }
  }, [props.search]);

  const handleCreate = () => {
    const data = {
      schoolId: schoolId,
      name: formData.name,
      grade: formData.class.split("-")[0],
      section: formData.section,
      contact: formData.contact,
      password: formData.password,
      expiryDate: new Date(formData.expiryDate),
      email: formData.email,
    };

    axios
      .post(Student.postData, data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setStudents([res.data.data.student, ...allStudents]);
          // console.log(res.data.data.student);
          setModal(false);
          DisplaySnackbar("Student Registered", "success");
          // console.log(allStudents);

          // console.log(res);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    // setLabel(addStudentFormDashboard(classes,classes.))
    if (formData.class && formData.class !== "select") {
      let temp = classes.find((item) => item.uniqueGrade === formData.class);
      setLabel(addStudentFormDashboard(classes, temp.sections));
    }
  }, [formData.class]);

  const toogleDisabled = (id) => {
    axios
      .patch(
        Student.toogleDisable(id),
        {},
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(res.data.message, "success");

          setStudents(
            allStudents.map((item) =>
              item._id === id ? { ...item, enabled: !item.enabled } : item
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  useEffect(() => {
    if (!modal) {
      setLabel(addStudentFormDashboard(classes, []));
      setFormData({
        schoolName: schoolName,
        name: "",
        class: "",
        section: "",
        password: "",
        email: "",
        contact: "",
        expiryDate: "",
      });
    }
  }, [modal]);

  const deleteStudent = (id) => {
    axios
      .delete(Student.deleteStudent(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setStudents(allStudents.filter((item, idx) => item._id !== id));
          DisplaySnackbar("Student Deleted successfully");
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  useEffect(() => {
    if (allStudents.length) {
      setTabContent(
        allStudents.map((item) => {
          return {
            id: item._id,
            name: item.name,
            class: item.grade,
            section:item.section,
            email: item.email,
            contact: item.contact,
            signUpDate: new Date(item.signUpDate).toLocaleDateString(),
            expiryDate: new Date(item.expiryDate).toLocaleDateString(),
            del: (
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  deleteStudent(item._id);
                }}
                color="secondary"
              />
            ),
            enable: (
              <Switch
                onClick={(e) => {
                  e.stopPropagation();
                  toogleDisabled(item._id);
                }}
                style={{ zIndex: "100000" }}
                checked={item.enabled}
                color="primary"
                className="enableIcon"
              />
            ),
            reset: (
              <CachedOutlinedIcon
                onClick={(e) => {
                  e.stopPropagation();
                  resetPassword(item._id);
                }}
              />
            ),
          };
        })
      );
    }
  }, [allStudents]);

  const uploadCSV = (e) => {
    if (!e.target.files.length) {
      return;
    }

    //To upload csv-to-json file to the server
    const reader = new FileReader();

    // function read() {

    //Extract contents of the CSV file
    const csv = e.target.files[0];
    reader.readAsText(csv);

    reader.onload = async function (e) {
      let emailList = [];
      let contactList = [];

      let errorMessages = [];

      //Convert CSV content to JSON
      const jsonContent = CSVtoJSON(e.target.result, emailList, contactList);

      //che4ck for number of entries present in the csv file
      if (jsonContent.length > 1000) {
        DisplaySnackbar("Only 1000 entries allowed per file");
        // clearFileInput()
        return;
      }

      if (jsonContent.length === 0) {
        DisplaySnackbar("CSV file is empty", "error");
        // clearFileInput()
        return;
      }

      //Validate content of JSON
      let validated = false,
        allCorrect = true;
      // jsonContent.forEach((student,index) => {
      //     validated = ValidateStudent(student)
      //     if(!validated) {
      //         allCorrect = false
      //         toast.error(`Invalid entry at ${index+1}`,{autoClose:false})
      //         clearFileInput()
      //         return
      //     }
      // })

      if (emailList.length !== new Set(emailList).size) {
        DisplaySnackbar(`Duplicated emails found`, "error");
        allCorrect = false;

        return;
      }

      //check if contact details are unique
      // if (contactList.length !== new Set(contactList).size) {
      //     allCorrect = false
      // }
      if (allCorrect) {
        axios
          .post(
            Student.importStudents(schoolId),
            { docs: jsonContent },
            {
              headers: {
                token: `${readCookie("token")}`,
              },
            }
          )
          .then((res) => {
            if (res.data.status === "error" || res.data.status === "fail") {
              DisplaySnackbar(res.data.message, "error");

              return;
            } else {
              DisplaySnackbar(
                `Inserted ${
                  res.data.insertedUsers ? res.data.insertedUsers : 0
                } entries, and skipped ${
                  res.data.skippedUsers ? res.data.skippedUsers : 0
                } entries out of total ${res.data.totalUsers} `,
                "success"
              );
              if (res.data.errorLogs) {
                // response.errorLogs.map(item=>{
                //     toast.info(item,{autoClose:false})
                // })

                const data = res.data.errorLogs;
                const fileName = `students_import_error_log_${new Date().toLocaleDateString()}`;
                const exportType = exportFromJSON.types.csv;

                exportFromJSON({ data, fileName, exportType });
              }

              if (!res.data.errors) {
                window.location.reload();
              }
            }
          })
          .catch((err) => {
            if (err.response) {
              DisplaySnackbar(err.response.data.message, "error");
            }
          });
      }
    };
  };

  const resetPassword = (id) => {
    setLoading(true);
    axios
      .get(Student.resetPassword(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Password Reset");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
        setLoading(false);
      });
  };

  const exportCSV = () => {
    setExportingData(true);
    axios
      .get(ExportData.exportStudentBySchool(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = [];
        data = res.data.data.students.map((item) => {
          return {
            "Student Name": item.name,
            "School Name": item.school.name,
            Branch: item.school.branch,
            Email: item.email,
            "Contact:": item.contact,
            Role: item.role,
            "Grade-Section": item.grade + "-" + item.section,
            "SignUp Date": new Date(item.signUpDate).toLocaleDateString(),
            "Expiry Date": new Date(item.expiryDate).toLocaleDateString(),
            Enabled: item.enabled,
          };
        });
        const fileName = "Students";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
        setExportingData(false);
      })
      .catch((err) => console.log(err));
  };

  const handleSort = (field, type) => {
    let find = sortBy.find((item) => item.field === field);
    if (find) {
      setSortBy(
        sortBy.map((item) =>
          item.field === field ? { field: field, type: type } : item
        )
      );
    } else {
      setSortBy([...sortBy, { field: field, type: type }]);
    }
    let temp = tabContent;

    if (type === "asc") {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setTabContent(temp);
    } else {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return 1;
        if (name1 > name2) return -1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setTabContent(temp);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <div>
      <CommonHeader>
        <div style={{ display: "flex", fontWeight: "500" }}>
          <div
             onClick={() => {
                setModal(true);
              }}
            className="iconContainer"
            style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
          >
            <AddBoxOutlinedIcon
              
              style={{ marginRight: "7px" }}
            />
            Add Students
          </div>
          <label
            for="importStudent"
            className="iconContainer"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              cursor: "pointer",
            }}
          >
            <PlaylistAddOutlinedIcon style={{ marginRight: "7px" }} />
            Import Students
          </label>
          <input
            id="importStudent"
            style={{ display: "none" }}
            type="file"
            accept=".csv"
            onChange={(e) => uploadCSV(e)}
          />
        </div>
        <Headerfeatures
          sortField={sortField}
          handleSort={handleSort}
          exportFile={exportCSV}
          viewBy={false}
        />
        {loading && <Loader type="spin" color="cyan" />}
      </CommonHeader>
      <Line />
      <TableComponent
        tabHeader={Students}
        handleOnClick={handleOnClick}
        tabContent={tabContent}
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cnt}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {modal && (
        <AddModalComponent
          handleCreate={handleCreate}
          formData={formData}
          setFormData={setFormData}
          label={label}
          headerText="Add Student"
          setModal={setModal}
        />
      )}
      {exportingData && <DocumentLoading />}
    </div>
  );
}

export default StudentComponent;
