import React from "react";
import {
  HomeWorkReportContainer,
  HomeWorkCardContainer,
} from "./HomeWorkReport.style";
import HomeWorkCard from "./HomeWorkCard";
import Loader from "../AnalyticsHelper/Loader/Loader";

function HomeWorkReport(props) {
  const { headerText, classes, cardHeader, data, loading } = props;
  //console.log(props);

  //console.log(props);
  const getPer = (done, total) => {
    if (total === 0) return parseFloat(0).toFixed(2);
    return parseFloat((done / total) * 100).toFixed(2);
  };

  return (
    <HomeWorkReportContainer width={props.width}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="header">
            <div style={{ fontSize: "20px", fontWeight: "600" }}>
              {headerText}
            </div>
            <div>
              <div></div>
            </div>
          </div>
          <HomeWorkCardContainer>
            {data.length &&
              data.map((item) => {
                return (
                  <HomeWorkCard
                    heading={item.topic}
                    time={new Date(item.from).toLocaleTimeString()}
                    date={new Date(item.from).toISOString().slice(0, 10)}
                    subject={item.subject}
                    chapter={item.chapter}
                    progress={getPer(item.submitted, item.students)}
                    section={item.section}
                    classes={item.grade}
                  />
                );
              })}
            {/* <HomeWorkCard heading={cardHeader} showClass={classes}/>
                <HomeWorkCard heading={cardHeader} showClass={classes}/>
                <HomeWorkCard heading={cardHeader} showClass={classes}/> */}
          </HomeWorkCardContainer>
        </>
      )}
    </HomeWorkReportContainer>
  );
}

HomeWorkReport.defaultProps = {
  headerText: "HomeWork Name",
  classes: true,
  cardHeader: "HomeWork Name",
  loading: false,
  data: [],
};
export default HomeWorkReport;
