import React,{useState} from 'react';
import { Table,TableBody,TableCell,TableContainer,TableHead,TablePagination,TableRow } from '@material-ui/core';
import {TablePage} from './Table.style';

function TableComponent(props) {
    const {tabHeader,tabContent,handleOnClick}=props;
    // const [page,setPage]=useState(0);
    // const [rowsPerPage,setRowsPerPage]=useState(10);
    return (
        <TablePage style={{width:"100%",overflowX:"auto"}}>
            <TableContainer style={{maxWidth:"100%",margin:"0 auto"}}>
           
           <Table style={{boxSizing:"border-box"}} stickyHeader aria-label="sticky table">
                    <TableHead  style={{backgroundColor:"transparent !important"}}>
                        <TableRow>
                            {
                                
                                    tabHeader.map((column)=>(
                                        <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{minWidth:column.minWidth,backgroundColor:column.backgroundColor,color:column.color,fontSize:column.fontSize,borderBottom:column.borderBottom}}

                                        >
                                            {column.label}

                                        </TableCell>
                                    ))
                                
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            tabContent.map((row)=>{
                            return(
                                   <TableRow className={`${row.hover?"row":""}`} style={{cursor:"pointer"}} onClick={(e)=>handleOnClick(row)} tabIndex={-1}>
                                       {tabHeader.map((column)=>{
                                           const value=row[column.id];
                                           return(
                                               <TableCell>
                                                   {
                                                       value
                                                   }
                                               </TableCell>
                                           )
                                       })
                                           
                                       }
                                    </TableRow>

                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer> 

        </TablePage>
    )
} 

TableComponent.defaultProps={
    tabHeader:[],
    tabContent:[],
    handleOnClick:()=>{}
}
export default TableComponent
