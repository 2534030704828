import React from "react";

import { ClassCard } from "../../Card/Card";
import { SubjectsCard } from "../../Card/Card";
import { TopicsCard } from "../../Card/Card";

const Card = (
  type,
  data,
  handleOnClick,
  setId,
  setDisabled,
  handleDeleteChapter,
  handleEdit
) => {
  if (type === "class")
    return (
      <ClassCard
        setId={setId}
        box-sizing
        Height
        handleOnClick={handleOnClick}
        flex="0.15"
        minHeight="80px"
        data={data}
        handleEdit={handleEdit}
        setDisabled={setDisabled}
      />
    );
  else if (type === "subjects")
    return (
      <SubjectsCard
        setDisabled={setDisabled}
        setId={setId}
        handleOnClick={handleOnClick}
        flex="0.15"
        minHeight="80px"
        data={data}
        handleDeleteChapter={handleDeleteChapter}
        handleEdit={handleEdit}
      />
    );
  else if (type === "Topics")
    return (
      <TopicsCard
        setDisabled={setDisabled}
        setId={setId}
        handleOnClick={handleOnClick}
        flex="0.15"
        minHeight="80px"
        data={data}
        handleEdit={handleEdit}
      />
    );
};

function RenderCard({
  data,
  type,
  handleOnClick,
  setId,
  setDisabled,
  handleDeleteChapter,
  handleEdit,
}) {
  return Card(
    type,
    data,
    handleOnClick,
    setId,
    setDisabled,
    handleDeleteChapter,
    handleEdit
  );
}

RenderCard.defaultProps = {
  setId: () => {},
  setDisabled: () => {},
};
export default RenderCard;
