import React,{useState} from 'react'
import {DropZoneContainer,Button} from './Dropzone.style';
import { DropzoneDialog } from 'material-ui-dropzone';
function DropzoneComponent(props) {
    const {setFile,fileType}=props;
    const [open,setOpen]=useState(false);
    const handleSave=(files)=>{
        if(fileType==="image")
        {
            setFile(files[0]);
        }
        else if(fileType==="video")
        {
            setFile(files[0]);
        }
        else if(fileType==="zip")
        {
            setFile(files[0]);
        }
        else{
            setFile(files[0]);
        }   
        
    }
    return (
        <DropZoneContainer>
            <Button onClick={()=>setOpen(true)}>
            Upload
            </Button>
            <DropzoneDialog
            open={open}
            showPreviews={true}
            maxFileSize={1100000000}
            onClose={()=>setOpen(false)}
            onSave={handleSave}

            />
        </DropZoneContainer>
    )
}

export default DropzoneComponent;
