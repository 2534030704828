import styled from 'styled-components';
export const BoardsContainer=styled.div`

`

export const BoardsHeader=styled.div`
display:flex;
justify-content:space-between;
.firstClass{
    display:flex;
    flex:0.3;
    justify-content:space-between;
}
.assessment{
    flex:0.5;
}
.secondClass{
    display:flex;
    flex:0.5;
    justify-content:space-between;
}
margin:30px 0px;
.child{
    display:flex;
    align-items:center;
    
}
.iconContainer{
    padding:8px 15px;
    border-radius:5px;
    &:hover{
        background-color:#5CE0D2;
        
    }
}
.icon{
    margin-right:10px;
}
`

export const ButtonWrapper=styled.div`
// border:1px solid #5CE0D2;
// border-radius:10px;

`
export const Button=styled.button`
border:none;
padding:8px 28px;
cursor:pointer;

display:inline-block;
border:1px solid #5CE0D2;
background-color:transparent;
height:100%;
font-size:1rem;
    ${({first})=>first&&`
        border-top-left-radius:10px;
        border-bottom-left-radius:10px;
    `}
    ${({second})=>second&&`
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
    `}
${({active})=>active&&`background-color:#5CE0D2;`}


`
export const SubjectsContainer=styled.div`


`
export const ChapterContainer=styled.div`


`