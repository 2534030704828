import React from 'react'

function StudentSvgComponent(props) {
    return (
    
    <div style={{background:"rgba(250, 88, 46, 0.5)",height:"31px",borderRadius:"50%",display:"flex",justifyContent:"center",alignItems:"center",width:"30px"}}>
<svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.75 17H18V15C18 13.3431 16.8583 12 15.45 12C14.6377 12 13.9142 12.4468 13.4472 13.1429M13.75 17H5.25M13.75 17V15C13.75 14.3438 13.6425 13.717 13.4472 13.1429M5.25 17H1V15C1 13.3431 2.14167 12 3.55 12C4.36227 12 5.08583 12.4468 5.55281 13.1429M5.25 17V15C5.25 14.3438 5.35746 13.717 5.55281 13.1429M5.55281 13.1429C6.17948 11.301 7.71059 10 9.5 10C11.2894 10 12.8205 11.301 13.4472 13.1429M12.05 4C12.05 5.65685 10.9083 7 9.5 7C8.09167 7 6.95 5.65685 6.95 4C6.95 2.34315 8.09167 1 9.5 1C10.9083 1 12.05 2.34315 12.05 4ZM17.15 7C17.15 8.10457 16.3889 9 15.45 9C14.5111 9 13.75 8.10457 13.75 7C13.75 5.89543 14.5111 5 15.45 5C16.3889 5 17.15 5.89543 17.15 7ZM5.25 7C5.25 8.10457 4.48888 9 3.55 9C2.61112 9 1.85 8.10457 1.85 7C1.85 5.89543 2.61112 5 3.55 5C4.48888 5 5.25 5.89543 5.25 7Z" stroke="#FA582E" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>



    )
}

export default StudentSvgComponent
