import styled from 'styled-components';

export const StudentPageContainer=styled.div`

`

export const StudentsPageContent=styled.div`

`

export const StudentDetailsContainer=styled.div``

export const StudentProfileContent=styled.div`

margin-top:16px;
display:flex;
.firstChild{
    display:flex;
    flex-direction:column;
    
}
.secondChild{
    flex:1;
    padding-left:100px;
    
}
.flx{
    padding-left:200px;
}

`