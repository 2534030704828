import React from "react";
import { FileContainer } from "./Media.style";

function Video(props) {
  const { file } = props;
  return (
    <FileContainer>
      <video
        width="60%"
        style={{
          marginTop: "2rem",
          border: "5px solid #5CE0D2",
          borderRadius: "3px",
        }}
        controls
        controlsList="nodownload"
      >
        <source src={file} type="video/mp4" />
        <source src={file} type="video/avi" />
        <source src={file} type="video/ogg" />
        <source src={file} type="video/swf" />
        Your browser does not support HTML video.
      </video>
    </FileContainer>
  );
}

export default Video;
