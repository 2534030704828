import React, { useState, useEffect } from "react";
import TableComponent from "../../Table/Table";
import { useParams } from "react-router-dom";
import { Courses } from "../../Table/TableHeaders";
import { CommonHeader } from "./TabComponent.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import { Line } from "../Dashboard.style";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@mui/material/Tooltip";
import { useAuth } from "../../../Auth/Auth";
import axios from "axios";
import { schools, courses } from "../../../Api/api";
import CradPopUp from "../../CommonComponent/CardPopUp/CardPopUp";

function CoursesComponent(props) {
  const [openpopup, setPopupOpen] = useState(false);

  const handleClickOpen = () => {
    setPopupOpen(true);
  };

  const [modal, setModal] = useState(false);
  const { readCookie, DisplaySnackbar } = useAuth();
  const { schoolId, schoolName } = useParams();
  const [tabContent, setTabContent] = useState([]);
  const [classes, setClasses] = useState([]);
  const [coursedata, setCoursedata] = useState([]);
  // const coursess = props.schoolsData.courses;
  const [formData, setFormData] = useState([]);
  const [schoolsData, setSchoolsData] = useState([]);
  const [boardsId, SetBoards] = useState({});

  console.log("boardsid:", boardsId);

  const handleDeleteCourseBySchool = (schoolId, coursesId) => {
    axios
      .delete(schools.deleteCourseBySchoolId(schoolId, coursesId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(`Class  Deleted`, "success");
          handleupdateCourselist(coursesId);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateCourselist = (coursesId) => {
    const updatedList = [...classes].filter((i) => i._id !== coursesId);
    setClasses(updatedList);
  };

  useEffect(() => {
    axios
      .get(schools.getSchoolsById(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setSchoolsData(res.data.data.school);
        SetBoards(res.data.data.boardId);
        setClasses(res.data.data.school.courses);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleAddCourse = async (ids) => {
    const addedCourses = [];
    for await (let id of ids) {
      let courseName = formData.find((item) => item.value === id);
      if (classes.find((item) => item._id === courseName.value)) {
        continue;
      }
      let data = { courses: [courseName.value] };
      await axios
        .post(schools.addCourseBySchool(schoolId), data, {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            addedCourses.push({
              _id: courseName.value,
              name: courseName.name,
              boardId: boardsId,
              totalSubjects: courseName.totalSubjects,
            })
          }
        })
        .catch((err) => {
          if (err.response) {
            DisplaySnackbar(
              `Error occured while adding ${courseName.name}: ${err.response.data.message}`,
              "error"
            );
          }
        });
    }

    setClasses([...classes, ...addedCourses]);
    DisplaySnackbar(`Course ${addedCourses.map(c => c.name).join(",")} Added`, "success");
    setModal(false);
    setPopupOpen(false);
  };

  useEffect(() => {
    if (Object.keys(boardsId).length > 0) {
      axios
        .get(courses.getBoardContent(boardsId), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setCoursedata(
            res.data.data.board.courses.map((item) => {
              return { name: item.name, value: item._id };
            })
          );
          setFormData(
            res.data.data.board.courses.map((item) => {
              return {
                name: item.name,
                value: item._id,
                totalSubjects: item.totalSubjects,
              };
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [boardsId]);
  useEffect(() => {
    setTabContent(
      classes.map((item, idx) => {
        return {
          sno: idx + 1,
          class: item.name,
          enable: (
            <Tooltip title="Delete">
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteCourseBySchool(schoolId, item._id);
                }}
                color="secondary"
              />
            </Tooltip>
          ),
        };
      })
    );
  }, [classes]);

  return (
    <div>
      <CommonHeader>
        <div style={{ display: "flex", fontWeight: "500" }}>
          <div
            className="iconContainer"
            onClick={
              () => handleClickOpen()
              // handleCreate()
            }
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <AddBoxOutlinedIcon style={{ marginRight: "7px" }} /> Add Course
          </div>
        </div>
        <CradPopUp
          open={openpopup}
          Title={`Add Course`}
          Disagree={"Cancel"}
          Agree={"Create"}
          formData={formData}
          handleAddCourse={handleAddCourse}
          onClose={(isDelete) => {
            setPopupOpen(!openpopup);
            //   if (isDelete) handleDeleteChapter(data._id);
          }}
        />
      </CommonHeader>
      <Line />
      <div style={{ maxWidth: "70vw" }}>
        <TableComponent tabHeader={Courses} tabContent={tabContent} />
      </div>
    </div>
  );
}

export default CoursesComponent;
