import React, { useEffect, useState } from "react";
import {
  SidebarContainer,
  SidebarContent,
  UserContainer,
  ToogleButton,
  UserDetails,
} from "./Sidebar.style";
import LeftArrowComponent from "../../../Svg/LeftArrow.component";
import AvatarComponent from "../../../Svg/Avatar.Component";
import DropDown from "./DropDown";
import axios from "axios";
import { schools, subjects, chapter, Student } from "../../../../Api/api";
import { useAuth } from "../../../../Auth/Auth";
function Sidebar(props) {
  const {
    show,
    setShow,
    schoolId,

    formData,
    setFormData,
    handleStudent,
    activeStudent,
    setGrade,
    type,
    setSubjectData,
    isSubject,
    isChapter,
  } = props;
  const { readCookie } = useAuth();
  const [classSection, setClassSection] = useState([]);
  const [subject, setSubject] = useState([]);
  //    const [activeStudent,setActiveStudent]=useState("");
  const [filterSubject, setFilterSubject] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topic, setTopic] = useState([]);
  const [student, setStudent] = useState([]);
  const [search, setSearch] = useState("");
  const getSubject = () => {
    // axios.get()
  };
  useEffect(() => {
    axios
      .get(schools.getClasses(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.grades;
        let tempclass = [];
        let tempSubject = [];
        // console.log(data);
        data.forEach((grade) => {
          grade.sections.forEach((section) => {
            tempclass.push({
              name: `${grade.grade}-${section.section}`,
              sectionId: section._id,
              classId: grade._id,
            });
          });

          grade.subjects.forEach((subject) => {
            tempSubject.push({
              name: subject.subject,
              subjectId: subject.subjectId,
              uniqueGrade: grade.uniqueGrade,
              gradeId: grade._id,
            });
          });
        });
        tempclass.length &&
          tempclass.sort((a, b) => {
            if (a.name > b.name) return 1;
            else return -1;
          });
        if (type === "test") {
          setFormData({ ...formData, class: tempclass[0] });
        }
        //console.log(tempclass);
        //console.log(tempSubject);

        setClassSection(() => [...tempclass]);
        setGrade(() => [...tempclass]);
        setSubject(() => [...tempSubject]);
      })
      .catch((err) => console.log(err));
    getSubject();
  }, []);
  // console.log(formData);
  // console.log(subject);
  useEffect(() => {
    if (formData.class) {
      let temp = subject.filter(
        (item) => item.gradeId === formData.class.classId
      );
      // console.log(temp);
      setFormData({ class: formData.class, subject: "", chapter: "" });
      handleStudent("");
      setFilterSubject(() => [...temp]);
      setSubjectData(() => [...temp]);
    }
  }, [formData.class]);
  useEffect(() => {
    if (formData.chapter) {
      axios
        .get(chapter.getTopicsByChapter(formData.chapter.chapterId), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          //console.log(res);
          let data = res.data.data.chapter.topics;
          handleStudent("");
          setTopic(
            data.map((item) => {
              return {
                name: item.name,
                topicId: item._id,
              };
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [formData.chapter]);
  useEffect(() => {
    if (formData.subject) {
      axios
        .get(subjects.getTopicBySubjects(formData.subject.subjectId), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          // console.log(res);
          let data = res.data.data.subject.chapters;
          setFormData({
            class: formData.class,
            subject: formData.subject,
            chapter: "",
          });
          handleStudent("");

          setChapters(
            data.map((item) => {
              return {
                name: item.name,
                chapterId: item._id,
              };
            })
          );
        })
        .catch((err) => console.log(err));
    }
  }, [formData.subject]);
  useEffect(() => {
    if (search) {
      axios
        .get(Student.getFilterDataBySchool(schoolId, search), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          // console.log(res);
          let data = res.data.data.students;
          let temp = formData?.class?.name;
          let temp2;
          if (temp)
            temp2 = data.filter(
              (item) => `${item.grade}-${item.section}` === temp
            );
          else temp2 = data;
          // console.log(temp2);
          setStudent(() => [...temp2]);
        })
        .catch((err) => console.log(err));
    } else {
      setStudent([]);
    }
  }, [search]);
  //console.log(student);
  return (
    <SidebarContainer show={show}>
      <div className="wraper">
        {show && (
          <>
            <SidebarContent>
              <DropDown
                name="class"
                variable="classId"
                formData={formData}
                setFormData={setFormData}
                placeholder="Class-section"
                options={classSection}
                width={!isSubject}
              />
              {isSubject && (
                <DropDown
                  name="subject"
                  placeholder="Subject"
                  formData={formData}
                  setFormData={setFormData}
                  variable="subjectId"
                  options={filterSubject}
                />
              )}
            </SidebarContent>
            <SidebarContent>
              {isChapter && (
                <DropDown
                  name="chapter"
                  placeholder="chapter"
                  formData={formData}
                  setFormData={setFormData}
                  variable="chapterId"
                  options={chapters}
                />
              )}
              {/* <DropDown name="topic" placeholder="topic" formData={formData} setFormData={setFormData} variable="topicId" options={topic}/> */}
            </SidebarContent>
            <input
              placeholder="Student"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              value={search}
              className="input"
            />
            <UserContainer>
              {student.length &&
                student.map((stu) => {
                  return (
                    <UserDetails
                      active={activeStudent === stu._id}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleStudent(stu);
                      }}
                    >
                      <div className="ftDiv">
                        <AvatarComponent />
                      </div>
                      <div className="stDiv">{stu.name}</div>
                      <div className="thddiv">
                        <div className="radioButton"></div>
                      </div>
                    </UserDetails>
                  );
                })}
            </UserContainer>
          </>
        )}
        <ToogleButton show={show} onClick={() => setShow(!show)}>
          <LeftArrowComponent
            style={{
              transform: "rotate(-90deg) translateX(-50%)",
              marginLeft: "41%",
            }}
          />
        </ToogleButton>
      </div>
    </SidebarContainer>
  );
}

Sidebar.defaultProps = {
  type: "",
  setGrade: () => {},
  setSubjectData: () => {},
  isSubject: true,
  isChapter: true,
};
export default Sidebar;
