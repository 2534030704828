import React from "react";
import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header, Container } from "./Modal.style";
import Details from "../Details/Details";

import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";

const MODAL_TYPE = {
  SUBJECT: "SUBJECT",
  CHAPTER: "CHAPTER",
  TOPIC: "TOPIC",
};

function AddModalComponent({
  setModal,
  label,
  headerText,
  headerText2 = "",
  EditChapter,
  formData,
  setFormData,
  label2 = [],
  buttonText,
  link = false,
  maxWidth = "",
  handleMultiSelect,
  minWidth,
  handleCreate,
  EditSubject,
  modalType,
  EditTopic,
  data,
  isBoard,
  EditBoard,
  editClass,
  handleEditModule,
}) {
  const handleOnChange = (e) => {
    console.log(formData, e.target.name, e.target.value);
    if (formData?.subject) {
      console.log("Changed");
      setFormData((formData) => ({
        ...formData, "subject": []
      }))
    }
    setFormData((formData) => ({ ...formData, [e.target.name]: e.target.value }));
  };

  const callbackFunction = (type = "SUBJECT") => {
    if (type === "SUBJECT") {
      EditSubject();
    } else if (type === "CHAPTER") {
      EditChapter();
    } else if (type === "TOPIC") {
      EditTopic();
    } else if (type === "BOARD") {
      EditBoard();
    } else if (type === "CLASS") {
      editClass();
    } else if (type === "COUNTRY") {
      handleCreate();
    } else if (type === "MODULE") {
      handleEditModule();
    }
  };

  return (
    <ModalWrapper>
      <ModalContainer
        style={{
          minWidth: `${minWidth || "400px"}`,
          maxWidth: `${maxWidth || "400px"}`,
        }}
      >
        <Header>
          <h2>{headerText}</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <Container>
          <Details
            formData={formData}
            handleOnClick={handleMultiSelect}
            handleOnChange={handleOnChange}
            margintop="10px"
            labelcolor="black"
            color="grey"
            fontsize=".9rem"
            height="32px"
            data={label}
            edit={true}
          />
        </Container>

        {link && (
          <>
            <Header>
              <h4>{headerText2}</h4>
            </Header>
            <Container>
              <Details
                formData={formData}
                handleOnClick={handleMultiSelect}
                handleOnChange={handleOnChange}
                labelcolor="black"
                color="grey"
                fontsize=".9rem"
                height="32px"
                data={label2}
                edit={true}
              />
            </Container>
          </>
        )}

        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          {buttonText === "Edit" ? (
            <Button
              onClick={() => {
                callbackFunction(modalType);
              }}
            >
              {buttonText}
            </Button>
          ) : (
            <Button onClick={() => handleCreate()}>{buttonText}</Button>
          )}
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

AddModalComponent.defaultProps = {
  buttonText: "Create",
};
export default AddModalComponent;
