import React from 'react'

function ChaptersSvgComponent() {
    return (
        <div style={{background:"rgba(157, 171, 221, 0.5)",height:"31px",borderRadius:"50%",display:"flex",justifyContent:"center",alignItems:"center",width:"30px"}}>
        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.0833 1.66669H4.74996C4.33003 1.66669 3.92731 1.84228 3.63037 2.15484C3.33344 2.4674 3.16663 2.89133 3.16663 3.33335V16.6667C3.16663 17.1087 3.33344 17.5326 3.63037 17.8452C3.92731 18.1578 4.33003 18.3334 4.74996 18.3334H14.25C14.6699 18.3334 15.0726 18.1578 15.3695 17.8452C15.6665 17.5326 15.8333 17.1087 15.8333 16.6667V6.66669L11.0833 1.66669Z" stroke="#9DABDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.0834 1.66669V6.66669H15.8334" stroke="#9DABDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.6667 10.8333H6.33337" stroke="#9DABDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.6667 14.1667H6.33337" stroke="#9DABDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.91671 7.5H7.12504H6.33337" stroke="#9DABDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>

        </svg>
        </div>
        

    )
}

export default ChaptersSvgComponent
