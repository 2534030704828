import React from 'react';
import { LoginContainer,ImgContainer } from './Login.style';
import Loginimg from '../../images/wave.png';
import LogInForm from './LogInForm';


function Login() {
    
    return (
        <LoginContainer>
            <ImgContainer>
                <img src={Loginimg} alt="loginimg"/>
            </ImgContainer>
            <LogInForm>

            </LogInForm>
        </LoginContainer>
    )
}

export default Login
