import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../../Loading/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DialogContentText } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth } from "../../../Auth/Auth";
import { schools } from "../../../Api/api";
import axios from "axios";
import { Tooltip } from "@mui/material";

export default function ListPopUp({
  open,
  onClose,
  Disagree,
  Agree,
  Title,
  loading,
  selectedSubjects,
  schoolId,
  gradeName,
  updateSubjectList,
  classId,
}) {
  const { readCookie, DisplaySnackbar } = useAuth();
  const [allSubjects, setAllSubjects] = useState([]);

  useEffect(() => {
    setAllSubjects(selectedSubjects);
  }, [selectedSubjects]);

  const handleDeleteSubjectByClass = (gradeName, subjectName, schoolId) => {
    axios
      .delete(
        schools.deleteSubjectByGradeName(gradeName, subjectName, schoolId),
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Subject deleted", "success");
          handleupdateSubjectlist(subjectName);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateSubjectlist = (subjectName) => {
    const updatedList = [...allSubjects].filter(
      (i) => i.subject !== subjectName
    );
    setAllSubjects(updatedList);
    updateSubjectList(classId, updatedList);
  };

  return (
    <div>
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle id="alert-dialog-title">{Title}</DialogTitle>
        <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
          <TableBody>
            {allSubjects.map((item) => (
              <TableRow>
                <TableCell component="th" scope="row" align="left">
                  {item.subject}
                </TableCell>
                <TableCell align="right">
                  <Tooltip title="Delete">
                    <DeleteIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSubjectByClass(
                          gradeName,
                          item.subject,
                          schoolId
                        );
                      }}
                      color="secondary"
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={() => onClose(false)} autoFocus>
            {Disagree}
          </Button>
          <Button onClick={() => onClose(true)}>{Agree}</Button>
          {loading && <Loader type="spin" color="cyan" />}
        </DialogActions>
      </Dialog>
    </div>
  );
}
