import React from 'react';
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {PieChart,Pie,Cell, ResponsiveContainer,Tooltip} from 'recharts';
import Loader from './Loader/Loader';

const data02 = [
    {
      "name": "Group A",
      "value": 80
    },
    {
      "name": "Group B",
      "value": 20
    },
    
  ];
  const COLORS=["#FF8989","#0F93A8"]
  const RADIAN = Math.PI / 180;

function AnalyticsContainer2(props) {
  const {heading,subHeading,colors,field,text,data02,loading,classes}=props;
  const [total,setTotal]=React.useState(0)
  React.useEffect(()=>{
    let temp=0;
    data02.forEach((item)=>{
      temp+=item.value;
    })
    setTotal(temp);

  },[data02])
  
    return (
        <AnalyticsContainer absolute>
           {loading?<Loader/>:<>
            <div className='firstDiv'>
            <div style={{textAlign:"right"}}>
          <div style={{fontWeight:"600",fontSize:"1.2rem",textAlign:"center"}}>{heading} {classes}</div>
          <div style={{textAlign:"center"}}>{subHeading} {total||"0"}</div>
          </div>
            <ResponsiveContainer>
            <PieChart width={700} height={250}>
  <Pie data={data02} stroke=''   fill="#82ca9d"   dataKey="value" nameKey="name" cx="40%" cy="40%" outerRadius={120}  >
      {data02.map((entry, index) => <Cell fill={colors[index % colors.length]}/>)}
      </Pie>
  {/* <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label /> */}
  <Tooltip />
</PieChart>
</ResponsiveContainer>
</div>
<div  className='secondDiv'>
    <div style={{display:"flex",alignItems:"center"}}>
        <div style={{width:"20px",height:"10px",backgroundColor:colors[0]}}></div>
        <div style={{marginLeft:"5px"}}>{field[0]}</div>
    </div>
    <div style={{display:"flex",alignItems:"center",marginTop:"7px"}}>
        <div style={{width:"20px",height:"10px",backgroundColor:colors[1]}}></div>
        <div style={{marginLeft:"5px"}}>{field[1]}</div>
    </div>
</div>
</>}
        </AnalyticsContainer>
    )
}


AnalyticsContainer2.defaultProps={
  heading:"Test Status of class ",
  subHeading:"Total Test Assigned",
  colors:["#0F93A8","#FF8989"],
  field:["Completed","Pending"],
  text:true,
  data02:[],
  classes:"",
  loading:false
}
export default AnalyticsContainer2
