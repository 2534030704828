import styled from "styled-components";

export const AnalyticsContainer=styled.div`


`
export const AnalyticsHeader=styled.div`
.firstClass{
    display:flex;
    justify-content:space-between;
}
h2{
    margin:0px;
}
.secondClass{
    margin-top:30px;
    display:flex;
}

`
export const ExportPageButton=styled.div`
display:flex;
align-items:center;
border-radius:20px;
padding:10px 15px;
background-color: #f7f7f7;
opacity:0.8;
box-shadow: 0px 3px 3px 0px #00000040;

`

export const AnalyticsContent=styled.div`

margin-top:50px;


`

export const GraphContainer=styled.div`

box-shadow: 0px 3px 3px 0px #00000040;
background-color: #f7f7f7;

border-radius:20px;
height:${({minHeight})=>minHeight};
padding:16px;
box-sizing:border-box;

`
export const GraphHeader=styled.div`
display:flex;
h1 ,p{
    margin:0px;
    display:flex;
    align-item:center;
}
h1{
    font-size:1.7rem;
    font-weight:600;
}
justify-content:space-between;

`
export const GraphContent=styled.div`
position:relative;
.label{
    position:absolute;
    top:50%;
    font-weight:500;
    transform:translateY(-50%) rotate(-90deg);
    left:-2.8rem;
    
}

`

export const ActiveUsersContent=styled.div`

`
export const UsersList=styled.div`

`