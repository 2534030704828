import styled from "styled-components";

export const PracticeAssessmentContainer=styled.div`

`
export const PracticeAssessmentHeader=styled.div`
.tabContainer{
    width:80%;
    margin:0 auto;
    display:flex;
    justify-content:space-between;
}

`
export const PracticeAssessmentContent=styled.div`
display:flex;
justify-content:space-between;
flex-wrap:wrap;


`

export const PracticeAssessmentTable=styled.div`

// position:relative;
`