                                                                                                                                                     import React, { useState, useEffect } from "react";
import {
  ManageSectionContainer,
  ManageSectionHeader,
  ManageSectionContent,
} from "./ManageSection.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import { ManageSectionCard } from "../Card/Card";
import { Line } from "../Dashboard/Dashboard.style";
import AddModalComponent from "../Modal/AddModalComponent";
import axios from "axios";
import { Section } from "../../Api/api";
import { useAuth } from "../../Auth/Auth";
import { addsection } from "../RenderDetailsData/RenderDetailsData";

// const data=[{sectionName:"A"},{sectionName:"B"},{sectionName:"C"}];

function ManageSectionComponent() {
  // console.log("Called")
  const { readCookie, DisplaySnackbar } = useAuth();
  const [modal, setModal] = useState(false);
  const [section, setSection] = useState("");
  const [sectionData, setSectionData] = useState([]);
  const [formData, setFormData] = useState({ name: "" });
  const [headerText, setHeaderText] = useState("Add Section");
  const [buttonText, setButtonText] = useState("Create");
  const [id, setId] = useState("");

  const handleOnClick = (sec, id, dc) => {
    if (dc !== "del") {
      setSection(sec);
      setHeaderText("Edit Section");
      setFormData({ name: sec });
      setButtonText("Edit");
      setModal(true);
      setId(id);
    } else {
      axios
        .delete(Section.deleteSection(id), {
          headers: {
            token: `${readCookie("token")}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setSectionData(sectionData.filter((item) => item._id !== id));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const data = addsection(section, headerText);

  useEffect(() => {
    // console.log("TES");
    axios.get(Section.getSection, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setSectionData(res.data.data.sections))
      .catch((err) => console.log(err));
    // console.log("Done");
  }, []);

  useEffect(() => {
    setFormData({ name: "" });
  }, [modal]);

  const handleCreateSection = () => {
    if (!formData.name) {
      DisplaySnackbar("Enter Section First", "success");
      return;
    }
    if (headerText === "Add Section") {
      axios
        .post(
          Section.addSection,
          { section: formData.name },
          {
            headers: {
              token: `${readCookie("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setSectionData([
            ...sectionData,
            {
              section: formData.name,
              _id: res.data.data.section._id,
              enabled: true,
            },
          ]);
          DisplaySnackbar("Section Added", "success");

          setFormData({ name: "" });
          setModal(false);
        })
        .catch((err) => {
          if (err.response) {
            DisplaySnackbar(err.response.data.message, "error");
          }
        });
    } else {
      axios
        .patch(
          Section.editSection(id),
          { section: formData.name, enabled: true },
          {
            headers: {
              token: `${readCookie("token")}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          // console.log("rahul");
          if (res.data.status === "success") {
            DisplaySnackbar("Section Edited", "success");
            setSectionData(
              sectionData.map((item) =>
                item._id === id ? { ...item, section: formData.name } : item
              )
            );

            setFormData({ name: "" });
            setModal(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            DisplaySnackbar(err.response.data.message, "error");
          }
        });
    }
  };
  return (
    <ManageSectionContainer>
      <ManageSectionHeader>
        <div>
          <h1> Manage Section</h1>
        </div>
        <div className="add-section">
          <AddBoxOutlinedIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setHeaderText("Add Section");
              setButtonText("Create");
              setSection("");
            }}
          />{" "}
          Add Section
        </div>
      </ManageSectionHeader>
      <Line />
      <ManageSectionContent>
        {sectionData?.map((item) => {
          {/* console.log(item); */}
          return (
            <ManageSectionCard
              marginBottom="16px"
              width="20%"
              minHeight="100px"
              key={item._id}
              id={item._id}
              enabled={item.enabled}
              handleOnClick={handleOnClick}
              sectionName={item.section}
            />
          );
        })}
      </ManageSectionContent>
      {modal && (
        <AddModalComponent
          formData={formData}
          setFormData={setFormData}
          headerText={headerText}
          label={data}
          buttonText={buttonText}
          handleCreate={handleCreateSection}
          section={section}
          setModal={setModal}
        />
      )}
    </ManageSectionContainer>
  );
}

export default ManageSectionComponent;
