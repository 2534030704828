import "./App.css";
import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import TeacherProfileComponent from "./Components/Teacher/TeacherProfileComponent";
import Subjects from "./Components/Course/boards/Subjects/Subjects";

import Dashboard from "./Components/Dashboard/Dashboard";
import SchoolComponent from "./Components/Dashboard/Schools/SchoolPage";
import TeacherPage from "./Components/Teacher/TeacherPage";
import StudentsPage from "./Components/Students/StudentsPage";
import EditProfile from "../src/Components/Profile/EditProfile";
import StudentProfileComponent from "./Components/Students/StudentProfileComponent";
import Setting from "./Components/Settings/Setting";
import ManageSectionComponent from "./Components/ManageSection/ManageSectionComponent";
import StudentPracticeAssessment from "./Components/SchoolsAnalytics/PracticeAssessment/StudentPracticeAsseement";
import Courses from "./Components/Course/Courses";
import Boards from "./Components/Course/boards/Boards";

import Analytics from "./Components/Analytics/Analytics";
import Resources from "./Components/Course/boards/Subjects/Chapters/Resources/Resources";
import PracticeAssessment from "./Components/SchoolsAnalytics/PracticeAssessment/PracticeAssessment";
import OnlineAssessment from "../src/Components/SchoolsAnalytics/OnlineAssessment/OnlineAssessment";
import StudentOnlineAssessment from "../src/Components/SchoolsAnalytics/OnlineAssessment/OnlineAssessmentStudent";
import Chapters from "./Components/Course/boards/Subjects/Chapters/Chapters";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import SchoolsAttendance from "./Components/SchoolsAnalytics/AttendanceReport/SchoolsAttendance";
import StudentAttendance from "./Components/SchoolsAnalytics/AttendanceReport/StudentAttendance";
import OverAllAttendance from "./Components/SchoolsAnalytics/AttendanceReport/OverAllAttendaceReport";
import { AuthProvider } from "./Auth/Auth";
import Media from "./Components/Media/Media";
import Login from "./Components/Login/Login";
import HomeWork from "./Components/SchoolsAnalytics/Homework/HomeWork";
import ClassHomeWork from "./Components/SchoolsAnalytics/Homework/ClassHomeWork";
import ResourcesAnalytics from "./Components/SchoolsAnalytics/Resources/ResourcesAnalytics";
import ResourcesStudent from "./Components/SchoolsAnalytics/Resources/ResourcesStudent";

const route = [
  "/",
  "/teacher",
  "/students",
  "/courses",
  "/analytics",
  "/manageSection",
  "/settings",
];
const App = () => {
  const [collapse, setCollapse] = useState(false);
  const location = useLocation();
  const [maxWidth, setMaxWidth] = useState("");

  useEffect(() => {
    let path = location.pathname;
    let dc = route.find((item) => item === path);
    if (
      path === "/practiceAnalytics" ||
      path === "/practiceAnalytics/student" ||
      path === "/onlineAssessment"
    ) {
      setMaxWidth("90vw");
    } else {
      setMaxWidth("");
    }

    if (!dc) setCollapse(true);
    else setCollapse(false);
  }, [location]);

  return (
    <div className="wrapper">
      <AuthProvider>
        {location.pathname !== "/login" && (
          <>
            <Navbar collapse={collapse} />
            <div
              style={{
                flex: "1",
                padding: "20px",
                maxWidth: `${maxWidth ? maxWidth : "100%"}`,
              }}
            >
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/dashboard/:schoolName/:schoolId"
                  element={<SchoolComponent />}
                />
                <Route
                  path="/dashboard/:schoolName/:schoolId/staff/:staffId"
                  element={<TeacherProfileComponent />}
                />
                <Route path="/teacher" element={<TeacherPage />} />
                <Route path="/students" element={<StudentsPage />} />
                <Route
                  path="/dashboard/:schoolName/:schoolId/student/:studentId"
                  element={<StudentProfileComponent />}
                />
                <Route path="/settings" element={<Setting />} />
                <Route
                  path="/manageSection"
                  element={<ManageSectionComponent />}
                />
                <Route path="/courses" element={<Courses />} />
                <Route path="/courses/:countryName/:id" element={<Boards />} />
                <Route
                  path="/courses/:countryName/:id/:boardName/:boardId/:className/:classId"
                  element={<Subjects />}
                />
                <Route
                  path="/courses/:countryName/:id/:boardName/:boardId/:className/:classId/:subjectName/:subjectId/:chapterName/:chapterId"
                  element={<Chapters />}
                />
                <Route path="/login" element={<Login />} />
                <Route
                  path="/courses/:countryName/:id/:boardName/:boardId/:className/:classId/:subjectName/:subjectId/:chapterName/:chapterId/:TopicName/:TopicId/modules/:modulesId"
                  element={<Resources />}
                />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/media" element={<Media />} />
                <Route path="/profile" element={<EditProfile />} />
                <Route
                  path="/practiceAnalytics"
                  element={<PracticeAssessment />}
                />
                <Route
                  path="/practiceAnalytics/student"
                  element={<StudentPracticeAssessment />}
                />
                <Route
                  path="/onlineAssessment"
                  element={<OnlineAssessment />}
                />
                <Route
                  path="/onlineAssessment/student"
                  element={<StudentOnlineAssessment />}
                />
                <Route
                  path="/schoolsAttendanceReport"
                  element={<SchoolsAttendance />}
                />
                <Route
                  path="/schoolsAttendanceReport/student"
                  element={<StudentAttendance />}
                />
                <Route
                  path="/overAllAttendance"
                  element={<OverAllAttendance />}
                />
                <Route path="/homework" element={<HomeWork />} />
                <Route path="homework/class" element={<ClassHomeWork />} />
                <Route
                  path="/resourcesAnalytics"
                  element={<ResourcesAnalytics />}
                />
                <Route
                  path="/resourcesStudent"
                  element={<ResourcesStudent />}
                />
              </Routes>
            </div>
          </>
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
