import React from "react";
import { useState } from "react";
import { CardContainer, CardHeading } from "./Card.style";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Switch from "@material-ui/core/Switch";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "../Course/boards/react-contextmenu.css";
import Popup from "../CommonComponent/Popup/Popup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { padding, textAlign } from "@mui/system";

function Card(props) {
  const [openpopup, setPopupOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    name,
    handleOnClick,
    id,
    toogleDisable,
    handleEdit,
    handleDelete,
    handleDeleteChapter,
  } = props;

  const handleClickOpen = () => {
    setPopupOpen(true);
  };

  const randomString1 = `${btoa(Math.random().toString()).substring(0, 16)}`;
  const ITEM_HEIGHT = 50;

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <CardContainer
        onClick={() => handleOnClick(id, name)}
        style={{ height: "100%" }}
      >
        <ContextMenuTrigger id={randomString1}>
          <CardHeading
            style={{
              display: "flex",
              fontSize: "1.1rem",
              justifyContent: "space-between",
              wordBreak: "break-all",
            }}
          >
            {name}
            <Switch
              style={{ zIndex: "100" }}
              onClick={(e) => {
                e.stopPropagation();
                toogleDisable(id);
              }}
              checked={props.enabled}
              color="primary"
              className="enableIcon"
            />
          </CardHeading>

          <div>Branch: {props.branch}</div>
          <div>City: {props.city}</div>
          <div>State: {props.state}</div>
        </ContextMenuTrigger>
      </CardContainer>
      <ContextMenu id={randomString1}>
        <MenuItem
          onClick={() => {
            handleEdit(props);
          }}
        >
          <Tooltip title="Edit">
            <div className="context">
              <EditIcon />
              <span>Edit</span>
            </div>
          </Tooltip>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.stopPropagation();
            handleClickOpen();
          }}
        >
          {" "}
          <Tooltip title="Delete">
            <div className="context">
              <DeleteOutlineIcon />
              <span>Delete</span>
            </div>
          </Tooltip>
        </MenuItem>
      </ContextMenu>
      <Popup
        open={openpopup}
        Title={` Are Your Sure You Want To Delete ${name} ?`}
        Disagree={"Cancel"}
        Agree={"Ok"}
        onClose={(isDelete) => {
          setPopupOpen(!openpopup);
          if (isDelete) handleDelete(props.id);
        }}
      />
    </>
  );
}
Card.defaultProps = {
  name: "",
  address: [],
  handleOnClick: () => {},
  id: "",
};
export default Card;

export const ManageSectionCard = (props) => {
  console.log(props);
  const { sectionName, handleOnClick, minHeight, marginBottom, width, id } = props;
  // return (<></>)
  return (
    <CardContainer
      style={{ marginLeft: "26px", minHeight: "150px" }}
      minHeight={minHeight}
      marginBottom={marginBottom}
      width={width}
    >
      <div className="onHover">Section Name: {sectionName}</div>
      <div
        onClick={() => handleOnClick(sectionName, id, "edit")}
        className="onHover"
      >
        {/* {" "} */}
        <Tooltip title="Edit">
          <span>Edit Section: <EditIcon /></span>
        </Tooltip>
      </div>
      <div
        onClick={() => handleOnClick(sectionName, id, "del")}
        className="onHover"
      >
        <Tooltip title="Delete">
          <span>{" "}
          Delete Section: <DeleteOutlineIcon /></span>
        </Tooltip>
      </div>
    </CardContainer>
  );
};

export const CountryCard = (props) => {
  const {
    country,
    boards,
    minHeight,
    flex,
    handleOnClick,
    id,
    width,
    marginLeft,
    marginBottom,
    handleDeleteCountry,
    showDisable,
    data,
  } = props;

  return (
    <CardContainer
      onClick={(e) => handleOnClick(id, e)}
      hoveranimation
      style={{ height: "100%" }}
      showDisable={false}
      marginLeft={marginLeft}
      marginBottom={marginBottom}
    >
      <div>
        <CardHeading
          style={{
            marginBottom: "10px",
            fontSize: "1.2rem",
            wordBreak: "break-all",
            fontWeight: "600",
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
          }}
        >
          {country}
        </CardHeading>
      </div>
      <div>Total Boards:{boards}</div>
    </CardContainer>
  );
};

export const ClassCard = (props) => {
  const {
    data,
    handleOnClick,
    flex,
    minHeight,
    setId,
    id,
    handleEdit,
    setDisabled,
  } = props;

  return (
    <>
      {" "}
      <CardContainer
        disable={data.disabled}
        onContextMenu={() => {
          setId(data._id);
          setDisabled(data.disabled);
        }}
        afterHover
        ht
        style={{ boxSizing: "border-box" }}
        width="100%"
        onClick={() => {
          handleOnClick(data._id);
        }}
        hoveranimation
        flex={flex}
        minHeight={minHeight}
      >
        <div>
          <h3
            style={{
              marginBottom: "10px",
              fontSize: "1.2rem",
              wordBreak: "break-all",
              fontWeight: "600",
            }}
          >
            {data.name}
          </h3>
        </div>
        <div style={{ wordBreak: "break-all" }}>
          Total Subjects: {data.totalSubjects}
        </div>
      </CardContainer>
    </>
  );
};
export const SubjectsCard = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    data,
    handleOnClick,
    flex,
    minHeight,
    setId,
    setDisabled,
    id,
    handleEdit,
    handleDelete,
    handleDeleteChapter,
  } = props;

  const ITEM_HEIGHT = 50;
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = (e) => {
  //   e.stopPropagation();
  //   setAnchorEl(null);
  // };

  return (
    <>
      {" "}
      <CardContainer
        ht
        disable={data.disabled}
        afterHover
        onContextMenu={() => {
          setId(data._id);
          setDisabled(data.disabled);
        }}
        onClick={() => handleOnClick(data._id)}
        style={{ boxSizing: "border-box" }}
        width="100%"
        hoveranimation
        flex={flex}
        minHeight={minHeight}
      >
        <div>
          <h3
            style={{
              marginBottom: "10px",
              fontSize: "1.2rem",
              wordBreak: "break-all",
              fontWeight: "600",
            }}
          >
            {data.name}
          </h3>
        </div>
        <div style={{ wordBreak: "break-all" }}>
          Total Topics:{data.totalTopics}
        </div>
      </CardContainer>
    </>
  );
};

export const TopicsCard = (props) => {
  const { data, handleOnClick, flex, minHeight, setId, setDisabled } = props;

  return (
    <CardContainer
      ht
      afterHover
      disable={data.disabled}
      onContextMenu={() => {
        setId(data._id);
        setDisabled(data.disabled);
      }}
      onClick={() => handleOnClick(data._id)}
      // style={{ boxSizing: "border-box" }}
      width="100%"
      hoveranimation
      flex={flex}
      minHeight={minHeight}
    >
      <div>
        <h3
          style={{
            marginBottom: "10px",
            fontSize: "1.2rem",
            wordBreak: "break-all",
            fontWeight: "600",
          }}
        >
          {data.name}
        </h3>
      </div>
      <div style={{ wordBreak: "break-all" }}>
        Total Resources:{data.totalResources || 0}
      </div>
    </CardContainer>
  );
};
