import React from 'react'
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {BarChart,CartesianGrid,XAxis,YAxis,Tooltip,Cell,Bar,ResponsiveContainer,LabelList,Legend} from 'recharts';
import ReactLoading from 'react-loading';


const data= [
    {
      "name": "Hindi",
      "knowledge": 40,
      "understanding": 24,
      "analysis":30,
      "application":40
    },
    {
      "name": "Science",
      "knowledge": 40,
      "understanding": 24,
      "analysis":30,
      "application":40
    },]
function AnalyticsContainer6(props) {
  const {data,classes,loading}=props;
  const [total,setTotal]=React.useState(0);
  React.useEffect(()=>{
    let temp=0;
   
    data.forEach((item)=>{
      temp+=item.Knowledge+item.Understanding+item.Analysis+item.Application;
    })
    setTotal(temp);  
  
  },[data])
    return (
        <AnalyticsContainer>
          {loading?<div style={{display:"flex",height:"100%",width:"100%",alignItems:"center",justifyContent:"center"}}><ReactLoading type="spin" color="cyan" height={100} width={100}/>
          </div>:<>
           <div className='yaxis'>
          Questions
          </div>
           <div style={{textAlign:"center"}}>
            <div style={{fontSize:"1.3rem",fontWeight:"600"}}>
         Cognitive Level Report of Class {classes}
            </div>
            <div>
             Question -{total}
            </div>
          </div>
          <div style={{width:"100%",height:"320px"}}>
             <ResponsiveContainer>
            <BarChart width="100%" height="100%" data={data}>
  <CartesianGrid strokeDasharray="1 1" />
  <XAxis tick={{ fill: "rgba(0, 0, 0)" }} dataKey="name" style={{fontWeight:"600",color:"black"}} />
  <Legend style={{fontWeight:"600"}} />
  <YAxis tickCount={10}  />
  <Tooltip cursor={{fill: '#fff'}} />
  
  <Bar barSize={26} dataKey="Knowledge" fill="#5CE0D2" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"600",fontSize:".7rem"}} dataKey="Knowledge" position="middle" />
    </Bar>
  <Bar barSize={26} dataKey="Understanding" fill="#EDA1FF" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"600",fontSize:".7rem"}} dataKey="Understanding" position="middle" />
    </Bar>
  <Bar barSize={26} dataKey="Analysis" fill="#56E58F" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"600",fontSize:".7rem"}} dataKey="Analysis" position="middle" />
    </Bar>
  <Bar barSize={26} dataKey="Application" fill="#FF6A99" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"600",fontSize:".7rem"}} dataKey="Application" position="middle" />
    </Bar>
  
</BarChart>
</ResponsiveContainer>
</div></>}
        </AnalyticsContainer>
    )
}

AnalyticsContainer6.defaultProps={
  data: [
    {
      "name": "Hindi",
      "Knowledge": 40,
      "Understanding": 24,
      "Analysis":30,
      "Application":40
    },
    {
      "name": "Science",
      "Knowledge": 40,
      "Understanding": 24,
      "Analysis":30,
      "Application":40
    },],
    classes:"",
    loading:false


}
export default AnalyticsContainer6
