import React from 'react'

function LeftArrowComponent(props) {
    return (
        <svg width="14" height="22" viewBox="0 0 14 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3166 21.3556C14.2278 20.4965 14.2278 19.1035 13.3166 18.2444L5.63317 11L13.3166 3.75564C14.2278 2.89648 14.2278 1.50352 13.3166 0.644366C12.4054 -0.214789 10.928 -0.214789 10.0168 0.644366L0.683417 9.44436C-0.227806 10.3035 -0.227806 11.6965 0.683417 12.5556L10.0168 21.3556C10.928 22.2148 12.4054 22.2148 13.3166 21.3556Z" fill="white"/>
</svg>

    )
}

export default LeftArrowComponent
