import { useEffect, useState } from "react";

import axios from "axios";

import { useAuth } from "../../../Auth/Auth";
import { Module } from "../../../Api/api";

import "./Upload.css";

function Upload(props) {
  const { readCookie } = useAuth();
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState({ status: null, message: null });
  const [signedURL, setSignedURL] = useState(null);
  const [resourceURL, setResourceURL] = useState(null);

  //STEP 1: GET SIGN URL
  const getSignURL = async () => {
    try {
      status.status = "info";
      status.message = "1. Creating Secure Route";
      setStatus({ ...status });
      const res = await axios.get(
        Module.getSignedURL(props.moduleId, props.file.name, props.file.type),
        {
          headers: {
            token: `${readCookie("token")}`,
          },
          validateStatus: () => true,
        }
      );

      if (res.data.status !== "success") {
        throw res;
      }
      setSignedURL(res.data.data.signedURL.signedURL);
    } catch (err) {
      props.status.current[props.field] = "failed";
      console.log(err);
      status.status = "error";
      status.message = err.message;
      setStatus({ ...status });
    }
    return;
  };

  const uploadFile = async () => {
    try {
      status.status = "info";
      status.message = "2. Uploading File";
      setStatus({ ...status });
      await axios.put(signedURL, props.file, {
        headers: {
          "Content-Type": props.file.type,
        },
        onUploadProgress: (progressEvent) => {
          const currentProgress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setProgress(currentProgress);
        },
      });
      const url = new URL(signedURL);
      setResourceURL({
        url: `${url.origin}${url.pathname}`,
        filename: url.pathname,
      });
    } catch (err) {
      //update upload status
      props.status.current[props.field] = "failed";
      //updata status message
      status.status = "error";
      status.message = err.message || err?.response?.data?.message;
      setStatus({ ...status });
    }
    return;
  };

  const updateResource = async () => {
    try {
      status.status = "info";
      status.message = "3. Updating Resource";
      setStatus({ ...status });
      await axios.patch(
        Module.updateResource(props.moduleId),
        {
          fieldname: props.field,
          fileurl: resourceURL.url,
          filename: resourceURL.filename,
        },
        {
          headers: {
            token: readCookie("token"),
            "content-type": "application/json",
          },
        }
      );

      if (props.isResourceLevel) {
        props.setResources({
          fieldName: props.field,
          fileUrl: resourceURL.url,
          fileName: resourceURL.filename,
        });
      }

      props.status.current[props.field] = "success";
      status.status = "success";
      status.message = "Upload Completed Successfully";
      setStatus({ ...status });
    } catch (err) {
      props.status.current[props.field] = "failed";
      console.log(err);
      status.status = "error";
      status.message = err.message || err?.response?.data?.message;
      setStatus({ ...status });
    }
    return;
  };

  //STEP 1 SIGN URL
  useEffect(() => getSignURL(), [props.file]);

  //STEP 2 UPLOAD FILE
  useEffect(() => {
    if (!signedURL) return;
    uploadFile();
  }, [signedURL]);

  //STEP 3 UPDATE RESOURCE
  useEffect(() => {
    if (!resourceURL) return;
    updateResource();
  }, [resourceURL]);

  return (
    <div className="file">
      <span>{props.field}</span>
      <div
        className={
          status.status ? `file-details ${status.status}` : "file-details"
        }
      >
        <p style={{ wordBreak: "break-word" }}>{props.file.name}</p>
        <span>{progress}%</span>
      </div>
      {status.message && (
        <span className={`file-upload-${status.status}`}>{status.message}</span>
      )}
    </div>
  );
}

export default Upload;
