import React from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "./Headerfeatures.style";

import ProfileImage from "../../images/profileImg.svg";
import { Link } from "react-router-dom";
import { useAuth } from "../../Auth/Auth";
import InputComponent from "../Input/InputComponent";
function HeaderPage(props) {
  const navigate = useNavigate();

  const {
    profilePic,
    title,
    teachers,
    currentPage,
    margin,
    leftHeader,
    close,
  } = props;

  const navigateTo = (path, tab) => {
    if (!path) return "/";
    const splittedPath = path.split("=");
    splittedPath[1] = tab;
    navigate(splittedPath.join("="));
  };

  return (
    <Header margin={margin}>
      <div className="ft">
        <div className="firstChild">{title}</div>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="secondChild"
        >
          {props.showSearch && (
            <InputComponent
              val={props.search}
              setVal={props.setSearch}
              type="text"
              search
              placeholder="Search..."
            />
          )}
          {profilePic ? (
            <Link style={{ marginLeft: "10px" }} to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImage}
                alt="profileimg"
              />
            </Link>
          ) : close ? (
            <h2 style={{ margin: "0px" }}>×</h2>
          ) : (
            ""
          )}
        </div>
      </div>
      {leftHeader ? (
        leftHeader
      ) : (
        <div className="st">
          <span
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            Dashboard
          </span>
          {` > `}
          <span
            onClick={() => {
              currentPage !== "/school" && navigate(props.schoolPath);
            }}
            style={{
              fontWeight: `${currentPage === "/school" ? "500" : ""}`,
              cursor: "pointer",
            }}
          >
            School
          </span>
          {teachers && (
            <span>
              {` > `}{" "}
              <span
                onClick={() => navigateTo(props.schoolPath, "staffs")}
                style={{ cursor: "pointer" }}
              >
                Staffs
              </span>
              {` > `}
              <span
                style={{
                  fontWeight: "600",
                  color: "#5CE0D2",
                  cursor: "none",
                }}
              >
                Profile
              </span>
            </span>
          )}
          {props.students && (
            <span>
              {` > `}{" "}
              <span
                onClick={() => navigateTo(props.schoolPath, "students")}
                style={{ cursor: "pointer" }}
              >
                Students
              </span>
              {` > `}
              <span
                style={{
                  fontWeight: "600",
                  color: "#5CE0D2",
                  cursor: "none",
                }}
              >
                Profile
              </span>
            </span>
          )}
        </div>
      )}
    </Header>
  );
}

HeaderPage.deafultProps = {
  profilePic: true,
  title: "",
  school: false,
  teachers: false,
  currentPage: "/school",
  margin: "0px",
  close: true,
};
export default HeaderPage;
