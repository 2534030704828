import React, { useEffect } from "react";
import { AnalyticsContainer } from "./AnalyticsHelper.style";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import ReactLoading from "react-loading";

const data02 = [
  {
    name: "Group A",
    value: 80,
  },
  {
    name: "Group B",
    value: 20,
  },
  {
    name: "Group C",
    value: 10,
  },
];
const COLORS = ["#FF8989", "#0F93A8"];
const RADIAN = Math.PI / 180;

function QuestionAttemptPieChart(props) {
  const {
    heading,
    subHeading,
    colors,
    field,
    text,
    data02,
    classes,
    total,
    loading,
  } = props;
  const [totalQue, setTotal] = React.useState(0);
  // console.log(total);
  useEffect(() => {
    let temp = 0;

    data02.forEach((item) => {
      temp += item.value;
    });
    setTotal(temp);
  }, [data02]);
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <>
        {percent && (
          <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {console.log(percent)}
            {`${((percent / 1) * total).toFixed(0)} `}
          </text>
        )}
      </>
    );
  };
  return (
    <AnalyticsContainer absolute>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading type="spin" color="cyan" height={100} width={100} />
        </div>
      ) : (
        <>
          <div className="firstDiv">
            <div style={{ textAlign: "right" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontSize: "1.2rem",
                  textAlign: "center",
                }}
              >
                {heading} {classes}
              </div>
              <div style={{ textAlign: "center" }}>
                {subHeading} {total || totalQue || "0"}
              </div>
            </div>

            <ResponsiveContainer>
              {/* <Tooltip cursor={{fill: '#fff'}} /> */}
              <PieChart width={700} height={250}>
                <Pie
                  data={data02}
                  stroke=""
                  fill="#82ca9d"
                  dataKey="value"
                  nameKey="name"
                  cx="40%"
                  cy="40%"
                  outerRadius={120}
                >
                  {data02.map((entry, index) => (
                    <Cell fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                {/* <Pie data={data02} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#82ca9d" label /> */}
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="secondDiv">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "20px",
                  height: "10px",
                  backgroundColor: colors[0],
                }}
              ></div>
              <div style={{ marginLeft: "5px" }}>{field[0]}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "7px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "10px",
                  backgroundColor: colors[1],
                }}
              ></div>
              <div style={{ marginLeft: "5px" }}>{field[1]}</div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "7px",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "10px",
                  backgroundColor: colors[2],
                }}
              ></div>
              <div style={{ marginLeft: "5px" }}>{field[2]}</div>
            </div>
          </div>
        </>
      )}
    </AnalyticsContainer>
  );
}

QuestionAttemptPieChart.defaultProps = {
  heading: "Test Status of class ",
  classes: "",
  subHeading: "Total Question",
  colors: ["#0F93A8", "#FF8989"],
  field: ["Completed", "Pending"],
  text: true,
  data02: [],
  loading: false,
};
export default QuestionAttemptPieChart;
