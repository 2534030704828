import React from 'react';
import { FileContainer } from './Media.style';

function Error() {
    return (
        <FileContainer style={{minHeight:"100vh",alignItems:"center"}}>
            <h1 style={{fontWeight:"500",fontSize:"4rem"}}>404 Error</h1>
            
        </FileContainer>
    )
}

export default Error
