import styled from 'styled-components';
export const SidebarContainer=styled.div`
// transition:0.3s;
${({show})=>show&&`

position:fixed;
top:50%;
transform:translateY(-50%);

width:362px;
left:70px;
background-color:#fff;
z-index:100;
box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.1);
border-radius: 15px;
padding:15px;
max-height:584px;
.wraper{
    position:relative;
}
.dropdown button{
    border-radius:30px;
    border-color:#51E3D4;
}
.dropdown button:hover{
    border-color:#51E3D4;
}
.input{
    display:block;
    width:100%;

    border:none;
    border-bottom:1px solid grey;
    padding:5px 15px;
    font-size:14px;
}
.input:focus{
    outline:none;
}



`}


`
export const SidebarContent=styled.div`
display:flex;
justify-content:space-between;
margin-bottom:10px;
.btn-primary{
    overflow:hidden;
}
.dropdown-menu{
    max-height:300px;
    overflow-Y:auto;
    width:auto !important;
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
        
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #cccc; 
        border-radius: 10px;
        // background-color:cyan;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px cyan; 
    }
}




`
export const UserContainer=styled.div`
overflow:auto;
max-height:300px;
&::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    
}

&::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    // background-color:cyan;
}

&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px cyan; 
}


`
export const ToogleButton=styled.div`
position:absolute;

bottom:50%;
display:flex;
align-items:center;


height: 40px;
width: 80px;
border-radius: 60px 60px 0 0;
cursor:pointer;
background: #5CE0D2;
transform: rotate(90deg) translateY(40px) translateX(35%);
// transition:0.3s;

${({show})=>show&&`
left:100%;
bottom:50%;
transform: rotate(90deg) translateY(10px) translateX(35%);

`}







`

export const UserDetails=styled.div`
display:flex;
height:54px;

.ftDiv{
    flex:0.2;
    display:flex;
    align-items:center;
}
.stDiv{
    flex:0.6;
    display:flex;
    align-items:center;
}
.radioButton{
    height:18px;
    width:18px;
    border:2px solid #5CE0D2;
    border-radius:50%;
    background-color:${({active})=>active?"#5CE0D2":""};
}
.thddiv{
    flex:0.2;
    display:flex;
    justify-content:center;
    align-items:center;
    
}

`

export const FilterDiv=styled.div`
width:auto;
max-width:150px;
.show>.btn-primary.dropdown-toggle{
    background-color:rgba(75, 223, 63, 0.51);
}
.dropdown button{
    background-color:rgba(75, 223, 63, 0.51);
    font-weight:600;
    border-radius:10px;
    overflow:hidden;
}
.dropdown-menu{
    max-height:300px;
    overflow-Y:auto;
    width:auto !important;
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 10px;
        
    }
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #cccc; 
        border-radius: 10px;
        // background-color:cyan;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px cyan; 
    }
}

`

export const CalendarDiv=styled.div`
.dropdown button{
    
}
.react-calendar{
    width: 280px;
    max-width: 100%;
    border-radius: 10px;

}
.btn-primary {
    color: black;
    background-color:rgba(75, 223, 63, 0.51) !important;
    border-color:#B46B82;
}

`