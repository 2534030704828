import React from 'react'

function ClockComponent() {
    return (
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6 0.916656C3.243 0.916656 1 2.97274 1 5.49999C1 8.02724 3.243 10.0833 6 10.0833C8.757 10.0833 11 8.02724 11 5.49999C11 2.97274 8.757 0.916656 6 0.916656ZM6 9.16666C3.7945 9.16666 2 7.5217 2 5.49999C2 3.47828 3.7945 1.83332 6 1.83332C8.2055 1.83332 10 3.47828 10 5.49999C10 7.5217 8.2055 9.16666 6 9.16666Z" fill="black"/>
<path d="M6.5 3.20834H5.5V5.95834H8.5V5.04168H6.5V3.20834Z" fill="black"/>
</svg>

    )
}

export default ClockComponent
