import React, { useState, useEffect } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { useParams } from "react-router-dom";
import { useAuth } from "../../../Auth/Auth";

import { ButtonWrapper, Button } from "./TabComponent.style";
import Details from "../../Details/Details";

import { SchoolDetails } from "../../RenderDetailsData/RenderDetailsData";
import { SchoolDetailsContainer } from "./TabComponent.style";
import { Input } from "../../Input/InputComponent";
import { LabelComponent } from "../../Details/details.style";
import axios from "axios";
import { schools } from "../../../Api/api";

function SchoolDetailsComponent(props) {
  // const {data}=props;
  // let SchoolData=SchoolDetails(props.schoolsData);
  const { setSchoolsData } = props;
  const [SchoolData, setSchoolData] = useState([]);
  const { schoolId } = useParams();
  const { readCookie, DisplaySnackbar } = useAuth();
  const [edit, setEdit] = useState(false);
  const [expiryDate, setExpiryDate] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    brach: "",
    city: "",
    country: "",

    state: "",
    studentCounts: "",
    teacherCounts: "",
  });
  // console.log(SchoolData);
  // console.log(props);
  useEffect(() => {
    if (props.schoolsData.createdAt) {
      setSchoolData(SchoolDetails(props.schoolsData));
      setExpiryDate(
        new Date(props.schoolsData.expiryDate).toISOString().slice(0, 10)
      );
      setFormData({
        name: props.schoolsData.name,
        city: props.schoolsData.city,
        country: props.schoolsData.country,
        state: props.schoolsData.state,
        branch: props.schoolsData.branch,
        teacherCounts: props.schoolsData.teacherCounts,
        studentCounts: props.schoolsData.studentCounts,
      });
    }
  }, [props]);

  const extendExpiryDate = () => {
    console.log(expiryDate);

    axios
      .patch(
        schools.extendExpiryDate(schoolId),
        { expiryDate },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setSchoolsData({ ...props.schoolsData, expiryDate: expiryDate });
          DisplaySnackbar("ExpiryDate Extended", "success");
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const handleUpdate = () => {
    const { name, city, country, state, branch, teacherCounts, studentCounts } =
      formData;
    if (
      !name ||
      !city ||
      !country ||
      !branch ||
      !teacherCounts ||
      !studentCounts ||
      !state
    ) {
      DisplaySnackbar("All fields are mandatory", "error");
      return;
    }

    axios
      .patch(
        schools.upDateSchools(schoolId),
        { ...formData },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Update Successfull", "success");
          setSchoolsData({
            ...props.schoolsData,
            name: formData.name,
            city: formData.city,
            country: formData.country,
            branch: formData.branch,
            teacherCounts: formData.teacherCounts,
            studentCounts: formData.studentCounts,
          });

          setEdit(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // console.log(SchoolData);
  return (
    <>
      <p style={{backgroundColor:"#5CE0D2",padding:"3px 13px",width:"fit-content",borderRadius:"10px",color:"white"}}>{props.schoolsData.board}</p>
      <SchoolDetailsContainer>
        <Details
          width="100%"
          formData={formData}
          handleOnChange={handleOnChange}
          justifycontent="flex-start"
          data={SchoolData}
          edit={edit}
          setEdit={setEdit}
        />
      </SchoolDetailsContainer>

      {edit && (
        <ButtonWrapper style={{ marginRight: "130px" }}>
          <Button onClick={() => setEdit(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleUpdate();
            }}
          >
            Save
          </Button>
        </ButtonWrapper>
      )}
      <div style={{ display: "flex", marginTop: "40px", marginLeft: "140px" }}>
        <div>
          <LabelComponent>Extend Expiry Date</LabelComponent>
          <Input
            height="36px"
            handleOnChange={(e) => setExpiryDate(e.target.value)}
            border="none"
            edit={true}
            type="date"
            value={expiryDate}
            placeholder="dd-mm-yyyy"
          />
        </div>
        <ButtonWrapper
          style={{ marginTop: "15px", display: "flex", alignItems: "center" }}
        >
          <Button
            onClick={() => extendExpiryDate()}
            style={{ display: "block" }}
          >
            Save
          </Button>
        </ButtonWrapper>
      </div>
    </>
  );
}

export default SchoolDetailsComponent;
