import React from "react";
import AnalyticsIcon from "../../Svg/AnalyticsIcon";
import { AnalyticsContainer, AnalyticsBox } from "./TabComponent.style";
import ArrowComponent from "../../Svg/ArrowComponent";
import { Link } from "react-router-dom";

function AnalyticsComponent(props) {
  const label = [
    {
      leftIcon: <AnalyticsIcon />,
      text: "Practice Assessment",
      bgColor: "#F08080",
      rightIcon: <ArrowComponent />,
      to: (school, id) =>
        `/practiceAnalytics?schoolName=${school}&schoolId=${id}`,
    },
    {
      leftIcon: <AnalyticsIcon />,
      text: "Online Test",
      bgColor: "#BA768B",
      rightIcon: <ArrowComponent />,
      to: (school, id) =>
        `/onlineAssessment?schoolName=${school}&schoolId=${id}`,
    },
    {
      leftIcon: <AnalyticsIcon />,
      text: "Attendance",
      bgColor: "#7CD375",
      rightIcon: <ArrowComponent />,
      to: (school) => `/schoolsAttendanceReport?schoolName=${school}`,
    },
    {
      leftIcon: <AnalyticsIcon />,
      text: "HomeWork",
      bgColor: "#3EDFCE",
      rightIcon: <ArrowComponent />,
      to: (school, id) => `/homework?schoolName=${school}&schoolId=${id}`,
    },
    {
      leftIcon: <AnalyticsIcon />,
      text: "Resources",
      bgColor: "#F8AB9D",
      rightIcon: <ArrowComponent />,
      to: (school) => `/resourcesAnalytics?schoolName=${school}`,
    },
  ];
  //console.log(props);
  return (
    <AnalyticsContainer>
      {label.map((item, idx) => {
        return (
          <Link
            style={{ textDecoration: "none" }}
            to={
              item?.to(props?.schoolsData?.name, props.schoolsData._id) || "/"
            }
          >
            <AnalyticsBox key={idx} bgColor={item.bgColor}>
              <div className="ftDiv">
                <div>{item.leftIcon}</div>
                <div style={{ marginLeft: "5px", textDecoration: "none" }}>
                  {item.text}
                </div>
              </div>

              <div className="stDiv">{item.rightIcon}</div>
            </AnalyticsBox>
          </Link>
        );
      })}
    </AnalyticsContainer>
  );
}

export default AnalyticsComponent;
