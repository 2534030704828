import React from 'react'

function TestSvgComponent(props) {
    return (
        <div style={{background:"rgba(211, 114, 255, 0.5)",height:"31px",borderRadius:"50%",display:"flex",justifyContent:"center",alignItems:"center",width:"30px"}}>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.66663 2.5H6.66663C7.55068 2.5 8.39853 2.85119 9.02365 3.47631C9.64877 4.10143 9.99996 4.94928 9.99996 5.83333V17.5C9.99996 16.837 9.73657 16.2011 9.26773 15.7322C8.79889 15.2634 8.163 15 7.49996 15H1.66663V2.5Z" stroke="#D372FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M18.3333 2.5H13.3333C12.4493 2.5 11.6014 2.85119 10.9763 3.47631C10.3512 4.10143 10 4.94928 10 5.83333V17.5C10 16.837 10.2634 16.2011 10.7322 15.7322C11.2011 15.2634 11.837 15 12.5 15H18.3333V2.5Z" stroke="#D372FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</div>

    )
}

export default TestSvgComponent
