import React, { useState, useEffect } from "react";
import { EditProfileContainer } from "./EditProfile.style";
import { Button } from "../Assessment/UploadAssessment.style";
import Logo from "../../images/2xcell_logo.png";
import Details from "../Details/Details";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import { Profile } from "../../Api/api";

const label = (email) => [
  [
    {
      name: "email",
      label: "Primary Email",
      height: "33px",
      width: "48%",
      value: email,
      disabled: true,
    },
    {
      name: "contact",
      label: "Primary Contact",
      height: "33px",
      width: "48%",
      fontsize: ".8rem",
      disabled: true,
    },
  ],
];
function EditProfile() {
  const { readCookie, userId, setImgUrl, imgUrl, DisplaySnackbar } = useAuth();
  const [email, setEmail] = useState("");
  // console.log(email);

  useEffect(() => {
    axios
      .get(Profile.getProfileData(userId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setEmail(res.data.data.admin.email);
      })
      .catch((err) => console.log(err));
  }, []);
  const [formData, setFormData] = useState({
    email: "",
    contact: "",
  });
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [image, setImg] = useState("");

  const handleUpload = (e) => {
    //     let form=new FormData();
    //     form.append("file",image);
    //    axios.patch(Profile.addProfilePic,form,{
    //        headers:{
    //            'token':`${readCookie('token')}`
    //        }
    //    }).then(res=>console.log(res)).catch(err=>console.log(err));
  };
  const handleUploadPicture = (data) => {
    let form = new FormData();
    form.append("file", data);

    axios
      .patch(Profile.addProfilePic, form, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setImgUrl(res.data.data.profile.fileUrl);
        DisplaySnackbar("Profile Pic Updated", "success");
        localStorage.setItem("imgUrl", res.data.data.profile.fileUrl);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const handleDelete = () => {
    axios
      .delete(Profile.deleteProfilePic, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Profile Pic deleted", "success");
          setImgUrl("");
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <EditProfileContainer>
      <h1>Edit Profile</h1>
      <div className="fileUpload">
        <img
          style={{ height: "60px", width: "60px", margin: "0px 9px" }}
          src={imgUrl || Logo}
          alt="logo"
        />
        <label for="img">{image?.name || "Upload Picture"}</label>
        <input
          onChange={(e) => {
            handleUploadPicture(e.target.files[0]);
          }}
          type="file"
          id="img"
          name="img"
          style={{ display: "none" }}
        ></input>
        <Button onClick={handleDelete}>Delete</Button>
      </div>
      {/* <div  className='field'>
                <Details handleOnChange={handleOnChange} formData={formData} setFormData={setFormData} fontsize=".9rem" height="33px" data={label(email)} edit={true}/>

            </div>
            <div style={{marginTop:"1rem"}}>
                <Button onClick={handleUpload}>
                    Save
                </Button>
            </div> */}
    </EditProfileContainer>
  );
}

export default EditProfile;
