import React, { useState, useEffect } from "react";
import TableComponent from "../../Table/Table";
import { useParams, useNavigate } from "react-router-dom";

import { Staff } from "../../Table/TableHeaders";
import { CommonHeader } from "./TabComponent.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../../Header/Headerfeatures";
import { Line } from "../Dashboard.style";
// import AddStaffComponent from '../../Modal/AddStaffComponent';
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";

import { useAuth } from "../../../Auth/Auth";
import axios from "axios";
import { Teacher, schools, ExportData } from "../../../Api/api";
import CSVtoJSON from "../../Functions/CSVtoJSON";
import PlaylistAddOutlinedIcon from "@material-ui/icons/PlaylistAddOutlined";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import {
  addStaffFormDashboard,
  LinkClass,
} from "../../RenderDetailsData/RenderDetailsData";
import Loader from "../../Loading/Loader";
import DocumentLoading from "../../Loading/ExportLoader";
import AddModalComponent from "../../Modal/AddModalComponent";
import exportFromJSON from "export-from-json";
import { TablePagination } from "@material-ui/core";

const sortField = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Role",
    field: "role",
  },
  {
    label: "Email",
    field: "email",
  },
  {
    label: "SignUp Date",
    field: "signUpDate",
  },
  {
    label: "Expiry Date",
    field: "expiryDate",
  },
];

function StaffComponent(props) {
  // console.log(props);
  const [modal, setModal] = useState(false);
  const [sectionsId, setSectionsId] = useState([]);
  const { readCookie, DisplaySnackbar } = useAuth();
  const { schoolId, schoolName } = useParams();
  const [staffs, setStaffs] = useState([]);
  const [tabContent, setTabContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState([]);
  const [classes, setClasses] = useState([]);
  const [label2, setLabel2] = useState([]);
  const [cnt, setCnt] = useState(0);
  const [exportingData, setExportingData] = useState(false);
  const [render, setRender] = useState(false);
  const navigation = useNavigate();
  const [sortBy, setSortBy] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [formData, setFormData] = useState({
    schoolName: "",
    teacherName: "",
    expiryDate: "",
    email: "",
    contact: "",
    role: "",
    password: "",
    class: "",
  });

  useEffect(() => {
    axios
      .get(Teacher.getStaffBySchool(schoolId, page + 1, rowsPerPage), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setStaffs(res.data.data.staffs))
      .catch((err) => console.log(err));
    setLabel(addStaffFormDashboard());
    axios
      .get(Teacher.getStaffCountBySchool(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setCnt(res.data.data.totalStaffs);
      })
      .catch((err) => console.log(err));
  }, [rowsPerPage, page]);

  useEffect(() => {
    setFormData({ ...formData, schoolName });

    if (schoolId) {
      axios
        .get(schools.getClasses(schoolId), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setClasses(res.data.data.grades);
          console.log(res);
          setLabel2(LinkClass(res.data.data.grades, [], []));
        })
        .catch((err) => console.log(err));
    }
  }, [props, render]);

  const handleOnClick = (data) => {
    navigation(`/dashboard/${schoolName}/${schoolId}/staff/${data.id}`);
  };
  useEffect(() => {
    // console.log(formData.class);
    if (formData.class) {
      let temp = classes.find((item) => item._id === formData.class);
      //console.log(temp);
      let dt = LinkClass(classes, temp.sections, temp.subjects);
      // console.log(dt);
      setLabel2(dt);
    }
  }, [formData.class]);

  useEffect(() => {
    axios
      .get(Teacher.getFilterDataBySchool(schoolId, props.search), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => setStaffs(res.data.data.staffs))
      .catch((err) => console.log(err));
  }, [props.search]);
  // console.log(formData);
  const handleCreate = () => {
    const data = {
      schoolId: schoolId,
      name: formData.teacherName,
      role: formData.role,
      expiryDate: new Date(formData.expiryDate),
      email: formData.email,
      contact: formData.contact,
      password: formData.password,
      sections: sectionsId.map((item, idx) => {
        return { sectionId: item, subjects: formData.subject };
      }),
    };
    axios
      .post(Teacher.addTeacher, data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setStaffs([res.data.data.staff, ...staffs]);
          DisplaySnackbar("Added Successfully", "success");
          setModal(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (!modal) {
      setFormData({
        schoolName: schoolName,
        teacherName: "",
        expiryDate: "",
        email: "",
        contact: "",
        role: "",
        password: "",
        class: "",
      });
    }
  }, [modal]);
  const handleMultiSelect = (type, id) => {
    if (type === "section") {
      if (sectionsId.includes(id)) {
        setSectionsId(sectionsId.filter((item) => item !== id));
      } else {
        setSectionsId([...sectionsId, id]);
      }
    }
  };

  const uploadCSV = (e) => {
    if (!e.target.files.length) {
      return;
    }

    //To upload csv-to-json file to the server
    const reader = new FileReader();

    // function read() {

    //Extract contents of the CSV file
    const csv = e.target.files[0];
    reader.readAsText(csv);

    reader.onload = function (e) {
      let emailList = [];
      let contactList = [];

      //Convert CSV content to JSON

      const jsonContent = CSVtoJSON(e.target.result, emailList, contactList);

      //Validate content of JSON

      let allCorrect = true;

      if (jsonContent.length > 1000) {
        return;
      }

      if (emailList.length !== new Set(emailList).size) {
        allCorrect = false;
        DisplaySnackbar("Duplicate emails found", "error");
      }

      if (allCorrect) {
        axios
          .post(
            Teacher.importTeachers(schoolId),
            { docs: jsonContent },
            {
              headers: {
                token: `${readCookie("token")}`,
              },
            }
          )
          .then((response) => {
            if (response.data.status === "fail") {
              DisplaySnackbar(response.message, "error");
            } else if (response.data.status === "success") {
              DisplaySnackbar(
                `Successfully inserted ${response.data.insertedUsers} entries, and skipped ${response.data.skippedUsers} entries, out of total ${response.data.totalUsers} entries`,
                "success"
              );
              if (response.data.errorLogs) {
                const data = response.data.errorLogs;
                const fileName = `staffs_import_error_log_${new Date().toLocaleDateString()}`;
                const exportType = exportFromJSON.types.csv;

                exportFromJSON({ data, fileName, exportType });
              }
              if (!response.data.errorLogs) {
                window.location.reload();
                setRender(!render);
              }
            } else {
              DisplaySnackbar(response.data.message, "error");
            }
          })
          .catch((err) => {
            if (err.response) {
              DisplaySnackbar(err.response.data.message, "error");
            }
          });
      }
    };
  };

  const resetPassword = (id) => {
    setLoading(true);
    axios
      .get(Teacher.resetPassword(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Password Reset");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
        setLoading(false);
      });
  };
  const exportCSV = () => {
    setExportingData(true);
    axios
      .get(ExportData.exportTeacherBySchool(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = [];
        data = res.data.data.staffs.map((item) => {
          return {
            "School Name": item?.school?.name,
            "Name": item?.name,
            Email: item?.email,
            Contact: item?.contact,
            "SignUp Date": item?.signUpDate,
            "Expiry Date": item?.expiryDate,
            Sections:!item?.sections ? "" : item?.sections
              .map((item) => `${item?.grade}-${item?.section}`)
              .join(","),
          };
        });
        const fileName = "Staff";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
        setExportingData(false);
      })
      .catch((err) => console.log(err));
    
  };
  const toogleDisabled = (id) => {
    axios
      .patch(
        Teacher.toogleDisable(id),
        {},
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(res.data.message, "success");
          setStaffs(
            staffs.map((item) =>
              item._id === id ? { ...item, enabled: !item.enabled } : item
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const deleteTeacher = (id) => {
    axios
      .delete(Teacher.deleteTeacher(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
        validateStatus: () => true
      })
      .then((res) => {
        if (res.data.status !== "success") {
            throw res.data;
        }
        setStaffs(staffs.filter((item, idx) => item._id !== id));
        console.log(staffs);
        DisplaySnackbar("Staff's data deleted", "success");
      })
      .catch((err) => {
        DisplaySnackbar(err.message, "error");
        console.log("Error", err)
      });
  };
  useEffect(() => {
    if (staffs.length) {
      setTabContent(
        staffs.map((item) => {
          return {
            id: item._id,
            name: item.name,
            role: item.role,
            signUpDate: new Date(item.signUpDate).toLocaleDateString(),
            contact: item.contact,
            email: item.email,
            expiryDate: new Date(item.expiryDate).toLocaleDateString(),
            del: (
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTeacher(item._id);
                }}
                color="secondary"
              />
            ),
            enable: (
              <Switch
                onClick={(e) => {
                  e.stopPropagation();
                  toogleDisabled(item._id);
                }}
                style={{ zIndex: "100000" }}
                checked={item.enabled}
                color="primary"
                className="enableIcon"
              />
            ),
            reset: (
              <CachedOutlinedIcon
                className="resetPassword"
                onClick={(e) => {
                  e.stopPropagation();
                  resetPassword(item._id);
                }}
              />
            ),
          };
        })
      );
    }
  }, [staffs, rowsPerPage, page]);

  const handleSort = (field, type) => {
    let find = sortBy.find((item) => item.field === field);
    if (find) {
      setSortBy(
        sortBy.map((item) =>
          item.field === field ? { field: field, type: type } : item
        )
      );
    } else {
      setSortBy([...sortBy, { field: field, type: type }]);
    }
    let temp = tabContent;

    if (type === "asc") {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });

      setTabContent(temp);
    } else {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }
        if (name1 < name2) return 1;
        if (name1 > name2) return -1;
        return 0;
      });
      setTabContent(temp);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <div>
      <CommonHeader>
        <div style={{ display: "flex", fontWeight: "500" }}>
          <div
            className="iconContainer"
            onClick={() => setModal(true)}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <AddBoxOutlinedIcon style={{ marginRight: "7px" }} /> Add Staff
          </div>
          <label
            for="import"
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
              cursor: "pointer",
            }}
            className="iconContainer"
          >
            <PlaylistAddOutlinedIcon style={{ marginRight: "7px" }} /> Import
            Staff
          </label>
          <input
            style={{ display: "none" }}
            type="file"
            id="import"
            accept=".csv"
            onChange={(e) => {
              uploadCSV(e);
            }}
          />
        </div>
        <Headerfeatures
          handleSort={handleSort}
          exportFile={exportCSV}
          sortField={sortField}
          setModal={setModal}
          viewBy={false}
        />
      </CommonHeader>
      <Line />
      {loading && <Loader type="spin" color="cyan" />}
      <TableComponent
        handleOnClick={handleOnClick}
        tabHeader={Staff}
        tabContent={tabContent}
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={cnt}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {modal && (
        <AddModalComponent
          maxWidth="620px"
          minWidth="620px"
          handleCreate={handleCreate}
          link={true}
          label2={label2}
          headerText2="Link Classes and Subjects"
          formData={formData}
          setFormData={setFormData}
          label={label}
          headerText="Add Staff"
          setModal={setModal}
          handleMultiSelect={handleMultiSelect}
        />
      )}
      {exportingData && <DocumentLoading />}
    </div>
  );
}

export default StaffComponent;
