import React from 'react';
import {AnalyticsTabs} from './AnalyticsHelper.style';

function AnalyticsTab(props) {

    const {Icon,heading,count}=props.data;
    return (
        <AnalyticsTabs>
            <div style={{display:"flex",alignItems:"center"}}>
                {<Icon/>}
            </div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                <div style={{fontSize:".8rem",fontWeight:"600",textAlign:"end"}}>{heading}</div>
                <div style={{fontSize:".8rem",fontWeight:"600",textAlign:"end"}}>{count}</div>
            </div>


        </AnalyticsTabs>
    )
}

export default AnalyticsTab
