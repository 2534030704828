import React, { useState } from "react";
import {
  PracticeAssessmentContainer,
  PracticeAssessmentHeader,
  PracticeAssessmentContent,
  PracticeAssessmentTable,
} from "../PracticeAssessment/practiceAssesment.style";
import { AnalyticsTabs } from "../AnalyticsHelper/AnalyticsHelper.style";
import AnalyticsContainer4 from "../AnalyticsHelper/AnalyticsContainer4";
import HomeWorkReport from '../HomeworkReport/HomeWorkReport';
import AssessmentReport from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTable';
import {ResourcesHeader,ResourcesData} from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader';
import HeaderPage from '../../Header/HeaderPage';
import { Line } from '../../Dashboard/Dashboard.style';
import { useLocation } from "react-router-dom";
function ResourcesAnalytics() {
  const label = [
    {
      heading: "Total Students",
      count: 20000,
    },
    {
      heading: "Average Students Done",
      count: "50%",
    },
    {
      heading: "Average Students Pending",
      count: "30%",
    },
  ];
  const data = [
    {
      name: "Class VI",
      Pending: 40,
      InProgress: 10,
      Done:10
    },
    {
      name: "Class VI",
      Pending: 40,
      InProgress: 10,
      Done:10
    },
    {
      name: "Class VI",
      Pending: 40,
      InProgress: 10,
      Done:10
    },
    {
      name: "Class VI",
      Pending: 40,
      InProgress: 10,
      Done:10
    },
    {
      name: "Class VI",
      Pending: 40,
      InProgress: 10,
      Done:10
    },
    {
      name: "Class VI",
      Pending: 40,
      InProgress: 10,
      Done:10
    },
  ];

  
  const leftHeader=()=>{

    return( <span
        style={{
          fontWeight: "600",
          padding: "5px",
          margin:"30px 0px",
          background:
            "linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)",
        }}
      >
       Resources
      </span>)
}
const search = useLocation().search;
const schoolName= new URLSearchParams(search).get('schoolName');
  
  
  return (
    <PracticeAssessmentContainer>
      <PracticeAssessmentHeader>
      <HeaderPage currentPage='/school' leftHeader={leftHeader()} school="true" title={schoolName} profilePic={false}/>
                <Line style={{marginBottom:"30px"}}/>
      

        <div className="tabContainer">
          {label.length &&
            label.map((item) => {
              return (
                <AnalyticsTabs style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {item.heading}
                  </div>
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.count}
                  </h5>
                </AnalyticsTabs>
              );
            })}
        </div>
      </PracticeAssessmentHeader>
      <PracticeAssessmentContent>
          <AnalyticsContainer4 colors={["#F28684","#0079EA","#59E0B0"]} data={data} field={["Pending","InProgress","Done"]}/>
          <HomeWorkReport headerText="Resource Report" cardHeader="Resource Name" classes={false}/>
      </PracticeAssessmentContent>
      <PracticeAssessmentTable>
                <AssessmentReport tabHeader={ResourcesHeader} tabContent={ResourcesData}/>
      </PracticeAssessmentTable>
    </PracticeAssessmentContainer>
  );
}

export default ResourcesAnalytics;
