import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { SchoolFormPage } from "../Dashboard.style";

import { SchoolFormPageContent, Tab } from "../Dashboard.style";
import { Line } from "../Dashboard.style";
import SchoolDetailsComponent from "../TabComponent/SchoolDetailsComponent";
import StaffComponent from "../TabComponent/StaffComponent";
import StudentComponent from "../TabComponent/StudentComponent";
import SubTeachersComponent from "../TabComponent/SubTeachersComponent";
import ClassesComponent from "../TabComponent/ClassesComponent";
import AnalyticsComponent from "../TabComponent/AnalyticsComponent";
import HeaderPage from "../../Header/HeaderPage";
import axios from "axios";
import { useAuth } from "../../../Auth/Auth";
import { schools } from "../../../Api/api";
import CoursesComponent from "../TabComponent/CoursesComponent";

const Labels = [
  "School Details",
  "Staff",
  "Students",
  "Subject Teachers",
  "Courses",
  "Classes",
  "Analytics",
];
const queryTabs = [
  "details",
  "staffs",
  "students",
  "subjectteachers",
  "courses",
  "classes",
  "analytics",
];
const TabComponent = [
  SchoolDetailsComponent,
  StaffComponent,
  StudentComponent,
  SubTeachersComponent,
  CoursesComponent,
  ClassesComponent,
  AnalyticsComponent,
];

const SchoolComponent = () => {
  const location = useLocation();
  const { schoolName, schoolId } = useParams();

  const { readCookie } = useAuth();

  const [activeTab, setActiveTab] = useState(-1);
  const [schoolsData, setSchoolsData] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setShowSearch([1, 2].includes(activeTab));
  }, [activeTab]);

  useEffect(() => {}, []);

  const updateTabIndex = (index) => {
    window.location.replace(`${location.pathname}?tab=${queryTabs[index]}`);
  };

  useEffect(() => {
    if ([0, 5].includes(activeTab)) {
      axios
        .get(schools.getSchoolsById(schoolId), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setSchoolsData(res.data.data.school);
        })
        .catch((err) => console.log(err));
    }
  }, [activeTab]);

  useEffect(() => {
    const tab = new URLSearchParams(location.search).get("tab");
    if (!tab) return;
    const tabIndex = queryTabs.indexOf(tab);
    setActiveTab(tabIndex);
  }, []);

  return (
    <SchoolFormPage>
      <HeaderPage
        search={search}
        setSearch={setSearch}
        showSearch={showSearch}
        currentPage="/school"
        school="true"
        title={schoolName}
        profilePic={true}
      />
      <Line />
      <SchoolFormPageContent>
        <div className="firstChild">
          {Labels.map((item, idx) => {
            return (
              <Tab
                onClick={() => updateTabIndex(idx)}
                active={activeTab === idx}
              >
                {item}
              </Tab>
            );
          })}
        </div>
        <div className="SecondChild">
          {TabComponent.map((RenderComponent, idx) => {
            return idx === activeTab ? (
              <RenderComponent
                search={search}
                showSearch={showSearch}
                schoolsData={schoolsData}
                setSchoolsData={setSchoolsData}
              />
            ) : (
              ""
            );
          })}
        </div>
      </SchoolFormPageContent>
    </SchoolFormPage>
  );
};

export default SchoolComponent;
