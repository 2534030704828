import { useState, useMemo, useRef } from "react";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";

import { InputField, InputContainer } from "../Input/Input.style";

import { Link } from "react-router-dom";

import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";

import "./ModuleSearch.css";

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

function List(props) {
  const { country, board, course, subject, chapter, topic, module } =
    useMemo(() => {
      return {
        country:
          props.mod.topicId?.chapterId?.subjectId?.courseId?.boardId
            ?.countryId || null,
        board:
          props.mod.topicId?.chapterId?.subjectId?.courseId?.boardId || null,
        course: props.mod.topicId?.chapterId?.subjectId?.courseId || null,
        subject: props.mod.topicId?.chapterId?.subjectId || null,
        chapter: props.mod.topicId?.chapterId || null,
        topic: props.mod.topicId || null,
        module: {
          id: props.mod._id,
          name: props.mod.name,
        },
      };
    }, [props.mod]);
  return (
    <li key={props.index} className="search-data">
      <Link
        style={{
          textDecoration: "none",
          color: "inherit",
        }}
        to={`/courses/${country?.name}/${country?._id}/${board.name}/${board._id}/${course.name}/${course._id}/${fixName(subject.name)}/${subject._id}/${fixName(chapter.name)}/${chapter._id}/${fixName(topic.name)}/${topic._id}/modules/${module.id}`}
      >
        <h5>{module?.name || ""}</h5>
        <p>
          <span>{country?.name || ""}</span>
          <span>{">"}</span>
          <span>{board?.name || ""}</span>
          <span>{">"}</span>
          <span>{course?.name || ""}</span>
          <span>{">"}</span>
          <span>{subject?.name || ""}</span>
          <span>{">"}</span>
          <span>{chapter?.name || ""}</span>
          <span>{">"}</span>
          <span>{topic?.name || ""}</span>
        </p>
      </Link>
    </li>
  );
}

export function ModuleSearch() {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);
  const paginationRef = useRef({ limit: 30, page: 0 });

  const { readCookie } = useAuth();

  function reset() {
    paginationRef.current = { limit: 30, page: 0 };
    setResults([]);
  }

  async function searchResult(prevResults) {
    if (loading) return;
    if (!search) return;
    setLoading(true);
    const newPage = paginationRef.current.page + 1;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/modules/search-modules-globally?name=${search}&page=${newPage}&limit=${paginationRef.current.limit}`,
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      );
      const modules = response.data.data.modules;
      if (modules.length) paginationRef.current.page = newPage;
      //reset to previous
      setResults([...prevResults, ...modules]);
    } catch (err) {
      alert("Something went wrong");
    }
    setLoading(false);
  }

  async function submit(event) {
    event.preventDefault();
    reset();
    await searchResult([]);
  }

  const toggleLMButton = useMemo(() => {
    if (!loading && search) {
      if (
        results.length <
        paginationRef.current.limit * paginationRef.current.page
      )
        return false;
      if (results.length) return true;
    }
    return false;
  }, [results, loading, search]);

  //Search Module
  return (
    <div className="module-search">
      <form onSubmit={submit}>
        <InputContainer>
          <InputField
            placeholder={"Search..."}
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            type={"text"}
          />
          {results.length ? (
            <CloseIcon className="searchIcon" onClick={() => {
              setSearch("");
              reset();
            }} />
          ) : (
            <SearchIcon className="searchIcon" />
          )}
        </InputContainer>
      </form>
      <div className="search-list">
        <ul>
          {results.map((mod, index) => {
            return <List key={index} mod={mod} />;
          })}
          {toggleLMButton && (
            <li onClick={() => searchResult(results)} className="load-more">
              Load more
            </li>
          )}
          {loading ? <li className="load-more">Loading....</li> : <></>}
        </ul>
      </div>
    </div>
  );
}
