import React from 'react';

import {WrapperDiv,Option} from '../Modal/Modal.style';
import {  DropdownButton,Dropdown } from "react-bootstrap";
import './dropdown.css';






const DropDown=(props)=>{

    
    const {placeholder,options,name,setFormData,formData}=props;
    
  
    
    // console.log(props);
  

   
    
    
    
    
    
    return(
      
      <WrapperDiv id="sel">
    

             <DropdownButton  className="dropdown " title={formData[name]||placeholder}>
                
         { options.map(item=>{
              return(
                <Dropdown.Item>
                <Option  onClick={()=>setFormData({...formData,[name]:item})}>
                  {item}
                  <div style={formData[name]===item?{backgroundColor:"#5CE0D2"}:{}} className="fill">
  
                  </div>
                </Option>
                </Dropdown.Item>
              )
            })
          }
          
                {/* //   </Dropdown.Item>
                ))} */}
              </DropdownButton> 
    </WrapperDiv>
  
    )
  }
  
 
  
  export default DropDown;
  