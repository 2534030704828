import React, { useState } from "react";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import AppsSharpIcon from "@material-ui/icons/AppsSharp";
import ViewAgendaOutlinedIcon from "@material-ui/icons/ViewAgendaOutlined";
import FilterAltOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import { HeaderContainer } from "./Headerfeatures.style";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
// import SortDropDown from '../Students/SortDropDown/SortDropDown';

function Headerfeatures(props) {
  const {
    view,
    setView,
    viewBy,
    filter,
    setPopUpFilter,
    exportcsv,
    sort,
    exportFile,
    handleSort,
    sortField = [],
  } = props;
  // const [dropdown,setDropDown]=useState(false);
  // console.log(handleSort);
  // console.log(exportFile);

  return (
    <HeaderContainer>
      {exportcsv && (
        <div
          className="iconContainer"
          style={{ cursor: "pointer" }}
          onClick={() => exportFile()}
        >
          <SystemUpdateAltOutlinedIcon className="Icons" />
          Export Csv
        </div>
      )}
      {sort && (
        <div>
          <Menu
            menuButton={
              <MenuButton
                style={{ backgroundColor: "none", border: "none" }}
                className="sort-by-btn iconContainer"
              >
                <ImportExportIcon className="Dashboard_MainBody_Right_mid_right_icon"></ImportExportIcon>{" "}
                Sort By
              </MenuButton>
            }
          >
            {sortField.map((item) => {
              return (
                <SubMenu label={item.label}>
                  <MenuItem
                    onClick={() => {
                      handleSort(item.field, "asc");
                    }}
                  >
                    Ascending
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleSort(item.field, "desc");
                    }}
                  >
                    Descending
                  </MenuItem>
                </SubMenu>
              );
            })}
          </Menu>
        </div>
      )}
      {viewBy && (
        <div
          className="iconContainer"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setView(view === "card" ? "list" : "card");
          }}
        >
          {view === "card" ? (
            <AppsSharpIcon className="Icons" />
          ) : (
            <ViewAgendaOutlinedIcon className="Icons" />
          )}
          View As
        </div>
      )}
      {filter && (
        <div style={{ cursor: "pointer" }} className="iconContainer">
          <FilterAltOutlinedIcon
            onClick={() => setPopUpFilter(true)}
            className="Icons"
          />
          Filters
        </div>
      )}
      {/* {dropdown&&<CoverScreen onClick={()=>setDropDown(false)}></CoverScreen>} */}
    </HeaderContainer>
  );
}

Headerfeatures.defaultProps = {
  view: "card",
  setView: () => {},
  viewBy: true,
  filter: false,
  setPopUpFilter: () => {},
  exportcsv: true,
  sort: true,
  exportFile: () => {},
  handleSort: () => {},
  sortField: [],
};
export default Headerfeatures;
