import React,{useState} from 'react';
import { GraphContainer } from './Analytics.style';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import { GraphHeader } from './Analytics.style';
// import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import { GraphContent } from './Analytics.style';
import BarGraph from './BarGraph';
import exportFromJSON from 'export-from-json';
function NewUsers() {
    const [exportData,setExportData]=useState({"currentWeek":[],"lastWeek":[]});


    const exportCsv = () => {
        const fileName = "WeeklyUsers";
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data:exportData["currentWeek"], fileName, exportType });
      };
    return (
        <GraphContainer minHeight="318px">
            <GraphHeader>
            <div>
                <h1 style={{position:"relative"}}>
                    New Users <CachedOutlinedIcon onClick={()=>window.location.reload()} style={{position:"absolute",cursor:"pointer",top:"7px",right:"-35px"}}/>
                </h1>
               
                </div>
                <div onClick={()=>exportCsv()} style={{cursor:"pointer",position:"relative",fontSize:"1.5rem"}}>
                    <SystemUpdateAltOutlinedIcon style={{cursor:"pointer",position:"absolute",top:"8px",left:"-25px",transform:"rotate(-90deg)"}}/> Export
                </div>
            </GraphHeader>
            <GraphContent>
                <BarGraph previous={false} exportData={exportData} setExportData={setExportData} />
                
            </GraphContent>
            <GraphContent>
            <BarGraph previous={true} exportData={exportData} setExportData={setExportData} />
            </GraphContent>
        </GraphContainer>
    )
}

export default NewUsers
