import styled from "styled-components";

export const CoursesPageContainer = styled.div``;
export const CoursesPageContent = styled.div`
  display: flex;
  justify-content: space-between;
  .react-contextmenu-item:hover {
    background: red;
  }
`;
export const EditButton = styled.div`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  transform: translateY(-50%);
  padding: 5px 0px;
  width: 130px;
  box-sizing: border-box;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0px 3px 3px 0px #00000040;
  .edit {
    cursor: pointer;
    padding: 5px;
    &:hover {
      background-color: #5ce0d2;
    }
  }

  border-radius: 10px;
`;
export const card = styled.div`
  width: 18%;
  margin-left: 20px;
`;

export const cardbox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
