import React, { useState } from "react";

import axios from "axios";
import exportFromJSON from "export-from-json";

import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header } from "./Modal.style";
import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";

import { useAuth } from "../../Auth/Auth";
import { sortByDateInAsc } from "../../utils/sortModule";

function ExportModal({ setModal, sources }) {
  const { readCookie, DisplaySnackbar } = useAuth();
  const [option, setOption] = useState(null);

  const getModuleType = (resources) => {
    var result = { notFound: true, move: 0 };
    const modulesTypes = [
      ["html"],
      ["mp4", "mkv", "m4v", "avi"],
      ["pptx", "ppt", "docx", "pdf"],
      ["mp3"],
      ["png", "jpg", "jpeg"],
    ];

    let lastStep = modulesTypes.length - 1;
    while (result.notFound) {
      result = findMainModuleFile(resources, modulesTypes[result.move], result);
      if (result.move === lastStep) break;
      result.move += 1;
    }
    return result;
  };

  const findMainModuleFile = (resources, mainFileTypes, data) => {
    for (let resource of resources) {
      if (!resource) {
        continue;
      }
      const nameParts = resource?.split(".");

      const fileExt = nameParts[nameParts.length - 1].toLowerCase();
      if (mainFileTypes.includes(fileExt)) {
        data["notFound"] = false;
        data["resource"] = resource;
        data["fileExt"] = fileExt;
        break;
      }
    }
    return data;
  };

  const getCategory = (fileType) => {
    if (["html"].includes(fileType)) return "Activity";
    if (["mp4", "mkv", "m4v", "avi"].includes(fileType)) return "Video";
    if (
      [
        "pptx",
        "ppt",
        "docx",
        "pdf",
        "doc",
        "txt",
        "png",
        "jpg",
        "jpeg",
      ].includes(fileType)
    )
      return "Worksheet";
    if (["mp3"].includes(fileType)) return "Audio";
    return "Other";
  };

  function sortAndUnwind(row, newRows) {
    const modules = {
      Video: [],
      Activity: [],
      Worksheet: [],
      Audio: [],
      Other: [],
    };
    const rowModules = row.modules;
    delete row.modules;
    rowModules.forEach((mod) => {
      if (mod.ModuleId) {
        const file = getModuleType(mod.Resources || []);
        mod["ModuleType"] = file.notFound ? null : getCategory(file.fileExt);
        if (!mod.ModuleType) return;
        modules[mod.ModuleType].push({
          ...row,
          Module: mod.Module,
          ModuleType: mod.ModuleType,
          "Module-Thumbnail": mod.thumbnail,
          ModuleId: mod.ModuleId,
          createdAt:mod.createdAt
        });
      }
    });

    [
      ...sortByDateInAsc(modules.Video),
      ...sortByDateInAsc(modules.Activity),
      ...sortByDateInAsc(modules.Worksheet),
      ...sortByDateInAsc(modules.Audio),
      ...sortByDateInAsc(modules.Other),
    ].forEach((row) => newRows.push(row));
    return newRows;
  }

  function modifyRows(rows) {
    let contents = [];
    for (let row of rows) {
      contents = sortAndUnwind(row, contents);
    }
    return contents;
  }

  const handleSubmit = async () => {
    if (option === null) {
      DisplaySnackbar("Please choose anyone option", "error");
      return;
    }

    const source = sources[option];
    if (!source) {
      DisplaySnackbar(
        "Something went wrong. Refresh page and try again",
        "error"
      );
      return;
    }

    DisplaySnackbar("Please wait exporting data takes time", "info");
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/export${source.api}`,
      {
        headers: {
          token: `${readCookie("token")}`,
        },
      }
    );

    if (res.data.status !== "success") {
      DisplaySnackbar(res.data.message, "error");
      return;
    }

    const exportType = exportFromJSON.types.csv;
    exportFromJSON({
      data: modifyRows(res.data.data.contents),
      fileName: `${source.filename}-${source.name}`,
      exportType,
      withBOM: true,
    });
    setModal(false);
  };

  return (
    <ModalWrapper>
      <ModalContainer>
        <Header>
          <h2>Export</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <p>Choose anyone option to export contents</p>
        <div style={{ margin: "10px 0", display: "flex" }}>
          <input
            type="radio"
            name="export-content"
            onChange={() => setOption(0)}
            style={{ display: "block", margin: "auto 20px auto 0" }}
          />
          <label style={{ fontSize: "1.1rem" }}>
            Export <b>{sources[0].name}</b>
          </label>
        </div>
        {sources[1].name && (
          <div style={{ margin: "10px 0", display: "flex" }}>
            <input
              type="radio"
              name="export-content"
              onChange={() => setOption(1)}
              style={{ display: "block", margin: "auto 20px auto 0" }}
            />
            <label style={{ fontSize: "1.1rem" }}>
              Export <b>{sources[1].name}</b>
            </label>
          </div>
        )}
        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          <Button onClick={handleSubmit}>Export</Button>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default ExportModal;
