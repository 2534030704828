import React,{useState} from 'react';
import {PracticeAssessmentContainer,PracticeAssessmentHeader,PracticeAssessmentContent,PracticeAssessmentTable} from '../PracticeAssessment/practiceAssesment.style';
import StudentSvgComponent from '../../Svg/Student.component';
import AnalyticsTab from '../AnalyticsHelper/AnalyticsTab';
import ChaptersSvgComponent from '../../Svg/Chapters.component';
import QuestionSvgComponent from '../../Svg/Question.component';
import TestSvgComponent from '../../Svg/Test.component';
import TopicsSvgComponent from '../../Svg/Topics.component';
import AnalyticsContainer1 from '../AnalyticsHelper/AnalyticsContainer1';
import AnalyticsContainer2 from '../AnalyticsHelper/AnalyticsContainer2';
import AnalyticsContainer3 from '../AnalyticsHelper/AnalyticsContainer3';
import AnalyticsContainer4 from '../AnalyticsHelper/AnalyticsContainer4';
import AnalyticsContainer5 from '../AnalyticsHelper/AnalyticsContainer5';
import AnalyticsContainer6 from '../AnalyticsHelper/AnalyticsContainer6';
import AnalyticsContainer7 from '../AnalyticsHelper/AnalyticsContainer7';
import Sidebar from '../AnalyticsHelper/Sidebar/Sidebar';

import { PracticeAssesment, PracticeAssesmentData } from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader';

import AssessmentReport from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTable';

function StudentOnlineAssessment() {
    const label=[
{

        Icon:TestSvgComponent,
        heading:"Tests",
        count:"22/120"
    },{

        Icon:QuestionSvgComponent,
        heading:"Questions Practiced",
        count:"200/500"
    },{

        Icon:ChaptersSvgComponent,
        heading:"Average Score",
        count:"65/100"
    }
    ]
    const [show,setShow]=useState(false);
    return (
        <PracticeAssessmentContainer style={{maxWidth:"91vw"}}>
            <PracticeAssessmentHeader>
                <h2>Online Assessment</h2>

                <div className='tabContainer'>
                {
                    label.length&&label.map((item)=>{
                        return<AnalyticsTab data={item}/>
                    })
                }
                </div>
            </PracticeAssessmentHeader>
            <div style={{padding:"0px 30px"}}>
            <PracticeAssessmentContent>
                <AnalyticsContainer1>
                    
                </AnalyticsContainer1>
                <AnalyticsContainer2>

                </AnalyticsContainer2>
                </PracticeAssessmentContent>
                <PracticeAssessmentContent>
                <AnalyticsContainer3>

                </AnalyticsContainer3>
                <AnalyticsContainer4>

                </AnalyticsContainer4>
                </PracticeAssessmentContent>
                <PracticeAssessmentContent>
                <AnalyticsContainer5>

                </AnalyticsContainer5>
                <AnalyticsContainer6>

                </AnalyticsContainer6>
                </PracticeAssessmentContent>
                <AnalyticsContainer7>

                </AnalyticsContainer7>
                </div>
                <PracticeAssessmentTable>
                    <AssessmentReport headerText="Class Report" tabHeader={PracticeAssesment} tabContent={PracticeAssesmentData}/>
                </PracticeAssessmentTable>
                <Sidebar show={show} setShow={setShow}/>
        </PracticeAssessmentContainer>
    )
}

export default StudentOnlineAssessment
