import React, { useState, useEffect } from "react";
import { ChapterContainer } from "../../Boards.style";
import { useParams, useLocation, Link, useNavigate } from "react-router-dom";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import InputComponent from "../../../../Input/InputComponent";
import ProfileImg from "../../../../../images/profileImg.svg";
import { Line } from "../../../../Dashboard/Dashboard.style";
import HeaderPage from "../../../../Header/HeaderPage";
import exportFromJSON from "export-from-json";
import {
  DashboardHeaderWrapper,
  DashboardHeader,
} from "../../../../Dashboard/Dashboard.style";
import { BoardsHeader, ButtonWrapper, Button } from "../../Boards.style";
import CommonComponent from "../../../../CommonComponent/CommonComponent";
import DocumentLoading from "../../../../Loading/ExportLoader";
import PublishIcon from "@material-ui/icons/Publish";
// import AddTopicComponent from '../../../../Modal/AddTopicComponent';
import UploadAssessmentComponent from "../../../../Modal/UploadAssessment";
import CreateAssessmentComponent from "../../../../Modal/CreateAssessmentComponent";
import ExportModal from "../../../../Modal/ExportModal";
import ExportQuestionModal from "../../../../Modal/ExportQuestionModal";
import ImportModal from "../../../../Modal/ImportModal";
import CopyContentModal from "../../../../Modal/CopyContentModal";
import ModuleUploadModal from "../../../../Modal/Module-v2/ModuleUploadModal";
import AddModules from "../../../../Modal/AddModules";
import { useAuth } from "../../../../../Auth/Auth";
import { chapter, Module, Question, Topics } from "../../../../../Api/api";
import AddModalComponent from "../../../../Modal/AddModalComponent";
import axios from "axios";
import { addTopic } from "../../../../RenderDetailsData/RenderDetailsData";

import { sortModule } from "../../../../../utils/sortModule";
import { ModuleSearch } from "../../../ModuleSearch";

const editModule = [
  [
    {
      label: "MODULE",
      placeholder: "Enter Module Name",
      width: "100%",
      name: "name",
    },
  ],
];
const TopicLabel = [
  [
    {
      label: "TOPIC",
      placeholder: "Enter Topic",
      width: "100%",
      name: "name",
    },
  ],
  [
    {
      label: "THUMBNAIL",
      placeholder: "Enter an url",
      width: "100%",
      name: "thumbnail",
    },
  ],
];

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

function Chapters() {
  const navigation = useNavigate();
  const [activeButton, setActiveButton] = useState(0);
  const [module, setModule] = useState(true);
  const [modal, setModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [exportQuestionModal, setExportQuestionModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [copyContentModal, setCopyModal] = useState(false);
  const [moduleUploadModal, setModuleUploadModule] = useState(false);
  const [type, setType] = useState("");
  const [topics, setTopics] = useState([]);
  const [allModule, setAllModule] = useState([]);
  const [filterModule, setFilterModule] = useState([]);
  const [rendr, setRndr] = useState(false);
  const query = useLocation().search;
  const [questionUploading, setQuestionUploading] = useState(false);
  const trial = new URLSearchParams(query).get("trial");
  const { readCookie, DisplaySnackbar, imgUrl } = useAuth();
  const [headerText, setHeaderText] = useState("");
  const { state } = useLocation();
  const [activeId, setActiveId] = useState("");
  const [editId, setEditId] = useState("");
  const [edit, setEdit] = useState(false);
  const [render, setRender] = useState(false);
  const [formData, setFormData] = useState({
    disabled: true,
    lang: "",
    modules: [],
    name: "",
    thumbnail: "",
    totalModules: 1,
    trial: true,
    _id: "",
  });
  const {
    id,
    classId,
    chapterId,
    subjectId,
    countryName,
    subjectName,
    chapterName,
    boardName,
    className,
    boardId,
    TopicName,
    TopicId,
  } = useParams();

  const [topicQuestion, setQuestion] = useState([]);
  const [search,setSearch] = useState("");

  const leftHeader = () => {
    return (
      <div>
        <span>Courses</span> {` > `}
        <Link style={{ textDecoration: "none", color: "black" }} to="/courses">
          {" "}
          <span>{countryName}</span>
        </Link>{" "}
        {` > `}
        <span
          onClick={() => sendBack("board")}
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        >
          {boardName}
        </span>
        {` > `}
        <span
          onClick={() => sendBack("board")}
          style={{ textDecoration: "none", color: "black", curosr: "pointer" }}
        >
          {className}
        </span>
        {` > `}
        <span
          onClick={() => sendBack("subject")}
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        >
          {subjectName}
        </span>{" "}
        {` > `}
        <span
          onClick={() => sendBack("subject")}
          style={{ textDecoration: "none", color: "black", cursor: "pointer" }}
        >
          {chapterName}
        </span>
        {` > `}
        <span>Topics</span>
      </div>
    );
  };

  const handleOnClick = (modulesId) => {
    let _topic = topics.find((item) => item._id === activeId);
    let TopicName = _topic?.name || "";
    let TopicId = _topic?._id || "";
    navigation(
      `/courses/${fixName(countryName)}/${id}/${fixName(
        boardName
      )}/${boardId}/${fixName(className)}/${classId}/${fixName(
        subjectName
      )}/${subjectId}/${fixName(chapterName)}/${chapterId}/${fixName(
        TopicName
      )}/${TopicId}/modules/${modulesId}`,
      {
        state: {
          topicId: activeId,
        },
      }
    );
  };

  const sendBack = (type) => {
    if (type === "board") {
      navigation(`/courses/${fixName(countryName)}/${id}`, {
        state: {
          boardID: boardId,
        },
      });
    } else {
      navigation(
        `/courses/${fixName(countryName)}/${id}/${fixName(
          boardName
        )}/${boardId}/${fixName(className)}/${classId}`,
        {
          state: {
            subjectId: subjectId,
            lang: state?.lang || "english",
          },
        }
      );
    }
  };

  const handleCreateTopic = () => {
    axios
      .post(
        Topics.addTopics,
        {
          name: formData.name,
          lang: state.lang,
          thumbnail: formData.thumbnail,
          chapterId: chapterId,
          trial,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (topics.length === 0) {
          setRndr(!rendr);
        }
        setTopics([...topics, res.data.data.topic]);

        DisplaySnackbar("Topic Added", "success");

        setModal(false);
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const EditTopic = () => {
    if (!formData.name) {
      DisplaySnackbar("Add Topic Name First", "error");
      return;
    }
    axios
      .patch(
        Topics.EditTopic(formData._id),
        {
          name: formData.name,
          lang: state.lang,
          thumbnail: formData.thumbnail,
          chapterId: chapterId,
          trial,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setTopics(
          topics.map((item) =>
            item._id === id
              ? {
                  ...item,
                  name: formData.name,
                  lang: state?.lang || "english",
                  thumbnail: formData.thumbnail,
                  trial,
                }
              : item
          )
        );
        setModal(false);
        DisplaySnackbar("Edit Topic SuccessFull", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  // useEffect(() => {
  //   if (!modal || !edit) {
  //     setFormData({
  //       disabled: true,
  //       lang: "",
  //       modules: [],
  //       name: "",
  //       thumbnail: "",
  //       totalModules: 1,
  //       trial: true,
  //       _id: "",
  //     });
  //   }
  // }, [modal, edit]);

  const handleEdit = (moduleId) => {
    setEdit(true);
    setEditId(moduleId);
    const moduleToEdit = allModule.find((item) => `${item._id}` === moduleId);
    //setEditId(id);
    //setHeaderText("Edit Module");
    //setModal(true);
    setFormData(moduleToEdit || {});
    //setType("MODULE");
    console.log("LOGGED 313");
  };

  useEffect(() => {
    if (!editId) {
    }
  }, [editId]);

  const handleEditModule = () => {
    if (!formData.name) {
      DisplaySnackbar("Enter Module Name", "error");
      return;
    }
    axios
      .patch(
        Module.updateModules(editId),
        {
          name: formData.name,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setAllModule(
          allModule.map((item) =>
            item._id === editId ? { ...item, name: formData.name } : item
          )
        );
        setFilterModule(
          filterModule.map((item) =>
            item._id === editId ? { ...item, name: formData.name } : item
          )
        );

        setEdit(false);
        DisplaySnackbar("Module Edited", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const handleCreate = () => {
    if (edit) {
      handleEditModule();
    } else if (headerText === "Add Topics") {
      handleCreateTopic();
    } else if (headerText === "Edit Topics") {
      EditTopic(id);
    }
  };
  const handleCreateAssessment = async (field) => {
    const {
      question,
      questionType,
      options,
      cognitive,
      difficulty,
      correctAnswers,
      quesImg,
      optionsFile,
    } = field;

    let form = new FormData();

    form.append("subjectId", subjectId);
    form.append("chapterId", chapterId);
    if (quesImg) {
      form.append("questionImg", quesImg);
    }
    for (let i = 0; i < optionsFile.length; i++) {
      form.append(`op${i + 1}`, optionsFile[i].value);
    }
    form.append(
      "question",
      JSON.stringify({
        question,
        questionType,
        options,
        cognitive,
        difficulty,

        correctAnswers,

        visibilityStatus: "publicForAll",
      })
    );
    form.append("topicId", activeId);

    await axios
      .post(Question.addQuestion, form, {
        headers: { token: `${readCookie("token")}` },
        validateStatus: () => true,
      })
      .then((res) => {
        // console.log(res);
        if (res.data.status !== "success") {
          throw res;
        }
        setQuestion([{ ...res.data.data.question }, ...topicQuestion]);
        setModal(false);
        DisplaySnackbar("Question Created", "success");
      })
      .catch((err) => {
        // console.log(err);
        DisplaySnackbar(err.data.message, "error");
      });
  };

  const handleUpdateQuestion = (id, data) => {
    axios
      .patch(Question.editQuestion(id), data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setQuestion(
          topicQuestion.map((que) =>
            que._id === id ? { ...res.data.data.question } : que
          )
        );
        DisplaySnackbar("Question Updated", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const ToogleDisable = (id, disable) => {
    axios
      .patch(
        Module.toogleDisableModule(id),
        {
          disabled: !disable,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setAllModule(
            allModule.map((item) =>
              item._id === id ? { ...item, disabled: !disable } : item
            )
          );
          setFilterModule(
            filterModule.map((item) =>
              item._id === id ? { ...item, disabled: !disable } : item
            )
          );
          DisplaySnackbar(
            `Module has been  ${disable ? "Enabled" : "Disabled"}`,
            "success"
          );
        }
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const handleDeleteQuestion = (id) => {
    axios
      .delete(Question.deleteQuestion(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setQuestion(topicQuestion.filter((item) => item._id !== id));
        // alert("Deleted Sucessfully");
        DisplaySnackbar("Deleted Successfully", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  // console.log(allModule);
  const handleUploadAssessment = async (quest) => {
    setModal(false);
    setQuestionUploading(true);
    await axios
      .post(
        Question.UploadQuestions,
        {
          subjectId: subjectId,
          chapterId: chapterId,
          topicId: activeId,
          questions: quest,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setModal(false);
        // setQuestion([...topicQuestion, ...quest]);
        setRender(!render);
        if (res?.data?.errors?.errorLogs?.length) {
          let data = res?.data?.errors?.errorLogs.map((item) => {
            return {
              row: item?.row,
              error: item?.error,
            };
          });

          const fileName = "Error_Logs";
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({ data, fileName, exportType });
        }
        DisplaySnackbar(
          `Question Inserted = ${res.data?.errors?.inserted} and Question Skipped= ${res?.data?.errors?.skipped} out of Total Question=${res?.data?.errors?.totalQuestions} `,
          "info",
          10000000
        );
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
    setQuestionUploading(false);
  };

  const onTopicChange = (id) => {
    if (id) {
      setActiveId(id);
      axios
        .get(Module.getModuleByTopics(id), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          const filteredModules = res.data.data.topic.modules.filter(
            (item) => item.lang === state.lang
          );

          const sortedModules = sortModule(filteredModules);
          setAllModule(sortedModules);
          setFilterModule(sortedModules);
        })
        .catch((err) => {
          if (err.reponse) {
            DisplaySnackbar(err.response.data.message, "error");
          }
        });
    }
  };
  useEffect(() => {
    axios
      .get(chapter.getTopicsByChapter(chapterId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let id = "";
        let data = res.data.data.chapter.topics.filter(
          (item) => item.lang === state.lang && item.trial === state.trial
        );
        id = data[0]?._id;
        setTopics(data);
        onTopicChange(id);
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  }, [rendr]);

  const handleDeleteTopic = (id) => {
    axios
      .delete(Topics.deleteTopic(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Topic deleted", "success");
          handleupdateDeleteTopiclist(id);
        }
      })
      .catch((err) => {
        DisplaySnackbar(err?.response?.data?.message, "error");
      });
  };

  const handleDisableTopic = (id, disable) => {
    axios
      .patch(
        Topics.toogleDisableTopic(id),
        {
          disabled: !disable,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const topicIndex = topics.findIndex((topic) => `${topic._id}` === id);
          topics[topicIndex].disabled = !disable;
          setTopics([...topics]);
          DisplaySnackbar(
            `Topic has been  ${disable ? "Enabled" : "Disabled"}`,
            "success"
          );
        }
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateDeleteTopiclist = (id) => {
    const filteredData = topics.filter((i) => i._id !== id);
    setTopics(filteredData);
  };

  const handleDeleteModule = (id) => {
    axios
      .delete(Module.deleteModule(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Module deleted", "success");
          handleupdateDeleteModulelist(id);
        }
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateDeleteModulelist = (id) => {
    const filteredData = allModule.filter((i) => i._id !== id);
    setAllModule([...filteredData]);
  };

  // const handleEditTopic = () => {
  //   console.log("clicked");
  //   EditTopic();
  // };


  useEffect(() => {
    if (filterModule.length) {
      setAllModule(filterModule);
    }
  }, [activeButton]);

  const handleEdittopic = (id) => {
    const topicToEdit = topics.find((item) => item._id === id);
    setEditId(id);
    setHeaderText("Edit Topics");
    setModal(true);
    setFormData(topicToEdit || {});
    setType("TOPIC");
  };

  const EditModule = (id) => {
    const moduleToEdit = allModule.find((item) => `${item._id}` === id);
    setEditId(id);
    setHeaderText("Edit Module");
    setModal(true);
    setFormData(moduleToEdit || {});
    setType("MODULE");
  };

  return (
    <ChapterContainer>
      <DashboardHeaderWrapper minHeight="90px">
        <DashboardHeader>
          <div>
            <h1>Courses</h1>
          </div>

          <div>
            {module?<ModuleSearch/>:<InputComponent
              val={search}
              setVal={setSearch}
              type="text"
              search
              placeholder="Search..."
            />}
            <Link to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImg}
                alt="profileimg"
              />
            </Link>
          </div>
        </DashboardHeader>
        <DashboardHeader></DashboardHeader>
        <HeaderPage
          close={false}
          leftHeader={leftHeader(
            countryName,
            boardName,
            className,
            subjectName,
            chapterName,
            id,
            classId,
            chapterId,
            subjectId
          )}
        />
      </DashboardHeaderWrapper>
      <Line />
      <BoardsHeader>
        <div className={`firstClass ${!module && "assessment"}`}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setType("TOPIC");
              setHeaderText("Add Topics");
            }}
            className="child iconContainer"
          >
            {" "}
            <AddBoxOutlinedIcon className="icon" /> Add Topics
          </div>
          <div
            onClick={() => {
              setModal(true);
              setType("");
              setHeaderText(module ? "Add Modules" : "Create Assessment");
            }}
            style={{ cursor: "pointer" }}
            className="child iconContainer"
          >
            <AddBoxOutlinedIcon className="icon" />{" "}
            {module ? "Add Modules" : "Create Assessment"}
          </div>
          {module && (
            <div
              onClick={() => {
                setCopyModal(true);
                setType("");
                setHeaderText("Copy Module");
              }}
              style={{ cursor: "pointer" }}
              className="child iconContainer"
            >
              <AddBoxOutlinedIcon className="icon" /> Copy Module
            </div>
          )}
          {module && (
            <div
              onClick={() => {
                setModuleUploadModule(true);
                setType("");
                setHeaderText("Add Module");
              }}
              style={{ cursor: "pointer" }}
              className="child iconContainer"
            >
              <AddBoxOutlinedIcon className="icon" /> Upload Module v2
            </div>
          )}
          {!module && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setModal(true);
                setType("assessment");
                setHeaderText("Upload Assessment");
              }}
              className="child iconContainer"
            >
              <PublishIcon className="icon" /> Upload Assessment
            </div>
          )}
          {!module && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setExportQuestionModal(true)}
              className="child iconContainer"
            >
              <SystemUpdateAltOutlinedIcon
                className="icon"
                style={{ transform: "rotate(180deg)" }}
              />{" "}
              Export Assessment
            </div>
          )}
        </div>
        <div style={{ justifyContent: "flex-end" }} className="secondClass">
          {module && (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setImportModal(true)}
              className="child iconContainer"
            >
              <SystemUpdateAltOutlinedIcon
                className="icon"
                style={{ transform: "rotate(180deg)" }}
              />{" "}
              Import
            </div>
          )}
          {module && (
            <div
              style={{ cursor: "pointer", marginRight: "20px" }}
              onClick={() => setExportModal(true)}
              className="child iconContainer"
            >
              <SystemUpdateAltOutlinedIcon className="icon" /> Export
            </div>
          )}
          <div className="child">
            <ButtonWrapper>
              <Button
                onClick={() => {
                  setActiveButton(0);
                  setModule(true);
                }}
                first
                active={activeButton === 0}
              >
                Module
              </Button>
              <Button
                onClick={() => {
                  setActiveButton(1);
                  setModule(false);
                }}
                second
                active={activeButton === 1}
              >
                Assessment
              </Button>
            </ButtonWrapper>
          </div>
        </div>
      </BoardsHeader>
      <Line />
      <CommonComponent
        onTabChange={onTopicChange}
        module={module}
        handleOnClick={handleOnClick}
        type="Topics"
        filterBy={search}
        tab={topics}
        showDisable={true}
        ToogleDisable={ToogleDisable}
        handleEdit={handleEdit}
        tabContent={allModule}
        assessment={activeButton === 1 ? true : false}
        topicId={activeId}
        topicQuestion={topicQuestion}
        setQuestion={setQuestion}
        render={render}
        isBoard={false}
        isTopic={true}
        setType={setType}
        isModule={true}
        handleEdittopic={handleEdittopic}
        handleDeleteTopic={handleDeleteTopic}
        handleDisableTopic={handleDisableTopic}
        handleDeleteModule={handleDeleteModule}
        handleUpdateQuestion={handleUpdateQuestion}
        handleDeleteQuestion={handleDeleteQuestion}
        EditModule={EditModule}
      />
      {edit && (
        <AddModalComponent
          buttonText={"Edit"}
          formData={formData}
          setFormData={setFormData}
          setModal={setEdit}
          label={type === "TOPIC" ? TopicLabel : editModule}
          headerText="Edit Module"
          handleEditModule={handleEditModule}
          modalType={type === "TOPIC" ? "TOPIC" : "MODULE"}
        />
      )}
      {modal &&
        (headerText === "Add Topics" ? (
          <AddModalComponent
            headerText={headerText}
            formData={formData}
            setFormData={setFormData}
            handleCreate={handleCreate}
            label={addTopic}
            setModal={setModal}
          />
        ) : headerText === "Add Modules" ? (
          <AddModules
            allModule={allModule}
            setAllModule={setAllModule}
            activeId={activeId}
            setModal={setModal}
            lang={state.lang}
          />
        ) : headerText === "Create Assessment" ? (
          <CreateAssessmentComponent
            handleCreateAssessment={handleCreateAssessment}
            setModal={setModal}
            no={topicQuestion.length + 1}
          />
        ) : headerText === "Edit Topics" ? (
          <AddModalComponent
            headerText={headerText}
            formData={formData}
            setFormData={setFormData}
            handleCreate={handleCreate}
            label={addTopic}
            // setModal={setModal}
            buttonText={headerText === "Edit Topics" ? "Edit" : "Create"}
            modalType={type == "TOPIC" ? "TOPIC" : "TOPIC"}
            EditTopic={EditTopic}
          />
        ) : (
          <UploadAssessmentComponent
            handleUploadAssessment={handleUploadAssessment}
            setModal={setModal}
          />
        ))}
      {questionUploading && (
        <DocumentLoading text="Please Wait we are uploading your questions" />
      )}
      {exportModal && (
        <ExportModal
          setModal={setExportModal}
          sources={[
            {
              name: chapterName,
              api: `/chapter-contents/${chapterId}?lang=${
                state?.lang || "english"
              }`,
              filename: `${countryName}-${boardName}-${className}-${subjectName}`,
            },
            {
              name: topics.find((item) => `${item._id}` === activeId)?.name,
              api: `/topic-contents/${activeId}?lang=${
                state?.lang || "english"
              }`,
              filename: `${countryName}-${boardName}-${className}-${subjectName}-${chapterName}`,
            },
          ]}
        />
      )}
      {importModal && (
        <ImportModal
          setModal={setImportModal}
          sources={[
            {
              name: chapterName,
              path: `${countryName} > ${boardName} > ${className} > ${subjectName} > ${chapterName}`,
              api: `/chapter-contents/${chapterId}`,
            },
            {
              name: topics.find((item) => `${item._id}` === activeId)?.name,
              path: `${countryName} > ${boardName} > ${className} > ${subjectName} > ${chapterName}`,
              api: `/topic-contents/${activeId}`,
            },
          ]}
        />
      )}
      {exportQuestionModal && (
        <ExportQuestionModal
          setModal={setExportQuestionModal}
          sources={[
            {
              name: chapterName,
              path: `${countryName} > ${boardName} > ${className} > ${subjectName} > ${chapterName}`,
              api: `?chapterId=${chapterId}`,
            },
            {
              name: topics.find((item) => `${item._id}` === activeId)?.name,
              path: `${countryName} > ${boardName} > ${className} > ${subjectName} > ${chapterName}`,
              api: `?topicId=${activeId}`,
            },
          ]}
        />
      )}
      {copyContentModal && (
        <CopyContentModal
          setModal={setCopyModal}
          destination={{
            id: activeId,
            name: topics.find((item) => `${item._id}` === activeId)?.name,
            api: `/copy-module`,
            setContent: (module) => {
              if (!module) return;
              setAllModule([...allModule, module]);
            },
          }}
        />
      )}
      {moduleUploadModal && (
        <ModuleUploadModal
          allModule={allModule}
          setAllModule={setAllModule}
          activeId={activeId}
          setModal={setModuleUploadModule}
          lang={state.lang}
        />
      )}
    </ChapterContainer>
  );
}

export default Chapters;
