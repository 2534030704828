import styled from "styled-components";

export const InputField=styled.input`
padding:3px 6px;
box-sizing:border-box;
border:1px solid #b8b7b4;
border-radius:10px;
display:block;
width:100%;
height:100%;
&:focus{
    outline:none;
}



`
export const InputContainer=styled.div`
position:relative;

box-sizing:border-box;
height:35px;
width: 200px;
.searchIcon{
    position:absolute;
    box-sizing:border-box;

    top:50%;
    color:#b8b7b4;
    right:3px;
    transform:translateY(-50%);

}

`
export const InputTag=styled.input`
display:block;
width:100%;
padding:8px 10px;
height:${({height})=>height?height:"100%"};
box-sizing:border-box;
border-radius:5px;
font-weight:500;
color:${({color})=>color?color:"#000"};
font-size:${({fontsize})=>fontsize?fontsize:"1.1rem"} ;
margin:${({marginTop})=>marginTop?marginTop:""};
background-color:${({background})=>background};

opacity:${({opacity})=>opacity};
border:${({border})=>border==="none"?`none`:`1px solid grey`};



${({disabled})=>disabled&&`
background-color:transparent;
border:${({bdr})=>bdr?"1px solid grey":"none"};
padding-left:0px;

`

}

${({select})=>select&&`border:1px solid grey;padding-left:10px;`}




border-color:${({bordercolor})=>bordercolor?bordercolor:"grey"};
&:focus{
    outline:none;
}


`


export const Dropdown=styled.select`
display:block;
width:100%;
height:100%;
padding:0px 10px;
box-sizing:border-box;
border-radius:5px;
font-weight:500;
background-color:transparent;
box-sizing:border-box;
&:focus{
    outline:none;
}


`

export const Option=styled.option`
height:40px;
padding:8px 10px;
box-sizing:border-box;
border-radius:5px;
font-weight:500;
background-color:transparent;

`