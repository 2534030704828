import styled from 'styled-components';
export const TablePage=styled.div`

    // position:absolute;
    // right: 0;
    // left: 0;
    // bottom: 0;
    // // top:30px;
    max-width:90%;margin:0 auto;
    .hover{
       background-color:rgba(75, 223, 63, 0.51);

    }
    background: #FFFFFF;
box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.37);
border-radius: 19px;
padding:20px 10px;
h2{
    font-size:27px;
    font-weight:600;
}
    // .link{
    //     &:hover{
    //         color:green;
    //     }
    // }
`