import React from 'react';
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {BarChart,CartesianGrid,XAxis,YAxis,Tooltip,Cell,Bar,ResponsiveContainer,LabelList,Legend} from 'recharts';
import ReactLoading from 'react-loading';

const data= [
    {
      "name": "Hindi",
      "strength": 40,
      "gap": 24,
      
    },
    {
      "name": "Science",
      
      "strength":30,
      "gap":40
    },]
function PracticeLearningStatusAndGap(props) {
  const {data,loading=false}=props;
    return (
        <AnalyticsContainer style={{marginBottom:"50px"}}>
          {loading?<div style={{display:"flex",width:"100%",height:"100%",alignItems:"center",justifyContent:"center"}}><ReactLoading type="spin" color="cyan" height={100} width={100}/>
          </div>:<>
           <div className='learningStrength'>
            Strength & Gap(%)
          </div>
            <div style={{textAlign:"center"}}>
            <div style={{fontSize:"1.3rem",fontWeight:"600"}}>
            Learning Strength and Gap
            </div>
            
          </div>
          <div style={{width:"100%",height:"320px"}}>
                   <ResponsiveContainer>
            <BarChart width="100%" height="100%" data={data}>
  <CartesianGrid strokeDasharray="1 1" />
  <XAxis tick={{ fill: "rgba(0, 0, 0)" }} dataKey="name" style={{fontWeight:"600",color:"black"}} />
  {/* <Legend style={{fontWeight:"600"}} /> */}
  <YAxis tickCount={10} allowDecimals={false}  />
  <Legend payload={[{
    id:"Strength",
    value:"Strength",
    type:"rect",
    color:"#5CE0D2"
  },{
    id:"Gap",
    value:"Gap",
    type:"rect",
    color:"#FF7E7E"
  }]} />
  <Tooltip cursor={{fill: '#fff'}} />
  
  <Bar barSize={26} dataKey="Score"  >
    {data.map((item,index)=>{
      return <Cell key={`cell-${index}`} fill={item.type==="Gap"?"#FF7E7E":"#5CE0D2"}/>
    })}
  
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"600",fontSize:"0.7rem"}} dataKey="Score" position="middle" />
    </Bar>
 
  {/* <Bar barSize={26} dataKey="gap" fill="#FF7E7E" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500"}} dataKey="gap" position="middle" />
    </Bar> */}
  
  
</BarChart>
</ResponsiveContainer>
</div></>}
        </AnalyticsContainer>
    )
}

export default PracticeLearningStatusAndGap;
