import styled from "styled-components"
export const Button=styled.button`
margin:0px 10px;
background-color:transparent;
border:none;
padding:8px 20px;
border:1px solid #5CE0D2;
border-radius:5px;
cursor:pointer;
transition:.5s;
&:hover{
    background-color:#5CE0D2;
}`