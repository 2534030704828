import styled from 'styled-components';
export const TablePage=styled.div`

    .row{
        &:hover{
            background-color:#5CE0D2;
        }
    }
    .MuiTableCell-stickyHeader{
        position:static !important;
    }
    .resetPassword:hover{
        opacity:0.3;
        transition:0.3s;
    }
    .resetPassword::after{
        content:"Reset Password";
        color:red;
    }
    // .link{
    //     &:hover{
    //         color:green;
    //     }
    // }
`