import React, { useState } from "react";
import {
  LoginFormContainer,
  LoginHeader,
  LoginButton,
  LoginField,
} from "./Login.style";
import { Input } from "../Input/InputComponent";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { login } from "../../Api/api";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import { useNavigate } from "react-router-dom";

function LogInForm() {
  const navigation = useNavigate();
  const {
    createCookie,
    setUserId,
    setName,
    setRole,
    setImgUrl,
    setLogedIn,
    DisplaySnackbar,
  } = useAuth();
  const [visible, setVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleLogin = () => {
    if (!formData.email || !formData.password) {
      alert("All field are mandatory");
    } else {
      axios
        .post(
          login,
          { email: formData.email, password: formData.password },
          {
            header: {
              "Content-Type": "application/json",
            },
            validateStatus: () => true,
          }
        )
        .then((res) => {
          if (res.data.status === "connecting") {
            throw {
              message: "Sorry! Please try again after few seconds!",
              type: "info",
            };
          }
          if (!["success", "authorized"].includes(res.data.status)) {
            throw { message: res.data.message, type: "error" };
          }

          createCookie("token", res.data.data.token, res.data.data.expiresIn);
          window.localStorage.setItem("token", res.data.token);

          setUserId(res.data.data.payload.userId);
          setName(res.data.data.payload.name);
          setRole(res.data.data.payload.name);
          localStorage.setItem("name", res.data.data.payload.name);
          localStorage.setItem("imgUrl", res.data.data.payload.profile.fileUrl);
          localStorage.setItem("userId", res.data.data.payload.userId);
          setLogedIn(true);
          DisplaySnackbar("Logged In Successfully!", "success");

          navigation("/");
        })
        .catch((err) => {
          if(err.message === "Network Error") {
            err.message = "Sorry! Please try again after few seconds!";
            err.type = "info";
          }
          DisplaySnackbar(err.message, err.type || "error");
        });
    }
  };
  return (
    <LoginFormContainer>
      <LoginHeader>
        <h2>Sign In</h2>
        <p>Sign in with your details</p>
      </LoginHeader>
      <LoginField>
        <Input
          value={formData.email}
          handleOnChange={handleOnChange}
          name="email"
          type="text"
          background="#dbfbff"
          bordercolor="#5CE0D2"
          marginTop="16px 0"
          placeholder="Username or email address"
        />
        <div className="pass">
          <Input
            value={formData.password}
            handleOnChange={handleOnChange}
            name="password"
            type={!visible ? "password" : "text"}
            bordercolor="#5CE0D2"
            marginTop="16px 0"
            placeholder="Password"
          />
          <div onClick={() => setVisible(!visible)} className="icons">
            {visible ? (
              <VisibilityOffOutlinedIcon />
            ) : (
              <VisibilityOutlinedIcon />
            )}
          </div>
        </div>
      </LoginField>
      <LoginButton onClick={() => handleLogin()}>
        Sign in to my account
      </LoginButton>
    </LoginFormContainer>
  );
}

export default LogInForm;
