import React,{useState} from 'react';
import { SettingContainer ,SettingHeader,SettingContent} from './Setting.style';
import ProfileImg from '../../images/profileImg.svg';
import {changePassword} from '../RenderDetailsData/RenderDetailsData'
import Details from '../Details/Details';
import {Line} from '../Dashboard/Dashboard.style';
import {Button} from '../Dashboard/TabComponent/TabComponent.style';
import axios from 'axios';
import {useAuth} from '../../Auth/Auth';
import { Profile } from '../../Api/api';

function Setting() {
    const [formData,setFormData]=useState({
        oldPassowrd:"",
        newPassword:"",
        confirmPassword:""
        
    })
    const {readCookie,DisplaySnackbar}=useAuth();
    const handleOnChange=(e)=>{
        setFormData({...formData,[e.target.name]:e.target.value})

    }

    const handleChangePassword=()=>{
        if(formData.newPassword!==formData.confirmPassword)
        {
            DisplaySnackbar("Passwords are not matching","error")
            return
        }
       
        axios.patch(Profile.changePassword,{currentPassword:formData.oldPassword,newPassword:formData.newPassword},{
            headers:{
                'token':`${readCookie('token')}`
            }
        }).then(res=>{
            
            
                DisplaySnackbar("Password has Changed Successfully","success");
            setFormData({
                oldPassowrd:"",
                newPassword:"",
                confirmPassword:""

            })
        }).catch(err=>{if(err.response)
            {
                DisplaySnackbar(err.response.data.message,"error");
            }
        
        });
    }
    return (
       <SettingContainer>
           <SettingHeader>
                <div className="firstClass">
                    <h1>
                        Settings
                    </h1>
                    <img src={ProfileImg} alt="profile img"/>

                </div>
                <div className="secondClass">
                        <h3>Password Settings</h3>
                </div>
           </SettingHeader>
           <Line/>
           <SettingContent>
                <Details relative={false} formData={formData} handleOnChange={handleOnChange} margintop="10px" labelcolor="black" color="grey" fontsize=".9rem" height="32px" data={changePassword} edit={true}/>
           </SettingContent>
           <div>
            <Button onClick={()=>handleChangePassword()} style={{marginLeft:"0px" ,marginTop:"30px"}}>
                Save
            </Button>
           </div>
       </SettingContainer>
    )
}

export default Setting
