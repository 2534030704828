import QuestionAttemptStatus from "../Components/SchoolsAnalytics/AnalyticsHelper/AnalyticsQuestionStatus";

export const OnlineAnalytics={
    overView:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/teststatusoverview?school=${schoolId}&section=${sectionId}`,
   testAssigned:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/totaltestsassigned?school=${schoolId}&section=${sectionId}`, 
    testAttemptStatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testsattemptstatus?school=${schoolId}&section=${sectionId}`,
    QuestionAttemptStatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testquestionsattemptstatus?school=${schoolId}&section=${sectionId}`,
    avgcorrectquestionlevelstatus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/avgcorrectquestionlevelstatus?school=${schoolId}&section=${sectionId}`,
    learningSttaus:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testslearningstatus?school=${schoolId}&section=${sectionId}`,
    testAttemptStatusPieChart:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testsattemptstatus?school=${schoolId}&section=${sectionId}&overall=true`, 
    questionAttemptStatusPieChart:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testquestionsattemptstatus?school=${schoolId}&section=${sectionId}&overall=true`,
    classReports:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/classreport?school=${schoolId}&section=${sectionId}`,

}
export const OnlineAnalyticsFilterByStudent={
    overView:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/teststatusoverview?school=${schoolId}&section=${sectionId}&student=${studentId}`,
   testAssigned:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/totaltestsassigned?school=${schoolId}&section=${sectionId}&student=${studentId}`, 
    testAttemptStatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testsattemptstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    QuestionAttemptStatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testquestionsattemptstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    avgcorrectquestionlevelstatus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/avgcorrectquestionlevelstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    learningSttaus:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testslearningstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    testAttemptStatusPieChart:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testsattemptstatus?school=${schoolId}&section=${sectionId}&student=${studentId}&overall=true`, 
    questionAttemptStatusPieChart:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/testquestionsattemptstatus?school=${schoolId}&section=${sectionId}&student=${studentId}&overall=true`,
    classReports:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/testanalytics/classreport?school=${schoolId}&section=${sectionId}&student=${studentId}`, 
}