import React, { useEffect, useState } from "react";

import axios from "axios";
import { Question } from "../../Api/api";
import { useAuth } from "../../Auth/Auth";
import Questions from "./Questions";
import { Wrapper } from "./Questions.style";
import DropDown from "../DropDown/DropDown";
import ReactPaginate from "react-paginate";
import Loader from "../Loading/Loader";
import Pagination from "@mui/material/Pagination";
// import Stack from '@mui/material/Stack';

function AllQuestion(props) {
  const { readCookie } = useAuth();
  const questionPerScreen = 5;
  const [loading, setLoading] = useState(true);
  const [currentScreen, setCurrentScreen] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const {
    topicId,
    topicQuestion,
    setQuestion,
    handleDeleteQuestion,
    handleUpdateQuestion,
    filterBy,
    render,
  } = props;
  const [formData, setFormData] = useState({ cognitive: "", difficulty: "" });
  const [FilterQuestions, setFilterQuestions] = useState([]);

  useEffect(() => {
    setFormData({ cognitive: "", difficulty: "" });
    setLoading(true);
    setCurrentScreen(1);
    axios
      .get(Question.getQuestions(topicId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        //console.log(res);
        setQuestion(res.data.data.questions);

        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [topicId, render]);

  useEffect(() => {
    setFilterQuestions(topicQuestion);
  }, [topicQuestion]);

  useEffect(() => {
    if (formData.cognitive || formData.difficulty || filterBy) {
      let temp = topicQuestion;
      if (formData.cognitive) {
        temp = temp.filter((item) => item.cognitive === formData.cognitive);
      }
      if (formData.difficulty) {
        temp = temp.filter((item) => item.difficulty === formData.difficulty);
      }
      if (filterBy) {
        temp = temp.filter((item) =>
          item.question.toLowerCase().includes(filterBy.toLowerCase())
        );
        setCurrentScreen(1);
      }
      setFilterQuestions(temp);
    } else {
      setFilterQuestions(topicQuestion);
    }
  }, [formData, filterBy]);

  useEffect(() => {
    setPageCount(Math.ceil(FilterQuestions.length / questionPerScreen));
  }, [FilterQuestions]);

  const changePage = ({ selected }) => {
    //console.log(selected);
    setCurrentScreen(selected + 1);
  };
  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [currentScreen]);

  return (
    <>
      {loading ? (
        <Loader type="spin" color="cyan" />
      ) : (
        <Wrapper>
          <div className="headingAssesment">
            <div className="child">
              <div className="totalQuestion">
                <div>Q</div>
                {FilterQuestions.length}
              </div>
              <div className="filter">
                <DropDown
                  options={[
                    "knowledge",
                    "analysis",
                    "understanding",
                    "application",
                  ]}
                  name="cognitive"
                  formData={formData}
                  setFormData={setFormData}
                  placeholder="Cognitive"
                />
                <DropDown
                  options={["easy", "intermediate", "hard"]}
                  name="difficulty"
                  formData={formData}
                  setFormData={setFormData}
                  placeholder="Difficulty"
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              forcePage={currentScreen - 1}
            />
          </div>
          <div>
            {FilterQuestions.slice(
              (currentScreen - 1) * questionPerScreen,
              currentScreen * questionPerScreen
            ).map((item, idx) => (
              <Questions
                handleDeleteQuestion={handleDeleteQuestion}
                id={item._id}
                key={item._id}
                handleUpdateQuestion={handleUpdateQuestion}
                {...item}
                no={(currentScreen - 1) * questionPerScreen + idx + 1}
              />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
              pageRangeDisplayed={1}
              marginPagesDisplayed={1}
              forcePage={currentScreen - 1}
            />
          </div>
          {/* <Pagination count={10} color="primary" /> */}
        </Wrapper>
      )}
    </>
  );
}

export default AllQuestion;
