import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@mui/material/Menu';
import { MenuItem } from 'react-contextmenu';

const BurgerMenu = (props) => {
  const {
    handleClickEdit,
    handleClickOpen,
    handleDisableOpen,
    ITEM_HEIGHT,
    handleClose,
    handleClick,
    itemData,
    isBoard,
    handleClickBoard,
    isClass,
    handleClickClass,
    isChapter,
    isTopic,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  return (
    <>
      <span>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-haspopup='true'
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            e.stopPropagation();
            handleClick(e, itemData);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      </span>
      <span>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => {
            setAnchorEl(null);
            handleClose(e);
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '10ch',
            },
          }}
        >
          <MenuItem onClick={(e) => handleClose(e)} style={{ padding: '0px' }}>
            <div style={{ padding: '15px', display: 'flex' }}>
              <span
                style={
                  {
                    //paddingRight: "10px",
                  }
                }
              >
                {isBoard ? (
                  <>
                    <Tooltip title='Edit'>
                      <EditIcon
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          handleClickBoard(e, itemData);
                        }}
                      />
                    </Tooltip>
                  </>
                ) : isClass ? (
                  <>
                    {' '}
                    <Tooltip title='Edits'>
                      <EditIcon
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          handleClickClass(e, itemData);
                        }}
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    {' '}
                    <Tooltip title='Edits'>
                      <EditIcon
                        onClick={(e) => {
                          setAnchorEl(e.currentTarget);
                          handleClickEdit(e, itemData);
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </span>
              <span>
                <Tooltip title='Delete'>
                  <DeleteOutlineIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickOpen(e, itemData._id);
                    }}
                  />
                </Tooltip>
              </span>
              {(isTopic || isChapter) && (
                <span>
                  <Tooltip title={itemData.disabled ? 'Enable' : 'Disable'}>
                    {itemData.disabled ? (
                      <DoneIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDisableOpen(e, itemData);
                        }}
                      />
                    ) : (
                      <CancelRoundedIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDisableOpen(e, itemData);
                        }}
                      />
                    )}
                  </Tooltip>
                </span>
              )}
            </div>
          </MenuItem>
        </Menu>
      </span>
    </>
  );
};

export default BurgerMenu;
