import React from "react";
import { AnalyticsContainer } from "./AnalyticsHelper.style";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Bar,
  ResponsiveContainer,
  LabelList,
  Legend,
} from "recharts";
import ReactLoading from "react-loading";

const data = [
  {
    name: "Hindi",
    knowledge: 40,
    understanding: 24,
    analysis: 30,
    application: 40,
  },
  {
    name: "Science",
    knowledge: 40,
    understanding: 24,
    analysis: 30,
    application: 40,
  },
];
function QuestionAttemptStatus(props) {
  const { dataField, colors, data, heading, classes, loading } = props;
  // console.log(props)
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let temp = 0;
    data.forEach((item) => {
      temp += item[dataField[0]] + item[dataField[1]] + item[dataField[2]];
    });
    setTotal(temp);
  }, [data]);

  return (
    <AnalyticsContainer>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading type="spin" color="cyan" height={100} width={100} />
        </div>
      ) : (
        <>
          {" "}
          <div className="yaxis">Questions</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "1.3rem", fontWeight: "600" }}>
              {heading} {classes}
            </div>
            <div>Questions -{total}</div>
          </div>
          <div style={{ width: "100%", height: "320px" }}>
            <ResponsiveContainer>
              <BarChart width="100%" height="100%" data={data}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis
                  tick={{ fill: "rgba(0, 0, 0)" }}
                  dataKey="name"
                  style={{ fontWeight: "600", color: "black" }}
                />
                <Legend style={{ fontWeight: "600" }} />
                <YAxis allowDecimals={false} />
                <Tooltip cursor={{ fill: "#fff" }} />

                <Bar barSize={26} dataKey={dataField[0]} fill={colors[0]}>
                  <LabelList
                    style={{
                      color: "#000",
                      fill: "rgba(0, 0, 0)",
                      fontWeight: "600",
                      fontSize: "0.7rem",
                    }}
                    dataKey={dataField[0]}
                    position="middle"
                  />
                </Bar>
                <Bar barSize={26} dataKey={dataField[1]} fill={colors[1]}>
                  <LabelList
                    style={{
                      color: "#000",
                      fill: "rgba(0, 0, 0)",
                      fontWeight: "600",
                      fontSize: "0.7rem",
                    }}
                    dataKey={dataField[1]}
                    position="middle"
                  />
                </Bar>
                <Bar barSize={26} dataKey={dataField[2]} fill={colors[2]}>
                  <LabelList
                    style={{
                      color: "#000",
                      fill: "rgba(0, 0, 0)",
                      fontWeight: "600",
                      fontSize: "0.7rem",
                    }}
                    dataKey={dataField[2]}
                    position="middle"
                  />
                </Bar>
                {/* <Bar barSize={26} dataKey="application" fill="#FF6A99" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500"}} dataKey="application" position="middle" />
    </Bar> */}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </AnalyticsContainer>
  );
}

QuestionAttemptStatus.defaultProps = {
  data: [],
  dataField: ["Correct", "Incorrect", "Unattempted"],
  colors: ["rgb(125, 230, 219)", "rgb(255, 152, 152)", "rgb(120, 234, 165)"],
  heading: "Cognitive Level Report of Class",
  classes: "",
  loading: false,
};
export default QuestionAttemptStatus;
