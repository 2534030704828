import React, { useContext, useState, createContext, useEffect } from "react";
import { Snackbar } from "@material-ui/core";

import { useNavigate } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@mui/material";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [time, setTIme] = useState(3000);

  const [logedIn, setLogedIn] = useState(false);
  const navigate = useNavigate();

  const deleteCookie = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  const createCookie = (fieldname, fieldvalue, expiry) => {
    let date = new Date();
    date.setTime(date.getTime() + expiry * 24 * 60 * 60 * 1000);
    //console.log(expiry / (1000 * 60 * 60));
    let expires = "expires=" + date.toGMTString();
    document.cookie = fieldname + "=" + fieldvalue + ";" + expires + ";path=/";
    // console.log(expires);
    //
    localStorage.setItem("time", 60 * 120);
    const interval = setInterval(() => {
      if (localStorage.getItem("time") > 0) {
        localStorage.setItem("time", localStorage.getItem("time") - 1);
        // console.log(localStorage.getItem("time"));
      } else {
        clearInterval(interval);
        setLoading(false);
        setLogedIn(false);
        DisplaySnackbar("Your Token has expired Login Again", "error");
        deleteCookie();

        navigate("/login");
      }
    }, 1000);
  };

  const readCookie = (cname) => {
    let name = cname + "=";
    let decoded_cookie = decodeURIComponent(document.cookie);
    let carr = decoded_cookie.split(";");
    for (var i = 0; i < carr.length; i++) {
      var c = carr[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const action = (
    <React.Fragment>
      <div>
        <CloseIcon
          style={{
            cursor: "pointer",
            color: "black",
            fontWeight: "500",
            transform: "translateY(-50%)",
            marginTop: "50%",
          }}
          onClick={() => setSnackBar(false)}
        />
      </div>
    </React.Fragment>
  );
  const DisplaySnackbar = (mssg, type, tim) => {
    setMessage(mssg);
    setSeverity(type);
    setSnackBar(true);
    //console.log("snackbar");
    if (tim) {
      setTIme(tim);
    } else {
      setTIme(3000);
    }
  };
  useEffect(() => {
    const cookie = readCookie(`token`);
    //console.log(cookie);

    if (!cookie) {
      setLogedIn(false);
      navigate("/login");
      setLoading(false);
    } else {
      setLogedIn(true);
      setLoading(false);
      const interval = setInterval(() => {
        if (localStorage.getItem("time") > 0) {
          localStorage.setItem("time", localStorage.getItem("time") - 1);
          // console.log(localStorage.getItem("time"));
        } else {
          clearInterval(interval);
          setLoading(false);
          setLogedIn(false);
          DisplaySnackbar("Your Token has expired Login Again", "error");
          deleteCookie();

          navigate("/login");
        }
      }, 1000);
    }
  }, []);

  const value = {
    logedIn,
    createCookie,
    setUserId,
    userId,
    name,
    setName,
    setLogedIn,
    role,
    setRole,
    imgUrl,
    setImgUrl,
    readCookie,
    DisplaySnackbar,
    deleteCookie,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBar}
        autoHideDuration={time}
        severity={severity}
        onClose={() => setSnackBar(false)}
      >
        <Alert action={action} severity={severity} sx={{ width: "100%" }} style={{ "whiteSpace": "pre-wrap"}}>
          {message}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};
