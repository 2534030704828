import styled from "styled-components";


export const LoginContainer=styled.div`
position:relative;
min-height:100vh;
`

export const ImgContainer=styled.div`
img{
    width:100%;
    position:fixed;
    bottom:30%;
    // width:100%;
    overflow:hidden;
    opacity:0.4;
    z-index:-10;
}

`

export const LoginFormContainer=styled.div`
width:390px;
box-sizing:border-box;
height:320px;

background-color:#fff;
position:fixed;
top:50%;
left:50%;
transform:translateX(-50%) translateY(-50%);
padding:24px 32px;
border-radius:10px;
box-shadow: 0px 4px 4px 0px #00000040;


`

export const LoginHeader=styled.div`
text-align:center;

`

export const LoginField=styled.div`
.pass{
    position:relative;
    .icons{
        position:absolute;
        top:50%;
        right:10px;
        transform:translateY(-50%);
        color:#5CE0D2;
    }
}


`

export const LoginButton=styled.button`

border:none;
display:block;
width:100%;
text-align:center;
padding:13px 0;
border-radius:7px;
background-color:#5CE0D2;
cursor:pointer;

`