import React from 'react';
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {BarChart,CartesianGrid,XAxis,YAxis,Tooltip,Cell,Bar,ResponsiveContainer,LabelList,Legend} from 'recharts';
import Loader from './Loader/Loader';



const data= [
    {
      "name": "A",
      "uv": 40,
      "pv": 24
    },
    {
      "name": "B",
      "uv": 30,
      "pv": 13
    },
    {
      "name": "C",
      "uv": 20,
      "pv": 90
    },
    {
      "name": "D",
      "uv": 27,
      "pv": 39
    },
    {
      "name": "E",
      "uv": 18,
      "pv": 48
    },
    {
      "name": "F",
      "uv": 23,
      "pv": 38
    },
    {
      "name": "G",
      "uv": 34,
      "pv": 43
    }
  ]
  const barColors = ["#7CB4A0", "#ECBE7A", "#B6E4FF","#9DABDD","#FF8989","#5CE0D2","#CCACFF"]
function AnalyticsContainer1(props) {
  const {data,field,yaxis,loading}=props;
    return (
        <AnalyticsContainer>
          {loading?<Loader/>:<>
          <div className='yaxis'>
         {/* {yaxis} */}
          </div>
            <ResponsiveContainer>
            <BarChart width="100%" height="100%" data={data}>
  <CartesianGrid strokeDasharray="1 1" />
  <XAxis tick={{ fill: "rgba(0, 0, 0)" }} dataKey="name" style={{fontWeight:"600",color:"black"}} />
  <YAxis label={{ value: yaxis, angle: -90, position: 'insideLeft',fontWeight:"600" }}   allowDecimals={false} />
  {/* <Legend payload={[{
    id:"Subject",
    value:"Science",
    type:"rect",
    color:"#000"
  }]} /> */}
  <Tooltip cursor={{fill: '#fff'}} />
  
  <Bar barSize={26} dataKey={field[1]} fill="#8884d8" >
    {
      data.map((item,index)=>{
        return  <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
      })
       
    }
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"600"}} dataKey={field[1]} position="middle" />
    </Bar>
  
</BarChart>

</ResponsiveContainer>
</>}
        </AnalyticsContainer>
    )
}


AnalyticsContainer1.defaultProps={
  data:[],
  field:[],
  yaxis:"Questions",
  loading:false
}
export default AnalyticsContainer1
