import React from 'react'
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {ResponsiveContainer,BarChart,XAxis,YAxis,Bar,CartesianGrid,Tooltip,Cell,LabelList,Legend} from 'recharts';




const data= [
    {
        name: "Easy",
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: "Medium",
        uv: 40,
        pv: 80,
        amt: 20,
      },
      {
        name: "Difficult",
        uv: 40,
        pv: 80,
        amt: 20,
      }
  ]
  const barColors = ["#7DE6DB", "rgb(255, 126, 126)", "rgb(86, 229, 143)"]
function AnalyticsContainer5() {
    return (
        <AnalyticsContainer>
           <div className='yaxis'>
          Questions
          </div>
          <div style={{textAlign:"center"}}>
            <div style={{fontSize:"1.3rem",fontWeight:"600"}}>
          Difficulty Level Report of Class VA
            </div>
            <div>
             Question Attempted-200
            </div>
          </div>
          <div style={{width:"100%",height:"320px"}}>
              <ResponsiveContainer>
            <BarChart width="100%" height="100%" data={data}>
  <CartesianGrid strokeDasharray="1 1" />
  <XAxis tick={{ fill: "rgba(0, 0, 0)" }} dataKey="name" style={{fontWeight:"600",color:"black"}} />
  <YAxis tickCount={10} domain={[0, 100]} />
  <Tooltip cursor={{fill: '#fff'}} />
  <Legend payload={[{
    id:"easy",
    value:"Easy",
    type:"rect",
    color:"#5CE0D2"
  },{
    id:"medium",
    value:"Medium",
    type:"rect",
    color:"#FF7E7E"
  },
  {
    id:"difficult",
    value:"Difficult",
    type:"rect",
    color:"#56E58F"
  }
  
  ]} />
  
  <Bar barSize={26} dataKey="pv"  fill="#7DE6DB" >
    {
      data.map((item,index)=>{
        return  <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
      })
       
    }
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500",fontFamily:"Roboto, sans-serif"}} dataKey="pv" position="middle" />
    </Bar>
   
   
  
</BarChart>
</ResponsiveContainer>
</div>
        </AnalyticsContainer>
    )
}

export default AnalyticsContainer5
