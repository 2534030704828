export const Homework={
    overView:(schoolId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworkoverview/?school=${schoolId}`,
    status:(schoolId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksstatus?school=${schoolId}`,
    report:(schoolId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksreport?school=${schoolId}`,
    submissionReport:(schoolId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/submissionsreport?school=${schoolId}`
}

export const HomeworkFilterbySection={
    overView:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworkoverview/?school=${schoolId}&section=${sectionId}`,
    status:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksstatus?school=${schoolId}&section=${sectionId}`,
    report:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksreport?school=${schoolId}&section=${sectionId}`,
    submissionReport:(schoolId,sectionId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/submissionsreport?school=${schoolId}&section=${sectionId}`
}
export const HomeworkFilterbySubject={
    overView:(schoolId,sectionId,subject)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworkoverview/?school=${schoolId}&section=${sectionId}&subject=${subject}`,
    status:(schoolId,sectionId,subject)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksstatus?school=${schoolId}&section=${sectionId}&subject=${subject}`,
    report:(schoolId,sectionId,subject)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksreport?school=${schoolId}&section=${sectionId}&subject=${subject}`,
    submissionReport:(schoolId,sectionId,subject)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/submissionsreport?school=${schoolId}&section=${sectionId}&subject=${subject}`
}

export const HomeworkFilterbyChapter={

    overView:(schoolId,sectionId,subject,chapterId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworkoverview/?school=${schoolId}&section=${sectionId}&subject=${subject}&chapter=${chapterId}`,
    status:(schoolId,sectionId,subject,chapterId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksstatus?school=${schoolId}&section=${sectionId}&subject=${subject}&chapter=${chapterId}`,
    report:(schoolId,sectionId,subject,chapterId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksreport?school=${schoolId}&section=${sectionId}&subject=${subject}&chapter=${chapterId}`,
    submissionReport:(schoolId,sectionId,subject,chapterId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/submissionsreport?school=${schoolId}&section=${sectionId}&subject=${subject}&chapter=${chapterId}`
}
export const HomeworkFilterByStudent={

    overView:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworkoverview?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    status:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksstatus?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    report:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/homeworksreport?school=${schoolId}&section=${sectionId}&student=${studentId}`,
    submissionReport:(schoolId,sectionId,studentId)=>`${process.env.REACT_APP_ORIGIN_STAGE}api/v1/admins/homeworkanalytics/submissionsreport?school=${schoolId}&section=${sectionId}&student=${studentId}`
}