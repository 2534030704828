import React, { useState, useEffect } from "react";
import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
// import MenuItem from '@mui/material/MenuItem';
// import DropDown from '@mui/material/Select';
import DropDown from "../DropDown/DropDown";
// import { Question } from "../../Api/api";
// import axios from 'axios';
// import {useAuth} from '../../Auth/Auth';

import {
  Header,
  CreateAssessmentContainer,
  AssessmentContainer,
  AssessmentContent,
  Dropdown,
  AssessmentButton,
} from "./Modal.style";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
// import { Dialog } from "@material-ui/core";
// import { Input } from "../Input/InputComponent";
import { useAuth } from "../../Auth/Auth";

function CreateAssessmentComponent({
  setModal,
  handleCreateAssessment,
  no = "1",
}) {
  
  const [formData, setFormData] = useState({
    questionType: "",
    cognitive: "",
    difficulty: "",
  });
  const [field, setField] = useState([{ placeholder: "answer", text: "" }]);
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState([{ value: "" }]);
  const [correctAnswer, setCorrectAnswer] = useState(-1);
  const [mmcq, setmmcq] = useState([]);
  const [questionImageFile, setQuestionImageFile] = useState(null);
  const [imagesForOptions, setImagesForOptions] = useState([{ value: "" }]);
  const [save, setSave] = useState(true);
  const { DisplaySnackbar } = useAuth();
  //console.log(imagesForOptions);
  //console.log(options);

  const addField = () => {
    if(!save){
      return;
    }
    if (
      (formData.questionType === "Mcq" || formData.questionType === "Mmcq") &&
      field.length < 4
    ) {
      setField([...field, { placeholder: "answer", text: "" }]);
      setOptions([...options, { value: "" }]);
      setImagesForOptions([...imagesForOptions, { value: "" }]);
    } else if (
      formData.questionType === "Correct Statement" &&
      field.length < 2
    ) {
      setField([
        ...field,
        { placeholder: field.length ? "False" : "True", text: "" },
      ]);
      setOptions([...options, { value: "" }]);
      setImagesForOptions([...imagesForOptions, { value: "" }]);
    } else if (formData.questionType === "Fill up") {
      setField([...field, { placeholder: "answer", text: "" }]);
      setOptions([...options, { value: "" }]);
      setImagesForOptions([...imagesForOptions, { value: "" }]);
    }
  };

  useEffect(() => {
    if (formData.questionType === "Correct Statement") {
      setField([{ placeholder: "True", text: "" }]);
    }
    setField([{ placeholder: "answer", text: "" }]);
    setOptions([{ value: "" }]);
    setCorrectAnswer(-1);
    setmmcq([]);
    setImagesForOptions([{ value: "" }]);
    setQuestionImageFile([{ value: "" }]);
  }, [formData.questionType]);

  const handleSaveQuestion = async () => {
    if (!save) return;
    // console.log(options, imagesForOptions);
    const { questionType, cognitive, difficulty } = formData;
    if (!questionType || !cognitive || !difficulty || (!question && !questionImageFile)) {
      // console.log(question, questionImageFile);
      DisplaySnackbar("Some Fields are empty", "error");
      return;
    }
    let chk = 0;
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === "" && imagesForOptions[i]?.value === "") {
        chk = 1;
        break;
      }
    }
    if (chk) {
      DisplaySnackbar(
        "Either Delete Empty Options or Enter text in it",
        "error"
      );
      return;
    }
    if (questionType === "Mcq" || questionType === "Correct Statement") {
      if (correctAnswer === -1) {
        DisplaySnackbar("Please Select Atleast One Answer", "error");
        return;
      }
    }
    if (questionType === "Mmcq" && !mmcq.length) {
      DisplaySnackbar("Please Select Atleast One Answer", "error");
      return;
    }

    let crt;
    if (formData.questionType === "Mmcq") {
      crt = mmcq.map((item) => `op${item + 1}`);
    } else if (
      formData.questionType === "Mcq" ||
      formData.questionType === "Correct Statement"
    ) {
      crt = `op${correctAnswer + 1}`;
    } else if (formData.questionType === "Fill up") {
      crt = options.map((item, idx) => {
        return { blankId: `op${idx + 1}`, answer: item.value };
      });
    } else if (formData.questionType === "One Word") {
      crt = options.map((item, idx) => {
        return { blankId: `op${idx + 1}`, answer: item.value };
      });
    }
    let temp = {
      question: question,
      options: options.map((item, idx) => {
        return { optionId: `op${idx + 1}`, value: item.value };
      }),
      questionType: formData.questionType.toLowerCase().split(" ").join(""),

      correctAnswers: {
        [formData.questionType.toLowerCase().split(" ").join("")]: crt,
      },
      marks: "1",
      cognitive: formData.cognitive.toLowerCase(),
      difficulty: formData.difficulty.toLowerCase(),
      quesImg: questionImageFile,
      visibilityStatus: "All",
      optionsFile: imagesForOptions,
    };
    setSave(false);
    await handleCreateAssessment(temp);
    setSave(true);
  };

  // console.log(question, field);
  const handleDelete = (idx) => {
    setField(field.filter((item, id) => id !== idx));
    setOptions(options.filter((item, id) => idx !== id));
    if (correctAnswer === idx) {
      setCorrectAnswer(-1);
    }
    if (mmcq.indexOf(idx) !== -1) {
      setmmcq(mmcq.filter((item) => item !== idx));
    }

    setImagesForOptions(imagesForOptions.filter((item, id) => id !== idx));
  };

  const Setmmcqanswer = (id) => {
    if (mmcq.indexOf(id) !== -1) {
      setmmcq(mmcq.filter((item) => item !== id));
    } else {
      setmmcq([...mmcq, id]);
    }
  };

  const handleQuestionUpload = (e) => {
    // console.log(e.target.files);
    setQuestionImageFile(e.target.files[0]);
    DisplaySnackbar("Question Image Uploaded", "success");
  };

  const handleOptionsImage = (e, idx) => {
    // console.log(idx);
    // console.log(e);
    setImagesForOptions(
      imagesForOptions.map((item, id) =>
        id === idx ? { value: e.target.files[0] } : item
      )
    );
    DisplaySnackbar("Image Uploaded", "success");

    // setImagesForOptions([...imagesForOptions,{optionId:`op${idx+1}`,value:e.target.files[0]}])
  };
  // console.log(imagesForOptions);
  const handleOnChange = (e, idx) => {
    //console.log(idx);
    setOptions(
      options.map((item, id) => (idx === id ? { value: e.target.value } : item))
    );
  };

  return (
    <ModalWrapper>
      <ModalContainer
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <CreateAssessmentContainer
          style={{ width: "70%", margin: "auto auto" }}
        >
          <Header>
            <div>
              <h1>Create Assessment</h1>
            </div>
            <div
              style={{
                height: "60px",
                color: "#fff",
                padding: "2px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "60px",
                borderRadius: "50%",
                backgroundColor: "#5CE0D2",
              }}
            >
              <div>Q</div>
              <div>{no}</div>
            </div>
          </Header>
          <div style={{ marginBottom: "10px" }}>
            <span>Courses</span> {` > `}
            <span>India </span> {` > `}
            <span>Boards</span> {` > `}
            <span>Subjects</span> {` > `}
            <span>Chapter</span> {` > `}
            <span>Topics</span>
          </div>
          <AssessmentContainer>
            {save?<AssessmentContent>
              <div className="firstClass">
                <div className="input">
                  <input
                    placeholder="Question Text"
                    value={question}
                    onChange={(e) => setQuestion(e.target.value)}
                    name="question"
                    disabled={formData.questionType ? false : true}
                    className="textInput"
                  />
                  <div className="icon">
                    <label style={{ display: "flex" }} htmlFor="question">
                      {" "}
                      {questionImageFile?.name}
                      <AttachFileOutlinedIcon />
                    </label>
                    <input
                      id="question"
                      style={{ display: "none" }}
                      type="file"
                      onChange={(e) => handleQuestionUpload(e)}
                    />
                  </div>
                </div>
                {field.map((item, idx) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      {formData.questionType !== "Fill up" &&
                        formData.questionType !== "One Word" &&
                        formData.questionType !== "Mmcq" && (
                          <div
                            onClick={() => setCorrectAnswer(idx)}
                            style={{
                              cursor: "pointer",
                              height: "15px",
                              width: "15px",
                              border: "2px solid #5CE0D2 ",
                              backgroundColor: `${
                                correctAnswer === idx ? "#5CE0D2" : ""
                              }`,
                            }}
                          ></div>
                        )}
                      {formData.questionType === "Mmcq" && (
                        <div
                          onClick={() => Setmmcqanswer(idx)}
                          style={{
                            cursor: "pointer",
                            height: "15px",
                            width: "15px",
                            border: "2px solid #5CE0D2 ",
                            backgroundColor: `${
                              mmcq.indexOf(idx) !== -1 ? "#5CE0D2" : ""
                            }`,
                          }}
                        ></div>
                      )}
                      <div
                        className="input"
                        style={{ flex: "1", margin: "0 15px" }}
                      >
                        <input
                          value={options[idx].value}
                          placeholder={item.placeholder}
                          onChange={(e) => handleOnChange(e, idx)}
                          disabled={formData.questionType ? false : true}
                          className="textInput"
                        />
                        <div className="icon">
                          <label htmlFor={`op${idx + 1}`}>
                            <AttachFileOutlinedIcon
                              onClick={() => console.log(idx)}
                            />
                            {imagesForOptions[idx]?.value?.name}
                          </label>
                          <input
                            id={`op${idx + 1}`}
                            name={`op${idx + 1}`}
                            style={{ display: "none" }}
                            type="file"
                            onChange={(e) => handleOptionsImage(e, idx)}
                          />
                        </div>
                      </div>
                      <div
                        onClick={() => handleDelete(idx)}
                        style={{
                          cursor: "pointer",
                          margin: "0px",
                          padding: "0px",
                          fontSize: "1.5rem",
                        }}
                      >
                        ×
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="secondClass">
                <Dropdown>
                  <DropDown
                    options={[
                      "Mcq",
                      "Mmcq",
                      "One Word",
                      "Fill up",
                      "Correct Statement",
                    ]}
                    name="questionType"
                    formData={formData}
                    setFormData={setFormData}
                    placeholder="Choose Question Type"
                  />
                </Dropdown>
                <Dropdown>
                  {/* <DropDown o /> */}
                  <DropDown
                    options={[
                      "Knowledge",
                      "Analysis",
                      "Understanding",
                      "Application",
                    ]}
                    name="cognitive"
                    formData={formData}
                    setFormData={setFormData}
                    placeholder="Cognitive"
                  />
                </Dropdown>
                <Dropdown>
                  <DropDown
                    options={["Easy", "Intermediate", "Hard"]}
                    name="difficulty"
                    formData={formData}
                    setFormData={setFormData}
                    placeholder="Difficulty"
                  />
                </Dropdown>
              </div>
            </AssessmentContent>:""}
            {save ? <div style={{ margin: "16px 0px" }} className="button1">
              <AssessmentButton onClick={() => addField()} blur>
                Add Options
              </AssessmentButton>
            </div>: ""}
            <div style={{ margin: "16px 0px" }} className="button2">
              <AssessmentButton onClick={() => handleSaveQuestion()}>
                {save ? "Save" : "Please wait Uploading Files ... "}
              </AssessmentButton>
              {save?<AssessmentButton
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  padding: "0px",
                }}
              >
                <DeleteOutlineOutlinedIcon />
              </AssessmentButton>:""}
            </div>
          </AssessmentContainer>

          <h2
            onClick={() => setModal(false)}
            style={{
              position: "fixed",
              top: "80px",
              right: "120px",
              fontSize: "2rem",
              cursor: "pointer",
              color: "grey",
            }}
          >
            ×
          </h2>
        </CreateAssessmentContainer>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default CreateAssessmentComponent;
