import React, { useState, useEffect } from "react";
import {
  TeacherProfileContainer,
  TeacherProfileContent,
} from "../Teacher/TeacherProfile.style";
import { useParams } from "react-router-dom";
import HeaderPage from "../Header/HeaderPage";
import { Line } from "../Dashboard/Dashboard.style";
import { Tab } from "../Dashboard/Dashboard.style";
import StudentDetails from "./StudentDetails";
// import StudentsSchedule from './StudentsSchedule';
import StudentsSchedule from "./StudentsSchedule";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import { Student } from "../../Api/api";

import { StudentProfileContent } from "./Students.style";
const tab = ["Student's Info", "Schedule"];
const TabComponent = [StudentDetails, StudentsSchedule];

/*const leftHeader = (
  <div className="st">
    <span>Dashboard</span> {` > `}
    <span>School</span> {` > `}
    <span>Students</span> {` > `}
    <span>Student Profile</span>
  </div>
);*/

function StudentProfileComponent() {
  const [activeTab, setActiveTab] = useState(0);
  const { readCookie } = useAuth();
  const { studentId, schoolId, schoolName } = useParams();
  const [studentData, setStudentData] = useState({});

  useEffect(() => {
    axios
      .get(Student.getStudentById(studentId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setStudentData(res.data.data.student);
      })
      .catch((err) => console.log(err));
  }, []);
  // const {state}=useLocation();
  //console.log(studentData);

  return (
    <TeacherProfileContainer>
      <HeaderPage
        leftHeader={false}
        currentPage="teachersProfile"
        margin="3px 0px 15px 0px"
        title={studentData?.name}
        profilePic={false}
        teachers={false}
        students={true}
        schoolPath={`/dashboard/${schoolName}/${schoolId}?tab=students`}
      />
      <Line />
      <StudentProfileContent>
        <div className="firstChild">
          {tab.map((item, idx) => {
            return (
              <Tab onClick={() => setActiveTab(idx)} active={activeTab === idx}>
                {item}
              </Tab>
            );
          })}
        </div>
        <div className={`secondChild ${activeTab === 0 ? "flx" : ""}`}>
          {TabComponent.map((RenderComponent, idx) => {
            return idx === activeTab ? (
              <RenderComponent
                schoolName={schoolName}
                data={studentData}
                setStudentData={setStudentData}
              />
            ) : (
              ""
            );
          })}
        </div>
      </StudentProfileContent>
    </TeacherProfileContainer>
  );
}

export default StudentProfileComponent;
