import styled from 'styled-components';

export const CommonComponentContainer=styled.div`

margin-top:16px;
display:flex;
.firstChild{
    display:flex;
    flex-direction:column;
    
}
.secondChild{
    flex:1;
    padding-left:100px;
}

`

export const Tabs=styled.div`
flex:0.1;
`

export const TabData=styled.div`
flex:1;
`
export const TabDataContainer=styled.div`
    display:flex;
    // justify-content:space-between;
    // margin-left:18px;
    flex-wrap:wrap;
`

