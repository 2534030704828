import React, { useState, useEffect } from "react";
import TableComponent from "../../Table/Table";
import { Classes } from "../../Table/TableHeaders";
import { useParams } from "react-router-dom";
import { schools, Section, Student, subjects } from "../../../Api/api";
import { useAuth } from "../../../Auth/Auth";
import axios from "axios";
import { CommonHeader } from "./TabComponent.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../../Header/Headerfeatures";
import { Line } from "../Dashboard.style";
import SubjectOutlinedIcon from "@material-ui/icons/SubjectOutlined";
import AddCommentOutlinedIcon from "@material-ui/icons/AddCommentOutlined";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@mui/material/Tooltip";
import {
  assignClassSubjectInSchool,
  AddSubject,
  AddSection,
} from "../../RenderDetailsData/RenderDetailsData";
// import AssignClassandSubjects from "../../Modal/AssignClassandSubjects";
import AddModalComponent from "../../Modal/AddModalComponent";

import Switch from "@material-ui/core/Switch";
import exportFromJSON from "export-from-json";
import ListPopUp from "../../../Components/CommonComponent/ListPopUp/ListPopUp";
import { sortSections } from "../../../utils/sort";

function ClassesComponent(props) {
  const [modal, setModal] = useState();
  const { readCookie, DisplaySnackbar } = useAuth();
  const { schoolId } = useParams();
  const [sections, setSections] = useState([]);

  const [data, setData] = useState({});
  const [addSectionLabel, setAddSectionLabel] = useState([]);
  const [AssignedClassLabel, setAssignedClassLabel] = useState([]);
  const [addSubjectLabel, setAddSubjectLabel] = useState([]);
  const [render, setRender] = useState(false);
  const [subjectsId, setSubjectsId] = useState([]);
  const [courses, setCourses] = useState([]);
  const [formAssigned, setFormAssigned] = useState({
    class: "",
    section: "",
    subject: "",
  });
  const [classes, setClasses] = useState([]);
  const [tabContent, setTabContent] = useState([]);
  const [subject, setSubjects] = useState([]);
  const [openpopup, setPopupOpen] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [gradeName, setGradeName] = useState("");
  const [classId, setClassId] = useState("");

  const handleClickOpen = (classId) => {
    setPopupOpen(true);
    setClassId(classId);
    setSelectedSubjects(classes.find((x) => x._id === classId)?.subjects || []);
    setGradeName(classes.find((x) => x._id === classId)?.grade || []);
  };

  const removeLangTag = (subjectName) => {
    if (!subjectName) return;
    return subjectName.split("(")[0].trim();
  };

  const getSections = () => {
    axios
      .get(Section.getSection, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        const secs = sortSections(res.data.data?.sections || [])
        setSections(secs);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axios
      .get(schools.getClasses(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setClasses(res.data.data.grades);
        setAddSubjectLabel(AddSubject(res.data.data.grades, []));

        setAssignedClassLabel(assignClassSubjectInSchool(courses, [], []));
      })
      .catch((err) => console.log(err));
    getSections();
  }, [render, courses]);

  useEffect(() => {
    if (sections.length && classes.length) {
      setAddSectionLabel(AddSection(classes, sections));
    }
  }, [sections, classes]);

  useEffect(() => {
    axios
      .get(schools.getSchoolsById(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        console.log("Fetched School!");
        console.log(res.data.data.school);
        setCourses([...res.data.data.school.courses]);
      })
      .catch((err) => console.log(err));
  }, []);

  const exportCSV = () => {
    let data = classes.map((item) => {
      return {
        "Grade-section": `${item.grade}-${item.sections
          .map((item) => item.section)
          .join(",")}`,
        Subjects: `${item.subjects?.map((item) => item.subject).join(",")}`,
        "Created-At": new Date(item.createdAt).toLocaleDateString(),
        Enabled: item.enabled,
      };
    });

    let fileName = "Classes";
    let exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };

  useEffect(() => {
    setFormAssigned({
      class: "",
      section: "",
      subject: "",
    });
    setSubjectsId([]);
  }, [modal]);

  const getSubject = () => {
    let id;
    if (data.headerText === "Assign  Subjects") {
      id = courses.find(
        (item) => item.name === formAssigned.class.split(",")[0]
      )?._id;
      // id=formAssigned?.class?.split(",")[1];
    } else {
      id = formAssigned.class;
    }

    const getSubjects = (subjects) => {
      return subjects
        .filter((sub) => !sub.disabled)
        .map((sub) => ({
          _id: sub._id,
          name: `${sub.name}  (${sub?.lang || "English"})`,
        }));
    };

    axios
      .get(subjects.getSubjectsByClass(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setAssignedClassLabel(
          assignClassSubjectInSchool(
            courses,
            sections,
            getSubjects(res.data.data.course?.subjects)
          )
        );
        setAddSubjectLabel(
          AddSubject(classes, getSubjects(res.data.data.course?.subjects))
        );
        setSubjects(res.data.data.course.subjects);
      })
      .catch((err) => console.log(err));
  };

  const toogleDisabled = (id) => {
    axios
      .patch(
        schools.toogleDisableClass(id),
        {},
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(res.data.message, "success");

          setClasses(
            classes.map((item) =>
              item._id === id ? { ...item, enabled: !item.enabled } : item
            )
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const updateSubjectList = (classId, subjectList) => {
    const copyClasses = [...classes];
    const index = copyClasses.findIndex((x) => x._id === classId);
    if (index > -1) {
      const currentClass = copyClasses[index];
      currentClass.subjects = subjectList;
      copyClasses.splice(index, 1, currentClass);
      setClasses([...copyClasses]);
    }
  };

  useEffect(() => {
    setTabContent(
      classes.map((item, idx) => {
        return {
          sno: idx + 1,
          class: item.grade,
          section: item.sections.map((dt) => dt.section).join(","),
          subjects: item.subjects.map((dt) => dt.subject).join(","),
          subscription: (
            <Switch
              onClick={(e) => {
                e.stopPropagation();
                toogleDisabled(item._id);
              }}
              style={{ zIndex: "100000" }}
              checked={item.enabled}
              color="primary"
              className="enableIcon"
            />
          ),
          edit: (
            <Tooltip title="Edit Subjects">
              <div className="context">
                <EditIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickOpen(item._id);
                  }}
                ></EditIcon>
              </div>
            </Tooltip>
          ),
        };
      })
    );
  }, [classes]);

  const handleEditSubject = () => {
    let data = {
      subjects: subjectsId.map((sub) => {
        return {
          subject: removeLangTag(sub.subject),
          subjectId: sub.subjectId,
        };
      }),
    };
    axios
      .patch(Student.addSubject(formAssigned.class.split(",")[1]), data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(`${res.data.message}`);
          setModal(false);
          setRender(!render);
        }
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    if (formAssigned.class) {
      getSubject();
    }
  }, [formAssigned.class]);

  const handleOnClick = (id) => {
    if (id === 0) {
      setData({
        data: assignClassSubjectInSchool,
        headerText: "Assign Class And Subjects",
      });
    } else if (id === 1) {
      setData({ data: AddSection, headerText: "EDIT SECTION" });
    } else {
      setData({ data: AddSubject, headerText: "Assign  Subjects" });
    }
    setFormAssigned((prev) => ({
      class: "",
      section: "",
      subject: "",
    }));
    setModal(true);
  };

  const handleEditSection = () => {
    axios
      .put(
        schools.addSections(formAssigned.class),
        { sections: formAssigned.section },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Section Added", "success");
          setRender(!render);
          setModal(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleCreate = () => {
    // console.log(data.headerText);
    if (data.headerText === "Assign Class And Subjects") {
      let courseName = courses.find((item) => item._id === formAssigned.class);
      if (tabContent.find((item) => item.class === courseName.name)) {
        DisplaySnackbar(`Class already exists`, "error");
      } else {
        //SEARCH FOR SUBJECTS ID ARRAY
        let data = {
          schoolId,
          grades: [
            {
              courseName: removeLangTag(courseName.name),
              courseId: formAssigned.class,
              sections: formAssigned.section,
              subjects: subjectsId
            },
          ],
        };
        axios
          .post(schools.createGrade, data, {
            headers: {
              token: `${readCookie("token")}`,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              setClasses([res.data.data.grades[0], ...classes]);

              DisplaySnackbar("Grade Added", "success");
              setModal(false);
            }
          })
          .catch((err) => {
            if (err.response) {
              DisplaySnackbar(err.response.data.message, "error");
            }
          });
      }
    } else if (data.headerText === "EDIT SECTION") {
      handleEditSection();
    } else {
      handleEditSubject();
    }
  };

  useEffect(() => {
    if (!modal) {
      setFormAssigned({ class: "", section: "", subject: "" });
    }
  }, [modal]);

  const handleMultiSelect = (type, id, name) => {
    // console.log("here");
    if (type === "subject") {
      let temp = subjectsId.find((item) => item.subjectId === id);
      if (temp) {
        setSubjectsId(subjectsId.filter((item) => item.subjectId !== id));
      } else {
        setSubjectsId([...subjectsId, { subject: removeLangTag(name), subjectId: id }]);
      }
    }
  };
  return (
    <div style={{ boxSizing: "border-box", overflow: "auto" }}>
      <CommonHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ display: "flex", fontWeight: "500" }}>
            <div
              onClick={() => handleOnClick(0)}
              className="iconContainer"
              style={{
                display: "flex",
                cursor: "pointer",
                alignItems: "center",
              }}
            >
              <AddCommentOutlinedIcon
                style={{ marginRight: "7px", cursor: "pointer" }}
              />
              Assign Class & Subject
            </div>
            <div
              className="iconContainer"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleOnClick(1)}
            >
              <AddBoxOutlinedIcon
                style={{ marginRight: "7px", cursor: "pointer" }}
              />{" "}
              Add Section
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "20px",
                cursor: "pointer",
              }}
              onClick={() => handleOnClick(2)}
              className="iconContainer"
            >
              <SubjectOutlinedIcon style={{ marginRight: "7px" }} /> Add Subject
            </div>
          </div>
        </div>
        <Headerfeatures exportFile={exportCSV} sort={false} viewBy={false} />
      </CommonHeader>
      <Line />
      <div style={{ maxWidth: "70vw" }}>
        <TableComponent tabHeader={Classes} tabContent={tabContent} />
      </div>
      {modal && (
        <AddModalComponent
          label={
            data.headerText === "Assign Class And Subjects"
              ? AssignedClassLabel
              : data.headerText === "EDIT SECTION"
              ? addSectionLabel
              : addSubjectLabel
          }
          headerText={data.headerText}
          setModal={setModal}
          formData={formAssigned}
          setFormData={setFormAssigned}
          handleCreate={handleCreate}
          minWidth="450px"
          handleMultiSelect={handleMultiSelect}
        />
      )}
      <ListPopUp
        open={openpopup}
        schoolId={schoolId}
        gradeName={gradeName}
        selectedSubjects={selectedSubjects}
        Title={`Delete Subject`}
        Disagree={"Cancel"}
        Agree={"Ok"}
        onClose={(isDelete) => {
          setPopupOpen(!openpopup);
          // if (isDelete) handleDelete(props.id);
        }}
        updateSubjectList={updateSubjectList}
        classId={classId}
      />
      ;
    </div>
  );
}

export default ClassesComponent;
