import React, { useState, useEffect } from "react";
import {
  TeacherProfileContainer,
  TeacherProfileContent,
} from "./TeacherProfile.style";
import { useParams } from "react-router-dom";
import HeaderPage from "../Header/HeaderPage";
import { Line } from "../Dashboard/Dashboard.style";
import { Tab } from "../Dashboard/Dashboard.style";
import TeachersSchedule from "./TabComponent/TeachersSchedule";
import TeacherDetails from "./TabComponent/TeacherDetails";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import { Teacher } from "../../Api/api";

const tab = ["Teacher's Info", "Schedule"];
const TabComponent = [TeacherDetails, TeachersSchedule];
function TeacherProfileComponent() {
  const [activeTab, setActiveTab] = useState(0);
  const [staffData, setStaffData] = useState([]);
  // const {state}=useLocation();
  const { staffId, schoolId, schoolName } = useParams();
  const { readCookie } = useAuth();
  useEffect(() => {
    axios
      .get(Teacher.getTeacherById(staffId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setStaffData(res.data.data.staff);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <TeacherProfileContainer>
      <HeaderPage
        currentPage="teachersProfile"
        margin="3px 0px 15px 0px"
        teachers
        title={staffData?.name}
        profilePic={false}
        schoolPath={`/dashboard/${schoolName}/${schoolId}?tab=staffs`}
      />
      <Line />
      <TeacherProfileContent>
        <div className="firstChild">
          {tab.map((item, idx) => {
            return (
              <Tab onClick={() => setActiveTab(idx)} active={activeTab === idx}>
                {item}
              </Tab>
            );
          })}
        </div>
        <div className={`secondChild ${activeTab === 0 ? "flx" : ""}`}>
          {TabComponent.map((RenderComponent, idx) => {
            return idx === activeTab ? (
              <RenderComponent
                schoolName={schoolName}
                data={staffData}
                setStaffData={setStaffData}
              />
            ) : (
              ""
            );
          })}
        </div>
      </TeacherProfileContent>
    </TeacherProfileContainer>
  );
}

export default TeacherProfileComponent;
