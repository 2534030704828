import styled from 'styled-components';

export const AnalyticsTabs=styled.div`

    width:148px;
    box-sizing:border-box;
    min-height:65px;
    border: 1px solid #4BDF3F;
    padding:5px;
box-sizing: border-box;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
border-radius: 10px;
display:flex;
justify-content:space-between;

`
export const AnalyticsContainer=styled.div`
width:48%;
border: 1px solid #4BDF3F;
margin:20px 0px;
box-sizing: border-box;
box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
border-radius: 19px;
height:400px;
padding:20px;
padding-left:0px;
padding-bottom:0px;

position:relative;
.yaxis{
    position:absolute;
    left:-20px;
    font-weight:600;
    top:50%;
    transform:translateY(-50%) rotate(-90deg);

}
.learningStrength{
    position:absolute;
    left:-55px;
    font-weight:600;
    top:50%;
    transform:translateY(-50%) rotate(-90deg);

}
.recharts-cartesian-axis-tick {    
    font-size: 1rem;
    font-family: Roboto, sans-serif;
}
.recharts-legend-item {
    font-weight:600;
    margin:0px 5px;
}
${({absolute})=>absolute&&`display:flex;



`}

.firstDiv{
    flex:0.8;
}
.secondDiv{
    flex:0.2;
    display:flex;
    flex-direction:column;
    justify-content:center;

}

`