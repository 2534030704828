import React, { useState, useEffect, useMemo } from "react";
import {
  EditContainer,
  EditContainerDiv1,
  EditContainerDiv2,
  InputField,
  Button,
} from "./Editquestion.style";
import DropDown from "../../DropDown/DropDown";
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import TrashOultinedIcon from "@material-ui/icons/CloseTwoTone";

function ImageField(props) {
  return (
    <>
      {props.isImage ? (
        <div onClick={props.removeImage}>
          <TrashOultinedIcon />
        </div>
      ) : (
        <label
          style={{
            fontSize: "2rem",
            cursor: "pointer",
            marginleft: "10px",
          }}
          for={props.id}
        >
          <AttachFileOutlinedIcon />
        </label>
      )}
      {!props.isImage ? (
        <>
          <span>{props.filename}</span>
          <input
            onChange={props.addImage}
            id={props.id}
            style={{ display: "none" }}
            type="file"
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

function Editquestion(props) {
  const [options, setOptions] = useState([...props.options]);
  const [question, setQuestion] = useState(props.question);
  const [formData, setFormData] = useState({
    cognitive: props.cognitive,
    difficulty: props.difficulty,
  });
  const [markedDeleted, setMarkedDeleted] = useState([]);
  const images = useMemo(() => {
    return { ...props.images };
  }, [props.images]);

  const [loading, setLoading] = useState(true);
  const [correctAnswer, setCorrectAnswer] = useState();
  const [updatedImages, setUpdatedImages] = useState({});
  const handleImages = (dt, id) => {
    setUpdatedImages({ ...updatedImages, [id]: dt.target.files[0] });
  };

  const removeImage = (id) => {
    markedDeleted.push(id);
    setMarkedDeleted([...markedDeleted]);
  };

  useEffect(() => {
    if (props.correctAnswers) {
      if (
        props.questionType === "mcq" ||
        props.questionType === "correctstatement"
      ) {
        setCorrectAnswer(
          parseInt(
            props?.correctAnswers[props.questionType]?.split("")?.at(-1) - 1
          )
        );
        setLoading(false);
      } else if (props.questionType === "mmcq") {
        setCorrectAnswer(
          props.correctAnswers[props.questionType]?.map((item) => {
            return parseInt(item.split("").at(-1)) - 1;
          })
        );
        setLoading(false);
      } else {
        setCorrectAnswer(props.correctAnswers[props.questionType]);
        setLoading(false);
      }
    }
  }, [props]);
  // console.log(correctAnswer);
  const handleCorrectAnswer = (id, type) => {
    if (type === "mmcq") {
      if (correctAnswer.indexOf(id) !== -1) {
        setCorrectAnswer(correctAnswer.filter((item, idx) => idx !== id));
      } else {
        setCorrectAnswer([...correctAnswer, id]);
      }
    } else if (type === "mcq" || type === "correctstatement") {
      setCorrectAnswer(id);
    }
  };

  const { questionType } = props;
  const Update = () => {
    let form = new FormData();
    const { cognitive, difficulty } = formData;
    let anser;
    if (props.questionType === "mmcq") {
      anser = {
        [questionType]: correctAnswer.map((item, idx) => `op${item + 1}`),
      };
    } else if (questionType === "mcq" || questionType === "correctstatement") {
      anser = { [questionType]: `op${correctAnswer + 1}` };
    } else if (questionType === "fillup" || questionType === "oneword") {
      anser = { [questionType]: correctAnswer };
    }

    console.log(markedDeleted);

    let visibilityStatus = "publicForAll";
    form.append(
      "question",
      JSON.stringify({
        question,
        questionType,
        options,
        cognitive,
        difficulty,
        correctAnswers: anser,
        visibilityStatus,
        images
      })
    );
    form.append("markedDeleted", JSON.stringify(markedDeleted));
    for (let [key, value] of Object.entries(updatedImages)) {
      form.append([key], value);
    }
    props.handleUpdate(props._id, form);
    props.setEdit(false);
  };
  const handleOnChange = (e, id, type) => {
    if (type === "mcq" || type === "correctstatement" || type === "mmcq") {
      setOptions(
        options.map((item, idx) =>
          id === idx ? { ...item, value: e.target.value } : item
        )
      );
    } else if (type === "fillup" || type === "oneword") {
      setCorrectAnswer(
        correctAnswer.map((item, idx) =>
          id === idx ? { ...item, answer: e.target.value } : item
        )
      );
    }
  };
  return (
    <EditContainer>
      <EditContainerDiv1>
        <div className="optionsChild">
          <InputField
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
            placeholder="Enter Question"
          />
          <ImageField
            id={"question"}
            isImage={images.questionImg && !markedDeleted.includes('questionImg')}
            filename={updatedImages?.questionImg?.name}
            addImage={(e) => {
              setUpdatedImages({
                ...updatedImages,
                questionImg: e.target.files[0],
              });
            }}
            removeImage={() => removeImage("questionImg")}
          />
        </div>
        <div className="options">
          {!loading &&
            options.map((item, id) => {
              if (
                props.questionType === "mcq" ||
                props.questionType === "correctstatement"
              ) {
                return (
                  <div className="optionsChild">
                    <div
                      onClick={() => handleCorrectAnswer(id, "mcq")}
                      style={{
                        width: "18px",
                        cursor: "pointer",
                        backgroundColor: `${
                          correctAnswer === id ? "#0f93a8" : "transparent"
                        }`,
                        marginRight: "10px",
                        border: "2px solid #0f93a8",
                        height: "18px",
                      }}
                    ></div>
                    <InputField
                      onChange={(e) => handleOnChange(e, id, "mcq")}
                      value={item.value}
                    />
                    <ImageField
                      id={`answer${item.optionId}`}
                      isImage={images[item.optionId] && !markedDeleted.includes(item.optionId)}
                      filename={updatedImages?.[item?.optionId]?.name || ""}
                      addImage={(e) => handleImages(e, item.optionId)}
                      removeImage={() => removeImage(item?.optionId || "")}
                    />
                  </div>
                );
              } else if (props.questionType === "mmcq") {
                return (
                  <div className="optionsChild">
                    <div
                      onClick={() => handleCorrectAnswer(id, "mmcq")}
                      style={{
                        width: "18px",
                        cursor: "pointer",
                        backgroundColor: `${
                          correctAnswer?.indexOf(id) !== -1
                            ? "#0f93a8"
                            : "transparent"
                        }`,
                        marginRight: "10px",
                        border: "2px solid #0f93a8",
                        height: "18px",
                      }}
                    ></div>
                    <InputField
                      onChange={(e) => handleOnChange(e, id, "mcq")}
                      value={item.value}
                    />
                    <ImageField
                      id={`answer${item.optionId}`}
                      isImage={images[item.optionId] && !markedDeleted.includes(item.optionId)}
                      filename={updatedImages?.[item?.optionId]?.name || ""}
                      addImage={(e) => handleImages(e, item.optionId)}
                      removeImage={() => removeImage(item?.optionId || "")}
                    />
                  </div>
                );
              } else {
                return (
                  <div className="optionsChild">
                    <InputField
                      onChange={(e) => handleOnChange(e, id, "fillup")}
                      value={correctAnswer[id].answer}
                    />
                    <ImageField
                      id={`answer${item.optionId}`}
                      isImage={images[item.optionId] && !markedDeleted.includes(item.optionId)}
                      filename={updatedImages?.[item?.optionId]?.name || ""}
                      addImage={(e) => handleImages(e, item.optionId)}
                      removeImage={() => removeImage(item?.optionId || "")}
                    />
                  </div>
                );
              }
            })}
        </div>
        <div className="buttonWrapper">
          <Button onClick={() => Update()}>Update</Button>
          <Button onClick={() => props.setEdit(false)}>Cancel</Button>
        </div>
      </EditContainerDiv1>
      <EditContainerDiv2>
        <div style={{ margin: "10px  20px" }}>
          <DropDown
            options={["knowledge", "analysis", "understanding", "application"]}
            name="cognitive"
            formData={formData}
            setFormData={setFormData}
            placeholder="Cognitive"
          />
        </div>
        <div style={{ margin: "10px 20px" }}>
          <DropDown
            options={["easy", "intermediate", "hard"]}
            name="difficulty"
            formData={formData}
            setFormData={setFormData}
            placeholder="Difficulty"
          />
        </div>
      </EditContainerDiv2>
    </EditContainer>
  );
}

export default Editquestion;
