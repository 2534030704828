import styled from 'styled-components';
export const SettingContainer=styled.div`
.firstClass{
    display:flex;
    justify-content:space-between;
    h1{
        margin:0px;
    }
    .secondClass{
        h3{
            margin:0px;
        }
    }
}

`

export const SettingHeader=styled.div`

`

export const SettingContent=styled.div`

`