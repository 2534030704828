import React, { useState } from "react";
import { TabDataContainer } from "./Commoncomponent.style";
import RenderCard from "./RenderCard/RenderCard";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
// import DoNotDisturbAltRoundedIcon from '@material-ui/icons/';
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import EditIcon from "@material-ui/icons/Edit";
import "../Course/boards/react-contextmenu.css";
import DoneIcon from "@material-ui/icons/Done";
import Popup from "../CommonComponent/Popup/Popup";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

function TabContainerData({
  tabContent,
  type,
  handleOnClick,
  handleEdit,
  showDisable,
  ToogleDisable,
  isTopic,
  isModule,
  isClass,
  isChapter,
  handleDeleteChapter,
  handleDeleteModule,
  handleDeleteClass,
}) {
  const [id, setId] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [openpopup, setPopupOpen] = useState(false);

  const handleClickOpen = () => {
    setPopupOpen(true);
  };
  console.log(tabContent);
  return (
    <TabDataContainer>
      {tabContent?.map((item, key) => {
        return (
          <div
            style={{ width: "18%", marginLeft: "18px", marginBottom: "18px" }}
            key={key}
          >
            {" "}
            <ContextMenuTrigger id="contextmenu">
              <RenderCard
                setDisabled={setDisabled}
                setId={setId}
                handleOnClick={handleOnClick}
                type={type}
                data={item}
                handleDeleteChapter={handleDeleteChapter}
                handleEdit={handleEdit}
              />
            </ContextMenuTrigger>{" "}
          </div>
        );
      })}
      {isTopic ? (
        <>
          <ContextMenu id="contextmenu">
            <MenuItem
              onClick={() => {
                handleEdit(id);
              }}
            >
              <div className="context">
                <EditIcon />
                <span>Edit</span>
              </div>
            </MenuItem>
            <MenuItem onClick={() => handleClickOpen()} className="disable">
              <DeleteOutlineIcon />
              <span>{"Delete"}</span>
            </MenuItem>
            {showDisable && (
              <MenuItem
                onClick={() => ToogleDisable(id, disabled)}
                //onClick={() => handleClickOpen()}
                className="disable"
              >
                {disabled ? <DoneIcon /> : <CancelRoundedIcon />}
                <span>{disabled ? "Enable" : "Disable"}</span>
              </MenuItem>
            )}
          </ContextMenu>
          <Popup
            open={openpopup}
            Title={`Are Your Sure You Want To Delete ? `}
            Disagree={"Cancel"}
            Agree={"Ok"}
            onClose={(isDelete) => {
              setPopupOpen(!openpopup);
              if (isDelete) handleDeleteModule(id);
            }}
          />
        </>
      ) : isModule ? (
        <>
          <ContextMenu id="contextmenu">
            <MenuItem
              onClick={() => {
                handleEdit(id);
              }}
            >
              <div className="context">
                <EditIcon />
                <span>Edit</span>
              </div>
            </MenuItem>
            {/* <MenuItem onClick={() => handleClickOpen()} className="disable">
              <DeleteOutlineIcon />
              <span>{"Delete"}</span>
            </MenuItem> */}
            {showDisable && (
              <MenuItem
                onClick={() => ToogleDisable(id, disabled)}
                //onClick={() => handleClickOpen()}
                className="disable"
              >
                {disabled ? <DoneIcon /> : <CancelRoundedIcon />}
                <span>{disabled ? "Enable" : "Disable"}</span>
              </MenuItem>
            )}
          </ContextMenu>
          <Popup
            open={openpopup}
            Title={`Are Your Sure You Want To Delete ? `}
            Disagree={"Cancel"}
            Agree={"Ok"}
            onClose={(isDelete) => {
              setPopupOpen(!openpopup);
              if (isDelete) handleDeleteModule(id);
            }}
          />
        </>
      ) : isClass ? (
        <>
          {" "}
          <>
            <ContextMenu id="contextmenu">
              <MenuItem
                onClick={() => {
                  handleEdit(id);
                }}
              >
                <div className="context">
                  <EditIcon />
                  <span>Edit</span>
                </div>
              </MenuItem>
              <MenuItem onClick={() => handleClickOpen()} className="disable">
                <DeleteOutlineIcon />
                <span>{"Delete"}</span>
              </MenuItem>
              {/* {showDisable && (
                <MenuItem
                  onClick={() => ToogleDisable(id, disabled)}
                  //onClick={() => handleClickOpen()}
                  className="disable"
                >
                  {disabled ? <DoneIcon /> : <CancelRoundedIcon />}
                  <span>{disabled ? "Enable" : "Disable"}</span>
                </MenuItem>
              )} */}
            </ContextMenu>
            <Popup
              open={openpopup}
              Title={`Are Your Sure You Want To Delete ? `}
              Disagree={"Cancel"}
              Agree={"Ok"}
              onClose={(isDelete) => {
                setPopupOpen(!openpopup);
                if (isDelete) handleDeleteClass(id);
              }}
            />
          </>
        </>
      ) : isChapter ? (
        <>
          <ContextMenu id="contextmenu">
            <MenuItem
              onClick={() => {
                handleEdit(id);
              }}
            >
              <div className="context">
                <EditIcon />
                <span>Edit</span>
              </div>
            </MenuItem>
            <MenuItem onClick={() => handleClickOpen()} className="disable">
              <DeleteOutlineIcon />
              <span>{"Delete"}</span>
            </MenuItem>
            {showDisable && (
              <MenuItem
                onClick={() => ToogleDisable(id, disabled)}
                //onClick={() => handleClickOpen()}
                className="disable"
              >
                {disabled ? <DoneIcon /> : <CancelRoundedIcon />}
                <span>{disabled ? "Enable" : "Disable"}</span>
              </MenuItem>
            )}
          </ContextMenu>
          <Popup
            open={openpopup}
            Title={`Are Your Sure You Want To Delete ? `}
            Disagree={"Cancel"}
            Agree={"Ok"}
            onClose={(isDelete) => {
              setPopupOpen(!openpopup);
              if (isDelete) handleDeleteChapter(id);
            }}
          />
        </>
      ) : (
        <ContextMenu id="contextmenu">
          <MenuItem
            onClick={() => {
              handleEdit(id);
            }}
          ></MenuItem>
          {showDisable && (
            <MenuItem
              onClick={() => ToogleDisable(id, disabled)}
              className="disable"
            >
              {disabled ? <DoneIcon /> : <CancelRoundedIcon />}
              <span>{disabled ? "Enable" : "Disable"}</span>
            </MenuItem>
          )}
        </ContextMenu>
      )}
    </TabDataContainer>
  );
}

export default TabContainerData;
