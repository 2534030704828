import React from "react";
import { Wrapper } from "./details.style";
import { LabelComponent } from "./details.style";
import { Input, DropDown, MultiSelectDropDown } from "../Input/InputComponent";
import EditIcon from "@material-ui/icons/Edit";
function Details(props) {
  const {
    data,
    setEdit,
    edit,
    flex,
    isLabel,
    handleOnChange,
    relative = true,
    formData,
    handleOnClick = () => {},
  } = props;

  return (
    <Wrapper
      relative={relative}
      width={props.width}
      margintop={props.margintop}
      flex={flex}
    >
      {data.map((row, idx) => {
        console.log(row);
        return (
          <div
            key={idx}
            style={{ justifyContent: props.justifycontent }}
            className="child"
          >
            {row.map((item, idx) => {
              return (
                <div
                  style={{
                    width: item.width,
                    margin: item.margin ? item.margin : "",
                  }}
                  key={idx}
                >
                  {isLabel && (
                    <LabelComponent style={{ color: props.labelcolor }}>
                      {item.label}
                    </LabelComponent>
                  )}
                  {item.type !== "select" && item.type !== "multiselect" ? (
                    <Input
                      value={formData[item.name] || item.value}
                      bdr={item.bdr || false}
                      handleOnChange={handleOnChange}
                      name={item.name || "temp"}
                      height={props.height}
                      fontsize={props.fontsize}
                      color={props.color}
                      margin={item.margin ? item.margin : ""}
                      placeholder={item.placeholder}
                      type={item.type}
                      border={item.border}
                      disabled={item.disabled || !edit}
                    />
                  ) : item.type === "select" ? (
                    <DropDown
                      handleOnChange={handleOnChange}
                      value={formData[item.name] || item.value}
                      disabled={item.disabled || !edit}
                      data={item}
                    />
                  ) : (
                    <MultiSelectDropDown
                      disabled={item.disabled || !edit}
                      value={formData[item.name]}
                      handleOnClick={handleOnClick}
                      handleOnChange={handleOnChange}
                      data={item}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
      {!edit && (
        <div onClick={() => setEdit(true)} className="editIcon">
          <EditIcon style={{ marginRight: "5px" }} />
          Edit Info
        </div>
      )}
    </Wrapper>
  );
}

Details.defaultProps = {
  data: [],
  edit: false,
  setEdit: () => {},
  flex: 0,
  isLabel: true,
  handleOnChange: () => {},
  formData: {},
  relative: true,
};
export default Details;
