import React from 'react'
import Avatar from '../../images/Avatar.png'

function AvatarComponent() {
    return (
        <img src={Avatar} alt="avatar"/>
    )
}

export default AvatarComponent;
