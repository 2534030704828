import React,{useState,useEffect} from 'react';
import { GraphContainer,GraphHeader } from './Analytics.style';
import SystemUpdateAltOutlinedIcon from '@material-ui/icons/SystemUpdateAltOutlined';
import CachedOutlinedIcon from '@material-ui/icons/CachedOutlined';
import { PieChart, Pie, ResponsiveContainer  } from "recharts";
import exportFromJSON from 'export-from-json';
import {analytics} from '../../Api/api';
import axios from 'axios';
import {useAuth} from '../../Auth/Auth';

function ProgressBarGraph() {
    const {readCookie}=useAuth();
    const [pieGraphData, setPieGraphData] = useState([]);
    useEffect(()=>{
        axios.get(analytics.getMonthlyUserData,{
            headers:{
                token: `${readCookie("token")}`,
            }
        }).then((res)=>{
            if (res.data.status === "success") {
                setPieGraphData(res.data.data.students);
                
              }

        }).catch(err=>console.log(err));
        
    },[])
    const exportCsv = () => {
        const data = pieGraphData;
        const fileName = "MonthlyUsers";
        const exportType = exportFromJSON.types.csv;
    
        exportFromJSON({ data, fileName, exportType });
      };
    
    return (
        <GraphContainer style={{position:"relative"}} minHeight="438px">
          <GraphHeader>
                <div>
                <h1 style={{position:"relative"}}>
                    Monthly User <CachedOutlinedIcon onClick={()=>window.location.reload()} style={{position:"absolute",cursor:"pointer",top:"7px",right:"50px"}}/>
                </h1>
                <p>
                Monthly division of Regular and Trial students
                </p>
                </div>
                <div onClick={()=>exportCsv()} style={{cursor:"pointer",position:"relative",fontSize:"1.5rem"}}>
                    <SystemUpdateAltOutlinedIcon style={{position:"absolute",top:"8px",left:"-28px",transform:"rotate(-90deg)"}}/> Export
                </div>
            </GraphHeader>
            
            <ResponsiveContainer height="auto" style={{height:"80%"}} aspect="1.5">

        <PieChart width={"100%"} >
          <Pie
            data={pieGraphData}
            
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={70}
            outerRadius={95}
            dataKey="students"
            
            fill="#5CE0D2"
            label />

          
        </PieChart>

      </ResponsiveContainer>
      <div style={{position:"absolute",bottom:"16px",left:"16px",right:"16px",display:"flex",justifyContent:"space-between"}}>
          <div>
                <div>Regular Students</div>
                <div style={{fontWeight:"500"}}>{pieGraphData.filter(data=>data._id === "regular")[0]?.students}</div>
          </div>
          <div>
                <div>Trial Students</div>
                <div style={{fontWeight:"500"}}>{pieGraphData.filter(data=>data._id === "trial")[0]?.students}</div>
          </div>
      </div>
      

    </GraphContainer>
    )
}

export default ProgressBarGraph
