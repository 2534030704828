import styled from 'styled-components';


export const NavbarWrapper=styled.div`
display:flex;
min-height:100vh;
flex-direction:column;
// justify-content:space-between;
background-color:#5CE0D2;
padding:10px 8px;
max-height:100vh;
box-sizing:border-box;
position:sticky;
// position:fixed;
top:0;


`
export const Logo=styled.div`
flex:0.25;

.logoContainer{
    width:120px;
    height:120px;
    border-radius:50%;
    background-color:#fff;
    margin:0 auto;
    overflow:hidden;
}
img{
    width:120px;
    height:120px;
}

`
export const NavbarLinkWrapper=styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
flex:0.45;


`

export const NavbarLink=styled.div`

padding:8px 10px;
border-radius:5px;
position:relative;
.link{
    text-decoration:none;
    color:black;
    font-size:1.2rem;
    display:flex;
    align-items:center;
    
}

&:hover{
    background-color:#fff;
    ${({submenu})=>submenu&&`margin-right:-9px;`}

    .afterhover{
        display:block;
        z-index:10000;
    }
}

.navIcon{
    margin-right:12px;
    
}



.afterhover{
    position:absolute;
    right:0px;
    transform:translateX(100%);
    top:0;
    display:none;
    
    z-index:10000;

    
    
    
    
   
    
}
.sub{
    padding:8px 10px;
    border-radius:5px;
    background-color:#fff;
    border:1px solid #5CE0D2;
    border-radius:5px;
    
    &:hover{
      
        background-color:#5CE0D2;
    }
}

`
export const Logout=styled.div`
flex:0.3;
display:flex;
flex-direction:column;
justify-content:flex-end;

.link{
    text-decoration:none;
    color:black;
    font-size:1.2rem;
    padding:
    display:flex;
    align-items:center;
    padding:8px 10px;
border-radius:5px;
    &:hover{
        background-color:#fff;
        ${({submenu})=>submenu&&`margin-right:-9px;`}
    
        .afterhover{
            display:block;
            z-index:10000;
        }
    }

}


.navIcon{
    margin-right:12px;

    
}


`