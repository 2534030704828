export const login = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/auth/login?user=admin`;

export const Section = {
  getSection: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sections`,
  addSection: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sections`,
  editSection: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sections/${id}`,
  deleteSection: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/sections/${id}`,
};
export const analytics = {
  getuser: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/analytics/last12houractiveusers`,
  getMonthlyUserData: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/analytics/monthlyregisteredstudents`,
  getWeeklyRegisterStudents: (previous) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/analytics/weeklyregisteredstudents?previous=${previous}`,
  getPageVisitByUser: (school, from, to, role) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/analytics/pagevisitbyusers?school=${school}&visitOn[gte]=${from}&visitOn[lte]=${to}&role=${role}&export=true`,
};

export const courses = {
  getAllCountry: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/countries`,
  getCountryBoard: (countryId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/countries/${countryId}`,
  getBoardContent: (boardId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/boards/${boardId}`,
  addCountry: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/countries`,
  editCountry: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/countries/${id}`,
  deleteCountry: (id) =>
    `${process.env.REACT_APP_ORIGIN_STAGE}api/v1/countries/${id}`,
};

export const Board = {
  addBoard: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/boards`,
  editBoard: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/boards/${id}`,
  deleteBoard: (id) =>
    `${process.env.REACT_APP_ORIGIN_STAGE}api/v1/boards/${id}`,
};
export const Class = {
  createClass: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/courses`,
  updateClass: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/courses/${id}`,
  deleteClass: (id) =>
    `${process.env.REACT_APP_ORIGIN_STAGE}api/v1/courses/${id}`,
};

export const subjects = {
  getSubjectsByClass: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/courses/${id}`,
  getTopicBySubjects: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/subjects/${id}`,
  addSubjects: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/subjects`,
  deleteSubjectByClass: (id) =>
    `${process.env.REACT_APP_UPLOAD_MODULES}/api/v1/subjects/${id}`,
  editSubject: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/subjects/${id}`,
  toogleDisableSubject: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/subjects/${id}/disable`,
};

export const chapter = {
  addChapter: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/chapters`,
  getTopicsByChapter: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/chapters/${id}`,
  toogleDisabled: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/chapters/${id}/disable`,
  EditChapter: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/chapters/${id}`,
  deleteChapter: (id) =>
    `${process.env.REACT_APP_UPLOAD_MODULES}/api/v1/chapters/${id}`,
};

export const Module = {
  getModuleByTopics: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/topics/${id}`,
  addModules: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules`,
  addModulesV2: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/create-module`,
  getSignedURL: (moduleId, filename, filetype) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${moduleId}/sign-resource-url?filename=${filename}&contentType=${filetype}`,
  updateResource: (moduleId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${moduleId}/create-resource`,
  updateModules: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${id}`,
  toogleDisableModule: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${id}/disable`,
  deleteModule: (id) =>
    `${process.env.REACT_APP_ORIGIN_STAGE}api/v1/modules/${id}`,
};

export const Topics = {
  addTopics: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/topics`,
  deleteTopic: (id) =>
    `${process.env.REACT_APP_UPLOAD_MODULES}/api/v1/topics/${id}`,
  toogleDisableTopic: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/topics/${id}/disable`,
  EditTopic: (id) =>
    `${process.env.REACT_APP_UPLOAD_MODULES}/api/v1/topics/${id}`,
};

export const resources = {
  getResources: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${id}`,
  // addResources:`${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules`
  // modules/61bb48bf5547a70010d68276/resources
  deleteResources: (moduleId, ResoucesId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${moduleId}/resources/${ResoucesId}`,
  addResources: (moduleId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/modules/${moduleId}/resources`,
};

export const Question = {
  addQuestion: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/questions`,

  getQuestions: (topicId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/questions?topicId=${topicId}&export=true`,

  UploadQuestions: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/questions/bulk`,
  deleteQuestion: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/questions/${id}`,
  editQuestion: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/questions/${id}`,
};

export const Teacher = {
  getTeacher: (page, limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs?page=${page}&limit=${limit}`,
  getStaffCount: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/total`,
  getStaffCountBySchool: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/total?school=${id}`,

  getStaffBySchool: (id, page, limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs?school=${id}&page=${page}&limit=${limit}`,
  addTeacher: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs`,
  getTeacherById: (staffId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}`,
  updateStaff: (staffId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}`,
  toogleDisable: (staffId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}/enable`,
  getFilterData: (data) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs?or[name][regex]=${data}&or[name][options]=i&or[email][regex]=${data}&or[email][options]=i`,
  importTeachers: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}/import-v2?user=teacher`,
  deleteTeacher: (staffId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}`,
  getFilterDataBySchool: (schoolId, data) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs?school=${schoolId}&or[name][regex]=${data}&or[name][options]=i&or[email][regex]=${data}&or[email][options]=i`,
  resetPassword: (id) =>
    `${process.env.REACT_APP_ORIGIN_STAGE}api/v1/staffs/${id}/resetpassword`,
};

export const Student = {
  getStudents: (page, limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?page=${page}&limit=${limit}`,
  getStudentsCount: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students/total`,

  getSortedData: (page, limit, sortby) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?sort=${sortby}&page=${page}&limit=${limit}`,
  postData: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students`,
  getStudentById: (studentId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students/${studentId}`,
  updateStudent: (studentId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students/${studentId}`,
  addSubject: (gradeId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${gradeId}/subjects`,
  toogleDisable: (studentId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students/${studentId}/enable`,
  getFilterData: (data) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?or[name][regex]=${data}&or[name][options]=i&or[email][regex]=${data}&or[email][options]=i`,
  importStudents: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}/import-v2?user=student`,
  deleteStudent: (studentId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students/${studentId}`,
  getFilterDataBySchool: (schoolId, data) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?school=${schoolId}&or[name][regex]=${data}&or[name][options]=i&or[email][regex]=${data}&or[email][options]=i`,
  resetPassword: (id) =>
    `${process.env.REACT_APP_ORIGIN_STAGE}api/v1/students/${id}/resetpassword`,
};

export const schools = {
  getSchools: (page, limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools?page=${page}&limit=${limit}&sort=-_id`,
  totalSchools: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/total`,
  getSchoolsById: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}`,
  getStudentsBySChool: (schoolId, page, limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?school=${schoolId}&page=${page}&limit=${limit}`,
  getClasses: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades?schoolId=${schoolId}`,
  registerSchool: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools`,
  upDateSchools: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}`,
  getStudentCount: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students/total?school=${schoolId}`,
  extendExpiryDate: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}/extendexpirydate`,
  createGrade: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades`,
  addSections: (gradeId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${gradeId}/sections`,
  toogleDisableSchool: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}/enable`,
  toogleDisableClass: (gradeId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${gradeId}/enable`,
  getFilterData: (data) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools?or[name][regex]=${data}&or[name][options]=i&or[state][regex]=${data}&or[city][regex]=${data}&or[branch][regex]=${data}&or[city][options]=i&or[branch][options]=i&or[state][options]=i`,

  sortSchools: (field, limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools?sort=${field}&limit=${limit}`,
  getSchoolsLmt: (limit) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools?fields=name,country,board&limit=${limit}`,
  updateSchools: (schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}`,
  deleteSchools: (schoolId) =>
    `${process.env.REACT_APP_UPLOAD_MODULES}/api/v1/schools/${schoolId}`,
  deleteSubjectByGradeName: (gradeName, subjectName, schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${gradeName}/subjects/${subjectName}?schoolId=${schoolId}`,
  deleteCourseBySchoolId: (schoolId, coursesId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}/courses/${coursesId}`,
  addCourseBySchool: (schoolId, coursesId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/${schoolId}/courses`,
};

export const subjectTeachers = {
  getSubjectTeachers: (gradeId, sectionId, schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${gradeId}/sections/${sectionId}/subjectTeachers?schoolId=${schoolId}`,

  assignSubjects: (staffId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}/assignSubjects`,

  getAssignedSubjects: (staffId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}/assignSubjects`,
};

export const studentSchedule = {
  getSubjects: (grade, section, schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${grade}/sections/${section}/subjects?schoolId=${schoolId}`,
  getTeachers: (grade, section, schoolId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/grades/${grade}/sections/${section}/subjectTeachers?schoolId=${schoolId}`,
};

export const Profile = {
  addProfilePic: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admins/profile`,
  deleteProfilePic: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admins/profile`,
  getProfileData: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/admins/${id}`,
  changePassword: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/auth/changepassword?user=admin`,
};

export const ExportData = {
  exportSchool: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/schools/?export=true`,
  exportStudent: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?export=true`,
  exportTeacher: `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs?export=true`,
  exportTeacherBySchool: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs?school=${id}&export=true`,
  exportStudentBySchool: (id) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/students?school=${id}&export=true`,
};

export const RemovedAssignSubject = {
  removeAssignSubject: (staffId, sectionId, subjectId) =>
    `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/staffs/${staffId}/sections/${sectionId}/subjects/${subjectId}`,
};
