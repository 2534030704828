import React from "react";
import { Option, OptionText, ImgContainer } from "./Questions.style";
import { htmlDecode, removeEqualQuotes } from "../../utils/parse";


function Options(props) {
  const { mark, option, radio, isQuestionImg } = props;

  return (
    <div className="optioncontainer" style={{ width: "48%" }}>
      <Option isQuestionImg={isQuestionImg}>
        <div
          style={{
            border: "1px solid #5ce0d2",
            borderRadius: "10px",
            padding: "9px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {radio && (
              <div
                style={
                  mark
                    ? { backgroundColor: "#5ce0d2" }
                    : { backgroundColor: "#fff" }
                }
                className="mark"
              ></div>
            )}
            <OptionText>
              <div
                dangerouslySetInnerHTML={{ __html: htmlDecode(removeEqualQuotes(option?.value || "")) }}
              ></div>
            </OptionText>
          </div>
          {props.images && (
            <ImgContainer>
              <img src={props.images} alt="optionsImage" />
            </ImgContainer>
          )}
        </div>
      </Option>
    </div>
  );
}

Options.defaultProps = {
  radio: true,
};
export default Options;
