import React, { useState, useEffect, useMemo } from "react";
import {
  CoursesPageContainer,
  CoursesPageContent,
  card,
  cardbox,
} from "./Courses.style";
import { TablePagination } from "@material-ui/core";
import {
  DashboardHeaderWrapper,
  DashboardHeader,
} from "../Dashboard/Dashboard.style";
import { useNavigate, Link } from "react-router-dom";
import ProfileImg from "../../images/profileImg.svg";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../Header/Headerfeatures";
import { Line } from "../Dashboard/Dashboard.style";
// import AddCountry from '../Modal/AddCountry';
import { courses } from "../../Api/api";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import { CountryCard } from "../Card/Card";
import AddModalComponent from "../Modal/AddModalComponent";
import countryList from "react-select-country-list";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Popup from "../CommonComponent/Popup/Popup";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import "./boards/react-contextmenu.css";
import exportFromJSON from "export-from-json";
import { ModuleSearch } from "./ModuleSearch";
const sortField = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Total Boards",
    field: "totalBoards",
  },
];

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

function Courses() {
  const [modal, setModal] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [FilterCountry, setFilterCountry] = useState([]);
  const { readCookie, DisplaySnackbar, imgUrl } = useAuth();
  const navigation = useNavigate();
  const [type, setType] = useState("");
  const [page, setPage] = useState(0);
  const [formData, setFormData] = useState({ country: "" });
  const [editForm, setShowEditForm] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const options = useMemo(() => countryList().getData(), []);
  const [id, setId] = useState({});
  const [openpopup, setPopupOpen] = useState(false);

  const label = [
    [
      {
        label: "COUNTRY",
        type: "select",
        options: options.map((item) => {
          return { name: item.label, value: item.label };
        }),
        placeholder: "select country",
        name: "country",
        width: "100%",
        value: "",
      },
    ],
  ];

  const handleClickOpen = (e, itemId) => {
    setId(itemId);
    setPopupOpen(true);
    e.stopPropagation();
  };

  useEffect(() => {
    axios
      .get(courses.getAllCountry, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        // DisplaySnackbar("popup","error");
        setAllCountries(res.data.data.countries);
        setFilterCountry(res.data.data.countries);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleOnClick = (id) => {
    const name = allCountries.find((item) => item._id === id).name;
    navigation(`/courses/${fixName(name)}/${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    if (!modal) {
      setFormData({ country: "" });
      setId({});
      setShowEditForm(false);
    }
  }, [modal]);

  const handleCreate = () => {
    if (!formData.country) {
      DisplaySnackbar("Select Country First", "error");
      return;
    }

    if (!editForm) {
      axios
        .post(
          courses.addCountry,
          { name: formData.country },
          {
            headers: {
              token: `${readCookie("token")}`,
            },
          }
        )
        .then((res) => {
          setAllCountries([
            ...allCountries,
            {
              name: formData.country,
              totalBoards: 0,
              _id: res.data.data.country._id,
            },
          ]);
          setModal(false);
          DisplaySnackbar("Country Added", "success");
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .patch(
          courses.editCountry(id.id),
          { name: formData.country },
          {
            headers: {
              token: `${readCookie("token")}`,
            },
          }
        )
        .then((res) => {
          setAllCountries(
            allCountries.map((item) =>
              item._id === id.id ? { ...item, name: formData.country } : item
            )
          );
          setModal(false);
          DisplaySnackbar("Country Edit Successful", "success");
        })
        .catch((err) => {
          DisplaySnackbar(err.response.data.message, "error");
        });
    }
  };

  const handleDeleteCountry = ({ id }) => {
    axios
      .delete(courses.deleteCountry(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Country deleted", "success");
          handleupdateCountryList(id);
        }
      })
      .catch((err) => {
        console.log("err", err.response);
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateCountryList = (id) => {
    const filteredData = allCountries.filter((i) => i._id !== id);
    setAllCountries(filteredData);
  };

  const exportFile = () => {
    //console.log(allCountries);

    const data = allCountries.map((item) => {
      return {
        Id: item._id,
        Name: item.name,
        TotalBoards: item.totalBoards,
        CreatedAt: item.createdAt,
      };
    });

    const fileName = "Countries";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
    DisplaySnackbar("File Exported", "success");
  };
  const handleSort = (field, type) => {
    let temp = allCountries;

    //console.log(type);
    //console.log(temp)
    if (type === "asc") {
      // console.log(field);
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "totalBoards") {
          name1 = a[field];
          name2 = b[field];
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      //console.log(temp);

      setAllCountries([...temp]);
    } else {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "totalBoards") {
          name1 = a[field];
          name2 = b[field];
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }
        if (name1 < name2) return 1;
        if (name1 > name2) return -1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setAllCountries([...temp]);
    }
  };
  return (
    <CoursesPageContainer>
      <DashboardHeaderWrapper>
        <DashboardHeader>
          <div>
            <h1>Courses</h1>
          </div>

          <div>
            <ModuleSearch />
            <Link to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImg}
                alt="profileimg"
              />
            </Link>
          </div>
        </DashboardHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "-50px",
            marginLeft: "-25px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={10}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>

        <DashboardHeader>
          <div
            style={{ cursor: "pointer", fontWeight: "500" }}
            onClick={() => {
              setModal(true);
            }}
            className="iconContainer"
          >
            <AddBoxOutlinedIcon /> Add Country
          </div>
          <Headerfeatures
            exportFile={exportFile}
            sortField={sortField}
            handleSort={handleSort}
            viewBy={false}
          />
        </DashboardHeader>
      </DashboardHeaderWrapper>
      <Line />
      <CoursesPageContent
        style={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {allCountries.map((item) => {
          return (
            <ContextMenuTrigger
              id="contextmenu"
              // className={card}
              key={item._id}
            >
              <div
                onContextMenu={() => {
                  setId({ id: item._id, name: item.name });
                }}
                style={{ margin: "20px" }}
              >
                <CountryCard
                  key={item._id}
                  id={item._id}
                  handleOnClick={handleOnClick}
                  country={item.name}
                  boards={item.totalBoards}
                  marginLeft="18px"
                  marginBottom="18px"
                />
              </div>
            </ContextMenuTrigger>
          );
        })}
      </CoursesPageContent>
      {modal && (
        <AddModalComponent
          buttonText={editForm ? "Edit" : "Create"}
          handleCreate={handleCreate}
          formData={formData}
          setFormData={setFormData}
          label={label}
          headerText={editForm ? "Edit Country" : `Add Country`}
          setModal={setModal}
          modalType={type == "COUNTRY" ? "COUNTRY" : "COUNTRY"}
        />
      )}
      <ContextMenu id="contextmenu">
        <MenuItem
          onClick={() => {
            setShowEditForm(true);
            setModal(true);
            setFormData({ country: id.name });
            setType("COUNTRY");
          }}
        >
          <div className="context">
            <EditIcon />
            <span>Edit</span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleClickOpen(e, id);
          }}
        >
          {" "}
          <div className="context">
            <DeleteOutlineIcon />
            <span>Delete</span>
          </div>
        </MenuItem>
      </ContextMenu>
      <Popup
        open={openpopup}
        Title={`Are Your Sure You Want To Delete ?`}
        Disagree={"Cancel"}
        Agree={"Ok"}
        onClose={(isDelete) => {
          setPopupOpen(!openpopup);
          if (isDelete) handleDeleteCountry(id);
        }}
      />
    </CoursesPageContainer>
  );
}

export default Courses;
