import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../../Loading/Loader";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

export default function CradPopUp({
  open,
  onClose,
  Disagree,
  Agree,
  Title,
  loading,
  formData,
  handleAddCourse,
}) {
  const [courses, setCourses] = React.useState([]);
  const handleChange = (event) => {
    setCourses(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => onClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ width: "500px" }}>
          {Title}
        </DialogTitle>
        <span style={{ width: "450px", paddingLeft: "50px" }}>
          <InputLabel id="demo-simple-select-label">Select Courses</InputLabel>
          <Select
            style={{ width: "400px" }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Select Courses"
            value={courses}
            onChange={handleChange}
            multiple={true}
          >
            <MenuItem
              disabled
              value=""
              style={{ color: "black", fontSize: "20px" }}
            >
              <em>Assign Courses</em>
            </MenuItem>
            {formData.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </span>
        <br></br>
        <DialogActions>
          <Button onClick={() => onClose(false)}>{Disagree}</Button>
          <Button onClick={() => handleAddCourse(courses)}>{Agree}</Button>
          {loading && <Loader type="spin" color="cyan" />}
        </DialogActions>
      </Dialog>
    </div>
  );
}
