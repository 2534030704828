import React, { useState, useEffect } from 'react';
import { CommonComponentContainer } from './Commoncomponent.style';
// import UploadAssessment from '../Assessment/UploadAssessment';
import AllQuestions from '../Questions/AllQuestion';

import TabContainerData from './TabData';
import { Tab } from '../Dashboard/Dashboard.style';
import Popup from '../CommonComponent/Popup/Popup';
import BurgerMenu from './BurgerMenu';

function CommonComponent(props) {
  const [openpopup, setPopupOpen] = useState(false);
  const [openDisablePopup, setDisablePopupOpen] = useState(false);
  const {
    tab,
    tabContent,
    type,
    handleOnClick,
    module,
    onTabChange,
    tabActive,
    setEdit,
    render,
    topicQuestion,
    setQuestion,
    topicId,
    handleEdit,
    showDisable,
    ToogleDisable,
    handleDelete,
    isBoard,
    isTopic,
    setType,
    isModule,
    isClass,
    isChapter,
    EditModule,
    handleDeleteChapter,
    handleDeleteSubjectByClass,
    handleEditsubject,
    handleDisableSubject,
    handleDeleteTopic,
    handleDisableTopic,
    handleEdittopic,
    handleDeleteModule,
    handleDeleteBoard,
    handleEditboard,
    handleEditclass,
    handleDeleteClass,
  } = props;

  const [selectedSubject, setSelectedSubject] = useState();
  const [activeTab, setActiveTab] = useState(tabActive);
  const [itemId, setItemId] = useState('');
  const [itemData, setItemData] = useState(null);
  const ITEM_HEIGHT = 30;

  useEffect(() => {
    setActiveTab(tabActive);
  }, [tabActive]);

  const handleClickOpen = (e, itemId) => {
    setItemId(itemId);
    setPopupOpen(true);
    e.stopPropagation();
  };

  const handleDisableOpen = (e, item) => {
    setItemId(item._id);
    setItemData(item);
    setDisablePopupOpen(true);
    e.stopPropagation();
  };

  const handleClickEdit = (event, item) => {
    const selectedSubjectId = selectedSubject?._id || '';
    if (!isTopic) {
      handleEditsubject(selectedSubjectId);
      setType('SUBJECT');
      setPopupOpen(false);
    } else if (isTopic) {
      handleEdittopic(item._id);
      setType('Edit TOPIC');
      setPopupOpen(false);
    } else if (isModule) {
      EditModule(item._id);
      setType('Edit MODULE');
      setPopupOpen(false);
    }
    //else {
    //   handleEdit(selectedSubjectId);
    //   setType("CHAPTER");
    // }
    setEdit(true);
    event.stopPropagation();
  };

  const handleClickBoard = (event, item) => {
    handleEditboard(item._id);
    setPopupOpen(false);
    setEdit(true);
    event.stopPropagation();
  };

  const handleClickClass = (event, item) => {
    handleEditclass(item._id);
    setPopupOpen(false);
    setEdit(true);
    event.stopPropagation();
  };

  const handleClick = (event, subject) => {
    setSelectedSubject(subject);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setPopupOpen(false);
  };

  return (
    <CommonComponentContainer>
      <div className='firstChild'>
        {tab.map((item, idx) => {
          return (
            <span
              key={idx}
              style={{
                opacity: item.disabled ? '0.5' : '1',
              }}
            >
              <Tab
                constwidthtab
                onClick={(e) => {
                  onTabChange(item._id);
                  setActiveTab(idx);
                  e.stopPropagation();
                }}
                key={item._id}
                id={item._id}
                active={activeTab === idx}
                constdesign
              >
                <span> {item.name}</span>
                <span>
                  <BurgerMenu
                    handleClick={handleClick}
                    handleClickEdit={handleClickEdit}
                    ITEM_HEIGHT={ITEM_HEIGHT}
                    open={openpopup}
                    handleClose={handleClose}
                    itemData={item}
                    handleClickOpen={handleClickOpen}
                    handleDisableOpen={handleDisableOpen}
                    isBoard={isBoard}
                    handleClickBoard={handleClickBoard}
                    isClass={isClass}
                    handleClickClass={handleClickClass}
                    isTopic={isTopic}
                    isChapter={isChapter}
                  />
                </span>
              </Tab>
            </span>
          );
        })}
      </div>
      <div className='secondChild'>
        {module ? (
          <TabContainerData
            ToogleDisable={ToogleDisable}
            showDisable={showDisable}
            handleEdit={handleEdit}
            handleOnClick={handleOnClick}
            type={type}
            tabContent={tabContent}
            handleDeleteChapter={handleDeleteChapter}
            isTopic={isTopic}
            handleDeleteModule={handleDeleteModule}
            isModule={isModule}
            isBoard={isBoard}
            isClass={isClass}
            isChapter={isChapter}
            handleDeleteClass={handleDeleteClass}
          />
        ) : (
          <AllQuestions
            render={render}
            filterBy={props.filterBy}
            handleUpdateQuestion={props.handleUpdateQuestion}
            handleDeleteQuestion={props.handleDeleteQuestion}
            topicQuestion={topicQuestion}
            setQuestion={setQuestion}
            topicId={topicId}
          />
        )}
      </div>
      {!isTopic ? (
        <Popup
          open={openpopup}
          Title={` Are Your Sure You Want To Delete ?`}
          Disagree={'Cancel'}
          Agree={'Ok'}
          onClose={(isDelete) => {
            setPopupOpen(!openpopup);
            if (isDelete) handleDeleteSubjectByClass(itemId);
          }}
        />
      ) : (
        <Popup
          open={openpopup}
          Title={` Are Your Sure You Want To Delete ?`}
          Disagree={'Cancel'}
          Agree={'Ok'}
          onClose={(isDelete) => {
            setPopupOpen(!openpopup);
            if (isDelete) handleDeleteTopic(itemId);
          }}
        />
      )}
      {isTopic && (
        <Popup
          open={openDisablePopup}
          Title={` Are Your Sure You Want To ${
            itemData?.disabled ? 'Enable' : 'Disable'
          } ${itemData?.name} ?`}
          Disagree={'Cancel'}
          Agree={'Ok'}
          onClose={(yes) => {
            setDisablePopupOpen(!openDisablePopup);
            if (yes) handleDisableTopic(itemId, itemData.disabled);
          }}
        />
      )}
      {isChapter && (
        <Popup
          open={openDisablePopup}
          Title={` Are Your Sure You Want To ${
            itemData?.disabled ? 'Enable' : 'Disable'
          } ${itemData?.name} ?`}
          Disagree={'Cancel'}
          Agree={'Ok'}
          onClose={(yes) => {
            setDisablePopupOpen(!openDisablePopup);
            if (yes) handleDisableSubject(itemId, itemData.disabled);
          }}
        />
      )}
      {isBoard && (
        <>
          <Popup
            open={openpopup}
            Title={` Are Your Sure You Want To Delete ?`}
            Disagree={'Cancel'}
            Agree={'Ok'}
            onClose={(isDelete) => {
              setPopupOpen(!openpopup);
              if (isDelete) handleDeleteBoard(itemId);
            }}
          />
        </>
      )}
    </CommonComponentContainer>
  );
}

CommonComponent.defaultProps = {
  module: true,
  tab: [],
  setEdit: () => {},
  handleEdit: () => {},
  showDisable: false,
  ToogleDisable: () => {},
  topicId: '',
  topicQuestion: [],
  setQuestion: () => {},
  handleDeleteQuestion: () => {},
  filterBy: '',
  tabActive: 0,
  render: false,
};
export default CommonComponent;
