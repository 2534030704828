import React from "react";
import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header, Container } from "./Modal.style";
import Details from "../Details/Details";
import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";

function FilterComponent(props) {
  const {
    data,
    setPopUpFilter,
    isLabel,
    isClear,
    formData,
    setFormData,
    handleApply,
  } = props;
  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // console.log(props);
  //console.log(data);

  return (
    <ModalWrapper>
      <ModalContainer style={{ minWidth: "350px" }}>
        <Header>
          <h2>Filters</h2>
          <h2
            onClick={() => setPopUpFilter(false)}
            style={{ cursor: "pointer" }}
          >
            ×
          </h2>
        </Header>
        <Container>
          <Details
            handleOnChange={handleOnChange}
            formData={formData}
            setFormData={setFormData}
            isLabel={isLabel}
            margintop="10px"
            labelcolor="black"
            color="grey"
            fontsize=".9rem"
            height="32px"
            data={data}
            edit={true}
          />
        </Container>
        <ButtonWrapper style={!isClear ? { justifyContent: "center" } : {}}>
          {isClear && (
            <Button
              style={{ padding: "10px 20px" }}
              onClick={() => setPopUpFilter(false)}
            >
              CLEAR ALL
            </Button>
          )}
          <Button onClick={() => handleApply()}>APPLY</Button>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

FilterComponent.defaultProps = {
  data: [],
  setPopUpFilter: () => {},
  isLabel: true,
  isClear: true,
};
export default FilterComponent;
