import React from 'react';
import {PracticeAssessmentContainer,PracticeAssessmentHeader,PracticeAssessmentContent,PracticeAssessmentTable} from '../PracticeAssessment/practiceAssesment.style';
import {AnalyticsTabs} from '../AnalyticsHelper/AnalyticsHelper.style';
import AnalyticsContainer3 from '../AnalyticsHelper/AnalyticsContainer3';
import HomeWorkReport from '../HomeworkReport/HomeWorkReport';
import { HomeWorkHeader,HomeWorkData } from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader';
import AssessmentReport from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTable';

function ClassHomeWork() {
    const label = [
        {
          heading: "Homeworks",
          count: "200",
        },
        {
          heading: "Average Students Done",
          count: "50%",
        },
        {
          heading: "Average Students Pending",
          count: "30%",
        },
       
      ];


      const data = [
        {
          name: "Math",
          Done: 40,
          Pending: 10,
        },
        {
          name: "Math",
          Done: 40,
          Pending: 10,
        },
        {
          name: "MathI",
          Done: 40,
          Pending: 10,
        },
        {
          name: "Math",
          Done: 40,
          Pending: 10,
        },
        {
          name: "Math",
          Done: 40,
          Pending: 10,
        },
        {
          name: "Math",
          Done: 40,
          Pending: 10,
        },
      ];
      
      
     
        
    return (
        <PracticeAssessmentContainer>
            <PracticeAssessmentHeader>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h5 style={{fontWeight:"600",padding:"5px",background:"linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)"}}>HomeWork</h5>
                   

                </div>
                <div className="tabContainer">
          {label.length &&
            label.map((item) => {
              return (
                <AnalyticsTabs style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {item.heading}
                  </div>
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.count}
                  </h5>
                </AnalyticsTabs>
              );
            })}
        </div>
                
            </PracticeAssessmentHeader>
            <PracticeAssessmentContent >
        <AnalyticsContainer3
          colors={["#F28684", "#59E0B0"]}
          headerText="HomeWork Report of All Classes"
          subHeading="Total Homeworks-200"
          data={data}
          dataField={["Done", "Pending"]}
          yAxis="HomeWorks"
          
        />
         <HomeWorkReport classes={false}/>
      </PracticeAssessmentContent>
      <PracticeAssessmentContent>
         
      </PracticeAssessmentContent>
            <PracticeAssessmentTable>
                <AssessmentReport  tabContent={HomeWorkData} tabHeader={HomeWorkHeader}/>
            </PracticeAssessmentTable>
        </PracticeAssessmentContainer>
    )
}

export default ClassHomeWork
