import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { StudentPageContainer, StudentsPageContent } from "./Students.style";
import {
  DashboardHeaderWrapper,
  DashboardHeader,
  Line,
} from "../Dashboard/Dashboard.style";
import InputComponent from "../Input/InputComponent";
import ProfileImg from "../../images/profileImg.svg";
import { TablePagination } from "@material-ui/core";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../Header/Headerfeatures";
import { StudentsPageData } from "../Table/TableHeaders";
import Loader from "../Loading/Loader";
import TableComponent from "../Table/Table";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
// import AddStudentComponent from "../Modal/AddStudentComponent";
import Switch from "@material-ui/core/Switch";
import { Student, schools, ExportData } from "../../Api/api";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import { useAuth } from "../../Auth/Auth";
import { addStudents } from "../RenderDetailsData/RenderDetailsData";
import AddModalComponent from "../Modal/AddModalComponent";
import exportFromJSON from "export-from-json";
import DocumentLoading from "../Loading/ExportLoader";
// import SortDropDown from "./SortDropDown/SortDropDown";

const sortField = [
  {
    label: "Name",
    field: "name",
  },
  {
    label: "Class",
    field: "class",
  },
  {
    label: "Signup Date",
    field: "signUpDate",
  },
  {
    label: "Expiry Date",
    field: "expiryDate",
  },
];

function StudentsPage() {
  const [modal, setModal] = useState(false);
  const [page, setPage] = useState(0);
  const [tabContent, setTabContent] = useState([]);
  const navigation = useNavigate();
  const [totalCount, setTotalCount] = useState(0);
  const [students, setStudents] = useState([]);
  const { readCookie, DisplaySnackbar } = useAuth();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [render, setRender] = useState(false);
  const [sortBy, setSortBy] = useState([]);
  const [schoolsData, setSchoolsData] = useState([]);
  const [label, setLabel] = useState();
  // const [render,setRender]=useState(false);
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [exportingData, setExportingData] = useState(false);
  // const [section,setSection]=useState([]);
  const [formData, setFormData] = useState({
    schoolName: "",
    name: "",
    class: "",
    section: "",
    password: "",
    email: "",
    contact: "",
    expiryDate: "",
  });

  // console.log(formData);
  // console.log(schoolsData)

  useEffect(() => {
    if (formData.schoolName) {
      axios
        .get(schools.getClasses(formData.schoolName), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setClasses(res.data.data.grades);
          // console.log(res);
          setLabel(addStudents(schoolsData, res.data.data.grades, []));

          // setLabel2(LinkClass(res.data.data.grades,[],[]));
        })
        .catch((err) => console.log(err));
    }
  }, [formData.schoolName]);

  useEffect(() => {
    if (formData.class) {
      let temp = classes.find((item) => item.uniqueGrade === formData.class);
      setLabel(addStudents(schoolsData, classes, temp.sections));
    }
  }, [formData.class]);

  const handleOnClick = (data) => {
    // console.log(data);
    navigation(
      `/dashboard/${data.schoolName}/${data.schoolId}/student/${data._id}`
    );
  };
  const getStudents = () => {
    axios
      .get(Student.getStudents(page + 1, rowsPerPage), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setStudents(res.data.data.students);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getStudents();
    axios
      .get(Student.getStudentsCount, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setTotalCount(res.data.data.totalStudents);
        }
      });
    axios
      .get(schools.getSchoolsLmt(150), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setSchoolsData(res.data.data.schools);
        setLabel(addStudents(res.data.data.schools, [], []));
      })
      .catch((err) => console.log(err));
  }, [render]);
  // console.log(students);
  useEffect(() => {
    if (students.length) {
      if (sortBy.length) {
        let temp = students;
        for (let i = 0; i < sortBy.length; i++) {
          const { field, type } = sortBy[i];
          if (type === "asc") {
            temp.sort((a, b) => {
              let name1, name2;

              if (field === "expiryDate" || field === "signUpDate") {
                name1 = new Date(a[field]);
                name2 = new Date(b[field]);
              } else {
                name1 = a[field].toLowerCase().split(" ").join("");
                name2 = b[field].toLowerCase().split(" ").join("");
              }

              if (name1 < name2) return -1;
              if (name1 > name2) return 1;
              return 0;
            });
            // console.log(temp);

            // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
          } else {
            temp.sort((a, b) => {
              let name1, name2;

              if (field === "expiryDate" || field === "signUpDate") {
                name1 = new Date(a[field]);
                name2 = new Date(b[field]);
              } else {
                name1 = a[field].toLowerCase().split(" ").join("");
                name2 = b[field].toLowerCase().split(" ").join("");
              }
              if (name1 < name2) return 1;
              if (name1 > name2) return -1;
              return 0;
            });
            // console.log(temp);

            // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
          }
        }
        console.log(temp);
        setTabContent(
          temp.map((item) => {
            return {
              _id: item._id,
              email: item.email,
              contact: item.contact,
              section: item.section,
              expiryDate: new Date(item.expiryDate).toLocaleDateString(),
              name: item.name,
              class: item.grade,
              signUpDate: new Date(item.signUpDate).toLocaleDateString(),
              schoolId: item?.school?._id,
              schoolName: item?.school?.name,
              del: (
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteStudent(item._id);
                  }}
                  color="secondary"
                />
              ),

              backgroundColor: true,
              enable: (
                <Switch
                  onClick={(e) => {
                    e.stopPropagation();
                    toogleDisabled(item._id);
                  }}
                  style={{ zIndex: "100000" }}
                  checked={item.enabled}
                  color="primary"
                  className="enableIcon"
                />
              ),
              reset: (
                <CachedOutlinedIcon
                  className="resetPassword"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetPassword(item?._id);
                  }}
                />
              ),
            };
          })
        );
      } else {
        setTabContent(
          students.map((item) => {
            return {
              name: item.name,
              email: item.email,
              class: item.grade,
              contact: item.contact,
              _id: item._id,
              section: item.section,
              expiryDate: new Date(item.expiryDate).toLocaleDateString(),
              signUpDate: new Date(item.signUpDate).toLocaleDateString(),
              schoolId: item?.school?._id,
              backgroundColor: true,
              schoolName: item?.school?.name,
              del: (
                <DeleteIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteStudent(item._id);
                  }}
                  color="secondary"
                />
              ),
              enable: (
                <Switch
                  onClick={(e) => {
                    e.stopPropagation();
                    toogleDisabled(item._id);
                  }}
                  style={{ zIndex: "100000" }}
                  checked={item.enabled}
                  color="primary"
                  className="enableIcon"
                />
              ),
              reset: (
                <CachedOutlinedIcon
                  className="resetPassword"
                  onClick={(e) => {
                    e.stopPropagation();
                    resetPassword(item?._id);
                  }}
                />
              ),
            };
          })
        );
      }
    }
  }, [students]);
  // console.log(students);

  const exportCSV = () => {
    setExportingData(true);
    axios
      .get(ExportData.exportStudent, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = [];
        // console.log(res);
        data = res.data.data.students.map((item) => {
          // console.log(item);
          return {
            "Student Name": item?.name,
            "School Name": item?.school?.name,
            Branch: item?.school?.branch,
            Email: item?.email,
            "Contact:": item?.contact,
            Role: item?.role,
            "Grade-Section": item?.grade + "-" + item?.section,
            "SignUp Date": new Date(item?.signUpDate).toLocaleDateString(),
            "Expiry Date": new Date(item?.expiryDate).toLocaleDateString(),
            Enabled: item.enabled,
          };
        });
        let fileName = "Students";
        let exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });

        setExportingData(false);
      })
      .catch((err) => console.log(err));
    // let data=students.map((item)=>{
    //   return{
    //     "Student Name":item.name,
    //     "School Name":item.school.name,
    //     "Branch":item.school.branch,
    //     "Email":item.email,
    //     "Contact:":item.contact,
    //     "Role":item.role,
    //     "Grade-Section":item.grade+'-'+item.section,
    //     "SignUp Date":new Date(item.signUpDate).toLocaleDateString(),
    //     "Expiry Date":new Date(item.expiryDate).toLocaleDateString(),
    //     "Enabled":item.enabled
    //   }
    // })
  };
  const toogleDisabled = (id) => {
    axios
      .patch(
        Student.toogleDisable(id),
        {},
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar(res.data.message, "success");
          //console.log(students);
          setStudents(
            students.map((item) =>
              item._id === id ? { ...item, enabled: !item.enabled } : item
            )
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  useEffect(() => {
    getStudents();
  }, [rowsPerPage, page, render]);

  const handleCreate = () => {
    const data = {
      schoolId: formData.schoolName,
      name: formData.name,
      grade: formData.class.split("-")[0],
      section: formData.section,
      contact: formData.contact,
      password: formData.password,
      expiryDate: new Date(formData.expiryDate),
      email: formData.email,
    };

    axios
      .post(Student.postData, data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        DisplaySnackbar("Student Created", "success");
        setRender(!render);
        setModal(false);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const deleteStudent = (id) => {
    axios
      .delete(Student.deleteStudent(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setRender(!render);
          DisplaySnackbar("Student Data Deleted successfully");
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  useEffect(() => {
    axios
      .get(Student.getFilterData(search), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setStudents(res.data.data.students);
        }
      })
      .catch((err) => console.log(err));
  }, [search]);
  const handleSort = (field, type) => {
    let find = sortBy.find((item) => item.field === field);
    if (find) {
      setSortBy(
        sortBy.map((item) =>
          item.field === field ? { field: field, type: type } : item
        )
      );
    } else {
      setSortBy([...sortBy, { field: field, type: type }]);
    }
    let temp = tabContent;

    if (type === "asc") {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setTabContent(temp);
    } else {
      temp.sort((a, b) => {
        let name1, name2;

        if (field === "expiryDate" || field === "signUpDate") {
          name1 = new Date(a[field]);
          name2 = new Date(b[field]);
        } else {
          name1 = a[field].toLowerCase().split(" ").join("");
          name2 = b[field].toLowerCase().split(" ").join("");
        }

        if (name1 < name2) return 1;
        if (name1 > name2) return -1;
        return 0;
      });
      // console.log(temp);

      // console.log(tabContent.sort((a,b)=>{return a.name>b.name}));
      setTabContent(temp);
    }
  };

  const resetPassword = (id) => {
    setLoading(true);
    axios
      .get(Student.resetPassword(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Password Reset");
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
        setLoading(false);
      });
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
  };
  return (
    <StudentPageContainer>
      <DashboardHeaderWrapper>
        <DashboardHeader>
          <div>
            <h1>Students</h1>
          </div>

          <div>
            <InputComponent
              val={search}
              setVal={setSearch}
              type="text"
              search
              placeholder="Search..."
            />
            <img src={ProfileImg} alt="profileimg" />
          </div>
        </DashboardHeader>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: "-50px",
            marginLeft: "-25px",
          }}
        >
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <DashboardHeader>
          <div
            style={{ cursor: "pointer", fontWeight: "500" }}
            onClick={() => {
              setModal(true);
            }}
          >
            <AddBoxOutlinedIcon /> Add Student
          </div>
          <Headerfeatures
            handleSort={handleSort}
            sortField={sortField}
            viewBy={false}
            exportFile={exportCSV}
          />
        </DashboardHeader>
      </DashboardHeaderWrapper>
      <Line />
      {loading && <Loader type="spin" color="cyan" />}
      <StudentsPageContent>
        <TableComponent
          handleOnClick={handleOnClick}
          tabContent={tabContent}
          tabHeader={StudentsPageData}
        />
      </StudentsPageContent>
      {modal && (
        <AddModalComponent
          handleCreate={handleCreate}
          formData={formData}
          setFormData={setFormData}
          label={label}
          headerText="Add Student"
          setModal={setModal}
        />
      )}
      {exportingData && <DocumentLoading />}
    </StudentPageContainer>
  );
}

export default StudentsPage;
