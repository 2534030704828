import React, { useEffect, useState } from "react";
import { GraphContainer } from "./Analytics.style";
import { analytics } from "../../Api/api";
import axios from "axios";
import { useAuth } from "../../Auth/Auth";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import { GraphHeader, GraphContent } from "./Analytics.style";
import exportFromJSON from "export-from-json";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
} from "recharts";

function LineGraph() {
  const { readCookie } = useAuth();
  const [liningGraphData, setLiningGraphData] = useState();

  const fillMissingData = (from, hour, data) => {
    const newdata = [];
    for (let i = 0; i < hour; i++) {
      const hour = new Date(from + i * 60 * 60 * 1000).getHours();
      const dataIndex = data.findIndex((dt) => dt._id === hour);
      newdata.push(dataIndex > -1 ? data[dataIndex] : { _id: hour, users: 0 });
    }
    return newdata;
  };

  useEffect(() => {
    axios
      .get(analytics.getuser, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          // console.log(res);
          const data = res?.data?.data;
          setLiningGraphData(
            fillMissingData(data?.from, data?.hours, data?.userActivities)
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const exportCsv = () => {
    const data = liningGraphData.map((item) => ({
      Time: `${item._id}:00`,
      Users: item.users,
    }));
    const fileName = "UserActivity";
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data, fileName, exportType });
  };
  return (
    <GraphContainer minHeight="438px">
      <GraphHeader>
        <div>
          <h1 style={{ position: "relative" }}>
            User Activity{" "}
            <CachedOutlinedIcon
              onClick={() => window.location.reload()}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "7px",
                right: "-16px",
              }}
            />
          </h1>
          <p>Active Users in last 12 hours</p>
        </div>
        <div
          onClick={() => {
            exportCsv();
          }}
          style={{
            cursor: "pointer",
            position: "relative",
            fontSize: "1.5rem",
          }}
        >
          <SystemUpdateAltOutlinedIcon
            style={{
              position: "absolute",
              top: "8px",
              left: "-25px",
              transform: "rotate(-90deg)",
            }}
          />{" "}
          Export
        </div>
      </GraphHeader>
      <GraphContent>
        <div className="label">No. of Users</div>
        <ResponsiveContainer
          height="auto"
          style={{ height: "80%" }}
          aspect="2.5"
        >
          <ComposedChart
            width={800}
            height={300}
            data={liningGraphData}
            margin={{ top: 25, right: 30, left: -30, bottom: 5 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="10%" stopColor="#5CE0D2" stopOpacity={0.8} />
                <stop offset="90%" stopColor="#f7f7f7" stopOpacity={0.6} />
              </linearGradient>
            </defs>
            <XAxis
              fontSize={14}
              fontWeight={800}
              strokeOpacity={0}
              dataKey="_id"
            />
            <YAxis fontSize={14} fontWeight={800} strokeOpacity={0} />
            <Tooltip />
            <CartesianGrid horizontal={false} vertical={false} stroke="#DDD" />

            <Line
              type="monotone"
              strokeLinecap="round"
              strokeWidth={2}
              stroke="#5CE0D2"
              dataKey="users"
              dot={true}
              legendType="none"
            />

            <Area
              type="monotone"
              dataKey="users"
              stroke={false}
              strokeWidth={2}
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </GraphContent>
      <div style={{ textAlign: "center", fontWeight: "500" }}>Time</div>
    </GraphContainer>
  );
}

export default LineGraph;
