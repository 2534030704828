import React from "react";
import { AnalyticsContainer } from "./AnalyticsHelper.style";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Bar,
  ResponsiveContainer,
  LabelList,
  Legend,
} from "recharts";
import ReactLoading from "react-loading";

const data = [
  {
    name: "Hindi",
    knowledge: 40,
    understanding: 24,
    analysis: 30,
    application: 40,
  },
  {
    name: "Science",
    knowledge: 40,
    understanding: 24,
    analysis: 30,
    application: 40,
  },
];
function OnlineDifficulty(props) {
  const { dataField, colors, data, heading, classes, loading } = props;
  /// console.log(props)
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let temp = 0;
    data.forEach((item) => {
      // temp+=item[dataField[0]]+item[dataField[1]]+item[dataField[2]];
      temp +=
        item["Total Easy"] +
        item["Total Intermediate"] +
        item["Total Difficult"];
    });
    setTotal(temp);
  }, [data]);

  return (
    <AnalyticsContainer>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading type="spin" color="cyan" height={100} width={100} />
        </div>
      ) : (
        <>
          {" "}
          <div className="yaxis">Questions</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "1.3rem", fontWeight: "600" }}>
              {heading} {classes}
            </div>
            <div>Questions -{total}</div>
          </div>
          <div style={{ width: "100%", height: "320px" }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={300}
                height={250}
                data={data}
                // barGap={2}
                barSize={26}
              >
                <CartesianGrid strokeDasharray="3 6" />
                <XAxis
                  tick={{ fill: "rgba(0, 0, 0)" }}
                  dataKey="Subject"
                  allowDataOverflow={true}
                  style={{ fontWeight: "600", color: "black" }}
                >
                  {/* <Label value="Subjects" position="insideBottom"/> */}
                </XAxis>

                <YAxis allowDataOverflow={true} allowDecimals={false} />
                <Tooltip cursor={{ fill: "#fff" }} />
                <Bar dataKey={"Easy Correct"} stackId="a" fill="#5CE0D2">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Easy Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(125, 230, 219, 1)" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Easy"} stackId="a" fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Easy"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )} */}
                </Bar>

                <Bar
                  dataKey={"Intermediate Correct"}
                  stackId="b"
                  fill="#FF7E7E"
                >
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Intermediate Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(255, 152, 152, 1)" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Intermediate"} stackId={"b"} fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Intermediate"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Difficult Correct"} stackId="c" fill="#56E58F">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Difficult Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="rgba(120, 234, 165, 1)" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Difficult"} stackId="c" fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Difficult"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#ccc" ></Cell> )} */}
                </Bar>
                <Legend
                  payload={[
                    {
                      id: "easy",
                      value: "Easy",
                      type: "rect",
                      color: "#5CE0D2",
                    },
                    {
                      id: "medium",
                      value: "Intermediate",
                      type: "rect",
                      color: "#FF7E7E",
                    },
                    {
                      id: "difficult",
                      value: "Difficult",
                      type: "rect",
                      color: "#56E58F",
                    },
                  ]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </AnalyticsContainer>
  );
}

OnlineDifficulty.defaultProps = {
  data: [],

  loading: false,
};
export default OnlineDifficulty;
