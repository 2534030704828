import React, { useState, useEffect } from "react";
import {
  GraphContainer,
  GraphHeader,
  ActiveUsersContent,
  UsersList,
} from "./Analytics.style";
import socketIOClient from "socket.io-client";
function ActiveUsers() {
  const socket = socketIOClient(`${process.env.REACT_APP_BACKEND_ENDPOINT}`);
  const [loginData, setLoginData] = useState([]);
  const [viewerData, setViewerData] = useState([]);
  const getActiveUsers = () => {
    socket.on("login", (data) => {
      // console.log(data);
      data["type"] = "login";
      setLoginData((loginData) => [...loginData, data]);
    });
    socket.on("pagevisit", (data) => {
      setLoginData((loginData) => [...loginData, data]);
    });
  };
  useEffect(() => {
    getActiveUsers();
  }, []);

  useEffect(() => {
    setViewerData(loginData.slice(0, 3));
  }, [loginData]);

  return (
    <GraphContainer minHeight="318px">
      <GraphHeader>
        <h2>Active Users</h2>
      </GraphHeader>
      <ActiveUsersContent>
        {viewerData.map((data, index) => {
          <div key={index}>
            <span className="circle">
              <span className="innerText">{data.name[0]}</span>
            </span>
          </div>;
        })}
        {loginData?.length > 3 && (
          <div>
            <span className="circle">
              <span className="innerText">{loginData.length - 3}</span>
            </span>
          </div>
        )}
        <UsersList>
          {loginData[0] &&
            loginData.map((data, index) =>
              data?.type === "login" ? (
                <div key={index}>{data.name}</div>
              ) : (
                <div key={index}>
                  {data.name} viewed {data.module} Module of {data.subject}{" "}
                  subject .
                </div>
              )
            )}
        </UsersList>
      </ActiveUsersContent>
    </GraphContainer>
  );
}

export default ActiveUsers;
