import React from 'react'

function AnanlyticsSvgComponent() {
    return (
        <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1875 11.375V0C16.7871 0 18.3164 0.300456 19.7754 0.901367C21.2344 1.50228 22.4912 2.31055 23.5459 3.32617C24.6006 4.3418 25.4399 5.55208 26.064 6.95703C26.688 8.36198 27 9.83464 27 11.375H15.1875ZM11.8125 26C10.2129 26 8.68359 25.6995 7.22461 25.0986C5.76562 24.4977 4.50879 23.6895 3.4541 22.6738C2.39941 21.6582 1.56006 20.4479 0.936035 19.043C0.312012 17.638 0 16.1654 0 14.625C0 13.0846 0.312012 11.612 0.936035 10.207C1.56006 8.80208 2.39941 7.5918 3.4541 6.57617C4.50879 5.56055 5.76562 4.75228 7.22461 4.15137C8.68359 3.55046 10.2129 3.25 11.8125 3.25V14.625H23.625C23.625 16.1654 23.313 17.638 22.689 19.043C22.0649 20.4479 21.2256 21.6582 20.1709 22.6738C19.1162 23.6895 17.8594 24.4977 16.4004 25.0986C14.9414 25.6995 13.4121 26 11.8125 26Z" fill="#56E58F"/>
        </svg>
        
    )
}

export default AnanlyticsSvgComponent;
