import React, { useState, useEffect } from "react";
import {
  PracticeAssessmentContainer,
  PracticeAssessmentHeader,
  PracticeAssessmentContent,
  PracticeAssessmentTable,
} from "./practiceAssesment.style";
import StudentSvgComponent from "../../Svg/Student.component";
import AnalyticsTab from "../AnalyticsHelper/AnalyticsTab";
import ChaptersSvgComponent from "../../Svg/Chapters.component";
import QuestionSvgComponent from "../../Svg/Question.component";
// import TestSvgComponent from "../../Svg/Test.component";
import TopicsSvgComponent from "../../Svg/Topics.component";
// import AnalyticsContainer1 from "../AnalyticsHelper/AnalyticsContainer1";
// import AnalyticsContainer2 from "../AnalyticsHelper/AnalyticsContainer2";
import AnalyticsContainer3 from "../AnalyticsHelper/AnalyticsContainer3";
// import AnalyticsContainer4 from "../AnalyticsHelper/AnalyticsContainer4";
// import AnalyticsContainer5 from "../AnalyticsHelper/AnalyticsContainer5";
import AnalyticsContainer6 from "../AnalyticsHelper/AnalyticsContainer6";
// import AnalyticsContainer7 from "../AnalyticsHelper/AnalyticsContainer7";
import QuestionAttemptPieChart from "../AnalyticsHelper/QuestionAttemptPieChart";
import { FilterComponent } from "../AnalyticsHelper/Sidebar/DropDown";
import Sidebar from "../AnalyticsHelper/Sidebar/Sidebar";
import QuestionAttemptStatus from "../AnalyticsHelper/AnalyticsQuestionStatus";
import HeaderPage from "../../Header/HeaderPage";
import { useLocation } from "react-router-dom";
import { Line } from "../../Dashboard/Dashboard.style";
import { useAuth } from "../../../Auth/Auth";
import axios from "axios";
import {
  Practice,
  PracticeFilterByStudent,
} from "../../../Api/PracticeAnalytics";

import {
  PracticeAssesment,
  PracticeAssesmentData,
} from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader";

import AssessmentReport from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTable";
import PracticeLearningStatusAndGap from "../AnalyticsHelper/PracticeLearningStatusAndGap";
import { analytics } from "../../../Api/api";
const getTime = (time) => {
  return `${Math.ceil(time / (1000 * 60))} min`;
};

function PracticeAssessment() {
  const label = (stu, que, ch, tp) => [
    {
      Icon: StudentSvgComponent,
      heading: "Students",
      count: stu || 0,
    },

    {
      Icon: QuestionSvgComponent,
      heading: "Questions",
      count: que || 0,
    },
    {
      Icon: ChaptersSvgComponent,
      heading: "Chapters",
      count: ch || 0,
    },
    {
      Icon: TopicsSvgComponent,
      heading: "Topics",
      count: tp || 0,
    },
  ];
  const [show, setShow] = useState(false);
  const search = useLocation().search;
  const { readCookie } = useAuth();
  const [overViewData, setOverViewData] = useState(label());
  const schoolName = new URLSearchParams(search).get("schoolName");
  const schoolId = new URLSearchParams(search).get("schoolId");
  const [completeStatusGraph, setCompleteStatus] = useState([]);
  const [learningStatus, setLearningStatus] = useState([]);
  const [student, setStudent] = useState("");
  const [filterBySubject, setFilterBySubject] = useState({ subject: "" });
  const [tabData, setTabData] = useState([]);
  const [tabConent, setTabContent] = useState([]);
  const [loading, setLoading] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [formData, setFormData] = useState({
    class: "",
    subject: "",
    chapter: "",
  });
  const [questionAttempt, setQuestionAttemp] = useState([]);
  const [questionAttemptPieChart, setQuestionAttemptPieChart] = useState([]);
  const [totalQuestion, setTotalQuestion] = useState(0);
  const [cognitive, setCognitive] = useState([]);
  const [difficulty, setDifficulty] = useState([]);
  const [subject, setSubject] = useState([]);
  //   console.log(fileUrl);

  const leftHeader = () => {
    return (
      <span
        style={{
          fontWeight: "600",
          padding: "5px",
          margin: "30px 0px",
          background:
            "linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)",
        }}
      >
        Practice Assessment
      </span>
    );
  };
  const overView = () => {
    axios
      .get(Practice.overView(schoolId, formData.class.sectionId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics[0];
        if (!res.data?.data.analytics.length) {
          setOverViewData(0, 0, 0, 0);
        }
        setOverViewData(
          label(data?.students, data?.questions, data?.chapters, data?.topics)
        );
      })
      .catch((err) => console.log(err));
  };

  const completeStatus = () => {
    let str;

    if (student) {
      str = PracticeFilterByStudent.completeStatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = Practice.completeStatus(schoolId, formData.class.sectionId);
    }
    loading[0] = true;
    setLoading([...loading]);
    axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let temp = [];
        temp = res.data.data.questionsCompleteStats.map((item) => {
          return {
            name: item._id.name,
            Pending: item.pending,
            Done: item.completed,
          };
        });
        setCompleteStatus([...temp]);
        loading[0] = false;
        setLoading([...loading]);
      })
      .catch((err) => console.log(err));
  };

  const questionAttemptStatus = () => {
    let str;
    loading[1] = true;
    setLoading([...loading]);
    if (student) {
      str = PracticeFilterByStudent.questionAttemptStatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = Practice.questionAttemptStatus(schoolId, formData.class.sectionId);
    }
    axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        setQuestionAttemp(
          data.map((item) => {
            return {
              name: item?.subject?.name,
              Correct: item?.correct,
              Incorrect: item?.incorrect,
              Unattempted: item?.unattempted,
            };
          })
        );
        loading[1] = false;
        setLoading([...loading]);
      })
      .catch((err) => console.log(err));
  };

  const QuestionAttemptStatusPieChart = async () => {
    let str;
    loading[2] = true;
    setLoading([...loading]);
    if (student) {
      str = PracticeFilterByStudent.questionAttempPieChart(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = Practice.questionAttempPieChart(schoolId, formData.class.sectionId);
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        let temp = 0;
        // temp += data.correct + data.incorrect + data.unattempted;
        let tempLevel = [
          {
            name: "Correct",
            value: 0,
          },
          {
            name: "Incorrect",
            value: 0,
          },
          {
            name: "Unattempted",
            value: 0,
          },
        ];

        data.forEach((item) => {
          tempLevel[0].value += item.correct;
          tempLevel[1].value += item.incorrect;
          tempLevel[2].value += item.unattempted;
          temp += item.correct + item.incorrect + item.unattempted;
        });
        setTotalQuestion(temp);
        setQuestionAttemptPieChart([...tempLevel]);
        loading[2] = false;
        setLoading([...loading]);
      })
      .catch((err) => console.log(err));
  };

  const getQuestionLevelStatus = () => {
    let str;
    loading[3] = true;
    setLoading([...loading]);
    if (student) {
      str = PracticeFilterByStudent.questionLevelStatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = Practice.questionLevelStatus(schoolId, formData.class.sectionId);
    }

    axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        setCognitive(
          data.map((item) => {
            return {
              name: item.subject.name,
              Knowledge: item.cognitive.knowledge.total,
              Understanding: item.cognitive.understanding.total,
              Analysis: item.cognitive.analysis.total,
              Application: item.cognitive.application.total,
            };
          })
        );
        setDifficulty(
          data.map((item) => {
            return {
              name: item.subject.name,
              Easy: item.difficulty.easy.total,
              Medium: item.difficulty.intermediate.total,
              Difficult: item.difficulty.hard.total,
            };
          })
        );
        loading[3] = false;
        setLoading([...loading]);
      })
      .catch((err) => console.log(err));
  };

  const getLearninStatus = async () => {
    let str;
    loading[4] = true;
    setLoading([...loading]);
    if (student) {
      str = PracticeFilterByStudent.learningStatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = Practice.learningStatus(schoolId, formData.class.sectionId);
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        let gap = [];
        let strength = [];
        if (data[0]?._id === "gap") {
          let con = data[0].contents;
          // console.log(con);
          gap = con.map((item) => {
            return {
              name: item?.content?.name,
              Score: item?.avgScore,
              type: "Gap",
            };
          });
        }
        if (data[0]?._id === "strength") {
          let con = data[0].contents;
          strength = con.map((item) => {
            return {
              name: item?.content?.name,
              Score: item?.avgScore,
              type: "Strength",
            };
          });
        }
        if (data[1]?._id === "gap") {
          let con = data[1].contents;
          // console.log(con);
          gap = con.map((item) => {
            return {
              name: item?.content?.name,
              Score: item?.avgScore,
              type: "Gap",
            };
          });
        }
        if (data[1]?._id === "strength") {
          let con = data[1].contents;
          strength = con.map((item) => {
            return {
              name: item?.content?.name,
              Score: item?.avgScore,
              type: "Strength",
            };
          });
        }
        setLearningStatus([...gap, ...strength]);
        loading[4] = false;
        setLoading([...loading]);
      })
      .catch((err) => console.log(err));
  };

  const getReports = async () => {
    let str;
    if (student) {
      str = PracticeFilterByStudent.classReports(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = Practice.classReports(schoolId, formData.class.sectionId);
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })

      .then((res) => {
        let temp = [];
        temp = res.data.data.reports.map((item, idx) => {
          return {
            sno: idx + 1,
            subject: item?.subjectId?.name,
            // chaptersdone:3,
            // topicdone:5,
            timespent: getTime(item?.timeSpend),
            questionsdone: item?.questionsAttempted,
            correct: item?.correct,
            incorrect: item?.incorrect,
            easy: item?.easy,
            medium: item?.intermediate,
            difficult: item?.hard,
            chapterName: item?.chapterId?.name,
            studentName: item?.studentId?.name,
            grade: `${item?.studentId?.grade}-${item?.studentId?.section}`,
            subjectName: item?.subjectId?.name,
            topicName: item?.topicId?.name,
            questions: item?.questions,
            score: item?.score,
            // knowledge:12,
            // understanding:9,
            // analysis:10,
            // application:20,
            // "1stattempt":12,
            // "2ndattempt":10
          };
        });
        setTabContent(() => [...temp]);
        setTabData(() => [...temp]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (formData.class) {
      const getData = async () => {
        await overView();
        await completeStatus();
        await questionAttemptStatus();
        await QuestionAttemptStatusPieChart();
        await getQuestionLevelStatus();
        await getLearninStatus();
        await getReports();
      };
      getData();
    }
  }, [formData, student]);
  //   console.log(completeStatusGraph);
  const handleStudent = (stu) => {
    // console.log(stu);
    if (!stu) {
      setStudent("");
    } else setStudent(stu._id);
  };

  useEffect(() => {
    if (filterBySubject.subject) {
      // console.log(filterBySubject);
      let temp = tabData.filter(
        (item) => item.subjectName === filterBySubject?.subject?.name
      );
      setTabContent(() =>
        temp.map((item, idx) => {
          return { ...item, sno: idx + 1 };
        })
      );
    }
  }, [filterBySubject]);
  return (
    <PracticeAssessmentContainer>
      <HeaderPage
        currentPage="/school"
        leftHeader={leftHeader()}
        school="true"
        title={schoolName}
        profilePic={false}
      />
      <Line style={{ marginBottom: "30px" }} />
      <PracticeAssessmentHeader>
        <div className="tabContainer">
          {overViewData.length &&
            overViewData.map((item) => {
              return <AnalyticsTab data={item} />;
            })}
        </div>
      </PracticeAssessmentHeader>
      <div style={{ padding: "0px 30px" }}>
        <PracticeAssessmentContent>
          <AnalyticsContainer3
            colors={["#7DE6DB", "#C4C4C4"]}
            data={completeStatusGraph}
            dataField={["Pending", "Done"]}
            classes={formData?.class?.name}
            loading={loading[0]}
          ></AnalyticsContainer3>
          <QuestionAttemptStatus
            data={questionAttempt}
            heading={"Question Status Report of Class"}
            classes={formData?.class?.name}
            loading={loading[1]}
          />
          {/* <QuestionAttemptStatus data={questionAttempt} /> */}
        </PracticeAssessmentContent>
        <PracticeAssessmentContent>
          <QuestionAttemptPieChart
            total={totalQuestion}
            data02={questionAttemptPieChart}
            field={["Correct", "Incorrect", "Unattempted"]}
            colors={[
              "rgb(15, 147, 168)",
              "rgb(255, 137, 137)",
              "rgb(157, 171, 221)",
            ]}
            classes={formData?.class?.name}
            loading={loading[2]}
          ></QuestionAttemptPieChart>
          {/* <AnalyticsContainer3>

                </AnalyticsContainer3> */}
          {/* <AnalyticsContainer4></AnalyticsContainer4>
           */}
          <QuestionAttemptStatus
            data={difficulty}
            dataField={["Easy", "Medium", "Difficult"]}
            heading="Question Level Report of Class"
            classes={formData?.class?.name}
            loading={loading[2]}
          />
        </PracticeAssessmentContent>
        <PracticeAssessmentContent>
          <AnalyticsContainer6
            classes={formData?.class?.name}
            data={cognitive}
            loading={loading[3]}
          ></AnalyticsContainer6>
          <PracticeLearningStatusAndGap
            data={learningStatus}
            loading={loading[4]}
          />
        </PracticeAssessmentContent>
      </div>
      <PracticeAssessmentTable>
        <div
          style={{ display: "flex", marginLeft: "50px", marginBottom: "10px" }}
        >
          <FilterComponent
            name="subject"
            formData={filterBySubject}
            setFormData={setFilterBySubject}
            options={subject}
            placeholder="subject"
            cond="subjectId"
          />
          {/* <FilterComponent placeholder="name" /> */}
        </div>
        <div>
          <AssessmentReport
            headerText="Class Report"
            tabHeader={PracticeAssesment}
            tabContent={tabConent}
          />
        </div>
      </PracticeAssessmentTable>
      <Sidebar
        show={show}
        formData={formData}
        setFormData={setFormData}
        type="test"
        setShow={setShow}
        schoolId={schoolId}
        handleStudent={handleStudent}
        activeStudent={student}
        setSubjectData={setSubject}
        isSubject={false}
        isChapter={false}
      />
    </PracticeAssessmentContainer>
  );
}

export default PracticeAssessment;
