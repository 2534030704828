import React from "react";
import { AnalyticsContainer } from "./AnalyticsHelper.style";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  Cell,
  Legend,
  LabelList,
} from "recharts";
import ReactLoading from "react-loading";

// const barColors = ["#7CB4A0", "#ECBE7A", "#B6E4FF","#9DABDD","#FF8989","#5CE0D2","#CCACFF"]
// const data= [
//     {
//         name: 'Page A',
//         uv: 40,
//         pv: 80,
//         amt: 2400,
//       },
//       {
//         name: 'Page B',
//         uv: 40,
//         pv: 80,
//         amt: 2210,
//       },
//       {
//         name: 'Page C',
//         uv: 40,
//         pv: 80,
//         amt: 2290,
//       },
//       {
//         name: 'Page D',
//         uv: 40,
//         pv: 80,
//         amt: 2000,
//       },
//       {
//         name: 'Page E',
//         uv: 40,
//         pv: 80,
//         amt: 2181,
//       },
//       {
//         name: 'Page F',
//         uv: 40,
//         pv: 80,
//         amt: 2500,
//       },
//       {
//         name: 'Page G',
//         uv: 40,
//         pv: 80,
//         amt: 2100,
//       },
//   ]
function AnalyticsContainer3(props) {
  const {
    data,
    dataField,
    colors,
    headerText,
    subHeading,
    yAxis,
    total,
    classes,
    loading,
  } = props;

  //console.log(data);
  const [totalQues, setTotal] = React.useState(0);

  React.useEffect(() => {
    let temp = 0;
    data.forEach((item) => {
      temp += item.Pending + item.Done;
    });
    setTotal(temp);
  }, [data]);

  return (
    <AnalyticsContainer>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading type="spin" color="cyan" height={100} width={100} />
        </div>
      ) : (
        <>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "1.3rem", fontWeight: "600" }}>
              {headerText} {classes}
            </div>
            <div>
              {subHeading} {total || totalQues}
            </div>
          </div>
          {/* <div className='yaxis'>
          {yAxis}
          </div> */}
          <div style={{ width: "100%", height: "320px" }}>
            <ResponsiveContainer>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis
                  tick={{ fill: "rgba(0, 0, 0)" }}
                  dataKey="name"
                  style={{ fontWeight: "600", color: "black" }}
                />
                <Legend style={{ fontWeight: "600" }} />
                <YAxis
                  tick={{ fill: "rgba(0, 0, 0)" }}
                  label={{
                    value: yAxis,
                    angle: -90,
                    position: "insideLeft",
                    fontWeight: "600",
                  }}
                  allowDecimals={false}
                />
                <Tooltip cursor={{ fill: "#fff" }} />

                <Bar
                  barSize={26}
                  dataKey={dataField[1]}
                  stackId="a"
                  fill={colors[0]}
                >
                  <LabelList
                    style={{
                      color: "#000",
                      fill: "rgba(0, 0, 0)",
                      fontWeight: "600",
                      fontSize: ".7rem",
                      fontFamily: "Roboto, sans-serif",
                    }}
                    dataKey={dataField[1]}
                    position="middle"
                  />
                </Bar>
                <Bar
                  barSize={26}
                  dataKey={dataField[0]}
                  stackId="a"
                  fill={colors[1]}
                >
                  <LabelList
                    style={{
                      color: "#000",
                      fill: "rgba(0, 0, 0)",
                      fontWeight: "600",
                      fontSize: ".7rem",
                      fontFamily: "Roboto, sans-serif",
                    }}
                    dataKey={dataField[0]}
                    position="top"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </AnalyticsContainer>
  );
}

AnalyticsContainer3.defaultProps = {
  data: [
    {
      name: "Page A",
      uv: 40,
      pv: 80,
      amt: 2400,
    },
    {
      name: "Page B",
      uv: 40,
      pv: 80,
      amt: 2210,
    },
    {
      name: "Page C",
      uv: 40,
      pv: 80,
      amt: 2290,
    },
    {
      name: "Page D",
      uv: 40,
      pv: 80,
      amt: 2000,
    },
    {
      name: "Page E",
      uv: 40,
      pv: 80,
      amt: 2181,
    },
    {
      name: "Page F",
      uv: 40,
      pv: 80,
      amt: 2500,
    },
    {
      name: "Page G",
      uv: 40,
      pv: 80,
      amt: 2100,
    },
  ],
  dataField: ["uv", "pv"],
  colors: ["#7DE6DB", "#C4C4C4"],
  headerText: "Test status of class",
  classes: "",
  subHeading: "Total Question",
  yAxis: "Questions",
  loading: false,
};
export default AnalyticsContainer3;
