const findMainModuleFile = (resources, mainFileTypes, data) => {
  for (let resource of resources) {
    const nameParts = resource.fileName?.split(".");
    const fileType = nameParts[nameParts.length - 1].toLowerCase();
    if (mainFileTypes.includes(fileType)) {
      data["notFound"] = false;
      data["resource"] = resource;
      data["fileType"] = fileType;
      break;
    }
  }
  return data;
};

//Function to return the file type
const getType = (type) => {
  if (["png", "jpg", "jpeg", "gif"].includes(type)) {
    return "others";
  } else if (["pdf", "text", "ppt", "pptx", "docx", "doc"].includes(type)) {
    return "worksheets";
  } else if (["mp4", "mkv", "webm", "m4v"].includes(type)) {
    return "videos";
  } else if (type === "html") {
    return "activities";
  }
  return "others";
};

export function sortByDateInAsc(mods) {
  return mods.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
}

const getModuleType = (resources) => {
  var result = { notFound: true };
  const modulesTypes = [
    ["html"],
    ["mp4", "mkv", "m4v"],
    ["pptx", "ppt", "docx", "pdf"],
    ["mp3"],
    ["png", "jpg", "jpeg"],
  ];
  var move = 0,
    lastStep = modulesTypes.length - 1;
  while (result.notFound) {
    result = findMainModuleFile(resources, modulesTypes[move], result);
    if (move === lastStep) break;
    move += 1;
  }
  if (result.notFound) return "others";
  return getType(result.fileType);
};

export function sortModule(modules) {
  const sortedModules = {
    videos: [],
    worksheets: [],
    activities: [],
    others: [],
  };

  for (let module of modules) {
    const moduleType = getModuleType(module.resources);
    sortedModules[moduleType].push(module);
  }

  return [
    ...sortByDateInAsc(sortedModules.videos),
    ...sortByDateInAsc(sortedModules.activities),
    ...sortByDateInAsc(sortedModules.worksheets),
    ...sortByDateInAsc(sortedModules.others),
  ];
}
