import styled from 'styled-components'
export const Wrapper=styled.div`


${({ relative})=> relative&&`position:relative;`}




${({width})=>width&&`width:${width};`}
.child{

    display:flex;
    flex-direction:row;
    justify-content:space-between;
    margin-top:${({margintop})=>margintop?margintop:"20px"};
    div{
    //    ${({flex})=>flex&&`flex:1;`}
      
        // margin-right:8px;
        
        
    }
}
.editIcon{
    position:absolute;
    top:30px;
    display:flex;
    cursor:pointer;
    align-items:center;
    right:20px;
}


`
export const LabelComponent=styled.div`
color:#969696;
margin-bottom:6px;


`