import styled from "styled-components";

export const HomeWorkReportContainer=styled.div`
${({width})=>console.log(width)}
width:${({width})=>width?width:"48%"};
border: 1px solid #4BDF3F;
margin:20px 0px;
box-sizing: border-box;
box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
border-radius: 19px;
height:400px;
padding:20px;
padding-left:0px;
padding-bottom:0px;

padding: 25px;
.header{
    display:flex;
    justify-content:space-between;
}

`
export const HomeWorkCardContainer=styled.div`
overflow-Y:auto;
max-height:320px;
&::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
    margin:20px 0px;
    
}

&::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #cccc; 
    border-radius: 10px;
    // background-color:cyan;
}

&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px cyan; 
}

`

export const Container=styled.div`

width:85%;
height:98px;
box-sizing:border-box;
margin:10px auto;
padding:3px;
background: #FFFFFF;
border: 1px solid #56E58F;
box-sizing: border-box;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
display:flex;
.firstDiv{
    flex:0.1;

}
overflow-Y:auto;

.secondDiv{
    flex:0.6;
    p{
        margin:0px;
        font-size:11px;
    }
    .time{
        display:flex; 
        font-size:11px;
        justify-content:space-between;

    }
}
.thirdDiv{
    flex:0.3;
    display:flex;
    justify-content:flex-end;
    align-items:center;
    div{
        height:80px;
        width:80px;
        
        text-align:end;
        margin-right:30px;
    }
}

`