import React, { useState } from "react";

import { ButtonWrapper, Button } from "../../Dashboard/Dashboard.style";
import { ModalWrapper, ModalContainer } from "../../Dashboard/Dashboard.style";
import { Header, AddModulesContainer } from "../Modal.style";

import DropzoneComponent from "../../Dropzone/DropzoneComponent";
import DocumentLoading from "../../Loading/ExportLoader";

import Uploader from "./Uploader";
import { useRef } from "react";

const AddResources = (props) => {
  const { setModal, moduleId, setResources } = props;

  const [loading, setLoading] = useState(false);
  const [uploader, setUploader] = useState(false);

  const files = useRef();

  const [thumbnail, setThumbnail] = useState("");
  const [video, setVideo] = useState("");
  const [file, setFile] = useState("");
  const [audio, setAudio] = useState("");

  const handleAdd = async () => {
    if (!moduleId) return;
    setLoading(false);
    files.current = {};
    //set files
    if (thumbnail) files.current["thumbnail"] = thumbnail;
    if (video) files.current["video"] = video;
    if (audio) files.current["audio"] = audio;
    if (file) files.current["file"] = file;
    setUploader(true);
    //Open Uploader Component
  };

  return (
    <ModalWrapper>
      <ModalContainer style={{ minWidth: "400px" }}>
        <Header>
          <h2>Add Resources</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setThumbnail} fileType="image" />{" "}
            <span>{thumbnail ? thumbnail.name : "thumbnail"}</span>
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setVideo} fileType="video" />{" "}
            <span>{video ? video.name : "video"}</span>
          </div>
        </AddModulesContainer>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setFile} fileType="file" />
            <span>{file ? file.name : "file"}</span>
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setAudio} fileType="audio" />
            <span>{audio ? audio.name : "audio"}</span>
          </div>
        </AddModulesContainer>

        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          <Button onClick={() => handleAdd()}>Add</Button>
        </ButtonWrapper>
        {/*Update This Loader*/}
        {loading && (
          <DocumentLoading text="Please Wait your data is being uploaded" />
        )}
        {uploader && (
          <Uploader
            setModal={setUploader}
            files={files.current}
            moduleId={moduleId}
            setResources={setResources}
            resourceLevel={true}
          />
        )}
      </ModalContainer>
    </ModalWrapper>
  );
};

export default AddResources;
