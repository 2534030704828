import React,{useState} from 'react';
import {PracticeAssessmentContainer,PracticeAssessmentHeader,PracticeAssessmentContent,PracticeAssessmentTable} from '../PracticeAssessment/practiceAssesment.style';
import {AnalyticsTabs} from '../AnalyticsHelper/AnalyticsHelper.style';
import AnalyticsContainer2 from '../AnalyticsHelper/AnalyticsContainer2';
import { StudentAttendanceHeader,StudentAttendanceData } from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader';
import AssessmentReport from '../AnalyticsHelper/AnanlyticsReport/AnalyticsTable';
import Sidebar from '../AnalyticsHelper/Sidebar/Sidebar';
function StudentAttendance() {
    const label=[
        {
            heading:"Total Lectures",
            count:"07",

        },{
            heading:"Present Lectures",
            count:"05",

        },{
            heading:"Absent Lectures",
            count:"02"
        },{
            heading:"Attendance %",
            count:"80%"

        }

    ]
    const[show,setShow]=useState(false);
    return (
        <PracticeAssessmentContainer>
             <PracticeAssessmentHeader>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h5 style={{fontWeight:"600",padding:"5px",background:"linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)"}}>Today's Attendance</h5>
                    <h5 style={{background:"rgba(86, 229, 143, 0.72)",fontWeight:"600",padding:"5px",borderRadius: "5px"}}>Overall Report</h5>

                </div>

                <div className='tabContainer'>
                {
                    label.length&&label.map((item)=>{
                        return(<AnalyticsTabs style={{flexDirection:"column"}}>
                            <div style={{fontSize:"13px",fontWeight:"600",textAlign:"center"}}>{item.heading}</div>
                            <h5 style={{textAlign:"center",fontWeight:"600",fontSize:"1.1rem"}}>{item.count}</h5>
                        </AnalyticsTabs>)
                    })
                }
                </div>
            </PracticeAssessmentHeader>
            <PracticeAssessmentContent style={{justifyContent:"center"}}>
                    <AnalyticsContainer2 text={false} field={["Present","Absent"]} colors={["#56E58F","#FF8989"]} heading="Average Attendance Report" subHeading="Total Subjects - 07"/>
            </PracticeAssessmentContent>
            <PracticeAssessmentTable>
                    <AssessmentReport tabHeader={StudentAttendanceHeader} tabContent={StudentAttendanceData} />
            </PracticeAssessmentTable>
            {/* <Sidebar show={show} setShow={setShow}/> */}
        </PracticeAssessmentContainer>
    )
}

export default StudentAttendance;
