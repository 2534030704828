import styled from 'styled-components';


export const ResourcesWrapper=styled.div`

.link{
    &:hover{
        color:green;
    }
}
`