import React, { useState } from "react";

import axios from "axios";

import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header } from "./Modal.style";
import { DropDown } from "../Input/InputComponent";
import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";

import { courses, subjects, chapter, Module } from "../../Api/api";
import { useAuth } from "../../Auth/Auth";
import { useEffect } from "react";

function CopyContentModal({ setModal, destination }) {
  const { readCookie, DisplaySnackbar } = useAuth();
  const [sourceId, setSourceId] = useState(null);
  const [countries, setCountries] = useState({
    name: "country",
    options: [{ name: "Country", value: "" }],
  });
  const [boards, setBoards] = useState({
    name: "board",
    options: [{ name: "Board", value: "" }],
  });
  const [classes, setCourses] = useState({
    name: "class",
    options: [{ name: "Course", value: "" }],
  });
  const [courseSubjects, setSubjects] = useState({
    name: "subject",
    options: [{ name: "Subject", value: "" }],
  });
  const [chapters, setChapters] = useState({
    name: "chapters",
    options: [{ name: "Chapter", value: "" }],
  });
  const [topics, setTopics] = useState({
    name: "topics",
    options: [{ name: "Topic", value: "" }],
  });
  const [modules, setModules] = useState({
    name: "module",
    options: [{ name: "Module", value: "" }],
  });
  const [formData, setFormData] = useState({});

  const getModuleType = (resources) => {
    var result = { notFound: true, move: 0 };
    const modulesTypes = [
      ["html"],
      ["mp4", "mkv", "m4v", "avi"],
      ["pptx", "ppt", "docx", "pdf"],
      ["mp3"],
      ["png", "jpg", "jpeg"],
    ];

    let lastStep = modulesTypes.length - 1;
    while (result.notFound) {
      result = findMainModuleFile(resources, modulesTypes[result.move], result);
      if (result.move === lastStep) break;
      result.move += 1;
    }
    return result;
  };

  const findMainModuleFile = (resources, mainFileTypes, data) => {
    for (let resource of resources) {
      const nameParts = resource.fileName?.split(".");
      const fileType = nameParts[nameParts.length - 1].toLowerCase();
      if (mainFileTypes.includes(fileType)) {
        data["notFound"] = false;
        data["resource"] = resource;
        data["fileType"] = fileType;
        break;
      }
    }
    return data;
  };

  const getCategory = (fileType) => {
    if (["html"].includes(fileType)) return "Activity";
    if (["mp4", "mkv", "m4v", "avi"].includes(fileType)) return "Video";
    if (["pptx", "ppt", "docx", "pdf"].includes(fileType)) return "Worksheet";
    if (["mp3"].includes(fileType)) return "Audio";
    if (["png", "jpg", "jpeg"].includes(fileType)) return "Image";
  };

  const mapModule = (module) => {
    const res = getModuleType(module.resources);
    return {
      name: `${module.name} (${getCategory(res.fileType)})`,
      value: module._id,
      disabled: res.notFound ? true : module.disabled,
    };
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!destination.id) {
      DisplaySnackbar("Destination not selected", "error");
      return;
    }

    if (!sourceId) {
      DisplaySnackbar("Please select module", "info");
      return;
    }

    DisplaySnackbar("Please wait coyping module", "info");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/v1/contents${destination.api}`,
        {
          sourceId,
          destinationId: destination.id,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      );

      if (res.data.status !== "success") {
        DisplaySnackbar(res.data.message, "error");
        return;
      }
      DisplaySnackbar("Content Copied!", "success");
      destination.setContent(res.data.data.module);
      closeModal();
    } catch (err) {
      DisplaySnackbar(err.message, "error");
    }
  };

  const closeModal = () => {
    setFormData({}); // Reset form data
    setModal(false);
  };

  //Fetch Country Data
  useEffect(() => {
    axios
      .get(courses.getAllCountry, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setCountries({
          name: "country",
          options: [
            { name: "Country", value: "" },
            ...res.data.data.countries.map((item) => {
              return { name: item.name, value: item._id };
            }),
          ],
        });
      })
      .catch((err) => console.log(err));
  }, []);

  //Fetch Board Data
  useEffect(() => {
    if (formData.country) {
      axios
        .get(courses.getCountryBoard(formData.country), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setBoards({
            name: "board",
            options: [
              { name: "Board", value: "" },
              ...res.data.data.country.boards.map((item) => {
                return { name: item.name, value: item._id };
              }),
            ],
          });
          formData.board = "";
          setFormData({ ...formData });
        })
        .catch((err) => console.log(err));
    } else {
      setBoards({
        name: "board",
        options: [{ name: "Board", value: "" }],
      });
      formData.board = "";
      setFormData({ ...formData });
    }
  }, [formData.country]);

  //Fetch Courses
  useEffect(() => {
    if (formData.board) {
      axios
        .get(courses.getBoardContent(formData.board), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setCourses({
            name: "class",
            options: [
              { name: "Course", value: "" },
              ...res.data.data.board.courses.map((item) => {
                return { name: item.name, value: item._id };
              }),
            ],
          });
          formData.class = "";
          setFormData({ ...formData });
        })
        .catch((err) => console.log(err));
    } else {
      setCourses({
        name: "class",
        options: [{ name: "Course", value: "" }],
      });
      formData.class = "";
      setFormData({ ...formData });
    }
  }, [formData.board]);

  //Fetch Subjects
  useEffect(() => {
    if (formData.class) {
      axios
        .get(subjects.getSubjectsByClass(formData.class), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setSubjects({
            name: "subject",
            options: [
              { name: "Subject", value: "" },
              ...res.data.data.course.subjects.map((item) => {
                return { name: item.name, value: item._id };
              }),
            ],
          });
          formData.subject = "";
          setFormData({ ...formData });
        })
        .catch((err) => console.log(err));
    } else {
      setSubjects({
        name: "subject",
        options: [{ name: "Subject", value: "" }],
      });
      formData.subject = "";
      setFormData({ ...formData });
    }
  }, [formData.class]);

  //Fetch Chapters
  useEffect(() => {
    if (formData.subject) {
      axios
        .get(subjects.getTopicBySubjects(formData.subject), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setChapters({
            name: "chapter",
            options: [
              { name: "Chapter", value: "" },
              ...res.data.data.subject.chapters.map((item) => {
                return { name: item.name, value: item._id };
              }),
            ],
          });
          formData.chapter = "";
          setFormData({ ...formData });
        })
        .catch((err) => console.log(err));
    } else {
      setChapters({
        name: "chapter",
        options: [{ name: "Chapter", value: "" }],
      });
      formData.chapter = "";
      setFormData({ ...formData });
    }
  }, [formData.subject]);

  //Fetch Topics
  useEffect(() => {
    if (formData.chapter) {
      axios
        .get(chapter.getTopicsByChapter(formData.chapter), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setTopics({
            name: "topic",
            options: [
              { name: "Topic", value: "" },
              ...res.data.data.chapter.topics.map((item) => {
                return { name: item.name, value: item._id };
              }),
            ],
          });
          formData.topic = "";
          setFormData({ ...formData });
        })
        .catch((err) => console.log(err));
    } else {
      setTopics({
        name: "topic",
        options: [{ name: "Topic", value: "" }],
      });
      formData.topic = "";
      setFormData({ ...formData });
    }
  }, [formData.chapter]);

  //Fetch Module
  useEffect(() => {
    if (formData.topic) {
      axios
        .get(Module.getModuleByTopics(formData.topic), {
          headers: {
            token: `${readCookie("token")}`,
          },
        })
        .then((res) => {
          setModules({
            name: "module",
            options: [
              { name: "Module", value: "" },
              ...res.data.data.topic.modules
                .map((item) => mapModule(item))
                .filter((item) => !item.disabled),
            ],
          });
          console.log(modules);
          formData.module = "";
          setFormData({ ...formData });
        })
        .catch((err) => console.log(err));
    } else {
      setModules({
        name: "module",
        options: [{ name: "Module", value: "" }],
      });
      formData.module = "";
      setFormData({ ...formData });
    }
  }, [formData.topic]);

  useEffect(() => {
    if (formData.module) {
      setSourceId(formData.module);
    } else {
      setSourceId(null);
    }
  }, [formData.module]);

  return (
    <ModalWrapper>
      <ModalContainer>
        <Header>
          <h2>Copy Module</h2>
          <h2 onClick={() => closeModal()} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <p>
          Select module to copy in{" "}
          <b style={{ color: "#5CE0D2" }}>{destination.name}</b>
        </p>
        <div style={{ display: "flex", justifyContent: "space-betweeb" }}>
          <DropDown
            data={countries}
            value={formData.country}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem", width: "45%" }}
            color="grey"
            placeholder="Country"
            height="36px"
          />
          &nbsp;&nbsp;
          <DropDown
            data={boards}
            value={formData.board}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem", width: "45%" }}
            color="grey"
            placeholder="Board"
            height="36px"
          />
        </div>
        <div style={{ marginTop: "11px" }}>
          <DropDown
            data={classes}
            value={formData.class}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem" }}
            color="grey"
            placeholder="Select Course"
            height="36px"
          />
        </div>
        <div style={{ marginTop: "11px" }}>
          <DropDown
            data={courseSubjects}
            value={formData.subject}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem" }}
            color="grey"
            placeholder="Select Subject"
            height="36px"
          />
        </div>
        <div style={{ marginTop: "11px" }}>
          <DropDown
            data={chapters}
            value={formData.chapter}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem" }}
            color="grey"
            placeholder="Select Chapter"
            height="36px"
          />
        </div>
        <div style={{ marginTop: "11px" }}>
          <DropDown
            data={topics}
            value={formData.topic}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem" }}
            color="grey"
            placeholder="Select Topic"
            height="36px"
          />
        </div>
        <div style={{ marginTop: "11px" }}>
          <DropDown
            data={modules}
            value={formData.module}
            handleOnChange={handleOnChange}
            style={{ fontSize: ".9rem" }}
            color="grey"
            placeholder="Select Module"
            height="36px"
          />
        </div>
        <ButtonWrapper>
          <Button onClick={() => closeModal()}>CANCEL</Button>
          <Button
            onClick={handleSubmit}
            style={{
              opacity: sourceId ? 1 : 0.5,
              border: `1px solid ${sourceId ? "#5CE0D2" : "grey"}`,
            }}
          >
            COPY
          </Button>
        </ButtonWrapper>
      </ModalContainer>
    </ModalWrapper>
  );
}

export default CopyContentModal;
