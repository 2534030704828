import styled from "styled-components";

export const DashboardHeader = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    margin: 0;
  }
  div {
    display: flex;
    align-items: center;
  }
  img {
    margin-left: 15px;
  }
  .iconContainer {
    padding: 8px 15px;
    border-radius: 5px;
    &:hover {
      background-color: #5ce0d2;
    }
  }
`;

export const DashboardContent = styled.div``;

export const DashboardContainer = styled.div`
  flex: 1;
`;

export const DashboardHeaderWrapper = styled.div`
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "150px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Line = styled.div`
  border-top: 1px solid #dbdad5;
`;
export const SchoolsContainer = styled.div`
display:flex;
justify-content:flex-start;

flex-wrap:wrap;


flex-wrap;
.cardWrapper{
    // position:relative;
    width:25%;
    margin:10px 20px;
    
    // z-index:1;
}
.enableIcon{
    // position:absolute;
    // right:10px;
    top:-10px;
    
    
}

`;

export const SchoolFormPage = styled.div`
  flex: 1;
`;
export const SchoolFormPageHeader = styled.div`
  margin-bottom: 16px;
  .ft {
    display: flex;
    justify-content: space-between;
    .firstChild {
      font-size: 2rem;
      font-weight: 500;
    }
  }
`;
export const SchoolFormPageContent = styled.div`
  margin-top: 16px;
  display: flex;
  .firstChild {
    display: flex;
    flex-direction: column;
  }
  .SecondChild {
    flex: 1;
    padding-left: 100px;
  }
`;

export const Tab = styled.div`
  margin-top: 20px;
  padding: 5px 8px;
  cursor: pointer;
  border: 1px solid rgba(92, 224, 210, 0.26);
  border-radius: 6px;
  ${({ constwidth }) =>
    constwidth &&
    `
    box-sizing:border-box;
    max-width:180px;
    word-break:break-all;

    // display:flex;
    // align-items:center;
    // justify-content:center;
`}
  ${({ constwidthtab }) =>
    constwidthtab &&
    `
box-sizing:border-box;
    max-width:200px;
    word-break:break-all;

    `}
    ${({ constdesign }) =>
    constdesign &&
    `display: flex;
align-items: center;
justify-content: space-between;`}
${({ active }) =>
    active &&
    `
    // border:1px solid cyan;
    box-shadow: rgb(92 224 210) 0px 0px 15px;
    

`}
`;

export const ListContainer = styled.div``;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1000;
  background-color: rgba(26, 25, 25, 0.199);
`;
export const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;

  border-radius: 10px;
  padding: 20px;
`;

export const AddSchoolWrapper = styled.div`
  min-width: 480px;
`;
export const AddSchoolContainer = styled.div`
  .formDiv {
    display: flex;

    box-sizing: border-box;
    margin-top: 4px;
    justify-content: space-between;
    label {
      margin-bottom: 6px;
    }
    > div {
      box-sizing: border-box;
      width: 47%;
    }
  }

  .date {
    margin-top: 4px;
    label {
      margin-bottom: 6px;
    }
  }
`;
export const AddSchoolHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  // justify-content:flex-end;
  justify-content: space-between;
`;
export const Button = styled.button`
  background-color: transparent;
  border: 1px solid #5ce0d2;
  padding: 8px 50px;
  font-size: 1rem;

  cursor: pointer;
  border-radius: 8px;
  width: 47%;
  transition: 0.5s;
  margin-top: 20px;
  &:hover {
    background-color: #5ce0d2;
  }
`;
