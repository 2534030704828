import React, { useState } from "react";
import Card from "../../Card/Card";
import { SchoolsContainer } from "../Dashboard.style";
import { useNavigate } from "react-router-dom";
import { ListContainer } from "../Dashboard.style";
import RenderListView from "./RenderListView";
import axios from "axios";
import { useAuth } from "../../../Auth/Auth";
import { schools } from "../../../Api/api";

function Schools({
  view,
  allSchools,
  toogleDisable,
  handleEdit,
  handleupdate,
}) {
  const navigate = useNavigate();
  const { readCookie, DisplaySnackbar } = useAuth();

  const handleOnClick = (id, name) => {
    navigate(`/dashboard/${name}/${id}?tab=details`);
  };

  const handleDelete = (id) => {
    axios
      .delete(schools.deleteSchools(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("School deleted", "success");
          handleupdate(id);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  return (
    <>
      {view === "card" ? (
        <SchoolsContainer>
          {allSchools.map((item, idx) => {
            return (
              <div className="cardWrapper" key={idx}>
                <Card
                  handleEdit={handleEdit}
                  toogleDisable={toogleDisable}
                  handleDelete={() => handleDelete(item._id)}
                  handleOnClick={handleOnClick}
                  key={item._id}
                  id={item._id}
                  {...item}
                />
              </div>
            );
          })}
        </SchoolsContainer>
      ) : (
        <ListContainer>
          <RenderListView tabContent={allSchools} />
        </ListContainer>
      )}
    </>
  );
}

export default Schools;
