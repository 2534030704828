import React from "react";
import { AnalyticsContainer } from "./AnalyticsHelper.style";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  Bar,
  ResponsiveContainer,
  LabelList,
  Legend,
} from "recharts";
import ReactLoading from "react-loading";

const data = [
  {
    name: "Hindi",
    knowledge: 40,
    understanding: 24,
    analysis: 30,
    application: 40,
  },
  {
    name: "Science",
    knowledge: 40,
    understanding: 24,
    analysis: 30,
    application: 40,
  },
];
function OnlineCognitiveReport(props) {
  const { dataField, colors, data, heading, classes, loading } = props;
  ///console.log(props)
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    let temp = 0;
    data.forEach((item) => {
      // temp+=item[dataField[0]]+item[dataField[1]]+item[dataField[2]];
      temp +=
        item["Total Understanding"] +
        item["Total Analysis"] +
        item["Total Application"] +
        item["Total Knowledge"];
    });
    setTotal(temp);
  }, [data]);

  return (
    <AnalyticsContainer>
      {loading ? (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading type="spin" color="cyan" height={100} width={100} />
        </div>
      ) : (
        <>
          {" "}
          <div className="yaxis">Questions</div>
          <div style={{ textAlign: "center" }}>
            <div style={{ fontSize: "1.3rem", fontWeight: "600" }}>
              {heading} {classes}
            </div>
            <div>Questions -{total}</div>
          </div>
          <div style={{ width: "100%", height: "320px" }}>
            <ResponsiveContainer>
              <BarChart
                width={300}
                height={250}
                data={data}
                // barGap={2}
                barSize={26}
              >
                <CartesianGrid strokeDasharray="1 1" />
                {/* <CartesianGrid strokeDasharray="3 6" /> */}
                <XAxis
                  tick={{ fill: "rgba(0, 0, 0)" }}
                  dataKey="Subject"
                  allowDataOverflow={true}
                  style={{ fontWeight: "600", color: "#000" }}
                >
                  {/* <Label value="Subjects" offset={isSmall?-25:-30} position="insideBottom"/> */}
                </XAxis>
                {/* <Brush dataKey="Subject" height={10} endIndex={data.length-1} /> */}
                <YAxis allowDecimals={false} />
                <Tooltip cursor={{ fill: "#fff" }} />
                <Bar dataKey={"Knowledge Correct"} stackId={"a"} fill="#5CE0D2">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Knowledge Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#5CE0D2" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Knowledge"} stackId={"a"} fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Knowledge"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )} */}
                </Bar>
                <Bar
                  dataKey={"Understanding Correct"}
                  stackId={"b"}
                  fill="#EDA1FF"
                >
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Understanding Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#EDA1FF" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Understanding"} stackId={"b"} fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Understanding"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Analysis Correct"} stackId={"c"} fill="#56E58F">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Analysis Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#56E58F" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Analysis"} stackId={"c"} fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Analysis"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )} */}
                </Bar>
                <Bar
                  dataKey={"Application Correct"}
                  stackId={"d"}
                  fill="#FF6A99"
                >
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Application Correct"}
                    position="middle"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#FF6A99" ></Cell> )} */}
                </Bar>
                <Bar dataKey={"Total Application"} stackId={"d"} fill="#cccc">
                  <LabelList
                    style={{ fontWeight: "600", fontSize: ".7rem" }}
                    dataKey={"Total Application"}
                    position="top"
                  />
                  {/* {data.map((item,index)=> <Cell key={`cell-${index}`} fill="#cccc" ></Cell> )} */}
                </Bar>
                <Legend
                  payload={[
                    {
                      id: "easy",
                      value: "Knowledge",
                      type: "rect",
                      color: "#5CE0D2",
                    },
                    {
                      id: "medium",
                      value: "Understanding",
                      type: "rect",
                      color: "#EDA1FF",
                    },
                    {
                      id: "difficult",
                      value: "Analysis",
                      type: "rect",
                      color: "#56E58F",
                    },
                    {
                      id: "application",
                      value: "Application",
                      type: "rect",
                      color: "#FF6A99",
                    },
                  ]}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </AnalyticsContainer>
  );
}

OnlineCognitiveReport.defaultProps = {
  data: [],

  loading: false,
};
export default OnlineCognitiveReport;
