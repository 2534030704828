import React, { useState, useEffect } from "react";
import {
  PracticeAssessmentContainer,
  PracticeAssessmentHeader,
  PracticeAssessmentContent,
  PracticeAssessmentTable,
} from "../PracticeAssessment/practiceAssesment.style";
import StudentSvgComponent from "../../Svg/Student.component";
import AnalyticsTab from "../AnalyticsHelper/AnalyticsTab";
import ChaptersSvgComponent from "../../Svg/Chapters.component";
import QuestionSvgComponent from "../../Svg/Question.component";
import TestSvgComponent from "../../Svg/Test.component";
import TopicsSvgComponent from "../../Svg/Topics.component";
import { useLocation } from "react-router-dom";
import AnalyticsContainer1 from "../AnalyticsHelper/AnalyticsContainer1";
import AnalyticsContainer2 from "../AnalyticsHelper/AnalyticsContainer2";
import AnalyticsContainer3 from "../AnalyticsHelper/AnalyticsContainer3";

import QuestionAttemptPieChart from "../AnalyticsHelper/QuestionAttemptPieChart";
import OnlineDifficulty from "../AnalyticsHelper/OnlineDifficulty";

import AnalyticsContainer7 from "../AnalyticsHelper/AnalyticsContainer7";
import {
  OnlineAnalytics,
  OnlineAnalyticsFilterByStudent,
} from "../../../Api/OnlineAnalytics";
import { useAuth } from "../../../Auth/Auth";
import OnlineCognitiveReport from "../AnalyticsHelper/OnlineCognitive";
import axios from "axios";
import Sidebar from "../AnalyticsHelper/Sidebar/Sidebar";
import HeaderPage from "../../Header/HeaderPage";
import { Line } from "../../Dashboard/Dashboard.style";
import {
  OnlineAssesmentHeader,
  onlineAssesmentData,
} from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader";

import AssessmentReport from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTable";
import { CalenderComponent } from "../AnalyticsHelper/Sidebar/DropDown";
import { FilterComponent } from "../AnalyticsHelper/Sidebar/DropDown";
import QuestionAttemptStatus from "../AnalyticsHelper/AnalyticsQuestionStatus";
// import { useLocation } from 'react-router-dom';

function OnlineAssessment() {
  const label = (stu, test, que, chp, top) => [
    {
      Icon: StudentSvgComponent,
      heading: "Students",
      count: stu || 0,
    },
    {
      Icon: TestSvgComponent,
      heading: "Tests",
      count: test || 0,
    },
    {
      Icon: QuestionSvgComponent,
      heading: "Questions",
      count: que || 0,
    },
    {
      Icon: ChaptersSvgComponent,
      heading: "Chapters",
      count: chp || 0,
    },
    {
      Icon: TopicsSvgComponent,
      heading: "Topics",
      count: top || 0,
    },
  ];
  const [show, setShow] = useState(false);
  const search = useLocation().search;
  const { readCookie } = useAuth();
  const [overViewData, setOverViewData] = useState(label());
  const [testAssigned, setTestAssigned] = useState([]);
  const [testAttempt, setTestAttemptStatus] = useState([]);
  const [cognitiveLevel, setCognitiveLevel] = useState([]);
  const [difficultyLevel, setDifficultyLevel] = useState([]);
  const [questionAttemptStatus, setQuestionAttempStatus] = useState([]);
  const [learningStatus, setLearningStatus] = useState([]);
  const [pieChartTestAssigned, setPieChartTestAssigned] = useState([]);
  const [QuestionStatusPieChart, setQuestionStatusPieChart] = useState([]);
  const [student, setStudent] = useState("");
  const [tabContent, setTabContent] = useState([]);
  const [tabData, setTabData] = useState([]);
  const [subjectData, setSubjectData] = useState([]);
  const [loading, setLoading] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const totaltest = React.useRef(0);
  const [filterByDate, setFilterByDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [filterBySubject, setFilterBySubject] = useState({
    subject: "",
  });
  const [formData, setFormData] = useState({
    class: "",
    subject: "",
    chapter: "",
    topic: "",
  });
  const schoolName = new URLSearchParams(search).get("schoolName");
  const schoolId = new URLSearchParams(search).get("schoolId");

  const leftHeader = () => {
    return (
      <span
        style={{
          fontWeight: "600",
          padding: "5px",
          margin: "30px 0px",
          background:
            "linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)",
        }}
      >
        Online Test
      </span>
    );
  };
  const overView = () => {
    let str;
    if (student) {
      str = OnlineAnalyticsFilterByStudent.overView(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.overView(schoolId, formData.class.sectionId);
    }
    axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics[0];
        if (data)
          setOverViewData(
            label(
              data.students,
              data.totalTests,
              data.totalQuestions,
              data.chapters,
              data.topics
            )
          );
      })
      .catch((err) => console.log(err));
  };

  const getTestAssigned = async () => {
    let str;
    loading[0] = true;
    setLoading([...loading]);
    if (student) {
      str = OnlineAnalyticsFilterByStudent.testAssigned(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.testAssigned(schoolId, formData.class.sectionId);
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        setTestAssigned(
          data.map((item) => {
            return {
              name: item?.subjectId?.name,
              Tests: item?.tests,
            };
          })
        );
      })
      .catch((err) => console.log(err));
    loading[0] = false;
    setLoading(() => [...loading]);
  };
  const testAttemptStatus = async () => {
    loading[2] = true;
    setLoading([...loading]);
    let str;
    if (student) {
      str = OnlineAnalyticsFilterByStudent.testAttemptStatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.testAttemptStatus(
        schoolId,
        formData.class.sectionId
      );
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        setTestAttemptStatus(
          data.map((item) => {
            return {
              name: item?.subjectId?.name,
              Done: item?.completed,
              Pending: item?.pending,
            };
          })
        );
      })
      .catch((err) => console.log(err));
    loading[2] = false;
    setLoading([...loading]);
  };

  const getQuestionAttemptStatus = async () => {
    let str;
    loading[3] = true;
    setLoading([...loading]);
    if (student) {
      str = OnlineAnalyticsFilterByStudent.QuestionAttemptStatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.QuestionAttemptStatus(
        schoolId,
        formData.class.sectionId
      );
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        setQuestionAttempStatus(
          data.map((item) => {
            return {
              name: item?.subjectId?.name,
              Correct: item?.totalCorrect,
              Incorrect: item?.totalIncorrect,
              Unattempted:
                item?.totalQuestions -
                (item?.totalCorrect + item?.totalIncorrect),
            };
          })
        );
      })
      .catch((err) => console.log(err));
    loading[3] = false;
    setLoading([...loading]);
  };

  const getavgCorrectQuestionLevelStatus = async () => {
    let str;
    loading[5] = true;
    setLoading([...loading]);
    if (student) {
      str = OnlineAnalyticsFilterByStudent.avgcorrectquestionlevelstatus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.avgcorrectquestionlevelstatus(
        schoolId,
        formData.class.sectionId
      );
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        let temp2 = [];
        let temp1 = [];
        data.forEach((item) => {
          const { cognitive, difficulty } = item;
          //console.log(difficulty, cognitive);
          temp1.push({
            Subject: item?.subjectId?.name,
            "Easy Correct": difficulty?.easy?.correct,
            "Total Easy": difficulty?.easy?.total,
            "Intermediate Correct": difficulty?.intermediate?.correct,
            "Total Intermediate": difficulty?.intermediate?.total,
            "Difficult Correct": difficulty?.hard?.correct,
            "Total Difficult": difficulty?.hard?.total,
          });

          temp2.push({
            Subject: item?.subjectId?.name,
            "Analysis Correct": cognitive?.analysis?.correct,
            "Total Analysis": cognitive?.analysis?.total,
            "Application Correct": cognitive?.application?.correct,
            "Total Application": cognitive?.application?.total,
            "Knowledge Correct": cognitive?.knowledge?.correct,
            "Total Knowledge": cognitive?.knowledge?.total,
            "Understanding Correct": cognitive?.understanding?.correct,
            "Total Understanding": cognitive?.understanding?.total,
          });
        });
        setCognitiveLevel([...temp2]);
        setDifficultyLevel([...temp1]);
      })
      .catch((err) => console.log(err));
    loading[5] = false;
    setLoading([...loading]);
  };

  const getLearningStatus = async () => {
    let str;
    loading[6] = true;
    setLoading([...loading]);
    if (student) {
      str = OnlineAnalyticsFilterByStudent.learningSttaus(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.learningSttaus(schoolId, formData.class.sectionId);
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        setLearningStatus(
          data.map((item) => {
            return {
              name: item?.subjectId?.name,
              strength: item?.strength,
              gap: item?.gap,
            };
          })
        );
      })
      .catch((err) => console.log(err));
    loading[6] = false;
    setLoading([...loading]);
  };

  const getTestAttemptPieChart = async () => {
    let str;
    loading[1] = true;
    setLoading([...loading]);
    if (student) {
      str = OnlineAnalyticsFilterByStudent.testAttemptStatusPieChart(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.testAttemptStatusPieChart(
        schoolId,
        formData.class.sectionId
      );
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics[0];
        totaltest.current = data?.completed + data?.pending;
        setPieChartTestAssigned([
          {
            name: "Done",
            value: data?.completed,
          },
          {
            name: "Pending",
            value: data?.pending,
          },
        ]);
      })
      .catch((err) => console.log(err));
    loading[1] = false;
    setLoading([...loading]);
  };

  const getPieChart = async () => {
    let str;
    loading[4] = true;
    setLoading([...loading]);
    if (student) {
      str = OnlineAnalyticsFilterByStudent.questionAttemptStatusPieChart(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.questionAttemptStatusPieChart(
        schoolId,
        formData.class.sectionId
      );
    }
    await axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics[0];
        setQuestionStatusPieChart([
          {
            name: "Correct",
            value: data?.totalCorrect,
          },
          {
            name: "Incorrect",
            value: data?.totalIncorrect,
          },
          {
            name: "Unattempted",
            value: data?.totalUnattempted,
          },
        ]);
      })
      .catch((err) => console.log(err));
    loading[4] = false;
    setLoading([...loading]);
  };

  const getTime = (tm) => {
    return `${parseFloat(tm / (1000 * 60)).toFixed(2)} min`;
  };

  const getReports = () => {
    let str;
    if (student) {
      str = OnlineAnalyticsFilterByStudent.classReports(
        schoolId,
        formData.class.sectionId,
        student
      );
    } else {
      str = OnlineAnalytics.classReports(schoolId, formData.class.sectionId);
    }

    axios
      .get(str, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let data = res.data.data.analytics;
        //   sno:1,
        // startDate:"12-11-2021",
        // endDate:"12-12-2021",
        // subject:"V",
        // chapter:"chap5",
        // topic:"topics",
        // testType:"sd",
        // date:"12-11-2021",
        // startTimeOfTest:"10:00",
        // timeSpent:"10min",
        // marks:100,
        // marksObtained:60,
        // classAverage:60,
        // correct:20,
        // inCorrect:10,
        // easy:10,
        // medium:20,
        // difficult:10,
        // knowledge:10,
        // understanding:10,
        // analysis:10,
        // application:10
        let temp = [];
        temp = data?.map((item, idx) => {
          return {
            sno: idx + 1,
            studentName: item?.studentId?.name,
            subject: item?.subjectId?.name,
            chapter: item?.chapters,
            topic: item?.topics,
            testType: item?.testType,
            startDate: new Date(item?.testId?.from).toLocaleDateString(),
            endDate: new Date(item?.testId?.to).toLocaleDateString(),
            date: new Date(item?.startedAt).toLocaleDateString(),
            startTimeOfTest: new Date(item?.startedAt).toLocaleTimeString(),
            grade: `${item?.studentId?.grade}-${item?.studentId?.section}`,

            classAverage: item?.totalScore,
            timeSpent: getTime(item?.timeSpent),
            marks: item?.totalMarks,
            marksObtained: item?.totalScore,
            correct: item?.correct,
            inCorrect: item?.incorrect,
            easy: item?.correctAttempt?.easy,
            medium: item?.correctAttempt?.intermediate,
            difficult: item?.correctAttempt?.hard,
            knowledge: item?.correctAttempt?.knowledge,
            understanding: item?.correctAttempt?.understanding,
            analysis: item?.correctAttempt?.analysis,
            application: item?.correctAttempt?.application,
          };
        });
        setTabContent([...temp]);
        setTabData([...temp]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (formData.class) {
      const getData = async () => {
        await overView();
        await getTestAssigned();
        await getTestAttemptPieChart();
        await testAttemptStatus();
        await getQuestionAttemptStatus();
        await getPieChart();

        await getavgCorrectQuestionLevelStatus();
        await getLearningStatus();
        await getReports();
      };
      getData();
    }
  }, [formData, student]);
  const handleStudent = (stu) => {
    console.log(stu);
    if (!stu) {
      setStudent("");
    } else {
      setStudent(stu?._id);
    }
  };
  //   console.log(overViewData);
  useEffect(() => {
    if (filterBySubject.subject) {
      //  console.log(filterBySubject);
      let temp = tabData.filter(
        (item) => item.subject === filterBySubject.subject.name
      );
      setTabContent(() =>
        temp.map((item, idx) => {
          return { ...item, sno: idx + 1 };
        })
      );
    }
  }, [filterBySubject]);
  useEffect(() => {
    let dt = tabData;
    //console.log(filterByDate);
    if (filterByDate.startDate) {
      dt = dt.filter(
        (item, idx) =>
          new Date(item?.startDate) >= new Date(filterByDate?.startDate)
      );
    }
    if (filterByDate.endDate) {
      dt = dt.filter(
        (item, idx) =>
          new Date(item?.endDate) <= new Date(filterByDate?.endDate)
      );
    }
    setTabContent(() =>
      dt.map((item, idx) => {
        return { ...item, sno: idx + 1 };
      })
    );
  }, [filterByDate]);
  return (
    <PracticeAssessmentContainer>
      <PracticeAssessmentHeader>
        <HeaderPage
          currentPage="/school"
          leftHeader={leftHeader()}
          school="true"
          title={schoolName}
          profilePic={false}
        />
        <Line style={{ marginBottom: "30px" }} />

        <div className="tabContainer">
          {overViewData.length &&
            overViewData.map((item) => {
              return <AnalyticsTab data={item} />;
            })}
        </div>
      </PracticeAssessmentHeader>
      <div style={{ padding: "0px 30px" }}>
        <PracticeAssessmentContent>
          <AnalyticsContainer1
            data={testAssigned}
            field={["Name", "Tests"]}
            yaxis="Tests"
            loading={loading[0]}
          ></AnalyticsContainer1>
          <AnalyticsContainer2
            loading={loading[1]}
            data02={pieChartTestAssigned}
            classes={formData?.class?.name}
          ></AnalyticsContainer2>
        </PracticeAssessmentContent>
        <PracticeAssessmentContent>
          <AnalyticsContainer3
            colors={["#7DE6DB", "#C4C4C4"]}
            data={testAttempt}
            subHeading="Total Test"
            dataField={["Pending", "Done"]}
            classes={formData?.class?.name}
            yAxis="Tests"
            loading={loading[2]}
          ></AnalyticsContainer3>
          {/* <AnalyticsContainer4>

                </AnalyticsContainer4> */}
          <QuestionAttemptStatus
            data={questionAttemptStatus}
            loading={loading[3]}
            heading={"Question Status Report of Class"}
            classes={formData?.class?.name}
            // loading={loading[1]}
          />
        </PracticeAssessmentContent>
        <PracticeAssessmentContent>
          <QuestionAttemptPieChart
            loading={loading[4]}
            classes={formData?.class?.name}
            data02={QuestionStatusPieChart}
            field={["Correct", "Incorrect", "Unattempted"]}
            colors={[
              "rgb(15, 147, 168)",
              "rgb(255, 137, 137)",
              "rgb(157, 171, 221)",
            ]}
            // classes={formData?.class?.name}
          />

          <OnlineDifficulty
            loading={loading[5]}
            data={difficultyLevel}
            classes={formData?.class?.name}
          />
        </PracticeAssessmentContent>
        <PracticeAssessmentContent>
          <OnlineCognitiveReport
            loading={loading[5]}
            data={cognitiveLevel}
            classes={formData?.class?.name}
          />
          <AnalyticsContainer7
            loading={loading[6]}
            data={learningStatus}
            classes={formData?.class?.name}
          ></AnalyticsContainer7>
        </PracticeAssessmentContent>
      </div>
      <PracticeAssessmentTable>
        <div
          style={{ display: "flex", marginLeft: "50px", marginBottom: "10px" }}
        >
          <FilterComponent
            name="subject"
            formData={filterBySubject}
            setFormData={setFilterBySubject}
            options={subjectData}
            placeholder="subject"
            cond="subjectId"
          />
          {/* <FilterComponent placeholder="StartDate" />
          <FilterComponent placeholder="EndDate" /> */}
          <CalenderComponent
            name="startDate"
            formData={filterByDate}
            setFormData={setFilterByDate}
            placeholder="Start Date"
          />
          <CalenderComponent
            name="endDate"
            formData={filterByDate}
            setFormData={setFilterByDate}
            placeholder="End Date"
          />
        </div>
        <AssessmentReport
          headerText="Test Report "
          tabHeader={OnlineAssesmentHeader}
          tabContent={tabContent}
        />
      </PracticeAssessmentTable>
      <Sidebar
        type="test"
        handleStudent={handleStudent}
        schoolId={schoolId}
        formData={formData}
        setFormData={setFormData}
        show={show}
        activeStudent={student}
        isSubject={false}
        isChapter={false}
        setSubjectData={setSubjectData}
        setShow={setShow}
      />
    </PracticeAssessmentContainer>
  );
}

export default OnlineAssessment;
