import React, { useState, useEffect } from "react";
import { TeacherSchedule } from "../../Table/TableHeaders";
import TableComponent from "../../Table/Table";
import { CommonHeader } from "../../Dashboard/TabComponent/TabComponent.style";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import Headerfeatures from "../../Header/Headerfeatures";
import { Line } from "../../Dashboard/Dashboard.style";
import { assignClassSubject } from "../../RenderDetailsData/RenderDetailsData";
//   import AssignClassandSubjects from "../../Modal/AssignClassandSubjects";

import axios from "axios";
import { useAuth } from "../../../Auth/Auth";
import {
  schools,
  subjectTeachers,
  RemovedAssignSubject,
} from "../../../Api/api";
import { useParams } from "react-router-dom";
import AddModalComponent from "../../Modal/AddModalComponent";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

function TeachersSchedule(props) {
  const [modal, setModal] = useState(false);
  const [label, setLabel] = useState([]);
  const { readCookie, DisplaySnackbar } = useAuth();
  const { schoolId, staffId } = useParams();
  const [grades, setGrades] = useState([]);
  const [sections, setSections] = useState([]);
  const [sectionsId, setSectionsId] = useState([]);
  const [teacherSchedule, setTeacherSchedule] = useState([]);
  const [render, setRender] = useState(false);
  const [tabContent, setTabContent] = useState([]);
  const [formData, setFormData] = useState({
    class: "",
    section: "",
    subject: "",
  });
  useEffect(() => {
    axios
      .get(schools.getClasses(schoolId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setGrades(res.data.data.grades);
        setLabel(assignClassSubject(res.data.data.grades, [], []));
      })
      .catch((err) => console.log(err));

    axios
      .get(subjectTeachers.getAssignedSubjects(staffId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setTeacherSchedule(res.data.data.sections);
        console.log(res);
      })
      .catch((err) => console.log(err));
  }, [render]);

  const handleMultiSelect = (type, id) => {
    if (type === "section") {
      if (sectionsId.includes(id)) {
        setSectionsId(sectionsId.filter((item) => item !== id));
      } else {
        setSectionsId([...sectionsId, id]);
      }
    }
  };

  const deleteSchedule = (subject, section) => {
    axios
      .delete(
        RemovedAssignSubject.removeAssignSubject(staffId, section, subject),
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "succcess") {
          setRender(!render);
          DisplaySnackbar("Deleted Assign Subject", "success");
        }
      })
      .catch((err) => {
        if (err.response) DisplaySnackbar(err.response.data.message, "error");
      });
  };
  useEffect(() => {
    if (teacherSchedule.length) {
      let temp = teacherSchedule.map((item) => {
        return item.subjects.map((subject, idx) => {
          return {
            sno: idx + 1,
            class: subject.grade,
            section: subject.section,
            subject: subject.subject,
            remove: (
              <DeleteOutlineIcon
                onClick={() => {
                  deleteSchedule(subject._id, subject.sectionId);
                }}
              />
            ),
          };
        });
      });
      let dt = [];
      temp.forEach((item) => {
        item.forEach((dat) => {
          dt.push(dat);
        });
      });

      setTabContent(
        dt.map((item, idx) => {
          return { ...item, sno: idx + 1 };
        })
      );
    }
  }, [teacherSchedule]);

  useEffect(() => {
    if (!modal) {
      setFormData({
        class: "",
        section: "",
        subject: "",
      });
      setSectionsId([]);
    }
  }, [modal]);

  const getSubjects = (subjects) => {
    return subjects
      .filter((subject) => subject.subjectId && !subject?.subjectId?.disabled)
      .map((sub) => ({
        subjectId: sub.subjectId._id,
        subject: `${sub.subject}  (${sub?.subjectId?.lang || "English"})`,
      }));
  };
  useEffect(() => {
    if (formData.class) {
      let temp = grades.find((item) => item.uniqueGrade === formData.class);
      setLabel(
        assignClassSubject(grades, temp.sections, getSubjects(temp.subjects))
      );
      setSections(temp.sections);
    }
  }, [formData.class]);
  const removeLangTag = (subjectName) => {
    if (!subjectName) return;
    return subjectName.split("(")[0].trim();
  };
  const handleCreate = () => {
    let data = {
      sections: sectionsId.map((item) => {
        return {
          sectionId: item,
          subjects: formData.subject.map((sub) => removeLangTag(sub)),
        };
      }),
    };

    axios
      .put(subjectTeachers.assignSubjects(staffId), data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setRender(!render);
          DisplaySnackbar("Assigned Subject and Class", "success");
          setModal(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  return (
    <div>
      <CommonHeader>
        <div
          onClick={() => setModal(true)}
          style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
        >
          <AddBoxOutlinedIcon style={{ marginRight: "7px" }} /> Add Subject
        </div>
        <Headerfeatures viewBy={false} />
      </CommonHeader>
      <Line />
      <TableComponent tabHeader={TeacherSchedule} tabContent={tabContent} />
      {modal && (
        <AddModalComponent
          handleMultiSelect={handleMultiSelect}
          handleCreate={handleCreate}
          formData={formData}
          setFormData={setFormData}
          setModal={setModal}
          label={label}
          headerText="Assign Class And Subject"
        />
      )}
    </div>
  );
}

export default TeachersSchedule;
