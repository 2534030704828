import React from 'react';
import {AnalyticsContainer} from './AnalyticsHelper.style';
import {BarChart,CartesianGrid,XAxis,YAxis,Tooltip,Cell,Bar,ResponsiveContainer,LabelList,Legend} from 'recharts';
import Loader from './Loader/Loader';

const data= [
    {
      "name": "Hindi",
      "strength": 40,
      "gap": 24,
      
    },
    {
      "name": "Science",
      
      "strength":30,
      "gap":40
    },]
function AnalyticsContainer7(props) {
  const {data,loading}=props;
    return (
        <AnalyticsContainer style={{marginBottom:"50px"}}>
          {loading?<Loader/>:<>
           <div className='learningStrength'>
            Strength & Gap(%)
          </div>
            <div style={{textAlign:"center"}}>
            <div style={{fontSize:"1.3rem",fontWeight:"600"}}>
            Learning Strength and Gap
            </div>
            
          </div>
          <div style={{width:"100%",height:"320px"}}>
                   <ResponsiveContainer>
            <BarChart width="100%" height="100%" data={data}>
  <CartesianGrid strokeDasharray="1 1" />
  <XAxis tick={{ fill: "rgba(0, 0, 0)" }} dataKey="name" style={{fontWeight:"600",color:"black"}} />
  <Legend style={{fontWeight:"600"}} />
  <YAxis allowDecimals={false} allowDataOverflow={true}  />
  <Tooltip cursor={{fill: '#fff'}} />
  
  <Bar barSize={26} dataKey="strength" fill="#5CE0D2" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500"}} dataKey="strength" position="middle" />
    </Bar>
 
  <Bar barSize={26} dataKey="gap" fill="#FF7E7E" >
    
     <LabelList style={{color:"#000",fill: "rgba(0, 0, 0)",fontWeight:"500"}} dataKey="gap" position="middle" />
    </Bar>
  
  
</BarChart>
</ResponsiveContainer>
</div>
</>}
        </AnalyticsContainer>
    )
}

AnalyticsContainer7.defaultProps={
  classes:"",
  loading:false
}
export default AnalyticsContainer7
