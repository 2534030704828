import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import Documents from "./Documents";
import Audio from "./Audio";
import Video from "./Video";
import Images from "./Images";
import Error from "./Error";

const getData = (type, file) => {
  if (type === "Document") {
    return <Documents file={file} />;
  } else if (type === "Image") {
    return <Images file={file} />;
  } else if (type === "Audio") {
    return <Audio file={file} />;
  } else if (type === "Video") {
    return <Video file={file} />;
  } else if (type === "html") {
    //console.log(file);
    window.location.replace(`${file}`);
    // window.location.replace(`https://${url}`)
  } else if (type === "Error") {
    return <Error />;
  }
};

function Media() {
  const [type, setType] = useState("");
  // console.log(fileUrl);
  const search = useLocation().search;
  const navigation = useNavigate();
  const fileUrl = new URLSearchParams(search).get("fileUrl");
  useEffect(() => {
    let fileType = fileUrl.split(".").at(-1);
    fileType = fileType.toLowerCase();
    // console.log()
    //console.log(fileType);
    if (["pptx", "ppt", "pdf", "txt", "docx"].includes(fileType)) {
      setType("Document");
    } else if (
      fileType === "png" ||
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "gif"
    ) {
      setType("Image");
    } else if (fileType === "mp3") {
      setType("Audio");
    } else if (
      fileType === "mp4" ||
      fileType === "mkv" ||
      fileType === "webm" ||
      fileType === "avi" ||
      fileType === "m4v"
    ) {
      setType("Video");
    } else if (fileType === "html") {
      setType("html");
    } else {
      setType("Error");
    }
  }, []);
  //  console.log(type);

  return <div>{type && getData(type, fileUrl)}</div>;
}

export default Media;
