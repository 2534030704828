import React,{useState} from "react";
import {
  PracticeAssessmentContainer,
  PracticeAssessmentHeader,
  PracticeAssessmentContent,
  PracticeAssessmentTable,
} from "../PracticeAssessment/practiceAssesment.style";
import { AnalyticsTabs } from "../AnalyticsHelper/AnalyticsHelper.style";
import AnalyticsContainer3 from "../AnalyticsHelper/AnalyticsContainer3";
import {
  ClassAttendanceHeader,
  ClassAttendanceData,
} from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTableHeader";
import AssessmentReport from "../AnalyticsHelper/AnanlyticsReport/AnalyticsTable";
import Sidebar from "../AnalyticsHelper/Sidebar/Sidebar";
import HeaderPage from '../../Header/HeaderPage';
import {useLocation} from 'react-router-dom';
import { Line } from '../../Dashboard/Dashboard.style';

const data = [
  {
    name: "Math",
    Present: 40,
    Absent: 10,
  },
  {
    name: "Science",
    Present: 40,
    Absent: 10,
  },
  {
    name: "English",
    Present: 40,
    Absent: 10,
  },
  {
    name: "Hindi",
    Present: 40,
    Absent: 10,
  },
  {
    name: "Social Science",
    Present: 40,
    Absent: 10,
  },
  {
    name: "Physical",
    Present: 40,
    Absent: 10,
  },
];

function OverAllAttendance() {
  const label = [
    {
      heading: "Total Lectures",
      count: "07",
    },
    {
      heading: "Present Lectures",
      count: "05",
    },
    {
      heading: "Absent Lectures",
      count: "02",
    },
    {
      heading: "Attendance %",
      count: "80%",
    },
  ];
  const [show,setShow]=useState(false);

  
  const leftHeader=()=>{

    return( <span
        style={{
          fontWeight: "600",
          padding: "5px",
          margin:"30px 0px",
          background:
            "linear-gradient(90deg, rgba(86, 229, 143, 0.55) 0%, rgba(92, 224, 210, 0) 100%)",
        }}
      >
        OverAll Attendance
      </span>)
}
const search = useLocation().search;
const schoolName= new URLSearchParams(search).get('schoolName');
  return (
    <PracticeAssessmentContainer>

      
      <PracticeAssessmentHeader>
      <HeaderPage currentPage='/school' leftHeader={leftHeader()} school="true" title={schoolName} profilePic={false}/>
                <Line style={{marginBottom:"30px"}}/>

        <div className="tabContainer">
          {label.length &&
            label.map((item) => {
              return (
                <AnalyticsTabs style={{ flexDirection: "column" }}>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "center",
                    }}
                  >
                    {item.heading}
                  </div>
                  <h5
                    style={{
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item.count}
                  </h5>
                </AnalyticsTabs>
              );
            })}
        </div>
      </PracticeAssessmentHeader>
      <PracticeAssessmentContent style={{ justifyContent: "center" }}>
        <AnalyticsContainer3
          colors={["#F28684", "#59E0B0"]}
          headerText="OverAll Attendance Report"
          subHeading="Total Subjects-07"
          data={data}
          dataField={["Present", "Absent"]}
          yAxis="Lectures"
        />
      </PracticeAssessmentContent>
      <PracticeAssessmentTable>
        <AssessmentReport
          tabHeader={ClassAttendanceHeader}
          tabContent={ClassAttendanceData}
        />
      </PracticeAssessmentTable>
      {/* <Sidebar show={show} setShow={setShow}/> */}
    </PracticeAssessmentContainer>
  );
}

export default OverAllAttendance;
