import React, { useEffect, useState } from "react";
import TableComponent from "../../Table/Table";
import { ListView } from "../../Table/TableHeaders";

function RenderListView({ tabContent }) {
  const [allSchools, setSchools] = useState([]);
  //console.log(tabContent);
  useEffect(() => {
    if (tabContent.length) {
      setSchools(
        tabContent.map((item) => {
          return {
            name: item.name,
            state: item.state,
            branch: item.branch,
            city: item.city,
            board: item.board,
            expiryDate: new Date(item.expiryDate).toLocaleDateString(),
            signUpDate: new Date(item.createdAt).toLocaleDateString(),
            studentCounts: item.studentCounts,
            teacherCounts: item.teacherCounts,
          };
        })
      );
    }
  }, [tabContent]);
  return <TableComponent tabHeader={ListView} tabContent={allSchools} />;
}

export default RenderListView;
