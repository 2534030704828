import React, { useState } from "react";

import { ModalWrapper, ModalContainer } from "../Dashboard/Dashboard.style";
import { Header, Container, AddModulesContainer } from "./Modal.style";
import DropzoneComponent from "../Dropzone/DropzoneComponent";
import { Input } from "../Input/InputComponent";
import { DropDown } from "../Input/InputComponent";
import { ButtonWrapper, Button } from "../Dashboard/Dashboard.style";
import { Module } from "../../Api/api";
import { useAuth } from "../../Auth/Auth";
import axios from "axios";
import DocumentLoading from "../Loading/ExportLoader";

const AddModules = (props) => {
  const { readCookie, DisplaySnackbar } = useAuth();
  const { setModal, activeId, setAllModule, allModule, lang } = props;
  const [thumbnail, setThumbnail] = useState("");
  const [video, setVideo] = useState("");
  const [file, setFile] = useState("");
  const [audio, setAudio] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
  });

  let data;
  const handleAdd = async () => {
    if (!formData.name) {
      DisplaySnackbar("Atleast Add Module Name", "error");
      return;
    }
    // setModal(false);
    setLoading(true);
    data = new FormData();
    data.append("thumbnail", thumbnail);
    data.append("video", video);
    data.append("file", file);
    data.append("audio", audio);
    data.append("lang", lang);
    data.append("name", formData.name);
    data.append("topicId", activeId);
    // for (var p of data) {
    //     console.log(p);
    //   }

    await axios
      .post(Module.addModules, data, {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        setAllModule([...allModule, res.data.data.module]);

        setLoading(false);
        setModal(false);
        DisplaySnackbar("Module Added", "success");
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false);
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
    setLoading(false);
  };
  console.log(data);
  const handleOnChange = (e) => {
    // console.log(e);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <ModalWrapper>
      <ModalContainer>
        <Header>
          <h2>Add Modules</h2>
          <h2 onClick={() => setModal(false)} style={{ cursor: "pointer" }}>
            ×
          </h2>
        </Header>
        <AddModulesContainer>
          <div style={{ width: "48%" }}>
            <Input
              name="name"
              handleOnChange={handleOnChange}
              fontsize=".9rem"
              placeholder="Enter Modules"
              height="32px"
            />
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setThumbnail} fileType="image" />{" "}
            <span>{thumbnail ? thumbnail.name : "thumbnail"}</span>
          </div>
        </AddModulesContainer>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setVideo} fileType="video" />{" "}
            <span>{video ? video.name : "video"}</span>
          </div>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setFile} fileType="file" />
            <span>{file ? file.name : "file"}</span>
          </div>
        </AddModulesContainer>
        <AddModulesContainer>
          <div style={{ width: "48%", display: "flex" }}>
            <DropzoneComponent setFile={setAudio} fileType="audio" />
            <span>{audio ? audio.name : "audio"}</span>
          </div>
        </AddModulesContainer>
        <ButtonWrapper>
          <Button onClick={() => setModal(false)}>CANCEL</Button>
          <Button onClick={() => handleAdd()}>Add</Button>
        </ButtonWrapper>
        {loading && (
          <DocumentLoading text="Please Wait your data is being uploaded" />
        )}
      </ModalContainer>
    </ModalWrapper>
  );
};

export default AddModules;
