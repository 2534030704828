import React, { useState, useEffect } from "react";
import { SubjectsContainer } from "../Boards.style";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import InputComponent from "../../../Input/InputComponent";
import ProfileImg from "../../../../images/profileImg.svg";
import { Line } from "../../../Dashboard/Dashboard.style";
import HeaderPage from "../../../Header/HeaderPage";
import {
  DashboardHeaderWrapper,
  DashboardHeader,
} from "../../../Dashboard/Dashboard.style";
import { BoardsHeader, ButtonWrapper, Button } from "../Boards.style";
import CommonComponent from "../../../CommonComponent/CommonComponent";
import AddModalComponent from "../../../Modal/AddModalComponent";
import ExportModal from "../../../Modal/ExportModal";
import ImportModal from "../../../Modal/ImportModal";
// import { CommonComponentContainer } from '../../../CommonComponent/Commoncomponent.style';
import { subjects, chapter } from "../../../../Api/api";
// import AddBoard from '../../../Modal/AddBoard';
import axios from "axios";
import { useAuth } from "../../../../Auth/Auth";
import { ModuleSearch } from "../../ModuleSearch";

const subjectLabel = [
  [
    {
      label: "SUBJECT",
      placeholder: "Enter Subject",
      width: "100%",
      name: "name",
    },
  ],
  [
    {
      label: "THUMBNAIL",
      placeholder: "Enter an url",
      width: "100%",
      name: "thumbnail",
    },
  ],
];
const chapterLabel = [
  [
    {
      label: "CHAPTER",
      placeholder: "Enter Chapter",
      width: "100%",
      name: "name",
    },
  ],
  [
    {
      label: "THUMBNAIL",
      placeholder: "Enter an url",
      width: "100%",
      name: "thumbnail",
    },
  ],
];

const fixName = (name) => {
  const charArrays = ["?", "+", "/"];
  charArrays.forEach((character) => {
    name = name.replaceAll(character, "");
  });
  return name;
};

function Subjects() {
  const [switchLang, setSwitchLang] = useState(0);
  const navigation = useNavigate();
  const [modal, setModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const [type, setType] = useState("");
  const [allSubjects, setAllSubjects] = useState([]);
  const [allChapters, setAllChapters] = useState([]);
  const [filterChapters, setFilterChapters] = useState([]);
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const { readCookie, DisplaySnackbar, imgUrl } = useAuth();
  const [headerText, setHeaderText] = useState("");
  const [constAllChapters, setConstAllChapters] = useState([]);
  const [activeSubjectId, setActiveSubjectId] = useState("");
  const [render, setRender] = useState(false);
  const [formData, setFromData] = useState({
    name: "",
    thumbnail: "",
  });
  const [editId, setEditId] = useState("");
  const [edit, setEdit] = useState(false);
  const { classId, id, countryName, boardName, className, boardId } =
    useParams();
  const leftHeader = () => {
    return (
      <div>
        <span>Courses</span> {` > `}
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to={`/courses`}
        >
          <span>{countryName}</span>
        </Link>
        {` > `}
        <span
          onClick={() => sndBack("board")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {boardName}
        </span>
        {` > `}
        <span
          onClick={() => sndBack("board")}
          style={{ textDecoration: "none", cursor: "pointer", color: "black" }}
        >
          {className}
        </span>
        {` > `}
        <span>Subjects</span> {` > `}
      </div>
    );
  };
  useEffect(() => {
    axios
      .get(subjects.getSubjectsByClass(classId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        // console.log(res);
        setAllSubjects(
          res.data.data.course.subjects.filter(
            (item) => item.lang === (state?.lang || "english")
          )
        );
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  }, [render]);

  const handleDeleteSubjectByClass = (id) => {
    axios
      .delete(subjects.deleteSubjectByClass(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("Subject deleted", "success");
          handleupdateSubjectByClasslist(id);
        }
      })
      .catch((err) => {
        if (err.response) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleupdateSubjectByClasslist = (id) => {
    const filteredData = allSubjects.filter((i) => i._id !== id);
    setAllSubjects(filteredData);
  };

  const getChapters = (id) => {
    axios
      .get(subjects.getTopicBySubjects(id || activeSubjectId), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        let dc = switchLang === 0 ? false : true;
        setConstAllChapters(
          res.data.data.subject.chapters.filter(
            (item) => item.lang === (state.lang || "english")
          )
        );
        setAllChapters(
          res.data.data.subject.chapters.filter(
            (item) =>
              item.lang === (state.lang || "english") && item.trial === dc
          )
        );
        setFilterChapters(
          res.data.data.subject.chapters.filter(
            (item) =>
              item.lang === (state.lang || "english") && item.trial === dc
          )
        );
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleDeleteChapter = (id) => {
    axios
      .delete(chapter.deleteChapter(id), {
        headers: {
          token: `${readCookie("token")}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          DisplaySnackbar("chapter deleted", "success");
          handleupdatechapterlist(id);
        }
      })
      .catch((err) => {
        DisplaySnackbar(err.response.data.message, "error");
      });
  };

  const handleupdatechapterlist = (id) => {
    const filteredData = allChapters.filter((i) => i._id !== id);
    setAllChapters(filteredData);
  };

  const onChangeSubjects = (id) => {
    setActiveSubjectId(id);
    getChapters(id);
  };

  useEffect(() => {
    let dc = switchLang === 0 ? false : true;
    if (constAllChapters.length) {
      setAllChapters(constAllChapters.filter((item) => item.trial === dc));
      setFilterChapters(constAllChapters.filter((item) => item.trial === dc));
    }
  }, [switchLang]);

  useEffect(() => {
    if (allSubjects.length) {
      let id;
      if (state?.subjectId) {
        id = state?.subjectId;
        let index = allSubjects.findIndex((item) => item._id === id);
        setActiveTab(index);
      } else {
        id = allSubjects[0]._id;
      }

      onChangeSubjects(id);
    }
  }, [allSubjects]);
  useEffect(() => {
    if (!modal) {
      setFromData({
        name: "",
        thumbnail: "",
      });
    }
  }, [modal]);

  const createChapter = () => {
    if (!formData.name) {
      DisplaySnackbar("Atleast Add Name of Chapter", "error");
      return;
    }
    axios
      .post(
        chapter.addChapter,
        {
          name: formData.name,
          lang: state?.lang || "english",
          thumbnail: formData.thumbnail,
          subjectId: activeSubjectId,
          trial: switchLang === 1,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setAllChapters([...allChapters, { ...res.data.data.chapter }]);
        setModal(false);
        DisplaySnackbar("Chapter Added Successfully", "success");
        getChapters();
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const createSubject = () => {
    if (!formData.name) {
      DisplaySnackbar("Atleast Add Subject Name", "error");
      return;
    }
    axios
      .post(
        subjects.addSubjects,
        {
          name: formData.name,
          lang: state?.lang || "english",
          thumbnail: formData.thumbnail,
          courseId: classId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setAllSubjects([
          ...allSubjects,
          {
            name: formData.name,
            lang: state?.lang || "english",
            _id: res.data.data.subject._id,
            thumbnail: formData.thumbnail,
          },
        ]);
        setModal(false);
        DisplaySnackbar("Subject Added ", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const EditSubject = () => {
    if (!formData.name) {
      DisplaySnackbar("Add Subject Name First", "error");
      return;
    }
    axios
      .patch(
        subjects.editSubject(formData._id),
        {
          name: formData.name,
          lang: state?.lang || "english",
          thumbnail: formData.thumbnail,
          courseId: classId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setAllSubjects(
          allSubjects.map((item) =>
            item._id === id
              ? {
                  ...item,
                  name: formData.name,
                  lang: state?.lang || "english",
                  thumbnail: formData.thumbnail,
                }
              : item
          )
        );

        setModal(false);
        DisplaySnackbar("Subject Edit SuccessFull", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };
  const ToogleDisable = (id, disable) => {
    axios
      .patch(
        chapter.toogleDisabled(id),
        {
          disabled: !disable,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          setAllChapters(
            allChapters.map((item) =>
              item._id === id ? { ...item, disabled: !disable } : item
            )
          );
          setFilterChapters(
            filterChapters.map((item) =>
              item._id === id ? { ...item, disabled: !disable } : item
            )
          );
          DisplaySnackbar(
            `Chapter has been  ${disable ? "Enabled" : "Disabled"}`,
            "success"
          );
        }
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleDisableSubject = (id, disable) => {
    axios
      .patch(
        subjects.toogleDisableSubject(id),
        {
          disabled: !disable,
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        if (res.data.status === "success") {
          const subjectIndex = allSubjects.findIndex(
            (subject) => `${subject._id}` === id
          );
          allSubjects[subjectIndex].disabled = !disable;
          setAllSubjects([...allSubjects]);
          DisplaySnackbar(
            `Topic has been  ${disable ? "Enabled" : "Disabled"}`,
            "success"
          );
        }
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const EditChapter = () => {
    if (!formData.name) {
      DisplaySnackbar("Add Chapter Name First", "error");
      return;
    }
    axios
      .patch(
        chapter.EditChapter(editId),
        {
          name: formData.name,
          thumbnail: formData.thumbnail,
          lang: state?.lang || "english" || "english",
        },
        {
          headers: {
            token: `${readCookie("token")}`,
          },
        }
      )
      .then((res) => {
        setAllChapters(
          allChapters.map((item) =>
            item._id === editId
              ? { ...item, name: formData.name, thumbnail: formData.thumbnail }
              : item
          )
        );
        setModal(false);
        DisplaySnackbar("Edit Chapter SuccessFull", "success");
      })
      .catch((err) => {
        if (err.reponse) {
          DisplaySnackbar(err.response.data.message, "error");
        }
      });
  };

  const handleCreate = () => {
    if (headerText === "Add Chapter") {
      createChapter();
    } else if (headerText === "Add Subjects") {
      createSubject();
    }
  };

  const handleEdit = (id) => {
    const chapterToEdit = allChapters.find((item) => item._id === id);
    setEditId(id);
    setHeaderText("Edit Chapter");
    setModal(true);
    setEdit(true);
    setFromData(chapterToEdit || {});
  };
  const handleEditsubject = (id) => {
    const subjectToEdit = allSubjects.find((item) => item._id === id);
    setEditId(id);
    setEdit(true);
    setHeaderText("Edit Subject");
    setModal(true);
    setFromData(subjectToEdit || {});
  };
  const handleOnClick = (chapterId) => {
    let lang = state?.lang || "english";
    // console.log(lang);
    // /courses/:countryName/:id/:boardName/:className/:classId/:subjectName/:subjectId/:chapterName/:chapterId
    let subName = allSubjects.find((item) => item._id === activeSubjectId).name;
    let chapternam = allChapters
      .find((item) => item._id === chapterId)
      .name?.replace("?", "");
    navigation(
      `/courses/${fixName(countryName)}/${id}/${fixName(
        boardName
      )}/${boardId}/${fixName(className)}/${classId}/${fixName(
        subName
      )}/${activeSubjectId}/${fixName(chapternam)}/${chapterId}?trial=${
        switchLang === 1
      }`,
      {
        state: {
          lang: lang,
          trial: switchLang === 0 ? false : true,
        },
      }
    );
  };

  const sndBack = (type) => {
    if (type === "board") {
      navigation(`/courses/${fixName(countryName)}/${id}`, {
        state: {
          boardID: boardId,
        },
      });
    }
  };
  return (
    <SubjectsContainer>
      <DashboardHeaderWrapper minHeight="90px">
        <DashboardHeader>
          <div>
            <h1>Courses</h1>
          </div>

          <div>
            <ModuleSearch />
            <Link to="/profile">
              <img
                style={{
                  maxWidth: "100px",
                  height: "50px",
                  borderRadius: "50%",
                }}
                src={localStorage.getItem("imgUrl") || ProfileImg}
                alt="profileimg"
              />
            </Link>
          </div>
        </DashboardHeader>
        <DashboardHeader></DashboardHeader>
        <HeaderPage close={false} leftHeader={leftHeader()} />
      </DashboardHeaderWrapper>
      <Line />
      <BoardsHeader>
        <div className="firstClass">
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setType("SUBJECT");
              setHeaderText("Add Subjects");
              setEdit(false);
            }}
            className="child iconContainer"
          >
            <AddBoxOutlinedIcon className="icon" /> Add Subjects
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setModal(true);
              setType("chapter");
              setHeaderText("Add Chapter");
              setEdit(false);
            }}
            className="child iconContainer"
          >
            <AddBoxOutlinedIcon className="icon" /> Add Chapter
          </div>
        </div>
        <div className="secondClass" style={{ width: "80%" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setImportModal(true)}
            className="child iconContainer"
          >
            <SystemUpdateAltOutlinedIcon
              className="icon"
              style={{ transform: "rotate(180deg)" }}
            />{" "}
            Import
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => setExportModal(true)}
            className="child iconContainer"
          >
            <SystemUpdateAltOutlinedIcon className="icon" /> Export Csv
          </div>
          <div className="child" style={{ width: "fit-content" }}>
            <ButtonWrapper>
              <Button
                onClick={() => setSwitchLang(0)}
                first
                active={switchLang === 0}
              >
                Regular
              </Button>
              <Button
                onClick={() => setSwitchLang(1)}
                second
                active={switchLang === 1}
              >
                Trial
              </Button>
            </ButtonWrapper>
          </div>
        </div>
      </BoardsHeader>
      <Line />
      <CommonComponent
        handleEdit={handleEdit}
        ToogleDisable={ToogleDisable}
        showDisable={true}
        handleOnClick={handleOnClick}
        onTabChange={onChangeSubjects}
        type="subjects"
        tabActive={activeTab}
        tab={allSubjects}
        tabContent={allChapters}
        handleDeleteChapter={handleDeleteChapter}
        handleDeleteSubjectByClass={handleDeleteSubjectByClass}
        isBoard={false}
        isTopic={false}
        handleDisableSubject={handleDisableSubject}
        handleEditsubject={handleEditsubject}
        setType={setType}
        isChapter={true}
      />
      {modal && (
        <AddModalComponent
          buttonText={edit ? "Edit" : "Create"}
          headerText={headerText}
          EditChapter={EditChapter}
          formData={formData}
          setFormData={setFromData}
          setModal={setModal}
          label={type === "SUBJECT" ? subjectLabel : chapterLabel}
          EditSubject={EditSubject}
          modalType={type === "SUBJECT" ? "SUBJECT" : "CHAPTER"}
          handleCreate={handleCreate}
        />
      )}
      {exportModal && (
        <ExportModal
          setModal={setExportModal}
          sources={[
            {
              name: className,
              filename: `${countryName}-${boardName}`,
              api: `/course-contents/${classId}?lang=${
                state.lang || "english"
              }`,
            },
            {
              name: allSubjects.find((sub) => `${sub._id}` === activeSubjectId)
                ?.name,
              filename: `${countryName}-${boardName}-${className}`,
              api: `/subject-contents/${activeSubjectId}?lang=${
                state.lang || "english"
              }`,
            },
          ]}
        />
      )}
      {importModal && (
        <ImportModal
          setModal={setImportModal}
          sources={[
            {
              name: className,
              path: `${countryName} > ${boardName} > ${className}`,
              api: `/course-contents/${classId}`,
            },
            {
              name: allSubjects.find((sub) => `${sub._id}` === activeSubjectId)
                ?.name,
              path: `${countryName} > ${boardName} > ${className}`,
              api: `/subject-contents/${activeSubjectId}`,
            },
          ]}
        />
      )}
    </SubjectsContainer>
  );
}

export default Subjects;
