import React, { useEffect, useState } from "react";
import { CommonHeader } from "../Dashboard/TabComponent/TabComponent.style";
import Headerfeatures from "../Header/Headerfeatures";
import { Line } from "../Dashboard/Dashboard.style";
import { studentsSchedule } from "../Table/TableHeaders";
import { useParams } from "react-router-dom";
import TableComponent from "../Table/Table";
import axios from "axios";
import { studentSchedule } from "../../Api/api";
import { useAuth } from "../../Auth/Auth";
function StudentsSchedule(props) {
  //  console.log(props);
  const { readCookie } = useAuth();
  const [subjects, setSubjects] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tabContent, setTabContent] = useState([]);

  const { schoolId } = useParams();
  useEffect(() => {
    if (props.data?.email) {
      axios
        .get(
          studentSchedule.getSubjects(
            props.data.grade,
            props.data.section,
            schoolId
          ),
          {
            headers: {
              token: `${readCookie("token")}`,
            },
          }
        )
        .then((res) => {
          setSubjects(res.data.data.subjects);
        })
        .catch((err) => console.log(err));

      axios
        .get(
          studentSchedule.getTeachers(
            props.data.grade,
            props.data.section,
            schoolId
          ),
          {
            headers: {
              token: `${readCookie("token")}`,
            },
          }
        )
        .then((res) => {
          setTeachers(res.data.data.subjects);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [props]);

  useEffect(() => {
    if (!loading) {
      //  console.log(subjects);
      let temp = subjects.map((item, idx) => {
        let dt = teachers.find((tchr) => tchr.subjectId === item.subjectId);
        // console.log(item._id);
        //console.log(teachers);
        return {
          sno: idx + 1,
          subject: item.subject,
          assignedteacher: dt?.teacher?.name || "Not Assigned",
        };
      });
      setTabContent(temp);
    }
  }, [loading, teachers, subjects]);
  //console.log(subjects);
  return (
    <div>
      <CommonHeader>
        <div style={{ display: "flex", alignItems: "center" }}></div>
        <Headerfeatures sort={false} viewBy={false} />
      </CommonHeader>
      <Line />
      <TableComponent tabContent={tabContent} tabHeader={studentsSchedule} />
    </div>
  );
}

export default StudentsSchedule;
