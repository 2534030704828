import React from "react";
import {
  InputField,
  InputContainer,
  InputTag,
  Dropdown,
  Option,
} from "./Input.style";
import SearchIcon from "@material-ui/icons/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const InputComponent = (props) => {
  const { placeholder, search, type, val, setVal } = props;
  return (
    <InputContainer>
      <InputField
        placeholder={placeholder}
        onChange={(e) => setVal(e.target.value)}
        value={val}
        type={type}
      />
      {search && <SearchIcon className="searchIcon" />}
    </InputContainer>
  );
};

export default InputComponent;

export const Input = (props) => {
  // console.log(props);
  return (
    <div style={{ boxSizing: "border-box" }}>
      <InputTag
        {...props}
        disabled={props.disabled}
        onChange={(e) => props.handleOnChange(e)}
      />
    </div>
  );
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultiSelectDropDown = (props) => {
  // console.log(props);
  return (
    <div
      style={{
        width: "100%",
        height: props.data.height,
        boxSizing: "border-box",
      }}
    >
      <Select
        multiple
        style={{ width: "100%" }}
        displayEmpty
        value={props.value || []}
        name={props.data.name}
        onChange={props.handleOnChange}
        MenuProps={MenuProps}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>Placeholder</em>;
          }

          return selected.join(", ");
        }}
        //   MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Assign Classes</em>
        </MenuItem>
        {props.data.options.map((item) => (
          <MenuItem
            key={item.value}
            value={item.name}
            name={item.name}
            onClick={() =>
              props.handleOnClick(props.data.name, item.id, item.name)
            }
          >
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export const DropDown = (props) => {
  const { data, handleOnChange, value } = props;
  // console.log(data,value);
  return (
    <div
      style={{ width: "100%", height: data.height, boxSizing: "border-box" }}
    >
      {/* <InputTag {...props} onChange={(e)=>props.handleOnChange(e)} />
       */}

      <Dropdown
        disabled={props.disabled}
        value={value || ""}
        onChange={(e) => {
          handleOnChange(e);
        }}
        id={data.id || data.name}
        name={data.name}
      >
        {data?.options?.map((item, idx) => {
          return (
            <Option value={item.value} key={idx}>
              {item.name}
            </Option>
          );
        })}
      </Dropdown>
    </div>
  );
};

Input.deafultProps = {
  handleOnChange: (e) => {},
};
InputComponent.deafultProps = {
  placeholder: "",
  search: false,
  type: "text",
  val: "",
  setVal: () => {},
};
