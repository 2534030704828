import styled from 'styled-components';
export const TeacherProfileContainer=styled.div`

flex:1;

`

export const TeacherProfileContent=styled.div`
margin-top:16px;
display:flex;
.firstChild{
    display:flex;
    flex-direction:column;
    
}
.secondChild{
    flex:1;
    padding-left:100px;
   
}
.flx{
    display:flex;

    justify-content:center;
}
`
export const TeacherPageContainer=styled.div`


`

export const TeacherPageContent=styled.div`

`